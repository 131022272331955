import videojs from 'video.js'

export class MiddleSpacer extends videojs.getComponent('Spacer') {
  constructor(player, options = {}) {
    super(player, options)

    this.addClass('vjs-middle-spacer')
    this.addClass('ml-auto') // Bootstrap's "margin-left: auto"
  }
}

videojs.registerComponent('middleSpacer', MiddleSpacer)
