<template>
  <div :class="{ 'image-block': isShowMode }">
    <div
      v-if="isCreateMode || isEditMode"
      class="image__form-control image__image"
    >
      <AlchemyPictureSelect
        :key="localImage.id"
        :attachment="localImage"
        @[EMITTERS.IMAGE_SELECTED_EMITTER]="imageSelected"
      />
      <span class="image__file-error">{{ errors.image.join() }}</span>
    </div>

    <div v-if="isCreateMode || isEditMode" class="image__form-control">
      <TextAreaInput
        v-if="isCreateMode || isEditMode"
        v-model:value="localImage.description"
        :max-length="800"
        placeholder="Description"
        :error="errors.description.join()"
      />
      <ImageCopyright
        :selected-copyright="localImage.copyright"
        @[EMITTERS.IMAGE_COPYRIGHT_CHANGED]="copyrightChanged"
      />
      <div v-if="isShowMode" class="image__delete" @click="deleteImage">
        <div class="image__delete-icon" />
      </div>
    </div>

    <div
      v-if="isCreateMode || isEditMode"
      class="video-resources__control-buttons"
    >
      <button
        :disabled="isButtonDisabled"
        class="video-resources__control-button"
        @click="submitForm"
      >
        {{ submitButtonTitle }}
      </button>
      <button
        v-if="isEditMode"
        class="video-resources__control-button"
        @click="cancelUpdate"
      >
        Cancel
      </button>
    </div>

    <div
      v-if="isShowMode"
      ref="imageContainer"
      class="image__container"
      :class="maybeActive"
      @click="imageEdit"
    >
      <div v-if="!readOnlyMode" class="image__delete" @click.stop="deleteImage">
        <div class="image__delete-icon" />
      </div>
      <div class="image__thumbnail-container">
        <img
          src="../../../shared/assets/images/loading.gif"
          alt="loading"
          class="image-loader"
        />
        <img :src="image.url" class="image__thumbnail" />
      </div>
    </div>

    <ImageModal
      v-if="readOnlyMode && showModal"
      :image="image"
      @[EMITTERS.VIDEO_RESOURCES_CLOSE_IMAGE_MODAL_EMITTER]="hideImage"
    />
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'

import ImagesApiService from '../services/imagesApi.service'
import { VIDEO_RESOURCES_MODES as MODES } from '../../../shared/utils/view_options'
import { EMITTERS } from '../../../shared/utils/emitters'
import AlchemyPictureSelect from './AlchemyPictureSelect'
import AlchemyPicturesApiService from '../services/alchemyPicturesApi.service'
import ImageModal from './ImageModal'
import TextAreaInput from './lib/TextAreaInput'
import ImageCopyright from './ImageCopyright'

const EMPTY_IMAGE = {
  id: null,
  description: '',
  copyright: '',
  image: null,
  url: null,
  alchemy_picture_id: null,
}

export default {
  name: 'ImageComponent',
  components: {
    ImageCopyright,
    AlchemyPictureSelect,
    ImageModal,
    TextAreaInput,
  },
  props: {
    active: Number,
    image: {
      id: Number | null,
      description: String | null,
      copyright: String | null,
      alchemy_picture_id: Number | null,
      image: File | null,
      url: String | null,
    },
    mode: {
      type: String,
    },
    readOnlyMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    EMITTERS.IMAGE_CREATED_EMITTER,
    EMITTERS.IMAGE_SELECTED_EMITTER,
    EMITTERS.IMAGE_EDIT_EMITTER,
    EMITTERS.IMAGE_UPDATED_EMITTER,
    EMITTERS.IMAGE_DELETED_EMITTER,
    EMITTERS.IMAGE_CANCEL_EMITTER,
    EMITTERS.IMAGE_COPYRIGHT_CHANGED,
  ],
  data() {
    return {
      EMITTERS,
      localImage: this.image || EMPTY_IMAGE,
      isButtonDisabled: false,
      copyrightType: 'none',
      errors: {
        description: [],
        image: [],
      },
      showModal: false,
    }
  },
  computed: {
    isEditMode() {
      return this.mode === MODES.EDIT
    },
    isCreateMode() {
      return this.mode === MODES.CREATE
    },
    isShowMode() {
      return this.mode === MODES.SHOW
    },
    submitButtonTitle() {
      return 'Save'
    },
    maybeActive() {
      return this.active === this.image.id ? 'active' : ''
    },
  },
  mounted() {
    if (this.isShowMode) {
      this.openImageFromUrl()
    }
  },
  methods: {
    openImageFromUrl() {
      const imageToShow = this.getImageIdFromUrlParams()

      if (imageToShow && parseInt(imageToShow) === this.image.id) {
        this.showModal = true
      }
    },
    getImageIdFromUrlParams() {
      const urlParams = new URLSearchParams(window.location.search)
      return urlParams.get('video-resources-image')
    },
    updateImage() {
      if (this.isCreateMode) return
      this.isButtonDisabled = true

      ImagesApiService.updateImage(this.localImage, this.optional_params)
        .then((response) => {
          this.$emit(EMITTERS.IMAGE_UPDATED_EMITTER, response.data.image)
          this.clearForm()
          this.clearErrors()
          this.enableButton()
        })
        .catch((e) => {
          this.updateErrors(e)
          this.enableButton()
        })
        .finally(() => {
          this.emitter.emit(EMITTERS.AFTER_IMAGE_CHANGE_EMITTER)
        })
    },
    createImage() {
      this.isButtonDisabled = true

      ImagesApiService.createImage(this.localImage, this.optional_params)
        .then((response) => {
          this.$emit(EMITTERS.IMAGE_CREATED_EMITTER, response.data.image)
          this.clearForm()
          this.enableButton()
          this.clearErrors()
        })
        .catch((e) => {
          this.enableButton()
          this.updateErrors(e)
        })
        .finally(() => {
          this.emitter.emit(EMITTERS.AFTER_IMAGE_CHANGE_EMITTER)
        })
    },
    deleteImage() {
      ImagesApiService.deleteImage(this.localImage, this.optional_params)
        .then(() => {
          this.$emit(EMITTERS.IMAGE_DELETED_EMITTER)
        })
        .catch(console.error)
    },
    clearForm() {
      Object.assign(this.localImage, EMPTY_IMAGE)
    },
    clearErrors() {
      this.errors.description = []
      this.errors.image = []
    },
    submitForm() {
      if (!this.isCopyrightValid()) {
        this.emitter.emit(EMITTERS.IMAGE_COPYRIGHT_SHOW_ERROR)
        return
      }

      if (this.isCreateMode) this.createImage()
      if (this.isEditMode) this.updateImage()
    },
    updateErrors(e) {
      this.errors.description = e.response.data.errors.description || []
      this.errors.image = e.response.data.errors.image || []
    },
    imageSelected(alchemyPictureId) {
      AlchemyPicturesApiService.selectImage(alchemyPictureId)
        .then((response) => {
          this.localImage.description = response.data.picture.description
          this.localImage.alchemy_picture_id = response.data.picture.id
          this.localImage.url = response.data.picture.url
          this.enableButton()
        })
        .catch(console.error)
    },
    imageEdit() {
      if (this.readOnlyMode) return this.showImage()

      const imageProps = cloneDeep(this.localImage)
      this.$emit(EMITTERS.IMAGE_EDIT_EMITTER, imageProps)
    },
    showImage() {
      this.showModal = true
    },
    hideImage() {
      this.showModal = false
      window.history.replaceState(
        {},
        document.title,
        document.location.pathname
      )
    },
    enableButton() {
      this.isButtonDisabled = false
    },
    cancelUpdate() {
      this.$emit(EMITTERS.IMAGE_CANCEL_EMITTER)
    },
    copyrightChanged(obj) {
      this.localImage.copyright = obj.copyright
      this.copyrightType = obj.type
    },
    isCopyrightValid() {
      const isCustom = this.copyrightType === 'custom'
      if (isCustom) return this.localImage.copyright.length >= 1

      return true
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../shared/assets/styles/video_resources';

.image-block {
  display: flex;
  flex: 0 0 calc(20% - 14px);
}

.image__form-control {
  display: flex;
  width: 517px;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 6px;
}

.image__form-button {
  padding-top: 8px;
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
}

.image__button {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #5c5c5c;
  padding: 3px 50px;

  &:disabled {
    background-color: #f1f3f4;
  }

  &:hover {
    background-color: #fafffa;
  }
}

.image__image {
  padding-bottom: 8px;
}

.image__delete {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.image__delete-icon {
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Ccircle cx='12' cy='12' r='12' fill='black' fill-opacity='0.6'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.0651 7.80995C17.3254 7.5496 17.3254 7.12749 17.0651 6.86714C16.8047 6.60679 16.3826 6.60679 16.1222 6.86714L11.966 11.0233L7.80995 6.86724C7.5496 6.60689 7.12749 6.60689 6.86714 6.86724C6.60679 7.12759 6.60679 7.5497 6.86714 7.81005L11.0232 11.9661L6.86852 16.1209C6.60817 16.3812 6.60817 16.8033 6.86852 17.0637C7.12887 17.324 7.55098 17.324 7.81133 17.0637L11.966 12.909L16.1209 17.0638C16.3812 17.3241 16.8033 17.3241 17.0637 17.0638C17.324 16.8034 17.324 16.3813 17.0637 16.121L12.9089 11.9661L17.0651 7.80995Z' fill='white'/%3E%3C/svg%3E");
}

.image__file-error {
  position: absolute;
  bottom: -8px;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #d35163;
}

.image__container {
  position: relative;
  color: #323232;
  cursor: pointer;

  &.active,
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  .image__delete {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.image__thumbnail {
  object-fit: cover;
  aspect-ratio: 1;
  width: 100%;
  border-radius: 4px;
}
.image-loader {
  display: none;
}
@media (max-width: 1030px) {
  .image__container {
    width: 130px;
    height: 130px;
  }
  .image__thumbnail {
    width: 130px;
    height: 130px;
  }
}
@media (max-width: 991px) {
  .image__container {
    width: calc(33vw - 10px);
    height: calc(33vw - 10px);
  }
  .image__thumbnail-container {
    width: calc(33vw - 10px);
    height: calc(33vw - 10px);
    position: relative;
  }
  .image__thumbnail {
    width: calc(33vw - 10px);
    height: calc(33vw - 10px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }
  .image-loader {
    display: block;
    width: calc(33vw - 10px);
    height: calc(33vw - 10px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
}
</style>
