<template :class="theme">
  <div
    v-if="shouldShowStep"
    class="tutorial-step"
    :data-step="currentStep.number"
    :style="{
      width: tutorialWidth,
      'margin-top': topMargin,
      'margin-left': leftMargin,
    }"
  >
    <div class="tutorial-title">
      {{ currentStep.title }}
    </div>

    <div class="tutorial-actions">
      <a
        v-for="action in currentStep.actions"
        :key="action.number"
        class="tutorial-action"
        :class="{
          active: action.active,
          inactive: !action.active,
          'blue-action': isBlueButton(action),
        }"
        @click="handleAction(action)"
      >
        {{ action.label }}
      </a>
    </div>
  </div>
</template>

<script>
import { THEMES } from '../../../shared/utils/view_options'
import { STEPS, ACTION_TYPES } from '../../../shared/utils/tutorial_steps'
import { EMITTERS } from '../../../shared/utils/emitters'

export default {
  name: 'TutorialStepComponent',
  props: {
    stepPositions: {
      type: Array,
      required: true,
    },
    stepNumber: {
      type: Number,
      required: true,
    },
  },
  emits: [EMITTERS.CHANGE_TUTORIAL_STEP_EMITTER],
  data() {
    return {
      theme: THEMES.DARK,

      ACTION_TYPES,
      BOOKMARK_LAST_STEP: 10,
      NOTES_LAST_STEP: 21,
      DEFAULT_WIDTH: 306,
      DEFAULT_TOP_MARGIN: 9,
      DEFAULT_LEFT_MARGIN: 0,
    }
  },
  computed: {
    currentStep() {
      return STEPS.find((step) => step.number === this.stepNumber)
    },
    tutorialWidth() {
      return `${this.currentStep.width || this.DEFAULT_WIDTH}px`
    },
    shouldShowStep() {
      return this.stepPositions.includes(this.stepNumber)
    },
    topMargin() {
      return `${this.currentStep.topMargin || this.DEFAULT_TOP_MARGIN}px`
    },
    leftMargin() {
      return `${this.currentStep.leftMargin || this.DEFAULT_LEFT_MARGIN}px`
    },
  },
  methods: {
    handleAction(action) {
      switch (action.type) {
        case ACTION_TYPES.COMPLETE:
        case ACTION_TYPES.SKIP:
          this.skipTutorial()
          break
        case ACTION_TYPES.NEXT:
          this.nextTutorialStep()
          break
        case ACTION_TYPES.GO_TO_DASHBOARD:
          this.skipTutorial()
          this.goToDashboard()
          break
        default:
          console.error('Unknown action type detected', action)
      }
    },
    goToDashboard() {
      const closedAt = new Date().getTime()
      localStorage.setItem('dashboardTooltipClosedAt', closedAt)

      const url =
        this.currentStep.number === this.NOTES_LAST_STEP
          ? this.dataset.bookmarksurl.replace('/bookmarks', '/notes')
          : this.dataset.bookmarksurl
      // Firefox executes window.location.href change before skipTutorial emitter event
      setTimeout(() => window.open(url, '_blank').focus(), 100)
    },
    nextTutorialStep() {
      this.$emit(EMITTERS.CHANGE_TUTORIAL_STEP_EMITTER, this.currentStep)
    },
    skipTutorial() {
      let step =
        this.currentStep.number > this.BOOKMARK_LAST_STEP
          ? this.NOTES_LAST_STEP
          : this.BOOKMARK_LAST_STEP

      this.$emit(EMITTERS.CHANGE_TUTORIAL_STEP_EMITTER, {
        number: step + 1,
      })
    },
    isBlueButton(action) {
      if (!action.active) return false
      const BLUE_ACTIONS = [ACTION_TYPES.NEXT, ACTION_TYPES.COMPLETE]
      return BLUE_ACTIONS.includes(action.type)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../shared/assets/styles/global';
.tutorial-step {
  position: absolute;

  border: 1px solid #6998ae;
  box-shadow: 0 4px 30px rgba(23, 24, 28, 0.6);
  background: #2b2834;

  border-radius: 6px;
  padding: 16px 12px 20px 16px;

  z-index: 999;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 19px;
    right: 0;
    top: -28px;
    width: 0;
    height: 0;
    border: 14px solid transparent;
    border-bottom-color: #6998ae;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 19px;
    right: 0;
    top: -27px;
    width: 0;
    height: 0;
    border: 14px solid transparent;
    border-bottom-color: #2b2834;
  }
}

.tutorial-step[data-step='1'] {
  width: 306px;
}

.tutorial-step[data-step='6'] {
  &::before {
    left: -28px;
    top: 31px;
    border: 14px solid transparent;
    border-right-color: #6998ae;
  }

  &:after {
    left: -27px;
    top: 31px;
    border: 14px solid transparent;
    border-right-color: #2b2834;
  }
}

.tutorial-step[data-step='7'],
.tutorial-step[data-step='10'] {
  right: 8px;

  &::before {
    left: 249px;
  }

  &:after {
    left: 249px;
  }
}

.tutorial-step[data-step='8'] {
  right: 8px;

  &::before {
    left: 236px;
  }

  &:after {
    left: 236px;
  }
}

.tutorial-step[data-step='15'],
.tutorial-step[data-step='18'],
.tutorial-step[data-step='20'],
.tutorial-step[data-step='21'] {
  &::before {
    left: -28px;
    top: 125px;
    border: 14px solid transparent;
    border-right-color: #6998ae;
  }

  &:after {
    left: -27px;
    top: 125px;
    border: 14px solid transparent;
    border-right-color: #2b2834;
  }
}
.tutorial-step[data-step='18'] {
  &::before {
    top: 10px;
  }

  &:after {
    top: 10px;
  }
}
.tutorial-step[data-step='20'],
.tutorial-step[data-step='21'] {
  &::before {
    top: 10px;
  }

  &:after {
    top: 10px;
  }
}
.tutorial-step[data-step='19'] {
  &::before {
    left: 245px;
  }

  &:after {
    left: 245px;
  }
}
.tutorial-step[data-step='11'],
.tutorial-step[data-step='16'],
.tutorial-step[data-step='17'] {
  &::before {
    left: 10px;
    top: 247px;
    border: 14px solid transparent;
    border-right-color: #6998ae;
    transform: rotate(-90deg);
  }

  &:after {
    left: 10px;
    top: 246px;
    border: 14px solid transparent;
    border-right-color: #2b2834;
    transform: rotate(-90deg);
  }
}
.tutorial-step[data-step='11'] {
  &::before {
    left: 15px;
    top: 127px;
  }

  &:after {
    left: 15px;
    top: 126px;
  }
}
.tutorial-step[data-step='17'] {
  &::before {
    top: 147px;
  }

  &:after {
    top: 146px;
  }
}

.tutorial-title {
  color: $white;
}

.tutorial-actions {
  display: flex;
  justify-content: left;
  margin-top: 16px;
}

.tutorial-action {
  padding: 7px 16px;
  background: #444050;
  box-shadow: 0 2px 10px rgba(73, 73, 73, 0.1);
  border-radius: 4px;
  margin-right: 8px;
  font-size: 14px;
  &.active {
    color: $white;
    cursor: pointer;
  }
  &.inactive {
    color: #8f8a9b;
    background: #444050;
    cursor: not-allowed;
  }
  &.blue-action {
    cursor: pointer;
    background: #6998ae;
    color: $white;
  }
}
</style>
