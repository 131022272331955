<template>
  <RichText
      v-model:content="content"
      @changed="updateBodyInput"
      :content-updated="true"
      :theme="THEMES.LIGHT"
      :extendedToolbar="true"
      placeholder="Enter an article"
      :toolbox-on-top="true"
  />
</template>

<script>
import { EMITTERS } from '../../shared/utils/emitters'
import { THEMES } from '../../shared/utils/view_options'
import RichText from '../../shared/components/RichText'

export default {
  name: 'AdminArticleAppComponent',
  components: {
    RichText,
  },
  data() {
    return {
      EMITTERS,
      THEMES,
      inputId: this.optional_params.inputId,
      content: this.optional_params.content,
    }
  },
  created() {
  },
  methods: {
    updateBodyInput(content) {
      document.querySelector(`#${this.inputId}`).value = content
    }
  },
}
</script>

<style lang="scss" scoped>
  .rich-text-container {
    width: 736px;
    border: 1px solid grey;
  }
</style>
