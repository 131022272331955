<template>
  <TextInput
    v-if="isCreateMode || isEditMode"
    v-model:value="localEbook.name"
    placeholder="Title"
    :max-length="80"
    :error="errors.name.join()"
  />

  <TextInput
    v-if="isCreateMode || isEditMode"
    v-model:value="localEbook.author"
    placeholder="Author"
    :max-length="80"
    :error="errors.author.join()"
  />

  <div
    v-if="isCreateMode || isEditMode"
    class="ebook__form-control document__document"
  >
    <div class="form-control">
      <DragAndDrop
        :key="localEbook.ebook_id"
        ref="ebookCoverControl"
        :attachment="localEbook.cover"
        theme="portrait"
        hint="(.jpg, .jpeg, .png)"
        placeholder="Upload cover"
        @[EMITTERS.FILE_SELECTED_EMITTER]="ebookCoverSelected"
      />
      <span
        class="document__file-error video-resources__form-error ebook__file-error"
      >
        {{ errors.cover.join() }}
      </span>
    </div>

    <div class="form-control">
      <DragAndDrop
        :key="localEbook.document"
        :attachment="localEbook.document"
        theme="portrait"
        :progress="progress"
        :uploading="uploading"
        hint="Max 100MB allowed"
        placeholder="Upload from device"
        @[EMITTERS.FILE_SELECTED_EMITTER]="ebookSelected"
      />
      <span
        class="document__file-error video-resources__form-error ebook__file-error"
      >
        {{ errors.document.join() }}
      </span>
    </div>

    <div class="ebook__drag-drop-separator">
      {{ $vueTranslate('admin.ebook.separator') }}
    </div>

    <div class="form-control">
      <div class="drag-drop-area" @click="openEbooksDialog">
        <div class="upload-icon"></div>
        <span class="file-info__text">
          {{ $vueTranslate('admin.ebook.choose_btn') }}
        </span>
      </div>
    </div>
  </div>

  <div v-if="isCreateMode || isEditMode" class="video-resources__form-control">
    <textarea
      v-model="localEbook.description"
      class="ebook__form-input"
      rows="7"
      :maxlength="800"
      placeholder="Description"
      @keydown.enter.exact.stop
      @keyup.enter.exact.stop
      @keydown.enter.shift.exact.prevent
    />
    <span class="ebook__error">{{ errors.description.join() }}</span>
    <span class="ebook__counter">
      {{ localEbook.description.length }}/{{ 800 }}
    </span>
  </div>

  <input
    v-if="isCreateMode || isEditMode"
    :id="ebookId()"
    ref="ebookIdInput"
    type="hidden"
    @change="handleEbookInputChange"
  />

  <div
    v-if="isCreateMode || isEditMode"
    class="document__form-buttons video-resources__control-buttons"
  >
    <button
      :disabled="isButtonDisabled"
      class="video-resources__control-button"
      @click="submitForm"
    >
      {{ submitButtonTitle }}
    </button>

    <button
      v-if="isEditMode"
      class="video-resources__control-secondary-button"
      @click="cancelUpdate"
    >
      Cancel
    </button>
  </div>

  <div
    v-if="isShowMode"
    ref="ebookContainer"
    class="document__container ebook__container"
    :class="[maybeActive]"
    @click="ebookEdit"
  >
    <div v-if="ebook.cover.thumbnailUrl" class="ebook__thumbnail-container">
      <img :src="ebook.cover.thumbnailUrl" class="ebook__item-thumbnail" />
    </div>
    <div class="ebook__info">
      <span
        :class="{ document__text__wrapped: readOnlyMode }"
        class="ebook__title"
      >
        {{ fileTitle }}
      </span>
      <span
        :class="{ document__text__wrapped: readOnlyMode }"
        class="ebook__author"
      >
        {{ ebook.author }}
      </span>
      <span
        v-if="!readOnlyMode"
        :class="{ document__text__wrapped: readOnlyMode }"
        class="ebook__size"
      >
        {{ ebook.document.file_size }}
      </span>
    </div>
    <div class="ebook__controls">
      <a v-if="!readOnlyMode" @click.stop="openPreview">
        <PreviewIcon />
      </a>

      <div v-if="!readOnlyMode" @click.stop="openDialog">
        <div class="document__delete-icon" />
      </div>
      <a
        v-if="readOnlyMode"
        :href="localEbook.document.downloadLink"
        download
        class="video-resources_download"
        @click.stop
      >
        <DownloadIcon v-if="readOnlyMode" />
        <div class="video-resources__filesize">
          {{ localEbook.document.file_size }}
        </div>
      </a>
    </div>

    <ConfirmationDialog
      v-if="showDialog"
      :theme="THEMES.LIGHT"
      :entity="ebook"
      :confirmation-text="$vueTranslate('admin.ebook.confirmation_text')"
      :delete-function="EBOOK_DELETE_FUNCTION"
      :on-deleted-emitter="EMITTERS.EBOOK_DELETED_EMITTER"
      @[EMITTERS.CLOSE_DIALOG_EMITTER]="closeDialog"
      @[EMITTERS.EBOOK_DELETED_EMITTER]="ebookDeleted"
    />
  </div>
  <PreviewDialog
    v-if="showPreview"
    :theme="theme"
    :entity="ebook"
    @[EMITTERS.CLOSE_DIALOG_EMITTER]="closePreview"
  />
</template>
<script>
import { cloneDeep } from 'lodash'

import EbooksApiService from '../services/ebooksApi.service'
import {
  THEMES,
  VIDEO_RESOURCES_MODES as MODES,
} from '../../../shared/utils/view_options'
import { EMITTERS } from '../../../shared/utils/emitters'
import DragAndDrop from './DragAndDrop'
import DownloadIcon from '../../../shared/components/icons/Download'
import PreviewIcon from '../../../shared/components/icons/Preview'
import TextInput from './lib/TextInput'
import AlchemyEbooksApiService from '../services/alchemyEbooksApi.service'
import ConfirmationDialog from '../../../study_tools/components/ConfirmationDialog'
import PreviewDialog from './PreviewDialog'

const EMPTY_EBOOK = {
  id: null,
  ebook_id: null,
  name: '',
  author: '',
  description: '',
  document: File,
  cover: File,
}

export default {
  name: 'EbookComponent',
  components: {
    DragAndDrop,
    DownloadIcon,
    PreviewIcon,
    TextInput,
    ConfirmationDialog,
    PreviewDialog,
  },
  props: {
    theme: {
      type: String,
      default: THEMES.LIGHT,
    },
    active: {
      type: Number,
      default: null,
    },
    ebook: {
      id: Number | null,
      ebook_id: Number | null,
      name: String,
      author: String,
      description: String,
      cover: File,
      document: File,
    },
    mode: {
      type: String,
      default: MODES.SHOW,
    },
    readOnlyMode: {
      type: Boolean,
      default: false,
    },
    previewMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    EMITTERS.EBOOK_CREATED_EMITTER,
    EMITTERS.EBOOK_EDIT_EMITTER,
    EMITTERS.EBOOK_UPDATED_EMITTER,
    EMITTERS.EBOOK_DELETED_EMITTER,
    EMITTERS.EBOOK_CANCEL_EMITTER,
  ],
  data() {
    return {
      EMITTERS,
      localEbook: this.ebook,
      isButtonDisabled: false,
      showDialog: false,
      showPreview: false,
      progress: 0,
      uploading: false,
      THEMES: THEMES,
      EBOOK_DELETE_FUNCTION: EbooksApiService.deleteEbook,
      errors: {
        name: [],
        author: [],
        description: [],
        document: [],
        cover: [],
      },
    }
  },
  computed: {
    isEditMode() {
      return this.mode === MODES.EDIT
    },
    isCreateMode() {
      return this.mode === MODES.CREATE
    },
    isShowMode() {
      return this.mode === MODES.SHOW
    },
    submitButtonTitle() {
      return this.isCreateMode
        ? this.$vueTranslate('admin.ebook.add_btn')
        : this.$vueTranslate('admin.ebook.update_btn')
    },
    maybeActive() {
      return this.active === this.ebook.id ? 'active' : ''
    },
    fileTitle() {
      if (!this.readOnlyMode) return this.ebook.name
      return this.ebook.name.replace(/\..+/gi, '')
    },
    fileFormat() {
      if (!this.ebook.document.name) return ''

      return this.ebook.document.name.match(/\.\w+/gi).pop().replace('.', '')
    },
  },
  mounted() {
    window.addEventListener('ebookIdChanged', this.handleEbookInputChange)
  },
  methods: {
    async updateEbook() {
      if (this.isCreateMode) return
      this.isButtonDisabled = true
      this.uploading = true

      try {
        const response = await EbooksApiService.updateEbook(
          this.localEbook,
          this.optional_params,
          (progress) => {
            this.progress = progress
          }
        )
        this.clearForm()
        this.clearErrors()
        this.$emit(EMITTERS.EBOOK_UPDATED_EMITTER, response.ebook)
      } catch (error) {
        this.updateErrors(error)
      } finally {
        this.uploading = false
        this.enableButton()
      }
    },
    async createEbook() {
      this.isButtonDisabled = true
      this.uploading = true

      try {
        const response = await EbooksApiService.createEbook(
          this.localEbook,
          this.optional_params,
          (progress) => {
            this.progress = progress
          }
        )
        this.clearForm()
        this.clearErrors()
        this.$emit(EMITTERS.EBOOK_CREATED_EMITTER, response.ebook)
      } catch (error) {
        this.updateErrors(error)
      } finally {
        this.uploading = false
        this.enableButton()
      }
    },
    ebookDeleted(ebook) {
      this.$emit(EMITTERS.EBOOK_DELETED_EMITTER, ebook)
    },
    closeDialog() {
      this.showDialog = false
    },
    openDialog() {
      this.showDialog = true
    },
    openPreview() {
      this.showPreview = true
    },
    closePreview() {
      this.showPreview = false
    },
    clearForm() {
      this.$refs.ebookCoverControl.reloadThumbnail()
      Object.assign(this.localEbook, EMPTY_EBOOK)
    },
    clearErrors() {
      this.errors.name = []
      this.errors.author = []
      this.errors.description = []
      this.errors.cover = []
      this.errors.document = []
    },
    submitForm() {
      if (this.isCreateMode) this.createEbook()
      if (this.isEditMode) this.updateEbook()
    },
    updateErrors(e) {
      this.errors.name = e.response.data.errors.name || []
      this.errors.author = e.response.data.errors.author || []
      this.errors.description = e.response.data.errors.description || []
      this.errors.cover = e.response.data.errors.cover || []
      this.errors.document = e.response.data.errors.file || []
    },
    ebookSelected(file) {
      this.localEbook.document = file
    },
    ebookCoverSelected(file) {
      this.localEbook.cover = file
    },
    ebookEdit() {
      if (this.readOnlyMode) return this.openPreview()

      const ebookProps = cloneDeep(this.localEbook)
      this.$emit(EMITTERS.EBOOK_EDIT_EMITTER, ebookProps)
    },
    enableButton() {
      this.isButtonDisabled = false
    },
    cancelUpdate() {
      this.$emit(EMITTERS.EBOOK_CANCEL_EMITTER)
    },
    openEbooksDialog() {
      Alchemy.openDialog(this.ebooksUrl(), this.modalOptions())
    },
    ebooksUrl() {
      return `/admin/ebooks?form_field_id=${this.ebookId()}`
    },
    ebookId() {
      return `ebook_id_attachment_${this.localEbook.id}`
    },
    modalOptions() {
      return {
        title: 'Choose ebook from library',
        size: '790x590',
        padding: false,
      }
    },
    handleEbookInputChange(event) {
      if (
        !this.$refs.ebookIdInput ||
        !event.detail[0] ||
        this.$refs.ebookIdInput.id !== event.detail[0].id
      ) {
        return
      }

      this.localEbook.ebook_id = event.detail.val()

      AlchemyEbooksApiService.selectEbook(this.localEbook.ebook_id)
        .then((response) => {
          Object.assign(this.localEbook, response.data)

          this.clearErrors()
          this.enableButton()
        })
        .catch(console.error)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../shared/assets/styles/video_resources';
@import '../../../shared/assets/styles/document';

.ebook__form-input {
  font-size: 14px;
  line-height: 18px;
  color: #323232;
  width: 100%;

  &::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #a6a6a6;
  }
}

.ebook__counter {
  font-size: 12px;
  line-height: 12px;
  text-align: right;
  color: #a6a6a6;
  font-family: 'DM Sans';
  font-style: normal;
}
.ebook__error {
  position: absolute;
  top: 36px;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #d35163;
}

.ebook__form-control {
  display: flex;
  gap: 16px;
  flex-direction: row;
  padding-bottom: 16px;

  .drag-drop-area {
    background-image: url("data:image/svg+xml,%3Csvg width='133' height='133' viewBox='0 0 137 137' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='133' height='133' rx='6' stroke='%23A1A1A1' stroke-linecap='round' stroke-dasharray='5 7'/%3E%3C/svg%3E%0A");
    cursor: pointer;
    width: 133px;
    height: 133px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 10px;
  }

  .file-info__text {
    max-width: 133px;
    max-height: 133px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #a1a1a1;
  }

  .upload-icon {
    width: 22px;
    height: 17px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='17' viewBox='0 0 22 17' fill='none'%3E%3Cpath d='M18.7928 10.625V13.4583C18.7928 13.8341 18.6042 14.1944 18.2686 14.4601C17.933 14.7257 17.4779 14.875 17.0033 14.875H4.47697C4.00238 14.875 3.54722 14.7257 3.21162 14.4601C2.87603 14.1944 2.6875 13.8341 2.6875 13.4583V10.625' stroke='%23A1A1A1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6.26562 7.08594L10.7393 10.6276L15.213 7.08594' stroke='%23A1A1A1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10.7344 10.625V2.125' stroke='%23A1A1A1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  }
}

.ebook__drag-drop-separator {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #5c5c5c;
  align-self: center;
}

.ebook__item-thumbnail {
  width: 64px;
  height: 80px;
  flex-shrink: 0;
  object-fit: cover;
  border-radius: 4px;
}
.document__file-error.ebook__file-error {
  top: auto;
  width: 140px;
  line-height: 10px;
}

.document__container.ebook__container {
  height: 80px;
  padding: 0 6px 0 0;
  margin-top: 12px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  border: 0;
  justify-content: flex-start;
  width: 49%;
  border-radius: 8px;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.ebook__info {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  height: 80px;
}

.ebook__size {
  padding-top: 26px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #a6a6a6;
}

.ebook__author {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #5c5c5c;
  height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 190px;
}

.ebook__title {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #323232;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 190px;
}

@media (max-width: 383px) {
  .ebook__title,
  .ebook__author {
    width: 150px;
  }
}

.light {
  .ebook__size {
    color: #a6a6a6;
  }
  .ebook__author {
    color: #5c5c5c;
  }

  .ebook__title {
    color: #323232;
  }
}

.dark {
  .ebook__size {
    color: #a6a6a6;
  }
  .ebook__author {
    color: #9c9c9c;
  }

  .ebook__title {
    color: #ffffff;
  }
}

.ebook__controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  padding-top: 6px;
  margin-left: auto;
  height: 80px;

  .document__delete-icon {
    margin: 12px 0;
    width: 18px;
  }
}

.ebook__thumbnail-container {
  padding: 0;
  height: 80px;
}

.ebook__download-button {
  border: 1px solid #ffffff;
}
</style>
