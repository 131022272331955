import { utcToZonedTime } from 'date-fns-tz'

const DEFAULT_TIMEZONE = 'America/Vancouver'
const VALID_REGIONS = [
  'Africa',
  'America',
  'Asia',
  'Australia',
  'Europe',
  'Indian',
  'Pacific',
]

export function formatTime(date, timezone) {
  return utcToZonedTime(date, timezone)
}

export function getCurrentTimeZone() {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return isValidTimezone(timezone) ? timezone : DEFAULT_TIMEZONE
}

export function isValidTimezone(timezone) {
  return VALID_REGIONS.some((region) => timezone.startsWith(region))
}

export function formatDateToString(date) {
  const totalMonth = date.getMonth() + 1
  const formattedMonth = ('0' + totalMonth).slice(-2)
  const day = ('0' + date.getDate()).slice(-2)
  return date.getFullYear() + '-' + formattedMonth + '-' + day
}

export function fetchTimezones() {
  return Intl.supportedValuesOf('timeZone').filter((timezone) =>
    isValidTimezone(timezone)
  )
}

export function fetchTimezone(timezoneName) {
  return {
    region: timezoneName.split('/')[0],
    city: timezoneName.split('/').slice(-1)[0],
  }
}

export function currentDate() {
  const date = new Date()

  return formatDateToString(date)
}
