import {
  createRouter,
  createWebHashHistory,
  createMemoryHistory,
} from 'vue-router'
function createAppRouter(
  routes_list,
  options = { redirect: true, history: true }
) {
  let routes = []
  if (options.redirect) {
    const default_route = routes_list[0].path
    routes = routes_list.concat([{ path: '/', redirect: default_route }])
  } else {
    routes = routes_list
  }

  return createRouter({
    history: options.history ? createWebHashHistory() : createMemoryHistory(),
    routes: routes,
  })
}

export default createAppRouter
