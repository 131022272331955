<template>
  <div class="social-hint__wrapper">
    <div ref="social-hint" class="social-hint share-btn">
      <a :href="twitterShareUrl()">
        <XTwitterIcon />
      </a>

      <a data-id="in">
        <i data-feather="linkedin"></i>
      </a>

      <a class="copy_current_text" @click="copyCurrentText">
        <i data-feather="copy"></i>
      </a>
    </div>

    <div ref="copied-hint" class="copy_current_url-tooltip__container">
      <span v-if="showCopiedTooltip">Copied!</span>
    </div>
  </div>
</template>

<script>
import XTwitterIcon from './icons/XTwitterIcon'

require('../utils/twitter')

const SHARE_URLS = {
  twitter: 'https://twitter.com/intent/tweet',
}
export default {
  name: 'SocialHint',
  components: { XTwitterIcon },
  data() {
    return {
      selectedText: '',
      showCopiedTooltip: false,
    }
  },
  mounted() {
    this.initializeSocialHint()
  },
  methods: {
    twitterShareUrl() {
      return `${SHARE_URLS.twitter}?text=${encodeURIComponent(
        this.sharedText(this.selectedText)
      )}`
    },
    sharedText(selectedText) {
      return `"${selectedText}"; \nShared from the article: ${this.currentUrl()}`
    },
    copyCurrentText() {
      const tempInput = document.createElement('input')
      tempInput.style = 'position: absolute; left: -1000px; top: -1000px'
      tempInput.value = this.selectedText
      document.body.appendChild(tempInput)
      tempInput.select()
      document.execCommand('copy')
      document.body.removeChild(tempInput)
      this.closeSocialHint()
      this.showCopiedTooltip = true
      setTimeout(() => (this.showCopiedTooltip = false), 2000)
    },
    currentUrl() {
      return window.location.origin + window.location.pathname
    },
    initializeSocialHint() {
      document.addEventListener('mouseup', () => this.handleSelectionHint())
      document.addEventListener('scroll', () => this.closeSocialHint())
    },
    closeSocialHint() {
      $('.social-hint').hide()
    },
    showSocialHint() {
      this.showCopiedTooltip = false
      $('.social-hint').show()
    },
    handleSelectionHint() {
      event.preventDefault()
      this.closeSocialHint()
      const selection = window.getSelection(), // get the selection then
        range = selection.getRangeAt(0), // the range at first selection group
        rect = range.getBoundingClientRect() // and convert this to useful data
      if (window.getSelection().toString().length < 2) return
      this.selectedText = window.getSelection().toString()
      const div = this.$refs['social-hint']
      div.dataset.title = this.sharedText(this.selectedText)
      div.dataset.url = this.currentUrl()
      div.style.top = rect.top - 42 + 'px' // set coordinates
      div.style.left = rect.left + 'px'

      this.showSocialHint()

      const copiedHint = this.$refs['copied-hint']
      copiedHint.style.top = rect.top - 42 + 'px' // set coordinates
    },
  },
}
</script>

<style lang="scss">
@import '../../shared/assets/styles/global';
.social-hint__wrapper {
  position: fixed;
  .social-hint {
    position: fixed;
    display: none;
    height: 32px;
    width: 104px;
    background-color: #6998ae;
    color: #ffffff;
    border-radius: 4px;

    &:after,
    &:before {
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      left: 50%;
      top: 100%;
      border: solid transparent;
    }
    &:after {
      margin-left: -4px;
      border-width: 4px;
      border-color: #6998ae transparent transparent transparent;
    }
    &:before {
      margin-left: -8px;
      border-width: 8px;
      border-color: #6998ae transparent transparent transparent;
    }

    a {
      margin: 2px 5px;
      cursor: pointer;
      color: white;
      &:hover {
        color: white;
      }
      .feather-x-twitter path {
        fill: white;
      }
      .feather-linkedin {
        fill: white;
        stroke: none;
      }
    }

    .copy_current_text svg {
      stroke: #ffffff;
    }
  }

  .copy_current_url-tooltip__container {
    position: fixed;
    right: 20%;

    span {
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      padding: 6px 12px;
      background: #ffffff;
      box-shadow: 0 2px 15px rgba(73, 73, 73, 0.2);
      border-radius: 4px;
      color: #323232 !important;
    }
  }
}
</style>
