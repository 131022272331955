// Separate pack for the GoodKit theme plugins and any other plugins
// that can be loaded in the end of the <body> tag to speed up the page load

// TODO: consider moving elements, essences, menus to 'application' pack
//  but be careful to use delegated event binding and wait for plugins
//  from 'libraries' pack are properly initialized
//  https://coda.io/d/Amazing-Discoveries-shared_daD69i5U8P9/Tech-debt-sink_suy3x#Tech-debt-tasks_tuJWy/r90

// Shared libraries' styles

import * as Sentry from '@sentry/browser'

import 'libraries.scss'

// Polyfills

import 'polyfills'

// Analytics

import 'analytics/gtm'

// Libraries

import 'bootstrap/js/src/alert'
import 'bootstrap/js/src/dropdown'
import 'bootstrap/js/src/collapse'
import 'bootstrap/js/src/tab'
import 'bootstrap/js/src/modal'
import 'bootstrap/js/src/scrollspy'

import '@fancyapps/fancybox'
import 'vendor/flickity-imagesloaded/flickity-imagesloaded'
import 'vendor/flickity-fade/flickity-fade'
import 'vendor/flickity-as-nav-for/as-nav-for'

// Plugins configuration

import 'theme/plugins'
import 'theme/dropdown'

// TODO: consider moving elements, essences, menus to 'application' pack
//  but be careful to use delegated event binding and wait for plugins
//  from 'libraries' pack are properly initialized
//  https://coda.io/d/Amazing-Discoveries-shared_daD69i5U8P9/Tech-debt-sink_suy3x#Tech-debt-tasks_tuJWy/r90

// Elements

import 'alchemy/elements/online_store_widget'
import 'alchemy/elements/trending_videos'
import 'alchemy/elements/donation_projects'
import 'alchemy/elements/vacancy'
import 'alchemy/elements/newsletter_subscription'
import 'alchemy/elements/hero'
import 'alchemy/elements/prophetic_news_header'
import 'alchemy/elements/watch/watch_series_banner'
import 'alchemy/elements/watch/watch_featured_section'
import 'alchemy/elements/read/read_articles_banner'
import 'alchemy/elements/read/read_navbar_active_link'
import 'alchemy/elements/read/read_scroller'

// Essences

import 'alchemy/essences/essence_video'

// Menus

import 'alchemy/menus/main_menu'

// Watch section

import 'watch/section_slider'
import 'watch/section_loader'
import 'watch/login_tooltip'
import 'watch/study_tools_tooltip'
import 'watch/transcript_tooltip'
import 'watch/watch_grid'
import 'watch/watch_landing_sub_menu'
import 'watch/search_page'
import 'watch/episode/episode_player'
import 'watch/episode/episode_countdown'
import 'watch/episode/suggested_next_slider'

// Vue apps
import 'apps'

// Profile

import 'user/profile-view'
import 'user/profile-edit'
import 'user/profile-sidebar'
import 'user/login_welcome_tooltip'
import 'form/password_tooltip'

// Menu
import 'menu/navbar_toggler'

// To make jQuery available in .js.erb files
// TODO: invent a solution that will be free from this global exposure
// https://coda.io/d/Amazing-Discoveries-shared_daD69i5U8P9/Tech-debt-sink_suy3x#Tech-debt-tasks_tuJWy/r189
import $ from 'jquery'
window.$ = jQuery
import 'select2'
import 'select2/dist/css/select2.css'
import 'select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.css'
import { createConsumer } from '@rails/actioncable'

import 'menu/donate_warning'

// Explicit setup
// TODO: extract these initializations into separate file.
// Tech debt item: https://coda.io/d/Amazing-Discoveries-shared_daD69i5U8P9/Tech-debt-sink_suy3x#Tech-debt-tasks_tuJWy/r162

import { registerVideoJSPlugins } from 'video/player'
import { bindNavbarEventHandlers } from 'theme/navbar'
import { setupAjaxCSRFTokenHeader } from 'utils'
import {
  collapsibleText,
  destroyAllCollapsibleTexts,
} from 'watch/collapsible_text'

import 'utils/tabs'

bindNavbarEventHandlers()
// NOTE: calling `setupAjaxCSRFTokenHeader` from `application` pack
// doesn't setup $.ajaxSettings properly
setupAjaxCSRFTokenHeader()

registerVideoJSPlugins() // this must be done before any first call to `videoJSPlayer` init function

// Setup collapsible text globally. Parent element of collapsible text partial
// must have "data-collapsible-text" attribute.
$(document).on('turbolinks:load', () => {
  collapsibleText('[data-collapsible-text]')
})

$(document).on('turbolinks:load', () => {
  $('.image-with-full-view').on('click', (event) => {
    const src =
      $(event.delegateTarget).attr('src') ||
      $(event.delegateTarget).find('img').attr('src')

    $('<div>')
      .css({
        background: `RGBA(0,0,0,.5) url('${src}') no-repeat center`,
        backgroundSize: 'contain',
        width: '100%',
        height: '100%',
        position: 'fixed',
        zIndex: '10000',
        padding: '20px',
        backgroundOrigin: 'content-box',
        top: '0',
        left: '0',
        cursor: 'zoom-out',
      })
      .click(function () {
        $(this).remove()
      })
      .appendTo('body')
  })
})

$(document).on('turbolinks:load', function () {
  const isProduction = process.env.NODE_ENV === 'production'
  const protocol = isProduction ? 'wss' : 'ws'
  const consumer = createConsumer(
    `${protocol}://${window.location.host}/api/cable`
  )
  const elements = $("[data-id='notification-counter']")
  if (elements.length) {
    consumer.subscriptions.create(`MessageCenterChannel`, {
      received(data) {
        elements.each(function () {
          const counter = data.current_count > 0 ? data.current_count : ''
          $(this).html(counter)
        })
      },
    })
  }
})

$(document).on('turbolinks:before-cache', destroyAllCollapsibleTexts)

Sentry.init({
  dsn:
    'https://1e9877ef4417db0669c762f754ec469e@o4507487177080832.ingest.us.sentry.io/4507617051803648',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})
