<template>
  <div :class="theme">
    <div v-if="!readOnlyMode" class="documents__title">
      {{ $vueTranslate('admin.guides.title') }}
    </div>
    <div class="documents__container">
      <div v-if="!readOnlyMode" class="documents__create">
        <div class="documents__secondary-title">{{ secondaryTitle() }}</div>
        <Guide
          :key="currentGuide.id"
          :mode="currentMode"
          :guide="currentGuide"
          @[EMITTERS.GUIDE_CREATED_EMITTER]="guideCreated"
          @[EMITTERS.GUIDE_UPDATED_EMITTER]="guideUpdated"
          @[EMITTERS.GUIDE_CANCEL_EMITTER]="guideUpdateCanceled"
        />
      </div>
      <div class="documents__index">
        <div v-if="!readOnlyMode" class="documents__secondary-title">
          {{ $vueTranslate('admin.guides.created_btn') }}
        </div>
        <div v-if="!loading" ref="guideList" class="documents__list">
          <Guide
            v-for="guide of guides"
            :key="guide.id"
            :active="activeGuideId"
            :guide="guide"
            :theme="theme"
            :mode="MODES.SHOW"
            :read-only-mode="readOnlyMode"
            @[EMITTERS.GUIDE_DELETED_EMITTER]="guideDeleted"
            @[EMITTERS.GUIDE_EDIT_EMITTER]="guideEdit"
          />
        </div>
        <img
          v-else
          src="../../../shared/assets/images/loading.gif"
          alt="loading"
          class="documents-loader"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, remove } from 'lodash'

import Guide from './Guide'
import {
  THEMES,
  VIDEO_RESOURCES_MODES as MODES,
} from '../../../shared/utils/view_options'
import GuidesApiService from '../services/guidesApi.service'
import { EMITTERS } from '../../../shared/utils/emitters'

const EMPTY_GUIDE = {
  id: null,
  title: '',
  document: File,
  description: '',
}

export default {
  name: 'GuidesComponent',
  components: {
    Guide,
  },
  props: {
    readOnlyMode: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: THEMES.LIGHT,
    },
  },
  data() {
    return {
      MODES,
      EMITTERS,
      guides: [],
      currentMode: MODES.CREATE,
      currentGuide: cloneDeep(EMPTY_GUIDE),
      activeGuideId: 0,
      loading: true,
    }
  },
  created() {
    this.fetchGuides()
  },
  methods: {
    fetchGuides() {
      GuidesApiService.getAllGuides(this.optional_params)
        .then((response) => {
          const responseData = response.data

          this.guides = responseData.guides
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false
        })
    },
    guideCreated() {
      this.currentGuide = cloneDeep(EMPTY_GUIDE)
      this.fetchGuides()
    },
    guideDeleted(guide) {
      remove(this.guides, (element) => element.id === guide.id)
      this.count--
    },
    updateGuide(guide) {
      const storedGuide = this.guides.find((element) => element.id === guide.id)
      Object.assign(storedGuide, guide)
    },
    guideUpdated(guide) {
      this.currentMode = MODES.CREATE
      this.currentGuide = cloneDeep(EMPTY_GUIDE)
      this.updateGuide(guide)
      this.activeGuideId = 0
    },
    guideEdit(guide) {
      this.currentMode = MODES.EDIT
      this.currentGuide = cloneDeep(guide)
      this.activeGuideId = guide.id
    },
    guideUpdateCanceled() {
      this.currentMode = MODES.CREATE
      this.activeGuideId = 0
      this.currentGuide = cloneDeep(EMPTY_GUIDE)
    },
    secondaryTitle() {
      return this.currentMode === MODES.CREATE
        ? 'Add study guide'
        : 'Edit study guide'
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../shared/assets/styles/documents';
</style>
