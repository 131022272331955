<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      :stroke="theme"
      d="M8.85867 4.59973C6.50733 4.59973 4.6012 6.50587 4.6012 8.85721C4.6012 11.2085 6.50733 13.1147 8.85867 13.1147C11.21 13.1147 13.1161 11.2085 13.1161 8.85721C13.1161 6.50587 11.21 4.59973 8.85867 4.59973ZM2.70898 8.85721C2.70898 5.46083 5.46229 2.70752 8.85867 2.70752C12.255 2.70752 15.0084 5.46083 15.0084 8.85721C15.0084 12.2536 12.255 15.0069 8.85867 15.0069C5.46229 15.0069 2.70898 12.2536 2.70898 8.85721Z"
      :fill="fillIcon()"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      :stroke="theme"
      d="M11.8925 11.8921C12.262 11.5227 12.861 11.5227 13.2305 11.8921L16.6231 15.2847C16.9925 15.6542 16.9925 16.2532 16.6231 16.6227C16.2536 16.9921 15.6545 16.9921 15.2851 16.6227L11.8925 13.2301C11.523 12.8607 11.523 12.2616 11.8925 11.8921Z"
      :fill="fillIcon()"
    />
  </svg>
</template>

<script>
import { THEMES } from '../../utils/view_options'

export default {
  name: 'MagnifyingGlass',
  props: {
    theme: {
      required: false,
      default: THEMES.DARK,
      type: String, // THEMES.DARK | THEMES.LIGHT,
    },
    customColor: {
      required: false,
      default: '',
      type: String,
    },
  },
  methods: {
    fillIcon() {
      if (this.customColor) return this.customColor

      return this.theme === THEMES.DARK ? '#FFFFFF' : '#323232'
    },
  },
}
</script>

<style scoped></style>
