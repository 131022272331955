<template>
  <div :class="theme">
    <div v-if="!readOnlyMode" class="documents__title">
      {{ $vueTranslate('admin.documents.title') }}
    </div>
    <div class="documents__container">
      <div v-if="!readOnlyMode" class="documents__create">
        <div class="documents__secondary-title">{{ secondaryTitle() }}</div>
        <Document
          :key="currentDocument.id"
          :mode="currentMode"
          :document="currentDocument"
          @[EMITTERS.DOCUMENT_CREATED_EMITTER]="documentCreated"
          @[EMITTERS.DOCUMENT_UPDATED_EMITTER]="documentUpdated"
          @[EMITTERS.DOCUMENT_CANCEL_EMITTER]="documentUpdateCanceled"
        />
      </div>
      <div class="documents__index">
        <div v-if="!readOnlyMode" class="documents__secondary-title">
          {{ $vueTranslate('admin.documents.created_btn') }}
        </div>
        <div v-if="!loading" ref="documentList" class="documents__list">
          <Document
            v-for="document of documents"
            :key="document.id"
            :active="activeDocumentId"
            :document="document"
            :theme="theme"
            :mode="MODES.SHOW"
            :read-only-mode="readOnlyMode"
            @[EMITTERS.DOCUMENT_DELETED_EMITTER]="documentDeleted"
            @[EMITTERS.DOCUMENT_EDIT_EMITTER]="documentEdit"
          />
        </div>
        <img
          v-else
          src="../../../shared/assets/images/loading.gif"
          alt="loading"
          class="documents-loader"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, remove } from 'lodash'

import Document from './Document'
import {
  THEMES,
  VIDEO_RESOURCES_MODES as MODES,
} from '../../../shared/utils/view_options'
import DocumentsApiService from '../services/documentsApi.service'
import { EMITTERS } from '../../../shared/utils/emitters'

const EMPTY_DOCUMENT = {
  id: null,
  title: '',
  description: '',
  document: File,
}

export default {
  name: 'DocumentsComponent',
  components: {
    Document,
  },
  props: {
    readOnlyMode: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: THEMES.LIGHT,
    },
  },
  data() {
    return {
      MODES,
      EMITTERS,
      documents: [],
      currentMode: MODES.CREATE,
      currentDocument: cloneDeep(EMPTY_DOCUMENT),
      activeDocumentId: 0,
      loading: true,
    }
  },
  created() {
    this.fetchDocuments()
  },
  methods: {
    fetchDocuments() {
      DocumentsApiService.getAllDocuments(this.optional_params)
        .then((response) => {
          const responseData = response.data

          this.documents = responseData.documents
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false
        })
    },
    documentCreated() {
      this.currentDocument = cloneDeep(EMPTY_DOCUMENT)
      this.fetchDocuments()
    },
    documentDeleted(document) {
      remove(this.documents, (element) => element.id === document.id)
      this.count--
    },
    updateDocument(document) {
      const storedDocument = this.documents.find(
        (element) => element.id === document.id
      )
      Object.assign(storedDocument, document)
    },
    documentUpdated(document) {
      this.currentMode = MODES.CREATE
      this.currentDocument = cloneDeep(EMPTY_DOCUMENT)
      this.updateDocument(document)
      this.activeDocumentId = 0
    },
    documentEdit(document) {
      this.currentMode = MODES.EDIT
      this.currentDocument = cloneDeep(document)
      this.activeDocumentId = document.id
    },
    documentUpdateCanceled() {
      this.currentMode = MODES.CREATE
      this.activeDocumentId = 0
      this.currentDocument = cloneDeep(EMPTY_DOCUMENT)
    },
    secondaryTitle() {
      return this.currentMode === MODES.CREATE
        ? this.$vueTranslate('admin.documents.add_btn')
        : this.$vueTranslate('admin.documents.edit_btn')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../shared/assets/styles/documents';
</style>
