import { emailConfirmationAlert } from './profile-shared'

document.addEventListener('turbolinks:load', function () {
  if ($('.profile_edit').length != 0) {
    initEditProfile()
  }
  document.addEventListener(
    'click',
    function (event) {
      if (event.target.id === 'user_email') {
        $('#changeEmailModal').modal('show')
      }
    },
    true
  )

  emailConfirmationAlert($('.ec-icon'), $('.ec-popup'))
})

$(document).on('template:profile_edit:load', function () {
  initEditProfile()
  emailConfirmationAlert($('.ec-icon'), $('.ec-popup'))
})

$(document).on('keyup', '#user_username', function () {
  var $counter = $('#username_length_counter')
  var min_length = 3
  var max_length = 30

  var color = $(this).val().length < min_length ? '#D35163' : '#6998AE'
  $(this).css({ border: `1px ${color} solid` })
  $counter.text(`${$(this).val().length}/${max_length}`)
})

function initEditProfile() {
  const $selectCountry = $('.select_country')
  const $selectState = $('.select_state')
  const $selectCity = $('.select_city')
  const $profileForm = $('.edit_user')
  const $profileSubmitButton = $('.profile_submit')
  const selectCountryPrompt = 'Select country...'
  const selectStatePrompt = 'Select state...'
  const selectCityPrompt = 'Select city...'
  const selectStateFirst = 'Select state first'
  const selectCountryFirst = 'Select country first'

  toggleSaveButton($profileForm, $profileSubmitButton)

  if ($selectCountry.val() != '') {
    $selectCountry.prepend(
      $('<option></option>').attr('value', '').text(selectCountryPrompt)
    )
  }

  if ($selectCity.find('option').length == 0) {
    $selectCity.prepend(
      $('<option></option>').attr('value', '').text(selectStateFirst)
    )
  }

  if ($selectState.find('option').length == 0) {
    $selectState.prepend(
      $('<option></option>').attr('value', '').text(selectCountryFirst)
    )
  }

  bindOnCountryChange(
    $selectCountry,
    $selectState,
    $selectCity,
    selectStatePrompt,
    selectCountryFirst,
    selectStateFirst
  )
  bindOnStateChange(
    $selectCountry,
    $selectState,
    $selectCity,
    selectCityPrompt,
    selectStateFirst
  )
}

function bindOnStateChange(
  $selectCountry,
  $selectState,
  $selectCity,
  selectCityPrompt,
  selectStateFirst
) {
  $selectState.on('change', function (e) {
    let state = e.target.value
    let country = $selectCountry.val()

    $.get(
      Routes.cities_profile_index_path({ state: state, country: country }),
      function (data) {
        if ($selectState.val() === '') {
          clearSelect($selectCity, selectStateFirst)
        } else {
          clearSelect($selectCity, selectCityPrompt)
          $selectCity.prop('disabled', false)

          $.each(data, function (key, value) {
            $selectCity.append(
              $('<option></option>').attr('value', value).text(value)
            )
          })
        }
      }
    )
  })
}

function bindOnCountryChange(
  $selectCountry,
  $selectState,
  $selectCity,
  selectStatePrompt,
  selectCountryFirst,
  selectStateFirst
) {
  $selectCountry.on('change', function (e) {
    let country = e.target.value

    clearSelect($selectCity, selectStateFirst)

    $.get(Routes.states_profile_index_path({ country: country }), function (
      data
    ) {
      if ($selectCountry.val() === '') {
        clearSelect($selectState, selectCountryFirst)
      } else {
        clearSelect($selectState, selectStatePrompt)
        $selectState.prop('disabled', false)

        $.each(data, function (key, value) {
          $selectState.append(
            $('<option></option>').attr('value', key).text(value)
          )
        })
      }
    })
  })
}

function clearSelect(input, prompt) {
  input.empty()
  input.append($('<option></option>').attr('value', '').text(prompt))
  input.prop('disabled', true)
}

function toggleSaveButton(form, button) {
  form
    .each(function () {
      $(this).data('serialized', $(this).serialize())
    })
    .on('change input', function () {
      $(this)
        .find(button)
        .prop('disabled', $(this).serialize() == $(this).data('serialized'))
    })
    .find(button)
    .prop('disabled', true)
}
