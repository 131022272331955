import { Quill, Delta } from '@vueup/vue-quill'

const Parchment = Quill.import('parchment')
const Block = Quill.import('blots/block')
const Container = Quill.import('blots/container')

class WrapperBlot extends Container {
  optimize(context) {
    super.optimize(context)
    let next = this.next
    if (
      next != null &&
      next.prev === this &&
      next.statics.blotName === this.statics.blotName &&
      next.domNode.tagName === this.domNode.tagName
    ) {
      next.moveChildren(this)
      next.remove()
    }
    this.parent.optimize()
  }

  replace(target) {
    if (target.statics.blotName !== this.statics.blotName) {
      let item = Parchment.create(this.statics.defaultChild)
      target.moveChildren(item)
      this.appendChild(item)
    }
    super.replace(target)
  }
}

WrapperBlot.scope = Parchment.Scope.BLOCK_BLOT

class WrappedBlot extends Block {
  attach() {
    super.attach()
    const possibleParentNames = Array.isArray(this.statics.parentName)
      ? this.statics.parentName
      : [this.statics.parentName]
    if (!possibleParentNames.includes(this.parent.statics.blotName)) {
      const wrapper = Parchment.create(this.statics.parentName)
      this.wrap(wrapper)
    }
  }

  static formats(domNode) {
    const classMatch =
      this.className && domNode.classList.contains(this.className)
    const tagMatch =
      domNode.tagName.toLowerCase() === this.tagName.toLowerCase()

    return this.className ? classMatch && tagMatch : tagMatch
  }

  remove() {
    if (this.prev == null && this.next == null) {
      this.parent.remove()
    } else {
      super.remove()
    }
  }

  removeChild(child) {
    if (this.children.length === 1 && this.parent.children.length === 1) {
      if (this === this.parent.children.tail && this.children.tail === child) {
        if (
          child.statics.blotName === 'break' &&
          this.domNode.textContent.length > 0
        ) {
          return
        }
        super.removeChild(child)
        return this.parent.remove()
      }
    }
    super.removeChild(child)
    if (
      this.statics.deleteWhenEmpty &&
      child.prev == null &&
      child.next == null
    ) {
      this.remove()
    }
  }

  replaceWith(name, value) {
    this.parent.isolate(this.offset(this.parent), this.length())
    if (name === this.parent.statics.blotName) {
      this.parent.replaceWith(name, value)
      return this
    } else {
      const allowedChild = this.parent.statics.allowedChildren.find((child) => {
        return child.blotName === name
      })
      if (allowedChild) {
        super.replaceWith(name, value)
      } else {
        super.replaceWith(this.parent.statics.defaultChild, value)
      }
    }
  }
}

function titleBackspaceHandler(range, context, formatsToPrevent) {
  if (range.index === 0 || this.quill.getLength() <= 1) return

  let _quill$getLine11 = this.quill.getLine(range.index),
    _quill$getLine12 = _slicedToArray(_quill$getLine11, 1),
    line = _quill$getLine12[0]

  let formats = {}

  if (context.offset === 0) {
    let _quill$getLine13 = this.quill.getLine(range.index - 1),
      _quill$getLine14 = _slicedToArray(_quill$getLine13, 1),
      prev = _quill$getLine14[0]

    if (prev != null && prev.length() > 1) {
      let curFormats = line.formats()
      let prevFormats = this.quill.getFormat(range.index - 1, 1)
      formats = Delta.AttributeMap.diff(curFormats, prevFormats) || {}
    }
  }

  let length = /[\uD800-\uDBFF][\uDC00-\uDFFF]$/.test(context.prefix) ? 2 : 1
  length = range.length > 0 ? range.length : length
  const index = range.length > 0 ? range.index : range.index - length

  if (line.domNode.textContent === '') return

  let lineToCheck = this.quill.getLine(index)
  lineToCheck = _slicedToArray(lineToCheck, 1)
  lineToCheck = lineToCheck[0]

  let shouldPrevent = true
  formatsToPrevent.forEach((format) => {
    if (lineToCheck.formats()[format]) shouldPrevent = false
  })
  if (shouldPrevent) return true

  this.quill.deleteText(index, length, Quill.sources.USER)

  if (Object.keys(formats).length > 0) {
    this.quill.formatLine(
      range.index - length,
      length,
      formats,
      Quill.sources.USER
    )
  }

  this.quill.focus()
}

WrappedBlot.deleteWhenEmpty = false

export { WrappedBlot, WrapperBlot, titleBackspaceHandler }
