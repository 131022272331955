<template>
  <div v-if="selectedTags.length > 0" class="tags-list-block">
    <div class="selected-tags">
      <div class="clear-tags-button" @click="clearTags">
        {{ $vueTranslate('study_tools.bookmarks_filter_tags.clean_btn') }}
      </div>
      <div v-for="tag in selectedTags" :key="tag" class="selected-tag">
        {{ tag }}

        <span class="remove-tag" @click="removeTag(tag)">
          <CloseIconComponent class="tags-filter-button__icon" :theme="theme" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIconComponent from '../../shared/components/icons/Close'
import { EMITTERS } from '../../shared/utils/emitters'
import { THEMES } from '../../shared/utils/view_options'

export default {
  name: 'BookmarkFilterTags',
  components: { CloseIconComponent },
  props: {
    tagsSelected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      reloadKey: 0,
      selectedTags: this.tagsSelected,
      theme: THEMES.LIGHT,
    }
  },
  watch: {
    $route(newRoute, oldRoute) {
      const newTags = newRoute.query.tags || ''
      const oldTags = oldRoute.query.tags || ''
      const tags = newTags.split(',')

      if (oldTags !== newTags && tags.length > 0)
        this.selectedTags = newTags.split(',')
    },
  },
  methods: {
    removeTag(tag) {
      this.selectedTags = this.selectedTags.filter((t) => t !== tag)
      this.emitter.emit(EMITTERS.FILTER_BOOKMARK_TAG_UPDATED, this.selectedTags)
    },
    clearTags() {
      this.selectedTags = []
      this.emitter.emit(EMITTERS.FILTER_BOOKMARK_TAG_UPDATED, this.selectedTags)
    },
  },
}
</script>

<style lang="scss" scoped>
.tags-list-block {
  display: flex;
  flex-direction: row;
  justify-content: left;
  width: 100%;
  min-height: 24px;
  gap: 6px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-right: 48px;
}

.selected-tags {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
}

.selected-tag {
  display: flex;
  flex-wrap: wrap;
  height: 24px;
  padding: 0 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 12px;
  border: 1px solid var(--text-disabled, #d1d3d4);
  background: var(--backgrounds-white, #fff);
  color: var(--text-secondary, #5c5c5c);
  text-align: center;
  font-size: 12px;
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.clear-tags-button {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 70px;
  height: 24px;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid var(--primary-steel-blue-default, #6998ae);
  background: var(--primary-steel-blue-default, #6998ae);
  color: var(--backgrounds-white, #fff);
  text-align: center;

  font-size: 12px;
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  cursor: pointer;
}
</style>
