<template>
  <FlashNotification site="admin" :theme="'light'" />
  <div v-if="banner" class="banner-form">
    <div class="banner-info">
      <div class="banner-name">{{ banner.name }}</div>
    </div>

    <div class="fields">
      <TextField
        v-for="field in textFields"
        :field="field"
        :value="field"
        @change="updatePreviewTexts(field)"
      />

      <NumberField
          v-for="field in numberFields"
          :field="field"
          :value="field"
          @change="updatePreviewTexts(field)"
      />

      <LinkField
        v-for="field in linkFields"
        :field="field"
        :value="field"
        @change="updatePreviewTexts(field)"
      />

      <LinkButton
          v-for="field in linkButtons"
          :field="field"
          :value="field"
          @change="updateButtonText(field)"
      />

      <div class="field">
        <div class="field-name">Public</div>
        <input type="checkbox" v-model="banner.published" />
      </div>
    </div>

    <div class="actions">
      <button @click="updateBanner">Save</button>
    </div>
  </div>
</template>

<script>
import bannersApi from './services/bannersApi'
import TextField from './fields/TextField'
import NumberField from './fields/NumberField'
import LinkField from './fields/LinkField'
import LinkButton from './fields/LinkButton'

import FlashNotification from '../notification/components/FlashNotification'
import {EMITTERS} from "../shared/utils/emitters";

export default {
  name: 'ManageBannerAppComponent',
  components: {
    FlashNotification,
    TextField,
    NumberField,
    LinkField,
    LinkButton,
  },
  data() {
    return {
      bannerId: parseInt(this.optional_params.banner_id),
      banner: null,
    }
  },
  mounted() {
    bannersApi.getBanner(this.bannerId).then((response) => {
      this.banner = response.data
    })
  },
  computed: {
    textFields() {
      return this.filterByType('text')
    },
    linkFields() {
      return this.filterByType('link')
    },
    numberFields() {
      return this.filterByType('number')
    },
    linkButtons() {
      return this.filterByType('link_button')
    }
  },
  methods: {
    filterByType(type) {
      return this.banner.fields.filter((field) => field.field_type === type)
    },
    updateBanner() {
      bannersApi.updateBanner(this.bannerId, this.banner).then((response) => {
        if (response.data.errors && response.data.errors.join(', ')) {
          this.emitter.emit(
              EMITTERS.SHOW_ERROR_NOTIFICATION_EMITTER, {
                message: response.data.errors.join(', '),
              }
          )
          return
        }
        window.location.reload()
        
        this.emitter.emit(
            EMITTERS.SHOW_NOTIFICATION_EMITTER, {
              message: 'Saved',
            }
        )
      })
    },
    updatePreviewTexts(field) {
      const fields = $(`*[field-name=${field.name}]`)
      fields.text(field.value)
    },
    updateButtonText(field) {
      const fields = $(`*[field-name=${field.name}]`)
      fields.text(field.value.label)
    }
  },
}
</script>

<style lang="scss" scoped>
.banner-form {
  width: 500px;
  padding: 10px 40px 10px;
}

.banner-info {
  .banner-name {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
  }
}
.actions {
  text-align: right;
  padding-right: 10px;
}
.field {
  display: flex;
  align-items: baseline;
  margin-bottom: 20px;
  .field-name {
    width: 150px;
    text-align: right;
    margin-right: 10px;
  }
  input {
    max-width: 250px;
  }
}
</style>
