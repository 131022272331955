import Flickity from 'vendor/flickity'
import { scrollToTarget } from 'utils'

const SLIDER_SELECTOR = '#donation_projects_body_slider'
const changeSlide = (e) => {
  const flickity = Flickity.data(SLIDER_SELECTOR)

  const direction = e.currentTarget.dataset.direction
  if (direction === 'prev') {
    flickity.previous()
  } else {
    flickity.next()
  }
}

document.addEventListener('turbolinks:load', () => {
  if (!document.querySelector(SLIDER_SELECTOR)) return

  $('.donation-project-slide__arrow').on('click', changeSlide)
})

document.addEventListener('turbolinks:load', () => {
  if (!document.querySelector('.donation')) return
  const anchor = window.location.hash.replace(/\//g, '')

  scrollToTarget(anchor)
})
