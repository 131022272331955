<template>
  <NewsContent />
</template>

<script>
import NewsContent from './components/NewsContent'

export default {
  name: 'NewsContentAppComponent',
  components: {
    NewsContent,
  },
}
</script>
