<template>
  <div class="back-button__container">
    <ScrollUpIcon @click="scrollToVideo" />
  </div>
</template>

<script>
import { ScrollUpIcon } from '../../../shared/components/icons'

export default {
  name: 'ScrollUpButtonComponent',
  components: {
    ScrollUpIcon,
  },
  methods: {
    scrollToVideo() {
      $('html, body').animate(
        {
          scrollTop: $('.watch-episode').offset().top,
        },
        500
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.back-button__container {
  position: relative;
  width: 100%;

  .bookmark-back-button {
    position: absolute;
    right: -28px;
    top: -98px;
  }
}
</style>
