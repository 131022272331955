<template>
  <svg
    width="20"
    height="14"
    viewBox="0 0 20 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.125 5.36659C15.5583 2.49159 13.0333 0.333252 10 0.333252C7.59167 0.333252 5.5 1.69992 4.45833 3.69992C1.95 3.96659 0 6.09159 0 8.66658C0 11.4249 2.24167 13.6666 5 13.6666H15.8333C18.1333 13.6666 20 11.7999 20 9.49992C20 7.29992 18.2917 5.51659 16.125 5.36659ZM11.6667 7.83325V11.1666H8.33333V7.83325H5.83333L10 3.66659L14.1667 7.83325H11.6667Z"
      :fill="fillColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'UploadComponent',
  props: {
    fillColor: {
      type: String,
      default: 'white',
    },
  },
}
</script>

<style scoped>
svg {
  cursor: pointer;
}
</style>
