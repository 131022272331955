<template>
  <div class="news-container row">
    <NewsThumbnail :news="news" />

    <div class="news-bookmarks-container col">
      <NewsBookmark
        v-for="newsBookmark of bookmarks"
        :key="newsBookmark.id"
        :news-bookmark="newsBookmark"
        :mode="MODES.SHOW"
        :theme="THEMES.LIGHT"
        :href="bookmarkHref()"
        @[EMITTERS.BOOKMARK_DELETED_EMITTER]="deleteBookmark"
        @[EMITTERS.BOOKMARK_UPDATED_EMITTER]="updateBookmark"
      />
      <div
        v-if="news.news_bookmarks.length > bookmarksLimit"
        class="toggle-container"
      >
        <span v-if="!expanded" class="show-more" @click="showMore">
          Show more ({{ news.news_bookmarks.length - bookmarksLimit }})
          <ArrowDownIcon :theme="theme" />
        </span>
        <span v-else class="show-less" @click="showLess">
          Show less ({{ bookmarksLimit }})
          <ArrowUpIcon :theme="theme" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { remove } from 'lodash'

import ArrowDownIcon from '../../shared/components/icons/ArrowDown'
import ArrowUpIcon from '../../shared/components/icons/ArrowUp'
import {
  THEMES,
  BOOKMARK_MODES as MODES,
} from '../../shared/utils/view_options'
import { EMITTERS } from '../../shared/utils/emitters'
import NewsBookmark from '../../news_bookmarks/components/NewsBookmark'
import NewsThumbnail from './NewsThumbnail.vue'

export default {
  name: 'NewsComponent',
  components: {
    NewsThumbnail,
    ArrowUpIcon,
    ArrowDownIcon,
    NewsBookmark,
  },
  props: {
    news: {
      title: String,
      id: Number,
      meta_title: String,
      thumbnail: String,
      image_link: String,
      news_path: String,
      author: Array,
      source_link: String,
      source_logo: String,
      news_bookmarks: {
        text: String,
        id: Number,
        slug: String,
        tags: Array,
      },
    },
  },
  emits: [EMITTERS.BOOKMARK_DELETED_EMITTER],
  data() {
    return {
      MODES,
      THEMES,
      theme: THEMES.PROFILE,
      bookmarks: [],
      expanded: false,
      bookmarksLimit: 3,
      EMITTERS,
    }
  },
  created() {
    this.bookmarks = this.news.news_bookmarks.slice(0, this.bookmarksLimit)
  },
  mounted() {
    this.emitter.on(EMITTERS.BOOKMARK_DELETED_EMITTER, this.deleteBookmark)
  },
  methods: {
    deleteBookmark(bookmark) {
      remove(this.news.news_bookmarks, (element) => element.id === bookmark.id)
      this.count--
      this.bookmarks = this.news.news_bookmarks.slice(0, this.bookmarksLimit)
      if (this.expanded) {
        this.showMore()
      }
      this.$emit(EMITTERS.BOOKMARK_DELETED_EMITTER, {
        news: this.news,
        bookmarks: this.bookmarks,
      })
    },
    updateBookmark(bookmark) {
      const storedBookmark = this.news.news_bookmarks.find(
        (element) => element.id === bookmark.id
      )
      Object.assign(storedBookmark, bookmark)
      this.$emit(EMITTERS.BOOKMARK_UPDATED_EMITTER)
    },
    bookmarkHref() {
      return `${this.news.news_path}`
    },
    showMore(_event) {
      this.expanded = true
      this.bookmarks = this.news.news_bookmarks
    },
    showLess(_event) {
      this.expanded = false
      this.bookmarks = this.news.news_bookmarks.slice(0, this.bookmarksLimit)
    },
    speakerNames() {
      if (!this.news.author[0]) return
      const firstSpeaker = this.news.author[0].full_name
      const authorCount = this.news.author.length - 1
      if (this.news.author.length === 1) return firstSpeaker

      return firstSpeaker + ' (+' + authorCount + ')'
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../shared/assets/styles/global';

.news-container {
  width: 100%;
  min-height: 220px;
  background-color: #ffffff;
  margin-bottom: 16px;
  padding: 16px;
}

.toggle-container {
  margin-top: 12px;
}

.show-more,
.show-less {
  cursor: pointer;
  background-color: $white;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: $quick-silver;
}

.news-bookmarks-container {
  padding-left: 24px;
}
.footnote {
  line-height: 14px;
  margin-top: 3px;
  width: 180px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  a {
    color: #5c5c5c;
    font-size: 14px;
    text-decoration: none;
  }
}
</style>
