import { Quill } from '@vueup/vue-quill'

/** Customizable tooltip, which follows the same design as Quill Editor's native
 * tooltip. Below are all the configurations that can be made, as well as a
 * description of what each one represents.
 *
 * [OPTIONS]
 * format = The format to be worked by tooltip.
 * inputLabel = Left label given to value input.
 * inputLabelClass = Overrides CSS class for value input label styling.
 * inputPlaceholder = Value input placeholder text (<input placeholder="...">).
 * inputClass = Overrides CSS class for value input styling.
 * actionText = Text for action "button" to the right of value input.
 * actionClass = Overrides CSS class for action text (the "button").
 * containerClass = Replaces CSS class for element that contains all others.
 * hideOnTyping = If true, tooltip will be hidden when typing in Quill.
 * hideOnAction = If true, tooltip will be hidden by clicking action text.
 * clearAfterHide = If true, the value input has its text cleared after tooltip is hidden.
 * defaultValue = Defines a default value for value input. If set, clearAfterHide is always false.
 * onAction = Function called when action text is clicked by the user. Setting a value for this property makes the user responsible for the tooltip action.
 * onShow = Function called when tooltip is revealed.
 * onHide = Function called when tooltip is hidden.
 */
class CustomizableTooltip {
  constructor(format, quill, options) {
    // Is everything ok here?
    this.checkState(format, quill)
    this.checkOptions(options)

    // Everything seems ok here...
    this.quill = quill
    this.format = format

    // Lets build...
    this.buildInterface()

    let thisTooltip = this
    this.quill.getModule('toolbar').addHandler(this.format, function () {
      thisTooltip.toolbarButtonPressed()
    })

    this.quill.container.prepend(this.wrapper)
    this.hide()
  }

  hasClass(element, className) {
    return (' ' + element.className + ' ').indexOf(' ' + className + ' ') > -1
  }

  getContentText() {
    return this.quill.getContents().ops.reduce((text, op) => {
      if (typeof op.insert === 'string') {
        return text + op.insert
      }
      // If it is not a string, the newline character is set, which has
      // a length of only 1 character.
      else {
        return text + '\n'
      }
    }, '')
  }

  // This prevents a surprise from appearing while using the editor. If a
  // problem exists, it will appear when the tooltip is being built.
  checkState(format, quill) {
    // Is Quill reference useful?
    if (quill === null || typeof quill !== 'object')
      throw 'Quill reference was not passed in argument, or is null.'

    // Was the format specified?
    if (!format || format.length <= 0) throw 'No format was specified.'

    // Is the format registered?
    if (!Quill.import('formats/' + format))
      throw (
        'No format "' +
        format +
        '" found. Please, be sure to pass a format that is registered within Quill.'
      )
  }

  /** Checks whether properties have been set correctly, or need to be
   * overwritten if not. */
  checkOptions(options) {
    if (!options || options == null) options = {}

    if (!options.inputLabel || options.inputLabel.length <= 0)
      options.inputLabel = 'Value'
    if (!options.inputLabelClass || options.inputLabelClass.length <= 0)
      options.inputLabelClass = 'rs-tooltip-label'
    if (!options.inputPlaceholder || options.inputPlaceholder.left <= 0)
      options.inputPlaceholder = 'Insert value here...'
    if (!options.inputClass || options.inputClass.length <= 0)
      options.inputClass = 'rs-tooltip-input'
    if (!options.actionText || options.actionText.length <= 0)
      options.actionText = 'Conclude'
    if (!options.actionClass || options.actionClass.length <= 0)
      options.actionClass = 'rs-tooltip-action'
    if (!options.containerClass || options.containerClass.length <= 0)
      options.containerClass = 'rs-tooltip-container'
    if (!options.hideOnTyping) options.hideOnTyping = false
    if (
      options.clearAfterHide &&
      options.defaultValue &&
      options.defaultValue.length >= 0
    )
      options.clearAfterHide = false
    if (!options.hideOnAction || options.hideOnAction === false)
      options.hideOnAction = true

    if (!options.limit) options.limit = 800

    options.selectionTextClass = 'rs-tooltip__selection-text'
    this.op = options
  }

  buildInterface() {
    this.buildWrapper()
    this.buildContainer()
    this.buildInputLabel()
    this.buildSelectionText()
    this.buildAdditionalFields()
    this.buildInput()
    this.buildAction()

    // Adds built elements into this tooltip container.
    this.wrapper.appendChild(this.container)
    this.container.appendChild(this.inputLabel)
    this.container.appendChild(this.input)
    if (this.additionalFields) this.container.appendChild(this.additionalFields)
    this.container.appendChild(this.action)
  }

  buildWrapper() {
    let wrapper = document.createElement('DIV')
    wrapper.classList.add('rs-tooltip-wrapper')
    this.wrapper = wrapper
  }

  buildContainer() {
    let thisTooltip = this
    let container = document.createElement('DIV')
    container.classList.add(this.op.containerClass)

    // Hide tooltip by clicking outside of it.
    document.body.addEventListener('click', (event) => {
      // Was it clicked off?
      if (!this.hasClass(event.target, thisTooltip.op.containerClass)) {
        // Prevents tooltip from ever appearing if its button is clicked.
        // The button can have several internal elements. You can even
        // click on the SVG element without clicking the button (the
        // button icon, or the elements representing the icon). We take
        // this into account with closest, and verify that in addition
        // to the button, some element of it has been clicked.
        if (
          this.hasClass(event.target, 'ql-' + thisTooltip.format) ||
          event.target.closest('.ql-' + thisTooltip.format)
        ) {
          return
        }

        thisTooltip.hide()
      }
    })

    // Prevents tooltip from being hidden if its content is clicked.
    container.addEventListener('click', (event) => {
      event.stopPropagation()
    })

    // Hide tooltip when typing text in the editor.
    if (this.op.hideOnTyping) {
      this.quill.on('text-change', function (delta, oldDelta, source) {
        if (source === 'user') {
          thisTooltip.hide()
        }
      })
    }

    this.container = container
  }

  buildInputLabel() {
    let label = document.createElement('DIV')
    label.innerText = this.op.inputLabel
    label.classList.add(this.op.inputLabelClass)

    this.inputLabel = label
  }

  buildSelectionText() {
    let selectionText = document.createElement('DIV')
    selectionText.classList.add(this.op.selectionTextClass)

    this.inputLabel.appendChild(selectionText)
    this.selectionText = selectionText
  }

  collectAdditionalField() {
    if (!this.additionalFields) return null
    let result = {}

    const fields = this.additionalFields.getElementsByTagName('input')
    for (const field of fields) {
      let value = field.value
      if (value && value === '') value = null
      result[field.name] = value
    }

    return result
  }

  buildAdditionalFields() {
    if (!this.op.additionalFields || this.op.additionalFields.length === 0) {
      return
    }

    let fields = document.createElement('DIV')
    fields.classList.add('rs-tooltip-additional-fields')
    this.op.additionalFields.forEach((fieldLabel) => {
      let container = document.createElement('DIV')
      let field = document.createElement('INPUT')
      let label = document.createElement('DIV')
      field.type = 'text'
      field.name = fieldLabel
      container.classList.add('rs-tooltip-additional-field')
      label.innerText = fieldLabel
      container.appendChild(label)
      container.appendChild(field)
      fields.appendChild(container)
    })

    this.additionalFields = fields
  }

  buildInput() {
    let thisTooltip = this
    let input = null
    if (this.op.textarea) {
      input = document.createElement('TEXTAREA')
    } else {
      input = document.createElement('INPUT')
      input.type = 'text'
    }
    input.classList.add(this.op.inputClass)
    input.placeholder = this.op.inputPlaceholder
    input.maxLength = this.op.limit
    input.value =
      this.op.defaultValue && this.op.defaultValue.length > 0
        ? this.op.defaultValue
        : ''

    // Has the user added an event of his own to this tooltip? If so, it
    // will be called as a priority.
    if (this.op.onAction && typeof this.op.onAction === 'function') {
      input.addEventListener('keydown', (e) => {
        if (e.key === 'Enter') {
          this.op.onAction(
            input.value,
            this.collectAdditionalField(),
            this.selection,
            e
          )
          if (thisTooltip.op.hideOnAction) thisTooltip.hide()
        }
      })
    }
    // Otherwise, the tooltip calls the default implementation. It is
    // understood that the user knows how this tooltip works, and has
    // configured it correctly.
    else {
      input.addEventListener('keydown', (e) => {
        if (e.key === 'Enter') {
          thisTooltip.insertEmbed(input.value, e)
          if (thisTooltip.op.hideOnAction) thisTooltip.hide()
        }
      })
    }

    this.input = input
  }

  buildAction() {
    let thisTooltip = this
    let linkAction = document.createElement('a')
    linkAction.innerText = this.op.actionText
    linkAction.classList.add(this.op.actionClass)

    // Has the user added an event of his own to this tooltip? If so, it
    // will be called as a priority.
    if (this.op.onAction && typeof this.op.onAction === 'function') {
      linkAction.addEventListener('click', (e) => {
        this.op.onAction(
          thisTooltip.input.value,
          this.collectAdditionalField(),
          this.selection,
          e
        )
        if (thisTooltip.op.hideOnAction) thisTooltip.hide()
      })
    }
    // Otherwise, the tooltip calls the default implementation. It is
    // understood that the user knows how this tooltip works, and has
    // configured it correctly.
    else {
      linkAction.addEventListener('click', (e) => {
        thisTooltip.insertEmbed(thisTooltip.input.value, e)
        if (thisTooltip.op.hideOnAction) thisTooltip.hide()
      })
    }

    this.action = linkAction
  }

  toolbarButtonPressed() {
    if (this.isVisible()) {
      this.hide()
      return
    }

    this.show()
  }

  setInputLabel(label) {
    if (!label || label.length <= 0) return
    this.inputLabel.innerText = label
  }

  setActionLabel(label) {
    if (!label || label.length <= 0) return
    this.action.innerText = label
  }

  setInputValue(value) {
    if (!value || value.length <= 0) return
    this.input.value = value
  }

  setAdditionalFieldValue(key, value) {
    if (!value || value.length <= 0) return
    let input = this.additionalFields.querySelectorAll(`input[name="${key}"]`)
    input = input ? input[0] : input
    if (!input) return

    input.value = value
  }

  setInputPlaceholder(placeholder) {
    if (!placeholder || placeholder.length <= 0) return
    this.input.placeholder = placeholder
  }

  getInputValue() {
    return this.input.value
  }

  show() {
    if (this.quill.getSelection().length === 0) return
    if (this.isVisible()) return
    this.selection = this.quill.getSelection()

    if (this.op.showSelection) {
      const text = this.quill.getText(this.quill.getSelection())
      this.selectionText.innerText = text
    }

    this.container.classList.remove('ql-hidden')

    if (this.op.onShow && typeof this.op.onShow === 'function') this.op.onShow()
  }

  hide() {
    if (!this.isVisible()) return

    this.container.classList.add('ql-hidden')

    if (!this.op.defaultValue && this.op.clearAfterHide) this.input.value = ''
    if (this.op.onHide && typeof onHide === 'function') this.op.onHide()
  }

  isVisible() {
    return !this.hasClass(this.container, 'ql-hidden')
  }

  insertEmbed(value) {
    if (!value || value.length <= 0) return

    let range = this.quill.getSelection()
    if (!range)
      range = {
        index: this.getContentText().length - 1,
        length: 0,
      }

    this.quill.insertEmbed(range.index, this.format, value)
  }
}

export default CustomizableTooltip
