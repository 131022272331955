import { Quill } from '@vueup/vue-quill'

import { WrapperBlot, WrappedBlot } from './blot_wrapper'
class QuoteContainer extends WrapperBlot {}
QuoteContainer.blotName = 'quote'
QuoteContainer.tagName = 'aside'
QuoteContainer.defaultChild = 'quote-paragraph'
QuoteContainer.className = 'ad-quote'

class QuoteParagraph extends WrappedBlot {}
QuoteParagraph.blotName = 'quote-paragraph'
QuoteParagraph.className = 'quote-paragraph'
QuoteParagraph.tagName = 'p'
QuoteParagraph.parentName = 'quote'

QuoteContainer.allowedChildren = [QuoteParagraph]
QuoteParagraph.requiredContainer = QuoteContainer

function initQuote() {
  let icons = Quill.import('ui/icons')

  icons['quote'] = icons['blockquote']

  Quill.register(QuoteContainer)
  Quill.register(QuoteParagraph)
}

export { initQuote, QuoteContainer }
