<template>
  <ScrollUpButton />
  <div v-if="authorized()">
    <div class="bookmarks-header study-tools-title-area">
      <span class="bookmarks-title">
        <span class="title-text">
          {{ $vueTranslate('study_tools.bookmarks.title') }}
        </span>
        <span class="counter">({{ count }})</span>
      </span>
      <OrderSelect
        :theme="theme"
        @[EMITTERS.BOOKMARK_ORDER_EMITTER]="orderChanged"
      />
    </div>

    <div class="bookmarks-container">
      <div class="bookmarks-list">
        <Bookmark
          v-if="!loading"
          class="bookmarks-form-container"
          :mode="MODES.CREATE"
          :theme="theme"
          href="#"
          @[EMITTERS.BOOKMARK_CREATED_EMITTER]="bookmarkCreated"
        />
        <div v-if="!loading">
          <Bookmark
            v-for="bookmark of bookmarks"
            :key="bookmark.id"
            :bookmark="bookmark"
            :mode="MODES.SHOW"
            :theme="theme"
            href="#"
            @[EMITTERS.BOOKMARK_DELETED_EMITTER]="deleteBookmark"
            @[EMITTERS.BOOKMARK_UPDATED_EMITTER]="updateBookmark"
          />
        </div>
        <img
          v-else
          src="../../shared/assets/images/loading.gif"
          alt="loading"
          class="bookmarks-loader"
        />
      </div>
    </div>
  </div>
  <div v-else>
    <Authorization></Authorization>
  </div>
</template>

<script>
import { remove } from 'lodash'

import Bookmark from './Bookmark'
import OrderSelect from './elements/OrderSelect'
import { BookmarksApiService } from '../services'
import { EMITTERS } from '../../shared/utils/emitters'
import {
  BOOKMARK_MODES as MODES,
  THEMES,
} from '../../shared/utils/view_options'
import Authorization from './Authorization.vue'
import ScrollUpButton from './elements/ScrollUpButton.vue'

export default {
  name: 'BookmarksComponent',
  components: {
    Bookmark,
    OrderSelect,
    ScrollUpButton,
    Authorization,
  },
  data() {
    return {
      MODES,
      EMITTERS,
      theme: THEMES.DARK,
      loading: true,
      bookmarks: [],
      bookmarkOrder: null,
      count: 0,
    }
  },
  created() {
    this.fetchBookmarks()
  },
  methods: {
    authorized() {
      return !!this.optional_params.user_id
    },
    updateBookmark(bookmark) {
      const storedBookmark = this.bookmarks.find(
        (element) => element.id === bookmark.id
      )
      Object.assign(storedBookmark, bookmark)
    },
    deleteBookmark(bookmark) {
      remove(this.bookmarks, (element) => element.id === bookmark.id)
      this.count--
    },
    bookmarkCreated() {
      this.fetchBookmarks()
    },
    orderChanged(param) {
      this.fetchBookmarks(param)
    },
    fetchBookmarks(
      order = this.bookmarkOrder || BookmarksApiService.DEFAULT_ORDER
    ) {
      this.bookmarkOrder = order
      BookmarksApiService.getAllBookmarks(order, this.optional_params)
        .then((response) => {
          const responseData = response.data

          this.bookmarks = responseData.bookmarks
          this.count = responseData.count
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../shared/assets/styles/global';

.bookmarks-header {
  font-weight: 500;
  display: flex;
  justify-content: space-between;

  .title-text {
    font-size: 20px;
    color: $white;
    margin-right: 4px;
  }

  .counter {
    font-size: 14px;
    color: $quick-silver;
  }
}

.bookmarks-title {
  margin: auto 0;
}

.bookmarks-container {
  display: flex;
  background-color: #2b2834;
  border-radius: 8px;
  padding-inline-start: 0;
  padding-bottom: 34px;
  height: 300px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 3px;

    &:hover {
      width: 10px;
    }
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
    background-color: transparent;
    //&:hover {
    //  background-color: #d9d9d9;
    //  opacity: .1;
    //
    //}
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: #8f8a9b;
    border-radius: 4px;
    border: 4px solid #8f8a9b;
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {
    display: none;
  }
}

.bookmarks-list {
  flex-wrap: wrap;
  width: 100%;
}

.bookmarks-loader {
  margin: 50px auto;
  width: 200px;
  display: flex;
  vertical-align: middle;
}

.bookmarks-form-container {
  min-height: 55px;
}
</style>
