<template>
  <div class="modal-b" :class="theme">
    <div class="modal-c">
      <div class="mobile__file-info">
        <div class="file__info">
          <div class="file__title">
            {{ entity.title }}
          </div>
          <span class="ebook__author">
            {{ entity.author }}
          </span>
          <span class="ebook__mobile-header">
            {{ $vueTranslate('store_items.preview_store_item.header') }}
          </span>
        </div>
      </div>

      <CloseIcon :theme="theme" class="cancel" @click.stop="cancel" />
      <div class="preview-container">
        <div class="preview__main">
          <div class="preview__document-viewer-container">
            <img
              class="preview__cover"
              :alt="entity.title"
              :class="{ 'no-image': isThumbnailEmpty() }"
              :src="isThumbnailEmpty() ? NO_IMAGE : entity.thumbnail_url"
              @error="handleImageError"
            />
          </div>
        </div>
        <div class="preview__secondary">
          <span class="header">
            {{ $vueTranslate('store_items.preview_store_item.header') }}
          </span>
          <span class="title">
            {{ entity.title }}
          </span>
          <span class="author">
            {{ entity.author }}
          </span>
          <span class="description">
            {{ entity.description }}
          </span>

          <div class="buttons-block">
            <div
              v-if="entity.usd_button_link"
              class="btn"
              @click="openLink(entity.usd_button_link)"
            >
              <AmericanFlag />{{ entity.usd_button_title }}
            </div>
            <div
              v-if="entity.cad_button_link"
              class="btn"
              @click="openLink(entity.cad_button_link)"
            >
              <CanadianFlag />{{ entity.cad_button_title }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'

import { EMITTERS } from '../../shared/utils/emitters'
import CloseIcon from '../../shared/components/icons/Close'
import CanadianFlag from '../../shared/components/icons/CanadianFlag'
import AmericanFlag from '../../shared/components/icons/AmericanFlag'

export default {
  name: 'PreviewStoreItem',
  components: {
    AmericanFlag,
    CanadianFlag,
    CloseIcon,
  },
  props: {
    theme: {
      required: true,
      type: String, // THEMES.DARK | THEMES.LIGHT,
    },
    entity: {
      type: Object,
      default: () => ({
        title: '',
        author: '',
        description: '',
        thumbnail_url: '',
        usd_store_name: '',
        usd_store_link: '',
        cad_store_name: '',
        cad_store_link: '',
      }),
      required: true,
    },
  },
  data() {
    return {
      NO_IMAGE: window.NO_IMAGE_FALLBACK,
      message: '',
    }
  },
  methods: {
    handleImageError(event) {
      event.target.src = this.NO_IMAGE
      event.target.style.objectFit = 'contain'
    },
    isThumbnailEmpty() {
      return isEmpty(this.entity.thumbnail_url)
    },
    openLink(link) {
      window.open(link, '_blank')
    },
    closeDialog() {
      this.$emit(EMITTERS.CLOSE_DIALOG_EMITTER)
    },
    cancel() {
      this.closeDialog()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../shared/assets/styles/document';

.modal-c {
  display: flex;
  flex-direction: column;
  margin: 4% auto;
  padding: 20px;
  width: 890px;
  box-shadow: 0 4px 30px rgba(23, 24, 28, 0.6);
  border-radius: 8px;
  position: relative;

  &.transcript {
    width: 926px;
  }
}

.cancel {
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 20px;
}

.preview-container {
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
  padding-top: 8px;
}

.preview__secondary {
  width: 360px;

  display: flex;
  flex-direction: column;
  gap: 8px;

  .header {
    font-family: DM Sans, serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    color: #323232;
  }

  .title {
    font-family: DM Sans, serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #323232;
  }

  .author {
    font-family: DM Sans, serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: #8b8b8b;
  }

  .description {
    font-family: DM Sans, serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #5c5c5c;
    overflow-y: scroll;
    max-height: 310px;
  }
}

.preview__main {
  width: 439px;
  position: relative;
}

.modal-b.dark {
  display: block;
  position: fixed;
  z-index: 1500;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(28, 25, 35, 0.8);

  .modal-c {
    background: #32303c;
    color: #ffffff;
  }

  .button {
    border-radius: 4px;
    border: none;
    font-size: 14px;
    height: 32px;
    width: 80px;
    color: #ffffff;
    justify-content: center;
    display: flex;
    padding: 4px 0;
  }

  .cancel {
    color: #ffffff;
  }

  .modal-text {
    color: #ffffff;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 24px;
  }
}

.light.modal-b {
  display: block;
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(28, 25, 35, 0.8);

  .modal-c {
    background: #ffffff;
    color: #323232;
  }

  .button {
    border-radius: 4px;
    border: none;
    font-size: 14px;
    height: 32px;
    width: 80px;
    color: #ffffff;
    justify-content: center;
    display: flex;
    padding: 6px 0;
  }

  .cancel {
    background-color: #ffffff;
    color: #6998ae;
  }

  .modal-text {
    color: #323232;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 24px;
    display: flex;
  }
}

svg {
  margin-right: 8px;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
}
.video-resources__header {
  display: none;
}
.mobile__file-info {
  display: none;
}

.ebook__mobile-header {
  display: none;
}

@media (max-width: 600px) {
  .modal-b .modal-c {
    height: auto;
  }
}

@media (max-width: 991px) {
  .video-resources__header {
    padding: 15px;
    display: flex;
    background: #1c1923;
    .video-resources__title {
      justify-self: center;
      margin: auto;
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 1.6;
    }
  }
  .mobile__file-info {
    display: flex;
    padding: 0 15px;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .modal-b .modal-c {
    border-radius: 0;
    width: 100vw;
    height: 100%;
    margin: 0;
    padding: 0;
    background: #1c1923;
  }
  .preview-container {
    background: #1c1923;
  }
  .preview-container {
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
    height: 80%;
    overflow-y: scroll;
  }
  .modal-b {
    .preview__main {
      width: 80%;
      max-width: 300px;
      height: unset;
    }
    .preview__secondary {
      width: 95%;

      .header {
        display: none;
      }
    }
  }

  .file__info {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
  }

  .ebook__author {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #9c9c9c;
    height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 140px;
    display: none;
  }

  .ebook__mobile-header {
    display: block;
    font-family: DM Sans, serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    text-align: left;
    color: #323232;
  }

  .file__title {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #ffffff;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 140px;
  }
  .video-resources_download {
    height: 38px;
  }

  .light.modal-b {
    .video-resources__header {
      background: #ffffff;
      color: #323232;
      path {
        stroke: #323232;
      }
    }
    .preview-container {
      background: #ffffff;
    }
    .modal-c {
      background: #ffffff;
    }
  }

  .buttons-block {
    bottom: 0;
    position: fixed;
    display: flex;
    flex-direction: row;
    gap: 8px;
    height: 80px;
    padding-left: 16px;
    padding-right: 16px;
    box-shadow: 0 4px 30px 0 #62626566;
    justify-content: center;
    background: white;
    align-items: center;
    width: 100%;
    margin-left: -10px !important;
  }

  .modal-c {
    height: 100%;
  }
}

.preview__cover {
  object-fit: cover;
  height: 500px;
}

.buttons-block {
  display: flex;
  flex-direction: row;
  gap: 8px;

  .btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: 100%;
    height: 32px;
    padding: 9px 16px 9px 16px;
    border-radius: 4px;
    cursor: pointer;
    background-color: #6998ae;
    font-family: DM Sans, serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: white;
  }
}

.preview__document-viewer-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
@media (max-width: 991px) {
  .preview__cover {
    height: 60vh;
  }
  .preview__read-button {
    margin-top: 20px;
  }
}
</style>
