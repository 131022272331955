<template>
  <VideosSelector 
    :entity-id="entityId"
    :entity-type="entityType"
  />
</template>

<script>
import VideosSelector from './components/ResourceSelector'

export default {
  name: 'App',
  components: { VideosSelector },
  data() {
    return {
      entityId: parseInt(this.optional_params.entity_id),
      entityType: this.optional_params.entity_type,
    }
  },
}
</script>

<style scoped lang="scss"></style>
