<template>
  <img
    v-if="loading"
    src="../../shared/assets/images/loading.gif"
    alt="loading"
    width="40"
    class="bookmarks-loader"
  />
  <div class="episodes-component__wrapper">
    <div v-if="isNoBookmarks()" class="episodes-placeholder">
      <div class="episodes-block">
        <div class="placeholder-border"></div>
        <div class="primary-placeholder-title">
          {{ $vueTranslate('profile.episodes.bookmarks_none') }}
        </div>
        <div class="secondary-placeholder-title">
          {{ $vueTranslate('profile.episodes.bookmarks_create_first') }}
        </div>
        <a :href="watch_page" class="placeholder-button btn btn-primary">
          {{ $vueTranslate('profile.episodes.watch_page_link') }}
        </a>
      </div>
    </div>

    <div
      v-if="!loading && (!isNoBookmarks() || hasFilter())"
      class="bookmarks-title-row"
    >
      <span class="bookmarks-title">
        <span class="title-text">
          {{ $vueTranslate('profile.episodes.bookmarks_title') }}
        </span>
        <span class="counter">({{ count }})</span>
      </span>
    </div>

    <div v-if="!isNoBookmarks()" class="bookmarks-filter-block">
      <BookmarkSearch />
      <BookmarkFilter
        v-if="tagsPresent()"
        :episodes="episodes"
        :tags="tags"
        :tags-selected="selectedTags"
      />
    </div>

    <BookmarkFilterTags
      v-if="!loading && tagsPresent()"
      :tags-selected="selectedTags"
    />

    <div class="main-block row">
      <Episode
        v-for="episode in episodes"
        v-if="!loading"
        :key="episode.id"
        :episode="episode"
        @[EMITTERS.BOOKMARK_DELETED_EMITTER]="deleteEpisode"
        @[EMITTERS.BOOKMARK_UPDATED_EMITTER]="updateBookmark"
      />

      <div v-if="isEmptyFilterResults()" class="filter-bookmark__no-result row">
        {{ $vueTranslate('profile.episodes.no_filters_results') }}
      </div>
    </div>
  </div>
</template>

<script>
import { remove } from 'lodash'

import Episode from './Episode'
import ApiService from '../services/episodesApi.service'
import { EMITTERS } from '../../shared/utils/emitters'
import BookmarkFilter from '../../study_tools/components/BookmarkFilter'
import BookmarksTagsApiService from '../../study_tools/services/bookmarksTagsApi.service'
import BookmarkSearch from '../../study_tools/components/BookmarkSearch'
import BookmarkFilterTags from '../../study_tools/components/BookmarkFilterTags'

export default {
  name: 'EpisodesComponent',
  components: {
    BookmarkFilterTags,
    BookmarkSearch,
    BookmarkFilter,
    Episode,
  },
  data() {
    return {
      count: 0,
      episodes: [],
      tags: [],
      selectedTags: [],
      loading: true,
      searchEnded: false,
      loadedByWatch: false,
      watch_page: window.location.origin,
      EMITTERS,
    }
  },
  watch: {
    $route(newRoute, oldRoute) {
      this.loadedByWatch = true
      this.fetchEpisodesByParams(newRoute, oldRoute)
    },
  },
  mounted() {
    this.emitter.on(EMITTERS.FILTER_BOOKMARK_TAG_UPDATED, (tags) => {
      this.selectedTags = tags
    })
    this.checkRender()
  },
  created() {
    this.fetchTags()
  },
  methods: {
    updateBookmark() {
      this.fetchTags()
    },
    deleteEpisode(obj) {
      if (obj.bookmarks.length === 0) {
        remove(this.episodes, (element) => element.id === obj.episode.id)
      }
      this.count--
      this.fetchTags()
    },
    fetchEpisodes(custom_params = null) {
      if (custom_params) this.loading = true

      const params = custom_params ? custom_params : this.optional_params

      ApiService.getAllEpisodes(params)
        .then((response) => {
          const responseData = response.data

          this.episodes = responseData.episodes
          this.count = responseData.count
          this.watch_page = responseData.watch_page
        })
        .catch((e) => {
          this.errors.push(e)
          console.error(this.errors)
        })
        .finally(() => {
          this.loading = false
        })
    },
    hasFilter() {
      const urlParams = this.$route.query
      return 'query' in urlParams || 'tags' in urlParams
    },
    isEmptyFilterResults() {
      const { loading, episodes, hasFilter } = this
      return !loading && episodes.length === 0 && hasFilter()
    },
    isNoBookmarks() {
      const { loading, episodes, hasFilter } = this
      return !loading && episodes.length === 0 && !hasFilter()
    },
    fetchTags() {
      BookmarksTagsApiService.getAllTags().then((response) => {
        this.tags = response.data.tags
      })
    },
    fetchEpisodesByParams(newRoute, oldRoute) {
      const urlParams = (newRoute && newRoute.query) || this.$route.query
      const tagsParam = urlParams.tags
      const queryParam = urlParams.query || ''

      const params = {}

      if (tagsParam) {
        const selectedTags = tagsParam.split(',')
        params.tags = selectedTags
        this.selectedTags = selectedTags
      }

      if (queryParam && queryParam.length >= 3) {
        params.query = queryParam
        this.searchEnded = false
      }

      if (queryParam.length < 3 && !this.searchEnded) {
        this.searchEnded = true
        this.fetchEpisodes(params)
        return
      }

      const newTags = (newRoute && newRoute.query.tags) || ''
      const oldTags = (oldRoute && oldRoute.query.tags) || ''

      if (this.searchEnded && newTags === oldTags) return

      this.fetchEpisodes(params)
    },
    checkRender() {
      setTimeout(() => {
        if (!this.loadedByWatch) {
          this.fetchEpisodesByParams(null, null)
          this.loadedByWatch = false
        }
      }, 1500)
    },
    tagsPresent() {
      return this.tags.length > 0
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../shared/assets/styles/global';

.episodes-block {
  width: 41.75rem;
  height: 21.625rem;
  left: 32.125rem;
  top: 18.438rem;
  background: #ffffff;
  box-shadow: 0 0.25rem 2.5rem rgba(167, 167, 167, 0.15);
  border-radius: 0.75rem;
  padding: 6.563rem 4.875rem 6.563rem 4.875rem;
  text-align: center;
  margin-right: 3.2rem;
}

.main-block {
  width: 100%;
  padding: 0 16px 0 32px;
  margin-bottom: 72px;
  display: flex;
}

.bookmarks-title-row {
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13px;
  margin-right: 48px;

  .bookmarks-title {
    height: 42px;
    vertical-align: middle;
    display: flex;
  }

  .title-text {
    font-size: 24px;
    color: #323232;
  }

  .counter {
    font-size: 14px;
    margin: auto 4px;
    color: #a6a6a6;
  }
}

.episodes-placeholder {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  justify-content: center;
  height: 624px;
  .episodes-block {
    overflow: hidden;
  }
  .placeholder-border {
    position: relative;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -6.563rem;
      left: -4.875rem;
      width: 41.75rem;
      height: 4px;
      background: #d18f42;
    }
  }
}

.placeholder-button {
  height: 56px;
  width: 300px;
}

.primary-placeholder-title {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0;
  text-align: center;
  color: #5c5c5c;
}

.secondary-placeholder-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0;
  text-align: center;
  color: #323232;
  margin-bottom: 20px;
  margin-top: 12px;
}

.bookmarks-search {
  font-size: 12px;
  color: #5c5c5c;
  background-color: #dbebf0;
  padding: 4px 12px;
  font-weight: 400;
}

.bookmarks-filter-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-height: 32px;
  height: auto;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-right: 48px;
}

.filter-bookmark__no-result {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;

  color: var(--text-secondary, #5c5c5c);
  font-size: 14px;
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-top: 16px;
}
</style>
