<template>
  <div v-if="!headless" class="modal-header">
    {{ modifyHeader() }}
  </div>
  <div v-if="headless" class="modal-buttons headless">
    <div class="cancel" @click.stop="cancel">
      Cancel
    </div>
    <div class="save" :class="maybeDisabled()" @click.stop="submitForm">
      Save
    </div>
  </div>
  <div class="modal-text" v-html="formattedText" />
  <div class="modal-tags">
    <span class="bookmark-edit-tags__title">{{ TAGS_TITLE }}</span>
    <div
      v-for="tag of filterTags(localNewsBookmark.tags)"
      :key="tag.name"
      class="bookmark-edit-tags__tag"
    >
      <span class="bookmark-edit-tags__tag-name">{{ tag.name }}</span>
      <CloseIcon
        class="bookmark-edit-tags__tag-remove"
        :theme="THEMES.LIGHT"
        @click="removeTag(tag)"
      />
    </div>

    <TagsSelect
      v-if="canAddMoreTags"
      :close-dropdown-trigger="closeDropdownTrigger"
      :tag="tagInput"
      :theme="THEMES.LIGHT"
      :attached-tags="localNewsBookmark.tags"
      :has-same-tag="hasSameTag"
      @[EMITTERS.TAG_ADDED_EMITTER]="addTag"
    />
    <div v-else class="tags-limit-message">
      {{ TAGS_LIMIT_MESSAGE }}
    </div>
  </div>
  <div v-if="!headless" class="modal-buttons">
    <div class="cancel" @click.stop="cancel">
      Cancel
    </div>
    <div class="save news-bookmark-save" :class="maybeDisabled()" @click.stop="submitForm">
      Save
    </div>
  </div>
</template>

<script>
import { cloneDeep, sortBy } from 'lodash'

import { EMITTERS } from '../../shared/utils/emitters'
import TagsSelect from '../../study_tools/components/elements/TagsSelect'
import {
  BOOKMARK_MODES as MODES,
  THEMES,
} from '../../shared/utils/view_options'
import { CloseIcon } from '../../shared/components/icons'
import NewsBookmarksApiService from '../services/newsBookmarksApi.service'

const EMPTY_NEWS_BOOKMARK = {
  id: null,
  text: '',
  match_number: 0,
  tags: [],
}
export default {
  name: 'ManageNewsBookmarkComponent',
  components: {
    TagsSelect,
    CloseIcon,
  },
  props: {
    newsBookmark: {
      id: Number | null,
      match_number: Number,
      text: String,
      tags: Array,
    },
    mode: {
      required: true,
      type: String, // MODES.EDIT | MODES.SHOW | MODES.CREATE,
      default: MODES.CREATE,
    },
    headless: {
      default: false,
      required: false,
      type: Boolean,
    },
  },
  data() {
    return {
      THEMES,
      EMITTERS,
      localNewsBookmark: this.newsBookmark,
      storedNewsBookmark: {},
      currentMode: this.mode,
      TAGS_TITLE: 'Tags',
      TAGS_LIMIT: 10,
      TAGS_LIMIT_MESSAGE: 'You have reached the tag limit',
      tagInput: { name: '' },
      closeDropdownTrigger: false,
      activeSave: this.mode !== MODES.EDIT,
    }
  },
  computed: {
    formattedText() {
      return (
        '<p>' + this.localNewsBookmark.text.replace(/\n\n/g, '</p><p>') + '</p>'
      )
    },
    canAddMoreTags() {
      return (
        this.filterTags(this.localNewsBookmark.tags).length < this.TAGS_LIMIT
      )
    },
    hasSameTag() {
      return !!this.localNewsBookmark.tags.find(
        (tag) => tag.name === this.tagInput.name && tag._destroy !== true
      )
    },
    isEditMode() {
      return this.localNewsBookmark.id !== null
    },
    isCreateMode() {
      return this.localNewsBookmark.id === null
    },
  },
  created() {
    this.storedNewsBookmark = cloneDeep(this.newsBookmark)
  },
  methods: {
    closeDialog() {
      this.$emit(EMITTERS.CLOSE_DIALOG_EMITTER, this.newsBookmark)
    },
    cancel() {
      Object.assign(this.newsBookmark, this.storedNewsBookmark)
      this.closeDialog()
    },
    submitForm() {
      if (!this.activeSave) return

      if (this.isCreateMode) this.createBookmark()
      if (this.isEditMode) this.updateBookmark()
    },
    updateBookmark() {
      NewsBookmarksApiService.updateBookmark(
        this.localNewsBookmark,
        this.optional_params
      )
        .then((_response) => {
          this.emitter.emit(EMITTERS.SHOW_NOTIFICATION_EMITTER, {
            message: 'Your bookmark was updated',
          })
        })
        .catch((_response) => {
          this.emitter.emit(
            EMITTERS.SHOW_ERROR_NOTIFICATION_EMITTER,
            'Failed to update bookmark'
          )
        })
        .finally(() => {
          this.$emit(EMITTERS.BOOKMARK_UPDATED_EMITTER)
          this.clearForm()
        })
    },
    createBookmark() {
      NewsBookmarksApiService.createBookmark(
        this.localNewsBookmark,
        this.optional_params
      )
        .then((_response) => {
          this.emitter.emit(EMITTERS.SHOW_NOTIFICATION_EMITTER, {
            message: 'Your bookmark was created',
          })
        })
        .catch((_response) => {
          this.emitter.emit(EMITTERS.SHOW_ERROR_NOTIFICATION_EMITTER, {
            message: 'Failed to create bookmark',
          })
        })
        .finally(() => {
          this.$emit(EMITTERS.BOOKMARK_CREATED_EMITTER)
          this.clearForm()
        })
    },
    closeTagsDropdown() {
      this.closeDropdownTrigger = !this.closeDropdownTrigger
    },
    addTag(tag) {
      this.$emit(EMITTERS.NEWS_BOOKMARK_CHANGED)
      this.activeSave = true
      this.localNewsBookmark.tags.push({ name: tag.name })
      this.resetTagsInput()
    },
    resetTagsInput() {
      this.tagInput.name = ''
    },
    filterTags(tags) {
      if (!tags) return []
      const filteredTags = tags.filter((tag) => !(tag.st_taggable_id === null))
      return sortBy(filteredTags, ['name', 'created_at'])
    },
    removeTag(tag) {
      this.$emit(EMITTERS.NEWS_BOOKMARK_CHANGED)
      this.activeSave = true
      if (this.isCreateMode) {
        this.localNewsBookmark.tags = this.localNewsBookmark.tags.filter(
          (element) => element !== tag
        )
      }

      if (this.isEditMode) {
        const removedTag = this.localNewsBookmark.tags.find(
          (element) => element === tag
        )
        removedTag.st_taggable_id = null
        removedTag.st_taggable_type = null
        removedTag._destroy = true
      }
    },
    clearForm() {
      this.closeTagsDropdown()
      this.reloadBookmark()
      this.tagsOpen = false
    },
    reloadBookmark() {
      const newsBookmark = this.isCreateMode
        ? EMPTY_NEWS_BOOKMARK
        : this.newsBookmark
      this.localNewsBookmark = cloneDeep(newsBookmark)
      this.resetTagsInput()
    },
    modifyHeader() {
      return this.isCreateMode ? 'Create Bookmark' : 'Edit Bookmark'
    },
    maybeDisabled() {
      return this.activeSave ? '' : 'disabled'
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../shared/assets/styles/global';
$edit-tags-color-light: #5c5c5c;
$tag-border-light: #d1d3d4;
$tags-limit-color-light: #8f8a9b;

.button {
  border-radius: 4px;
  border: none;
  font-size: 14px;
  height: 32px;
  width: 80px;
  color: $white;
  justify-content: center;
  display: flex;
  padding: 6px 0;
}

.save {
  @extend .button;
  background-color: #6998ae;
  margin: 0 8px;
  cursor: pointer;
  opacity: 1;

  &.disabled {
    cursor: default;
    opacity: 0.5;
  }
}

.cancel {
  @extend .button;
  box-shadow: 0 2px 10px rgba(73, 73, 73, 0.2);
  background-color: $white;
  color: #6998ae;
  cursor: pointer;
}

.modal-header {
  color: #323232;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0;
  text-align: left;
  display: flex;
  padding: 16px 0;
}

svg {
  margin-right: 8px;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 16px;
}

.modal-text {
  color: #323232;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  padding: 16px 0;
  border-top: 1px solid #eaedee;
  border-bottom: 1px solid #eaedee;
  overflow-y: scroll;
  max-height: 200px;

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 6px;
    height: 6px;
    border: none;
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
    background-color: transparent;
    display: none;
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: #8f8a9b;
    border: 4px solid #8f8a9b;
    border-radius: 4px;
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {
    display: none;
  }
}

.modal-tags {
  padding: 16px 0;
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 100%;
}

.bookmark-edit-tags__title {
  font-size: 14px;
  flex-grow: 0.02;
  margin: auto 0;
  color: $edit-tags-color-light;
}

.bookmark-edit-tags__tag {
  display: flex;
  padding: 4px 10px;
  margin: 3px 3px;
  border-radius: 20px;
  font-size: 12px;
  justify-content: space-between;
  flex-grow: 0.02;
  border: 1px solid $tag-border-light;
}

.bookmark-edit-tags__tag-remove {
  margin: auto 0;
}

.tags-limit-message {
  font-size: 14px;
  line-height: 18px;
  margin: auto 3px;
  color: $edit-tags-color-light;
}

.bookmark-edit-tags__tag-name {
  color: $edit-tags-color-light;
}
</style>
