<template>
  <div class="main-block-wrapper">
    <div class="main-block">
      <div class="video-resources__sidebar">
        <div class="video-resources__title">Video Resources</div>
        <div
          v-for="tab in VIDEO_RESOURCES_ADMIN_ROUTES"
          class="video-resources__tab"
        >
          <router-link class="video-resources__block" :to="tab.path">
            {{ tab.name }}
          </router-link>
        </div>
      </div>
      <div class="video-resources__container">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { RouterLink, RouterView } from 'vue-router'

import { VIDEO_RESOURCES_ADMIN_ROUTES } from '../../shared/utils/routes'
import {EMITTERS} from "../../shared/utils/emitters";

export default {
  name: 'VideoResourcesAppComponent',
  components: {
    RouterLink,
    RouterView,
  },
  data() {
    return {
      VIDEO_RESOURCES_ADMIN_ROUTES,
      EMITTERS,
      episodeId: this.optional_params.episode_id,
    }
  },
  created() {
    this.updateActiveTab()
  },
  mounted() {
    window.addEventListener('hashchange', () => {
      this.updateActiveTab()
    })
  },
  methods: {
    updateActiveTab() {
      window.location.hash = this.$router.currentRoute.value.path
    },
  },
}
</script>

<style lang="scss" scoped>
.main-block-wrapper {
  position: relative;
  width: 100%;
  font-family: 'DM Sans', sans-serif;
}

.main-block {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}

.video-resources__sidebar {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 17%;
  padding-right: 16px;
  gap: 8px;
}

.video-resources__container {
  width: 83%;
  display: flex;
  flex-direction: column;
  padding-top: 40px;
}
.video-resources__tab {
  display: flex;
  a {
    padding: 6px 12px;
    border-radius: 3px;
    color: #323232;
    text-decoration: none;

    &.router-link-active {
      background: #414042;
      color: #ffffff;
    }
  }
}

.video-resources__title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #323232;
}
</style>
