<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M10 2.5C5.86 2.5 2.5 5.86 2.5 10C2.5 14.14 5.86 17.5 10 17.5C14.14 17.5 17.5 14.14 17.5 10C17.5 5.86 14.14 2.5 10 2.5ZM7.9675 13.2175L5.275 10.525C4.9825 10.2325 4.9825 9.76 5.275 9.4675C5.5675 9.175 6.04 9.175 6.3325 9.4675L8.5 11.6275L13.66 6.4675C13.9525 6.175 14.425 6.175 14.7175 6.4675C15.01 6.76 15.01 7.2325 14.7175 7.525L9.025 13.2175C8.74 13.51 8.26 13.51 7.9675 13.2175Z"
      fill="#DAA568"
    />
  </svg>
</template>

<script>
export default {
  name: 'VerifiedIcon',
}
</script>

<style scoped>
svg {
  cursor: pointer;
}
</style>
