import { STUDY_TOOLS_BOOKMARKS_ROUTES } from '../shared/utils/routes'
import createAppRouter from './create_router'

const studyToolsBookmarksRouter = createAppRouter(
  STUDY_TOOLS_BOOKMARKS_ROUTES,
  {
    redirect: true,
    history: true,
  }
)

export default studyToolsBookmarksRouter
