export const API_URL = window.location.origin

export const HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
}

export const API_ROUTES = {
  series: (seriesId) => `/api/series/${seriesId}`,
}

export const BASE_API_CONFIG = {
  baseURL: API_URL,
  headers: HEADERS,
}
