<template>
  <div :class="[theme, lockedClass]" class="notes-list">
    <NoteForm
      v-if="showInlineForm()"
      v-model:active="isLocked"
      :hide-title="true"
      :theme="theme"
      :cancel-with-redirect-to="'profile_note_list'"
      @[EMITTERS.NOTE_CREATED_EMITTER]="closeInlineEdit"
      @[EMITTERS.NOTE_UPDATED_EMITTER]="closeInlineEdit"
    />
    <div
      v-if="!showInlineForm()"
      class="notes-list-container"
      :class="{ 'overflow-unset': inlineEdit }"
    >
      <div v-if="!loading" class="notes__list">
        <TutorialStep
          v-if="showTutorial && notes.length > 0"
          :step-positions="[20, 21]"
          :step-number="tutorialStep"
          @[EMITTERS.CHANGE_TUTORIAL_STEP_EMITTER]="propagateStepChange"
        />
        <div v-for="note in notes" :key="note">
          <div class="notes__note" @click="editNote(note)">
            <div class="locked-label-wrapper">
              <div
                v-if="currentNote && currentNote.id === note.id"
                class="locked-label"
              >
                {{ $vueTranslate('study_tools.notes_list.locked_label') }}
              </div>
            </div>

            <div class="notes__note-content-wrapper">
              <div class="notes__note-title">{{ note.title }}</div>
              <div class="notes__note-content">{{ note.plain_content }}</div>
            </div>

            <div class="note-actions">
              <div class="tags-area" @click.stop>
                <button
                  v-if="note.tags && note.tags.length"
                  type="button"
                  :data-id="note.id"
                  class="tags-button bookmark-action"
                  @click="toggleTagBlock({ hide: false, refId: note.id })"
                >
                  {{ $vueTranslate('study_tools.notes_list.tags_title') }}
                </button>
                <Tags
                  v-if="tagsOpen === note.id"
                  :tags="note.tags"
                  :container-name="'notes-list-container'"
                  :theme="theme"
                  @[EMITTERS.TOGGLE_TAG_BLOCK_EMITTER]="toggleTagBlock"
                />
              </div>
              <div class="delete-button-wrapper" @click.stop>
                <a class="note-action delete-action">
                  <DeleteIcon :theme="theme" @click="openDeleteDialog(note)" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <img
        v-else
        src="../../shared/assets/images/loading.gif"
        alt="loading"
        class="note-loader"
      />

      <div v-if="!loading && notes.length === 0" class="empty-list">
        {{ $vueTranslate('study_tools.notes_list.empty_notes_list_title') }}
      </div>
    </div>

    <ConfirmationDialog
      v-if="noteToDelete"
      :theme="theme"
      :entity="noteToDelete"
      :on-deleted-emitter="EMITTERS.NOTE_DELETED_EMITTER"
      :confirmation-text="
        $vueTranslate('study_tools.notes_list.delete_confirmation_text')
      "
      :delete-function="DELETE_FUNCTION"
      @[EMITTERS.CLOSE_DIALOG_EMITTER]="closeDeleteDialog"
      @[EMITTERS.NOTE_DELETED_EMITTER]="noteDeleted"
    />
  </div>
</template>

<script>
import { EMITTERS } from '../../shared/utils/emitters'
import { NOTE_DELETED } from '../../shared/utils/notificationMessages'
import Tags from './Tags'
import NoteForm from './NoteForm'
import DeleteIcon from '../../shared/components/icons/Delete'
import ConfirmationDialog from './ConfirmationDialog'
import {
  BOOKMARK_MODES as MODES,
  SITES,
  THEMES,
} from '../../shared/utils/view_options'
import { NotesApiService } from '../services'
import TutorialStep from './elements/TutorialStep'

export default {
  name: 'NoteFormComponent',
  components: {
    Tags,
    DeleteIcon,
    ConfirmationDialog,
    NoteForm,
    TutorialStep,
  },
  props: {
    reloadTrigger: {
      type: Boolean,
      required: true,
    },
    isLocked: {
      type: Boolean,
      required: true,
    },
    episodeId: {
      type: String,
      required: false,
      default: null,
    },
    inlineEdit: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: THEMES.DARK,
    },
    notesLimit: {
      type: Number,
      default: null,
    },
    showTutorial: {
      type: Boolean,
      default: false,
    },
    tutorialStep: {
      type: Number,
      default: null,
    },
  },
  emits: [EMITTERS.NOTE_DELETED_EMITTER, EMITTERS.CHANGE_TUTORIAL_STEP_EMITTER],
  data() {
    return {
      MODES,
      EMITTERS,
      loading: true,
      count: 0,
      noteReloadTrigger: false,
      notes: [],
      lockedLabelOffset: 0,
      tagsOpen: false,
      noteToDelete: undefined,
      currentNoteOriginal: undefined,
      ATTRIBUTES_TO_COMPARE: ['content', 'id', 'title'],
      DELETE_FUNCTION: NotesApiService.deleteNote,
    }
  },
  computed: {
    lockedClass() {
      let noteChanged =
        this.currentNoteOriginal &&
        this.currentNote.id === this.currentNoteOriginal.id
      noteChanged =
        noteChanged &&
        (!_.isEqual(
          _.pick(this.currentNote, this.ATTRIBUTES_TO_COMPARE),
          _.pick(this.currentNoteOriginal, this.ATTRIBUTES_TO_COMPARE)
        ) ||
          _.xorBy(
            this.currentNote.tags,
            this.currentNoteOriginal.tags,
            (tag) => tag.id
          ).length !== 0)

      this.$store.dispatch('updateNoteChanged', !!noteChanged)
      if (this.inlineEdit) return ''
      if (!this.isLocked) return ''

      return noteChanged || !this.currentNote.id ? 'locked' : ''
    },
    currentNote() {
      if (!this.$store) return null

      return this.$store.state.note
    },
  },
  watch: {
    reloadTrigger() {
      this.fetchNotes()
    },
    notesLimit() {
      if (this.notesLimit && this.notesLimit >= this.notes.length) {
        this.notes = this.notes.slice(0, this.notesLimit)
      } else {
        this.fetchNotes()
      }
    },
  },
  created() {
    this.setupLeaveConfirmation()
    this.fetchNotes()
  },
  methods: {
    setupLeaveConfirmation() {
      if (!this.$store.state.leaveConfirmationCallbackAdded) {
        $('a, .profile_sidebar .nav-link').on('click', this.confirmLeavePage)
        window.addEventListener('beforeunload', (event) => {
          if (!this.hasUnsavedChanges()) return true

          event.preventDefault()
          event.returnValue = ''
          return false
        })
        this.$store.dispatch('leaveConfirmationCallbackAdded')
      }
    },
    fetchNotes() {
      return NotesApiService.getAllNotes(this.episodeId, this.optional_params)
        .then((response) => {
          const responseData = response.data

          if (this.notesLimit) {
            this.notes = responseData.notes.slice(0, this.notesLimit)
          } else {
            this.notes = responseData.notes
          }
          this.count = responseData.count
          this.initCurrentNoteOriginal()
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false
        })
    },
    initCurrentNoteOriginal() {
      if (this.currentNote) {
        const noteId = this.currentNote.id
        const currentNoteIndex = _.findIndex(this.notes, (note) => {
          return note.id === noteId
        })
        if (currentNoteIndex >= 0) {
          this.currentNoteOriginal = this.notes[currentNoteIndex]
        }
      }

      if (
        this.currentNoteOriginal &&
        this.notes.findIndex(
          (note) => note.id === this.currentNoteOriginal.id
        ) === -1
      ) {
        this.currentNoteOriginal = undefined
        this.$store.dispatch('resetNote')
      }
    },
    editNote(note) {
      this.currentNoteOriginal = note
      this.$store.dispatch('editNote', note)
      if (!this.inlineEdit) {
        $('.study-tools-app .navigation')
          .get(0)
          .scrollIntoView({ behavior: 'smooth' })
      } else {
        this.$router.push({
          name: 'profile_note_edit',
          params: { id: note.id },
        })
      }
    },
    confirmLeavePage(event) {
      if (!this.hasUnsavedChanges()) return true
      const $sideBarBtns = $('.profile_sidebar .nav-link')

      if (confirm('Do you want to leave?\nYou have unsaved changes')) {
        $sideBarBtns.removeClass('prevented')
        return true
      } else {
        $sideBarBtns.addClass('prevented')
        $sideBarBtns.removeClass('active')
        $('.notes-button .nav-link').addClass('active')
        event.stopPropagation()
        event.preventDefault()
        return false
      }
    },
    hasUnsavedChanges() {
      if (!this.dataset.notes_url) return false
      if (this.dataset.notes_url !== window.location.pathname) return false
      if (!this.$route) return false
      if (this.$route.name !== 'profile_note_edit') return false
      if (!this.inlineEdit) return false

      return this.$store.state.noteChanged
    },
    toggleTagBlock(data) {
      this.tagsOpen = data.hide ? false : data.refId
    },
    closeDeleteDialog() {
      this.noteToDelete = undefined
    },
    openDeleteDialog(note) {
      this.noteToDelete = note
    },
    noteDeleted(deletedNote) {
      this.fetchNotes().then(() => {
        this.$emit(EMITTERS.NOTE_DELETED_EMITTER, this.notes, deletedNote)
        this.emitter.emit(EMITTERS.SHOW_NOTIFICATION_EMITTER, {
          message: NOTE_DELETED,
          site: SITES.WATCH,
        })
      })
    },
    showInlineForm() {
      if (!this.inlineEdit) return false
      if (!this.currentNote) return false
      if (!this.currentNoteOriginal) return false

      return !!this.currentNote.id
    },
    closeInlineEdit() {
      this.currentNoteOriginal = undefined
      this.$store.dispatch('resetNote')
      this.$router.push({ name: 'profile_note_list' })
    },
    propagateStepChange(event) {
      this.$emit(EMITTERS.CHANGE_TUTORIAL_STEP_EMITTER, event)
    },
  },
}
</script>

<style lang="scss">
.notes-list-container {
  .bookmark-tags-container {
    margin-left: -170px;
  }
}
</style>

<style lang="scss" scoped>
@import '../../shared/assets/styles/global';

.note-loader {
  margin: 50px auto;
  width: 200px;
  display: flex;
  vertical-align: middle;
}

.notes-list {
  margin-top: 28px;
}

.notes-list-container {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding-inline-start: 0;
  height: 350px;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 3px;

    &:hover {
      width: 10px;
    }
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}
.notes-list-container.overflow-unset {
  overflow-y: unset;
  overflow-x: unset;
}
.note-actions {
  display: flex;
  margin-left: auto;
  justify-content: end;
  width: 205px;
  height: 30px;
  margin-right: 3px;
  align-items: center;
}

.delete-button-wrapper {
  margin-top: 16px;
  margin-right: 16px;
}

.locked {
  pointer-events: none;

  .locked-label-wrapper {
    display: block;
    position: relative;
    width: 0;
    .locked-label {
      position: absolute;
      width: 120px;
      left: 0;
      top: 10px;
      background: black;
      padding: 4px 12px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }
  }
}
.locked-label-wrapper {
  display: none;
}
.empty-list {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: auto;
}
.notes__note {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.notes__note-content-wrapper {
  width: 100%;
  padding: 16px;
}
.notes__note-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 2px;
}
.notes__note-content {
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  height: unset;
}
.tags-area {
  margin-top: 18px;
  margin-right: 16px;
  position: relative;
}
.note-tags__title {
  font-size: 12px;
  line-height: 16px;
}

.tags-button {
  padding: 3px 12px;
  font-size: 14px;
  border-radius: 25px;
}

// theme
// dark
$edit-tags-color-light: #5c5c5c;
$tag-border-light: #d1d3d4;
$tags-limit-color-light: #8f8a9b;
$edit-tags-color-dark: #d1d3d4;
$tag-border-dark: #444050;
$tags-limit-color-dark: #8f8a9b;
$tags-white: #ffffff;
$tags-button-dark: #3b3946;
$note-hovered-dark: #32303c;

// light

$tag-border-light: #eaedee;

.light {
  .note-tags__tag-name {
    color: $edit-tags-color-light;
  }
}

.dark {
  &.locked {
    .locked-label-wrapper {
      .locked-label {
        color: $tags-white;
        background: #b56941;
      }
    }

    .notes__note-title,
    .notes__note-content,
    .note__action-button,
    .tags-button {
      color: $edit-tags-color-light;
    }
    .delete-button-wrapper {
      opacity: 0.3;
    }
  }
  .notes-list-container {
    background-color: #2b2834;
    &::-webkit-scrollbar-thumb {
      background-color: $tags-limit-color-dark;
      border: 4px solid $tags-limit-color-dark;
    }
  }

  .tags-button {
    background-color: #3b3946;
    border: 1px solid #444050;
    color: #ffffff;
  }

  .notes__note {
    border-bottom: 1px solid $tag-border-dark;
    &:hover {
      background: $note-hovered-dark;
      cursor: pointer;
    }
  }
  .notes__note-title,
  .notes__note-content,
  .note__action-button {
    color: $tags-white;
  }
  .note__cancel-button {
    background-color: $tag-border-dark;
  }

  .note-tags__title {
    color: $tags-white;
  }

  .note-tags__tag-name {
    color: $edit-tags-color-dark;
  }

  .note-tags__tag {
    border: 1px solid $tag-border-dark;
  }
  .empty-list {
    color: $tags-limit-color-dark;
  }
}

.light {
  .notes__note {
    border-bottom: 1px solid $tag-border-light;

    &:hover {
      background: #f9f9f9;
    }
  }

  .tags-button {
    background-color: #ffffff;
    border: 1px solid #d1d3d4;
    color: #5c5c5c;
  }
  &.notes-list {
    margin-top: 0;
  }
  .notes-list-container {
    height: unset;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
