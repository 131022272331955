<template>
  <div class="message-bubble" @mouseover="markAsRead()">
    <div v-if="!message.seen_at && !markedAsSeen" class="message-seen"></div>
    <div class="message-title">
      {{ message.title }}
    </div>

    <div v-if="message && message.image_url" class="message-image">
      <img
        :src="message.image_url"
        class="message-image-in-text"
        alt="Message image"
        @click="imageOpen = true"
      />

      <div v-if="imageOpen" class="image-pop-up" @click="imageOpen = false">
        <img :src="message.image_url" alt="Message Big image" />
      </div>
    </div>

    <div class="message-text" v-html="message.text"></div>

    <LinkPreview v-if="needsLinkPreview()" :text="message.text" />

    <div class="message-sent-at">
      {{ sentAt }}
    </div>
  </div>
</template>

<script>
import ApiService from '../services/messagesApi.service'
import LinkPreview from '../../shared/components/LinkPreview'

export default {
  name: 'MessageComponent',
  components: { LinkPreview },
  props: {
    message: {
      required: true,
      type: Object,
    },
    index: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      poolSize: 3,
      imageOpen: false,
      markedAsSeen: false,
    }
  },
  computed: {
    sentAt() {
      const timeOptions = { timeStyle: 'short' }
      return new Date(this.message.sent_at).toLocaleTimeString([], timeOptions)
    },
  },
  methods: {
    markAsRead() {
      if (this.message.seen_at) return
      this.markedAsSeen = true
      ApiService.markMessageAsSeen(this.message.id)
        .then()
        .catch((e) => {
          this.errors.push(e)
          console.error(this.errors)
        })
    },
    needsLinkPreview() {
      return this.index + 1 <= this.poolSize
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../shared/assets/styles/global';

.message-seen {
  height: 8px;
  width: 8px;
  background-color: #d18f42;
  border-radius: 50%;
  position: absolute;
  right: 16px;
  top: 16px;
}
.message-bubble {
  position: relative;
  background-color: $white;
  padding: 16px 24px 16px 32px;
  border-radius: 8px;
  margin-bottom: 16px;
}
.message-title {
  font-size: 16px;
  font-weight: 500;
  color: #323232;
  overflow-wrap: break-word;
}
.message-text {
  font-size: 14px;
  color: #5c5c5c;
  margin-bottom: 6px;
  overflow-wrap: break-word;
}

.message-sent-at {
  margin-top: 20px;
  color: #5c5c5c;
  font-size: 12px;
  text-align: right;
}

.message-date-label {
  font-size: 14px;
  color: #5c5c5c;
  text-align: center;
  font-weight: 500;
  margin: 8px 0;
}
.image-pop-up {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(28, 25, 35, 0.6);
  z-index: 999999;

  img {
    box-shadow: 0 4px 30px rgba(23, 24, 28, 0.6);
    border-radius: 4px;
    transform: translate(-50%, -50%);
    height: 500px;
    margin-top: 50vh;
    margin-left: 50vw;
  }
}
.message-image-in-text {
  max-width: 100%;
}
</style>
