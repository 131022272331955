<template>
  <SearchBar v-if="!loading" :settings="settings" data-turbolinks="false" />
</template>

<script>
import SearchBar from './components/SearchBar'
import settingsApiService from '../shared/services/settingsApi.service'

export default {
  name: 'App',
  components: { SearchBar },
  data() {
    return {
      settings: null,
      loading: true,
    }
  },
  mounted() {
    this.fetchSettings()
  },
  methods: {
    fetchSettings() {
      settingsApiService
        .fetchSettings()
        .then((response) => {
          this.settings = response.data
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
