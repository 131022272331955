export const THEMES = {
  DARK: 'dark',
  LIGHT: 'light',
  PROFILE: 'profile',
}

export const SITES = {
  WATCH: 'watch',
  PROFILE: 'profile',
}

export const BOOKMARK_MODES = {
  EDIT: 'edit',
  SHOW: 'show',
  CREATE: 'create',
}

export const COMMENT_MODES = {
  EDIT: 'edit',
  SHOW: 'show',
  CREATE: 'create',
}

export const THEME_COLOR = {
  DARK: '#FFFFFF',
  LIGHT: '#A6A6A6',
  PROFILE: '#6998AE',
}

export const VIDEO_RESOURCES_MODES = {
  EDIT: 'edit',
  CREATE: 'create',
  SHOW: 'show',
}

export const VIDEO_RESOURCES_TABS = [
  { name: 'Links', active: true },
  { name: 'Documents' },
]
