<template>
  <div>
    <DonateBlock v-if="displayDonate" :banner="JSON.parse(optional_params.donate_banner)"
                 :ga-tracking-info="{ pageType: 'satellite_schedule'}" />
    <div
      class="program__block"
      :class="{ program__active: isActive() }"
      :data-program-Id="program.id"
    >
      <div
        class="program__time"
        :class="{ 'program__time-active': isActive() }"
      >
        {{ getTime() }}
        <div v-if="isActive()" class="program__time-label">
          {{ $vueTranslate('schedule.program.label_title') }}
        </div>
      </div>
      <div class="program__description">
        <span class="program__title">
          {{ program.program_name }}
        </span>
        <span v-if="program.series_name" class="program__series">
          {{ program.series_name }}
        </span>
        <span v-if="program.speaker_name" class="program__speaker">
          {{ $vueTranslate('schedule.program.speaker_prefix')
          }}{{ program.speaker_name }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import DonateBlock from './DonateBlock'
import { formatTime } from '../utils/time_utils'

export default {
  name: 'ProgramComponent',
  components: { DonateBlock },
  props: {
    timezone: {
      type: String,
      required: true,
    },
    program: {
      program_name: String,
      series_name: String,
      speaker_name: String,
      scheduled_at_in_timezone: String,
    },
    nextProgram: {
      program_name: String,
      series_name: String,
      speaker_name: String,
      scheduled_at_in_timezone: String,
    },
    displayDonate: Boolean,
  },
  methods: {
    getTime() {
      const scheduled_at = formatTime(
        new Date(this.program.scheduled_at_in_timezone),
        this.timezone
      )
      return (
        scheduled_at.getHours() +
        ':' +
        String(scheduled_at.getMinutes()).padStart(2, '0')
      )
    },
    isActive() {
      const startTime = formatTime(
        new Date(this.program.scheduled_at_in_timezone),
        this.timezone
      )
      const currentTime = formatTime(new Date(), this.timezone)
      const endOfDay = formatTime(new Date(), this.timezone)
      endOfDay.setHours(23, 59, 59, 59)

      if (startTime.getDate() !== currentTime.getDate()) return false

      const endTime = this.nextProgram
        ? formatTime(
            new Date(this.nextProgram.scheduled_at_in_timezone),
            this.timezone
          )
        : endOfDay

      return currentTime >= startTime && endTime >= currentTime
    },
  },
}
</script>

<style lang="scss">
.program__block {
  position: relative;
  height: 127px;
  border-bottom: solid #eaedee 1px;

  @media (max-width: 768px) {
    height: 149px;
    padding: 16px;
    display: flex;
    flex-direction: column;
  }
}

.program__active {
  border: 1px solid #d18f42 !important;
  border-radius: 8px;
  width: 101.2%;
  right: 6px;

  @media (max-width: 768px) {
    width: 100%;
    right: 0;
  }
}

.program__time {
  position: absolute;
  width: 143px;
  height: 100%;
  padding-top: 16px;
  padding-left: 20px;
  font-family: 'DM Sans', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #323232;
  border-right: solid #eaedee 1px;

  @media (max-width: 768px) {
    position: relative;
    width: 100%;
    height: auto;
    padding-top: 0;
    padding-left: 0;
    border-right: none;
  }
}

.program__timeunit {
  font-family: 'DM Sans', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #5c5c5c;

  @media (max-width: 768px) {
    margin-left: 6px;
  }
}

.program__time-active {
  width: 148px;

  @media (max-width: 768px) {
    width: 100%;
    display: flex;
  }
}

.program__description {
  position: relative;
  top: 16px;
  left: 168px;
  display: flex;
  flex-direction: column;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;

  @media (max-width: 768px) {
    top: 0;
    left: 0;
  }
}

.program__title {
  font-family: 'DM Sans', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #323232;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.program__series {
  margin-top: 4px;
  font-family: 'DM Sans', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #323232;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.program__speaker {
  margin-top: 4px;
  font-family: 'DM Sans', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #5c5c5c;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.program__time-label {
  position: absolute;
  width: 67px;
  height: 24px;
  left: 30px;
  padding-top: 4px;
  background: #d18f42;
  border-radius: 4px;
  font-family: 'DM Sans', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: $white;

  @media (max-width: 768px) {
    position: relative;
    left: 0;
    margin-left: auto;
  }
}
</style>
