<template>
  <TextInput
    v-if="isCreateMode || isEditMode"
    v-model:value="localGuide.title"
    :max-length="80"
    placeholder="Title"
    :error="errors.title.join()"
  />

  <div
    v-if="isCreateMode || isEditMode"
    class="video-resources__form-control document__document"
  >
    <DragAndDrop
      :key="localGuide.document"
      :attachment="localGuide.document"
      :progress="progress"
      :uploading="uploading"
      placeholder="Upload file"
      theme=""
      @[EMITTERS.FILE_SELECTED_EMITTER]="guideSelected"
    />
    <span class="document__file-error video-resources__form-error">
      {{ errors.document.join() }}
    </span>
  </div>

  <TextAreaInput
    v-if="isCreateMode || isEditMode"
    v-model:value="localGuide.description"
    :max-length="800"
    placeholder="Description"
    :error="errors.description.join()"
  />

  <div
    v-if="isCreateMode || isEditMode"
    class="document__form-buttons video-resources__control-buttons"
  >
    <button
      :disabled="isButtonDisabled"
      class="video-resources__control-button"
      @click="submitForm"
    >
      {{ submitButtonTitle }}
    </button>

    <button
      v-if="isEditMode"
      class="video-resources__control-button"
      @click="cancelUpdate"
    >
      {{ $vueTranslate('admin.guide.cancel_btn') }}
    </button>
  </div>

  <div
    v-if="isShowMode"
    ref="guideContainer"
    class="document__container"
    :class="[maybeActive]"
    @click="guideEdit"
  >
    <div class="video-resources__file__icon">
      <div v-if="readOnlyMode" class="video-resources__file__format">
        {{ fileFormat }}
      </div>
    </div>
    <span
      :class="{ document__text__wrapped: readOnlyMode }"
      class="document__text"
    >
      {{ fileTitle }}
    </span>
    <div class="document__control-spacer" />
    <a
      v-if="readOnlyMode"
      :href="localGuide.document.downloadLink"
      download
      target="_blank"
      class="video-resources_download"
      @click.stop
    >
      <DownloadIcon v-if="readOnlyMode" />
      <div class="video-resources__filesize">
        {{ localGuide.document.file_size }}
      </div>
    </a>
    <div class="video-resources__controls">
      <a
        v-if="!readOnlyMode"
        class="video-resources__preview"
        @click.stop="openPreview"
      >
        <PreviewIcon />
      </a>
      <div
        v-if="!readOnlyMode"
        class="document__delete"
        @click.stop="deleteGuide"
      >
        <div class="document__delete-icon" />
      </div>
    </div>
    <PreviewDialog
      v-if="showPreview"
      :theme="theme"
      :entity="guide"
      @[EMITTERS.CLOSE_DIALOG_EMITTER]="closePreview"
    />
  </div>
</template>
<script>
import { cloneDeep } from 'lodash'

import GuidesApiService from '../services/guidesApi.service'
import {
  THEMES,
  VIDEO_RESOURCES_MODES as MODES,
} from '../../../shared/utils/view_options'
import { EMITTERS } from '../../../shared/utils/emitters'
import DragAndDrop from './DragAndDrop'
import DownloadIcon from '../../../shared/components/icons/Download'
import TextInput from './lib/TextInput'
import TextAreaInput from './lib/TextAreaInput'
import PreviewIcon from '../../../shared/components/icons/Preview'
import PreviewDialog from './PreviewDialog'

export default {
  name: 'GuideComponent',
  components: {
    DragAndDrop,
    DownloadIcon,
    TextInput,
    TextAreaInput,
    PreviewIcon,
    PreviewDialog,
  },
  props: {
    theme: {
      default: THEMES.DARK,
      type: String,
    },
    active: {
      type: Number,
      default: null,
    },
    // eslint-disable-next-line vue/require-default-prop
    guide: {
      id: Number | null,
      title: String,
      description: String,
      document: File,
    },
    mode: {
      type: String,
      default: MODES.SHOW,
    },
    readOnlyMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    EMITTERS.GUIDE_CREATED_EMITTER,
    EMITTERS.GUIDE_EDIT_EMITTER,
    EMITTERS.GUIDE_UPDATED_EMITTER,
    EMITTERS.GUIDE_DELETED_EMITTER,
    EMITTERS.GUIDE_CANCEL_EMITTER,
  ],
  data() {
    return {
      EMITTERS,
      localGuide: this.guide,
      isButtonDisabled: false,
      uploading: false,
      progress: 0,
      showPreview: false,
      errors: {
        title: [],
        document: [],
        description: [],
      },
    }
  },
  computed: {
    isEditMode() {
      return this.mode === MODES.EDIT
    },
    isCreateMode() {
      return this.mode === MODES.CREATE
    },
    isShowMode() {
      return this.mode === MODES.SHOW
    },
    submitButtonTitle() {
      return this.isCreateMode
        ? this.$vueTranslate('admin.guide.add_btn')
        : this.$vueTranslate('admin.guide.update_btn')
    },
    maybeActive() {
      return this.active === this.guide.id ? 'active' : ''
    },
    fileTitle() {
      if (!this.readOnlyMode) return this.guide.title
      return this.guide.title.replace(/\..+/gi, '')
    },
    fileFormat() {
      if (!this.guide.document.name) return ''
      if (this.guide.document.name.split('.').length === 1) return ''

      return this.guide.document.name.match(/\.\w+/gi).pop().replace('.', '')
    },
  },
  methods: {
    async updateGuide() {
      if (this.isCreateMode) return
      this.isButtonDisabled = true
      this.uploading = true

      try {
        const response = await GuidesApiService.updateGuide(
          this.localGuide,
          this.optional_params,
          (progress) => {
            this.progress = progress
          }
        )
        this.clearForm()
        this.clearErrors()
        this.$emit(EMITTERS.GUIDE_UPDATED_EMITTER, response.guide)
      } catch (error) {
        this.updateErrors(error)
      } finally {
        this.uploading = false
        this.enableButton()
      }
    },
    async createGuide() {
      this.uploading = true
      this.isButtonDisabled = true

      try {
        const response = await GuidesApiService.createGuide(
          this.localGuide,
          this.optional_params,
          (progress) => {
            this.progress = progress
          }
        )
        this.clearForm()
        this.clearErrors()
        this.$emit(EMITTERS.GUIDE_CREATED_EMITTER, response.guide)
      } catch (error) {
        this.updateErrors(error)
      } finally {
        this.uploading = false
        this.enableButton()
      }
    },
    deleteGuide() {
      GuidesApiService.deleteGuide(this.localGuide, this.optional_params)
        .then(() => {
          this.$emit(EMITTERS.GUIDE_DELETED_EMITTER, this.localGuide)
        })
        .catch(console.error)
    },
    clearForm() {
      this.localGuide.title = ''
      this.localGuide.document = File
      this.localGuide.description = ''
      this.localGuide.id = null
      this.progress = 0
    },
    clearErrors() {
      this.errors.title = []
      this.errors.document = []
      this.errors.description = []
    },
    submitForm() {
      if (this.isCreateMode) this.createGuide()
      if (this.isEditMode) this.updateGuide()
    },
    updateErrors(e) {
      this.errors.title = e.response.data.errors.title || []
      this.errors.document = e.response.data.errors.document || []
      this.errors.description = e.response.data.errors.description || []
    },
    guideSelected(file) {
      this.localGuide.document = file
    },
    guideEdit() {
      if (this.readOnlyMode) return this.openPreview()

      const guideProps = cloneDeep(this.localGuide)
      this.$emit(EMITTERS.GUIDE_EDIT_EMITTER, guideProps)
    },
    enableButton() {
      this.isButtonDisabled = false
    },
    cancelUpdate() {
      this.$emit(EMITTERS.GUIDE_CANCEL_EMITTER)
    },
    openPreview() {
      this.showPreview = true
    },
    closePreview() {
      this.showPreview = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../shared/assets/styles/video_resources';
@import '../../../shared/assets/styles/document';
</style>
