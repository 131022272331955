// TODO: name or place this file better because it's about the Main site main menu only

import { scrollToTarget } from 'utils'

$(document).on('click', '.buy_link', function () {
  // Hide open collapse before scrolling (makes sense on mobile)
  const collapse = document.querySelector('#top_navbar .navbar-collapse')
  let scrollTarget = $(this).data('scrollTarget')
  const scrollDelay = 300

  $(collapse).collapse('hide')
  setTimeout(scrollToTarget, scrollDelay, scrollTarget)
})
