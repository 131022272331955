<template>
  <svg
    width="10"
    height="16"
    viewBox="0 0 10 16"
    class="feather feather-facebook"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.79458 9.00121L9.2475 6.10331H6.41565V4.22277C6.41565 3.42996 6.81123 2.65717 8.07953 2.65717H9.36694V0.189919C9.36694 0.189919 8.19865 -0.00585938 7.08165 -0.00585938C4.74953 -0.00585938 3.22515 1.38211 3.22515 3.8947V6.10331H0.632812V9.00121H3.22515V16.0067H6.41565V9.00121H8.79458Z"
      fill="#A6A6A6"
    />
  </svg>
</template>

<script>
export default {
  name: 'FacebookIcon',
}
</script>

<style scoped></style>
