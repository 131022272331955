// Prevent closing dropdown on click inside.
// TODO: Remove this after we upgrade to Bootstrap v5 which has desired
// capabilities out-of-box.
// Reference: https://getbootstrap.com/docs/5.0/components/dropdowns/#auto-close-behavior
$(document).on('click', '.dropdown-menu', (e) => {
  const target = e.target

  if (target.classList.contains('dropdown-menu')) {
    e.stopPropagation()
  }
})

$(document).on('turbolinks:load', () => {
  const activeClass = 'active'

  $('#dropdown_menu_button').on('click', function () {
    $(this).toggleClass(activeClass)
  })

  $(document).click(function () {
    const dropDown = $('#dropdown_menu_button')

    if (dropDown.hasClass(activeClass)) {
      dropDown.toggleClass(activeClass)
    }
  })
})
