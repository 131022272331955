import axios from 'axios'

import { API_ROUTES, API_URL, FORM_HEADERS } from '../../shared/utils/api'
import { SECTION_TYPES } from '../utils/name_util'

export default {
  async performSearch(query, page, type, per_page = 12, advanced_search = {}) {
    if (type === 'all') {
      const promises = SECTION_TYPES.map((t) => {
        const config = {
          baseURL: API_URL,
          headers: FORM_HEADERS,
          params: {
            query,
            page,
            type: t,
            per_page: 10,
            advanced_search: advanced_search[t],
          },
        }
        return axios
          .get(API_ROUTES.search, config)
          .then((response) => {
            const result = {}
            result[t] = response.data
            return result
          })
          .catch(() => [])
      })

      const results = await Promise.all(promises)
      const data = Object.assign({}, ...results)

      if (data.length === 0) throw new Error('No data found for any type.')

      return data
    }

    const config = {
      baseURL: API_URL,
      headers: FORM_HEADERS,
      params: {
        query,
        page,
        type,
        per_page,
        advanced_search: advanced_search[type],
      },
    }

    return axios.get(API_ROUTES.search, config)
  },
}
