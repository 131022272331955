import axios from 'axios'

import { API_ROUTES, BASE_API_CONFIG } from '../utils/api'

export default {
  getSeries(seriesId) {
    return axios.request({
      ...BASE_API_CONFIG,
      method: 'GET',
      url: API_ROUTES.series(seriesId),
    })
  },
}
