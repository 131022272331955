import axios from 'axios'

import { API_ROUTES, BASE_API_CONFIG } from '../../../shared/utils/api'

export default {
  uploadMedia(
    uuid,
    container,
    file_name,
    title,
    sku,
    description,
    type,
    optional_params
  ) {
    const required_params = {
      uuid: uuid,
      container: container,
      file_name: file_name,
      title: title,
      sku: sku,
      description: description,
      type: type,
    }

    const data = {
      ...optional_params,
      ...required_params,
    }

    return axios.request({
      ...BASE_API_CONFIG,
      method: 'POST',
      url: API_ROUTES.admin_upload_media,
      data,
    })
  },
  async checkSku(sku) {
    return await axios.request({
      ...BASE_API_CONFIG,
      method: 'GET',
      url: API_ROUTES.admin_upload_video_sku(sku),
    })
  },
  checkJobStatus(jobId) {
    return axios.request({
      ...BASE_API_CONFIG,
      method: 'GET',
      url: API_ROUTES.admin_upload_jobs_status(jobId),
    })
  },
  fetchAzureParamsParams() {
    return axios.request({
      ...BASE_API_CONFIG,
      method: 'POST',
      url: API_ROUTES.admin_medias_sas,
    })
  },
}
