<template>
  <div class="all-tab-block">
    <div
      v-for="section in SECTIONS"
      :key="section"
      class="content-section"
      :class="section + '-section'"
    >
      <div class="tab-section">
        <span class="name" :class="{ name__light: isLight }">
          {{ getTabName(section) }}
        </span>
        <span class="count" :class="{ count__light: isLight }">
          ({{ getTotalCountByType(section) }})
        </span>

        <div v-if="showSearchSelector(section)" class="search-type-block">
          <div
            class="radio-block"
            :class="{
              'radio-block-white': isLight,
            }"
          >
            <input
              :id="`basic-search-${section}`"
              v-model="advanced_search[section]"
              type="radio"
              :name="`search-selector-${section}`"
              :value="false"
              @change="updateAdvanceSearch"
            />
            <label
              class="radio-label"
              :class="{
                'radio-label-white': isLight,
              }"
              for="without-copyright"
            >
              {{ $vueTranslate('search.basic_search') }}
            </label>
          </div>
          <div
            class="radio-block"
            :class="{
              'radio-block-white': isLight,
            }"
          >
            <input
              :id="`advanced-search-${section}`"
              v-model="advanced_search[section]"
              type="radio"
              :name="`search-selector-${section}`"
              :value="true"
              @change="updateAdvanceSearch"
            />
            <label
              class="radio-label"
              :class="{
                'radio-label-white': isLight,
              }"
              for="ad-copyright"
            >
              {{ $vueTranslate('search.search_by_text') }}
            </label>
          </div>
        </div>
      </div>
      <div
        v-if="isFuzzy(section) && getEntriesByType(section).length > 0"
        class="no-results-block"
        :class="{ 'no-results-block__light': isLight }"
      >
        {{ messages.FUZZY_NO_RESULTS(query) }}
      </div>
      <EntriesCarousel
        v-if="getEntriesByType(section).length > 0"
        :type="section"
        :items="getEntriesByType(section)"
        :total-count="getTotalCountByType(section)"
        :query="query"
        :settings="settings"
        :is-light="isLight"
      />
      <div
        v-else
        class="no-results-block"
        :class="{ 'no-results-block__light': isLight }"
      >
        {{ messages.NO_RESULTS }}
      </div>
    </div>
  </div>
</template>

<script>
import { SEARCH_TABS, SECTION_TYPES } from '../utils/name_util'
import { MESSAGES } from '../utils/localization_util'
import EntriesCarousel from './EntriesCarousel'
import { EMITTERS } from '../../shared/utils/emitters'
export default {
  name: 'AllTab',
  components: { EntriesCarousel },
  props: {
    advancedSearch: {
      required: true,
      type: Object,
      default: () => ({
        videos: false,
        prophetic_news: false,
        articles: false,
      }),
    },
    entries: {
      required: true,
      type: Array,
      default: () => [],
    },
    query: {
      required: true,
      type: String,
      default: '',
    },
    settings: {
      required: true,
      default: () => ({
        watch_host: '',
        main_host: '',
        events_host: '',
      }),
      type: Object,
    },
    isLight: {
      default: false,
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      SECTIONS: SECTION_TYPES,
      messages: MESSAGES,
      advanced_search: this.advancedSearch,
    }
  },
  methods: {
    getEntriesByType(type) {
      return this.entries[type].entries || []
    },
    isFuzzy(type) {
      return this.entries[type].fuzzy || false
    },
    getTotalCountByType(type) {
      return this.entries[type].total_count || 0
    },
    getTabName(name) {
      return SEARCH_TABS[name]
    },
    showSearchSelector(type) {
      return type === 'prophetic_news' || type === 'articles'
    },
    updateAdvanceSearch() {
      this.emitter.emit(
        EMITTERS.UPDATE_SEARCH_TYPE_SELECTOR,
        this.advanced_search
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.all-tab-block {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content-section {
  display: flex;
  flex-direction: column;
}

.tab-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin-bottom: 16px;

  .name {
    color: var(--backgrounds-white, #fff);
    font-family: DM Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }

  .name__light {
    color: #323232;
  }

  .count {
    color: var(--text-input, #a6a6a6);
    font-family: DM Sans, serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }

  .count__light {
    color: #5c5c5c;
  }
}

.no-results-block {
  width: 100%;
  color: #f3f4f5;
  font-family: DM Sans, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 20px;
}

.no-results-block__light {
  color: #5c5c5c;
}
</style>
