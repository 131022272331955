document.addEventListener('turbolinks:load', function () {
  initCloseAlert()
})

$(document).on('alert:load', function () {
  initCloseAlert()
})

function closeAlerts() {
  $('.alert').alert('close')
}

function initCloseAlert() {
  const $alert = $('.alert')

  if ($alert.length !== 0 && !$alert.hasClass('alert-danger')) {
    setTimeout(closeAlerts, 4000)
  }
}
