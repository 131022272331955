<template>
  <div class="recent-search-block" data-turbolinks="false">
    <img
      v-if="loading"
      src="../../shared/assets/images/loading.gif"
      alt="loading"
      class="recent-search-loader"
    />
    <div v-if="!loading && items.length >= 1" class="recent-search__header">
      <span class="title" :class="{ title__light: isLightTheme() }">
        {{ HEADER_TITLE }}
      </span>
      <span class="clean-all" @click="clearAll()">
        {{ CLEAR_ALL_BTN }}
      </span>
    </div>
    <div v-if="!loading && items.length >= 1" class="recent-search__list">
      <RecentSearchItem
        v-for="item in items"
        :key="item.id"
        :item="item"
        :settings="settings"
        :is-light="isLightTheme()"
        :theme="theme"
        @[EMITTERS.RECENT_SEARCH_ITEM_REMOVE]="removeItem"
      />
    </div>
  </div>
</template>

<script>
import RecentSearchService from '../services/recentSearch.service'
import { THEMES } from '../../shared/utils/view_options'
import RecentSearchItem from './RecentSearchItem'
import { EMITTERS } from '../../shared/utils/emitters'

export default {
  name: 'RecentSearch',
  components: { RecentSearchItem },
  props: {
    theme: {
      required: true,
      type: String,
    },
    settings: {
      required: true,
      default: () => ({
        watch_host: '',
        main_host: '',
        events_host: '',
      }),
      type: Object,
    },
  },
  data() {
    return {
      HEADER_TITLE: 'Recently viewed search results',
      CLEAR_ALL_BTN: 'Clear all',
      items: [],
      loading: true,
      destroying_process: false,
      EMITTERS,
    }
  },
  mounted() {
    this.fetchRecentlySearches()
  },
  methods: {
    clearAll() {
      RecentSearchService.deleteAllRecentlySearch(this.optional_params).finally(
        () => {
          this.items = []
        }
      )
    },
    fetchRecentlySearches() {
      setTimeout(() => {
        RecentSearchService.getAllRecentlySearches(this.optional_params)
          .then((response) => {
            this.items = response.data
          })
          .finally(() => {
            this.loading = false
          })
      }, 500)
    },
    removeItem(itemToRemove) {
      this.items = this.items.filter((item) => item !== itemToRemove)
    },
    isLightTheme() {
      return this.theme !== THEMES.DARK
    },
  },
}
</script>

<style lang="scss" scoped>
.recent-search-block {
  display: flex;
  width: 688px;
  flex-direction: column;
  margin: 0 auto;

  @media (max-width: 991.98px) {
    margin: 0 32px 0 42px;
  }
}

.recent-search__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
  margin-left: 30px;

  @media (max-width: 991.98px) {
    margin-left: 0;
  }

  .title {
    font-family: DM Sans, serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    color: #fff;

    @media (max-width: 991.98px) {
      font-size: 16px;
    }
  }

  .title__light {
    color: #323232;
  }

  .clean-all {
    font-family: DM Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #8f8a9b;
    cursor: pointer;
    margin-right: 3px;

    @media (max-width: 991.98px) {
      font-size: 14px;
    }
  }
}

.recent-search__list {
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  width: 100%;
  max-height: 220px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 3px;

    &:hover {
      width: 10px;
    }
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: #8f8a9b;
    border-radius: 4px;
    border: 4px solid #8f8a9b;
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {
    display: none;
  }

  @media (max-width: 991.98px) {
    margin-left: 0;
  }

  margin-bottom: 56px;
}

.recent-search-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin: 0 auto 56px;
}
</style>
