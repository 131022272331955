<template>
  <EpisodeSelector class="select" />
</template>

<script>
import EpisodeSelector from './components/EpisodeSelector'

export default {
  name: 'EpisodeSelectorAppComponent',
  components: {
    EpisodeSelector,
  },
}
</script>
