<template>
  <ScrollUpButton />
  <div class="donate-block">
    <h3>{{ $vueTranslate('study_tools.donate.title') }}</h3>

    <p>
      {{ $vueTranslate('study_tools.donate.description') }}
    </p>

    <a
      class="btn btn-primary donate-button"
      href="https://new.amazingdiscoveries.org/explore-projects"
    >
      {{ $vueTranslate('study_tools.donate.btn') }}
    </a>
  </div>
</template>

<script>
import ScrollUpButton from './elements/ScrollUpButton'

export default {
  name: 'DonateComponent',
  components: {
    ScrollUpButton,
  },
}
</script>

<style lang="scss">
.donate-block {
  width: 100%;
  height: 389px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 1em 2em 2em 1em;

  p {
    color: #ffffff;
  }
}

.donate-button {
  width: 15em;
}
</style>
