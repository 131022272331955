<template>
  <div class="search-block">
    <div class="search-info" :class="{ 'search-info__light': isLightTheme() }">
      {{ messages.SEARCH_INFO }}
      <span class="search-info-query">
        {{ `"${query}"` }}
      </span>
    </div>

    <NavBar :is-light="isLightTheme()" :query="query" />

    <div class="search-results">
      <img
        v-if="loading"
        src="../../shared/assets/images/loading.gif"
        alt="loading"
        class="search-loader"
      />
      <div v-if="!loading && !isAllTab()" class="tab-info">
        <span class="name" :class="{ name__light: isLightTheme() }">
          {{ getTabName() }}
        </span>
        <span class="count" :class="{ count__light: isLightTheme() }">
          ({{ total_count }})
        </span>

        <div v-if="showSearchSelector()" class="search-type-block">
          <div
            class="radio-block"
            :class="{
              'radio-block-white': isLightTheme(),
            }"
          >
            <input
              :id="`basic-search-${getType()}`"
              v-model="advanced_search[getType()]"
              type="radio"
              :name="`search-selector-${getType()}`"
              :value="false"
              @change="performSearch(true)"
            />
            <label
              class="radio-label"
              :class="{
                'radio-label-white': isLightTheme(),
              }"
              for="without-copyright"
            >
              {{ $vueTranslate('search.basic_search') }}
            </label>
          </div>
          <div
            class="radio-block"
            :class="{
              'radio-block-white': isLightTheme(),
            }"
          >
            <input
              :id="`advanced-search-${getType()}`"
              v-model="advanced_search[getType()]"
              type="radio"
              :name="`search-selector-${getType()}`"
              :value="true"
              @change="performSearch(true)"
            />
            <label
              class="radio-label"
              :class="{
                'radio-label-white': isLightTheme(),
              }"
              for="ad-copyright"
            >
              {{ $vueTranslate('search.search_by_text') }}
            </label>
          </div>
        </div>
      </div>
      <div v-if="!loading" ref="resultBox" class="results">
        <div
          v-if="entries.length <= 0"
          class="no-results-block"
          :class="{ 'no-results-block__light': isLightTheme() }"
        >
          {{ messages.NO_RESULTS }}
        </div>
        <div
          v-if="fuzzy && entries.length > 0 && !isAllTab()"
          class="no-results-block"
          :class="{ 'no-results-block__light': isLightTheme() }"
        >
          {{ messages.FUZZY_NO_RESULTS(query) }}
        </div>

        <router-view
          v-if="!loading"
          :entries="entries"
          :is-light="isLightTheme()"
          :settings="settings"
          :query="query"
          :fuzzy="fuzzy"
          :advanced-search="advanced_search"
        />

        <div class="search-loader-data">
          <img
            v-if="loadingData"
            src="../../shared/assets/images/loading.gif"
            alt="loading"
            class="search-loader"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '../components/nav/NavBar'
import searchApiService from '../services/searchApi.service'
import { SEARCH_TABS } from '../utils/name_util'
import { MESSAGES } from '../utils/localization_util'
import { EMITTERS } from '../../shared/utils/emitters'

export default {
  name: 'SearchPage',
  components: { NavBar },
  props: {
    settings: {
      required: true,
      default: () => ({
        watch_host: '',
        main_host: '',
        events_host: '',
      }),
      type: Object,
    },
  },
  data() {
    return {
      query: '',
      page: 1,
      next_page: 1,
      total_count: 0,
      entries: [],
      fuzzy: false,
      loading: true,
      loadingData: false,
      bottomOffset: 550,
      messages: MESSAGES,
      advanced_search: {
        videos: false,
        prophetic_news: false,
        articles: false,
      },
    }
  },
  watch: {
    $route() {
      this.performSearch(true)
    },
  },
  mounted() {
    this.performSearch(true)
    document.addEventListener('scroll', this.handleScroll, true)

    this.emitter.on(
      EMITTERS.UPDATE_SEARCH_TYPE_SELECTOR,
      this.updateSearchEntries
    )
  },
  methods: {
    updateSearchEntries(advancedSearch) {
      this.advanced_search = advancedSearch
      this.performSearch(true)
    },
    performSearch(clear = false) {
      const routeQuery = this.$route.query
      if (clear || routeQuery.query !== this.query) {
        this.page = 1
        window.scrollTo(0, 0)
      }

      this.query = routeQuery.query || ''

      if (this.page === 1 || this.isAllTab()) this.loading = true

      setTimeout(() => {
        searchApiService
          .performSearch(
            this.query,
            this.page,
            this.getType(),
            12,
            this.advanced_search
          )
          .then((response) => {
            if (this.getType() === 'all') {
              this.entries = response
              return
            }

            if (this.page === 1) this.entries = response.data.entries
            else this.entries = [...this.entries, ...response.data.entries]

            this.next_page = response.data.next_page
            this.total_count = response.data.total_count
            this.fuzzy = response.data.fuzzy
          })
          .finally(() => {
            if (this.page === 1) this.loading = false

            this.loadingData = false
          })
      }, 1000)
    },
    isLightTheme() {
      return window.location.hostname !== this.settings.watch_host
    },
    handleScroll() {
      if (this.isProcessing() || this.isAllTab()) return

      const resultBox = document.querySelector('body')
      const scrollPosition = resultBox.scrollTop + resultBox.clientHeight
      const totalHeight = resultBox.scrollHeight

      if (this.shouldLoadMoreResults(scrollPosition, totalHeight)) {
        this.loadingData = true
        this.page++
        this.performSearch()
      }
    },
    isProcessing() {
      const routeQuery = this.$route.query
      return (
        routeQuery.query !== this.query ||
        this.loadingData ||
        this.loading ||
        this.next_page === null
      )
    },
    shouldLoadMoreResults(scrollPosition, totalHeight) {
      const canLoadMore =
        !this.loadingData && this.entries.length < this.total_count
      const reachedBottom = scrollPosition >= totalHeight - this.bottomOffset
      return canLoadMore && reachedBottom
    },
    getType() {
      return this.$route.name.split('-')[1]
    },
    getTabName() {
      return SEARCH_TABS[this.getType()]
    },
    isAllTab() {
      return this.$route.name === 'search-all'
    },
    showSearchSelector() {
      return (
        this.getType() === 'prophetic_news' || this.getType() === 'articles'
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.search-loader {
  display: flex;
  width: 40px;
  align-items: center;
  margin: 0 auto;
}

.search-block {
  margin-top: 40px;
}

.search-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--backgrounds-white, #fff);
  font-family: DM Sans, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 32px;
}

.search-info-query {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

.search-info__light {
  color: #323232;
}

.search-results {
  display: flex;
  flex-direction: column;
}

.tab-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin-bottom: 16px;

  .name {
    color: var(--backgrounds-white, #fff);
    font-family: DM Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }

  .name__light {
    color: #323232;
  }

  .count {
    color: var(--text-input, #a6a6a6);
    font-family: DM Sans, serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }

  .count__light {
    color: #5c5c5c;
  }
}

.results {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
}

.search-loader-data {
  display: flex;
  margin: 0 auto;
  width: 100%;

  img {
    width: 40px;
    height: 40px;
  }
}

.no-results-block {
  width: 100%;
  color: #f3f4f5;
  font-family: DM Sans, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.no-results-block__light {
  color: #5c5c5c;
}
</style>
