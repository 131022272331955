<template>
  <div class="video-card-block">
    <a class="card-link" :title="title" :href="entityUrl">
      <img class="thumbnail" :alt="title" :src="thumbnail" />
      <span class="card-title" :class="{ 'card-title__light': isLight }">
        {{ title }}
      </span>
    </a>
    <span
      v-if="titleAlt"
      class="card-title-alt"
      :class="{ 'card-title-alt__light': isLight }"
    >
      {{ titleAlt || description }}
    </span>

    <div v-if="isSeries" class="series-label">{{ seriesLabel }}</div>
    <div v-if="isSeries" class="footnote">{{ getFootnote() }}</div>
    <SpecialEpisodeLabels :episode="getSpecialLabelsData()" :is-search="true" />
    <div v-if="episodeNumber && seriesSlug" class="episode-label">{{ episodeNumber }}</div>

    <div class="topics-block">
      <span
        v-for="topic in topics"
        :key="topic.id"
        class="topic"
        :class="{ topic__light: isLight }"
      >
        <a class="topic-link" :title="topic.name" :href="getTopicUrl(topic)">
          {{ topic.name }}
        </a>
      </span>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import SpecialEpisodeLabels from '../../shared/components/SpecialEpisodeLabels.vue'

export default {
  name: 'VideoCard',
  components: { SpecialEpisodeLabels },
  props: {
    title: {
      required: true,
      default: '',
      type: String,
    },
    titleAlt: {
      required: false,
      default: '',
      type: String,
    },
    episodesCount: {
      required: false,
      default: '',
      type: String,
    },
    thumbnail: {
      required: true,
      default: '',
      type: String,
    },
    slug: {
      required: true,
      default: '',
      type: String,
    },
    seriesSlug: {
      required: false,
      default: '',
      type: String,
    },
    topics: {
      required: false,
      default: () => [],
      type: Array,
    },
    searchQuery: {
      required: true,
      default: '',
      type: String,
    },
    exclusive: {
      required: true,
      default: false,
      type: Boolean,
    },
    premiering: {
      required: true,
      default: false,
      type: Boolean,
    },
    liveAtLabel: {
      required: true,
      default: '',
      type: String,
    },
    countdownTooltip: {
      required: true,
      default: '',
      type: String,
    },
    settings: {
      required: false,
      default: () => ({
        watch_host: '',
        main_host: '',
        events_host: '',
      }),
      type: Object,
    },
    isLight: {
      required: true,
      default: true,
      type: Boolean,
    },
    isSeries: {
      required: false,
      default: false,
      type: Boolean,
    },
    sequenceNumber: {
      required: false,
      default: null,
      type: Number,
    },
    seriesLabel: {
      required: false,
      default: '',
      type: String,
    },
  },
  computed: {
    entityUrl() {
      return this.isSeries ? this.seriesUrl() : this.episodeUrl()
    },
    episodeNumber() {
      return this.sequenceNumber ? `Episode ${this.sequenceNumber}` : null
    }
  },
  methods: {
    episodeUrl() {
      const protocol = window.location.protocol
      const watchHost = this.settings.watch_host
      const seriesSlug = this.seriesSlug || 'single-episode'
      const queryParams = `?last_search=${this.searchQuery}&search_from=web`

      return `${protocol}//${watchHost}/${seriesSlug}/${this.slug}${queryParams}`
    },
    seriesUrl() {
      const protocol = window.location.protocol
      const watchHost = this.settings.watch_host
      const seriesSlug = this.slug
      const queryParams = `?last_search=${this.searchQuery}&search_from=web`

      return `${protocol}//${watchHost}/series/${seriesSlug}${queryParams}`
    },
    getFootnote() {
      if (this.isSeries) return `Series • ${this.episodesCount} episodes`
    },
    getTopicUrl(topic) {
      return `/topic/${topic.slug}`
    },
    getSpecialLabelsData() {
      return {
        exclusive: this.exclusive,
        premiering: this.premiering,
        live_at_label: this.liveAtLabel,
        countdown_tooltip: this.countdownTooltip,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.video-card-block {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  max-width: 260px;
  flex: 1;

  .thumbnail {
    height: 140px;
    width: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;', serif;
    margin-bottom: 8px;
  }
}

.video-card__content {
  display: flex;
  flex-direction: column;
}

.card-title {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  font-family: 'DM Sans', sans-serif !important;
  cursor: pointer;
}

.card-title__light {
  color: #323232;
}

.card-title-alt {
  margin-top: 4px;
  color: #a6a6a6;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.01em;
  font-family: 'DM Sans', sans-serif !important;
  white-space: break-spaces;
}

.card-title-alt__light {
  color: #5c5c5c;
}

.topics-block {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  gap: 6px;
}

.topic {
  padding: 4px 10px 4px;
  font-size: 12px;
  line-height: 16px;
  font-family: 'DM Sans', serif;
  font-style: normal;
  font-weight: 400;
  background-color: #3b3946;
  color: #d1d3d4;
  border: 1px solid #444050;
  box-sizing: border-box;
  border-radius: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.topic__light {
  color: #5c5c5c;
  background-color: #fff;
  border: 1px solid #d1d3d4;
}

.card-link,
.topic-link {
  color: inherit;
  white-space: break-spaces;
  cursor: pointer;
  line-height: 0;
}

.footnote {
  font-size: 0.875rem;
}
.series-label {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0 0 4px 0;
  background: #b56941;
  color: #fff;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: DM Sans, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 4px 12px;
}
.episode-label {
  position: absolute;
  top: 110px;
  width: 100%;
  height: 30px;
  background: rgba(50, 145, 147, 0.5);
  color: #fff;
  font-family: DM Sans, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 2.24px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>