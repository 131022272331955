<template>
  <div class="timezone-selector">
    <div class="region-selector selector">
      <div class="dropdown">
        <div
          class="selected"
          :class="{ active: regionOpen }"
          @click="toggleRegionDropdown()"
        >
          {{ selectedRegion }}
        </div>
        <div class="items" :class="{ disabled: !regionOpen }">
          <div
            v-for="regionName in Object.keys(timezones)"
            :key="regionName"
            @click="selectRegion(regionName)"
          >
            {{ regionName }}
          </div>
        </div>
      </div>
    </div>
    <div class="city-selector selector">
      <div class="dropdown">
        <div
          class="selected"
          :class="{ active: cityOpen }"
          @click="toggleCityDropdown()"
        >
          {{ formatCity(selectedCity) }}
        </div>
        <div class="items" :class="{ disabled: !cityOpen }">
          <div
            v-for="cityName in timezones[selectedRegion]"
            :key="cityName"
            @click="selectCity(cityName)"
          >
            {{ formatCity(getCityName(cityName)) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import groupBy from 'lodash/groupBy'

import {
  fetchTimezone,
  fetchTimezones,
  getCurrentTimeZone,
} from '../utils/time_utils'
import { EMITTERS } from '../../shared/utils/emitters'

export default {
  name: 'TimezoneSelector',
  props: {
    timezone: {
      type: String,
      required: true,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  emits: [EMITTERS.TIMEZONE_SELECTED, EMITTERS.SCHEDULE_POP_UP_OPENED],
  data() {
    return {
      timezones: {}, // { Europe: ['Europe/Berlin'] }
      selectedRegion: null, // Europe
      selectedCity: null, // Berlin
      regionOpen: false,
      cityOpen: false,
    }
  },
  mounted() {
    this.prefillDropdowns()
    this.initData()
    this.emitter.on(EMITTERS.SCHEDULE_POP_UP_OPENED, (name) => {
      if (name !== this.$options.name) {
        this.regionOpen = false
        this.cityOpen = false
      }
    })
  },
  methods: {
    initData() {
      this.selectCity(this.timezone, false)
    },
    toggleRegionDropdown(condition = !this.regionOpen) {
      this.regionOpen = condition
      this.cityOpen = false
      if (this.regionOpen)
        this.emitter.emit(EMITTERS.SCHEDULE_POP_UP_OPENED, this.$options.name)
    },
    toggleCityDropdown(condition = !this.cityOpen) {
      this.cityOpen = condition
      this.regionOpen = false
      if (this.cityOpen)
        this.emitter.emit(EMITTERS.SCHEDULE_POP_UP_OPENED, this.$options.name)
    },
    selectCity(timezoneName, shouldToggleDropdown = true) {
      this.setSelectedCity(timezoneName)
      if (shouldToggleDropdown) this.toggleCityDropdown()
      this.emitter.emit(EMITTERS.TIMEZONE_SELECTED, timezoneName)
    },
    selectRegion(timezoneName) {
      this.setSelectedRegion(timezoneName)
      const defaultTimezone = this.timezones[timezoneName][0]
      this.selectCity(defaultTimezone, false)
      this.toggleRegionDropdown()
    },
    setSelectedRegion(timezoneName) {
      this.selectedRegion = fetchTimezone(timezoneName).region
      this.setSelectedCity(this.timezones[this.selectedRegion][0])
    },
    setSelectedCity(timezoneName) {
      this.selectedCity = this.getCityName(timezoneName)
    },
    prefillDropdowns() {
      const options = fetchTimezones()
      this.timezones = groupBy(options, (timezoneName) => {
        return fetchTimezone(timezoneName).region
      })
      this.setSelectedRegion(getCurrentTimeZone())
      this.setSelectedCity(getCurrentTimeZone())
    },
    getCityName(timezone) {
      return timezone && fetchTimezone(timezone).city
    },
    formatCity(cityName) {
      return cityName && cityName.replace(/_/g, ' ')
    },
  },
}
</script>

<style lang="scss">
.timezone-selector {
  display: flex;
  width: 420px;
  justify-content: space-between;

  .selector {
    position: relative;
    width: 240px;
    height: 32px;
    text-align: left;
    outline: none;
    line-height: 47px;
    background-color: #f9f9f9;
    border-radius: 4px;
    border: none;
    margin-right: 10px;

    @media (max-width: 767.98px) {
      width: 100%;
    }
  }

  .selected {
    width: 100%;
    position: absolute;
    padding: 7px 16px;
    font-family: 'DM Sans', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #323232;
    cursor: pointer;

    &:after {
      position: absolute;
      content: '';
      top: 11px;
      right: 20px;
      border: solid $quick-silver;
      border-width: 0 2px 2px 0;
      border-radius: 2px;
      display: inline-block;
      padding: 3px;
      transform: rotate(45deg);
    }
  }

  .dropdown .active {
    background: #f9f9f9;
    border-radius: 4px 4px 0 0;
  }

  .items {
    width: 100%;
    position: absolute;
    left: 0;
    top: 32px;
    background: #f9f9f9;
    box-shadow: 0 4px 4px rgba(98, 98, 101, 0.4);
    border-radius: 0 0 4px 4px;
    z-index: 10;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 14rem;
  }

  .items::-webkit-scrollbar {
    width: 0.188rem;
    height: 5.125rem;
  }

  .items::-webkit-scrollbar-thumb {
    background: $quick-silver;
    border-radius: 0.25rem;
  }

  .items div {
    padding: 7px 16px;
    font-family: 'DM Sans', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #323232;
    cursor: pointer;
  }

  .items div:hover {
    background: #f3f4f5;
    border-radius: 4px;
  }

  .disabled {
    display: none;
  }
}
</style>
