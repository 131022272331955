<template>
  <div class="news-tab">
    <NewsCard
      v-for="news in entries"
      :key="news.id"
      :is-new="news.new"
      :title="news.title"
      :image-link="news.image_link"
      :slug="news.slug"
      :topics="news.topics"
      :settings="settings"
      :is-light="isLight"
    />
  </div>
</template>

<script>
import NewsCard from './NewsCard'

export default {
  name: 'VideoTab',
  components: { NewsCard },
  props: {
    entries: {
      required: true,
      type: Array,
      default: () => [],
    },
    settings: {
      required: true,
      default: () => ({
        watch_host: '',
        main_host: '',
        events_host: '',
      }),
      type: Object,
    },
    query: {
      required: true,
      default: '',
      type: String,
    },
    isLight: {
      default: false,
      type: Boolean,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.news-tab {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;

  @media (max-width: 463.98px) {
    justify-content: center;
  }
}
</style>
