import { createStore } from 'vuex'

const globalStore = createStore({
  state() {
    return {
      routing: {
        breadcrumbs: [],
      },
    }
  },
  mutations: {
    updateBreadcrumbs(state, breadcrumbs) {
      state.routing = Object.assign(state.routing, breadcrumbs)
    },
  },
  actions: {
    updateBreadcrumbs(state, breadcrumbs) {
      this.commit('updateBreadcrumbs', {
        breadcrumbs: breadcrumbs,
      })
    },
  },
})

export default globalStore
