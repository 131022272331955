<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 5H5H3C2.44772 5 2 5.44772 2 6C2 6.55228 2.44772 7 3 7H4V20C4 21.6569 5.34315 23 7 23H17C18.6569 23 20 21.6569 20 20V7H21C21.5523 7 22 6.55228 22 6C22 5.44772 21.5523 5 21 5H19H17V4C17 2.34315 15.6569 1 14 1H10C8.34315 1 7 2.34315 7 4V5ZM9 5H15V4C15 3.44772 14.5523 3 14 3H10C9.44772 3 9 3.44772 9 4V5ZM16 7H8H6V20C6 20.5523 6.44772 21 7 21H17C17.5523 21 18 20.5523 18 20V7H16ZM9 11V17C9 17.5523 9.44772 18 10 18C10.5523 18 11 17.5523 11 17V11C11 10.4477 10.5523 10 10 10C9.44772 10 9 10.4477 9 11ZM13 17V11C13 10.4477 13.4477 10 14 10C14.5523 10 15 10.4477 15 11V17C15 17.5523 14.5523 18 14 18C13.4477 18 13 17.5523 13 17Z"
      fill="black"
    />
    <mask
      :id="maskId()"
      style="mask-type: alpha;"
      maskUnits="userSpaceOnUse"
      x="2"
      y="1"
      width="20"
      height="22"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7 5H5H3C2.44772 5 2 5.44772 2 6C2 6.55228 2.44772 7 3 7H4V20C4 21.6569 5.34315 23 7 23H17C18.6569 23 20 21.6569 20 20V7H21C21.5523 7 22 6.55228 22 6C22 5.44772 21.5523 5 21 5H19H17V4C17 2.34315 15.6569 1 14 1H10C8.34315 1 7 2.34315 7 4V5ZM9 5H15V4C15 3.44772 14.5523 3 14 3H10C9.44772 3 9 3.44772 9 4V5ZM16 7H8H6V20C6 20.5523 6.44772 21 7 21H17C17.5523 21 18 20.5523 18 20V7H16ZM9 11V17C9 17.5523 9.44772 18 10 18C10.5523 18 11 17.5523 11 17V11C11 10.4477 10.5523 10 10 10C9.44772 10 9 10.4477 9 11ZM13 17V11C13 10.4477 13.4477 10 14 10C14.5523 10 15 10.4477 15 11V17C15 17.5523 14.5523 18 14 18C13.4477 18 13 17.5523 13 17Z"
        fill="white"
      />
    </mask>
    <g :mask="`url(#${maskId()})`">
      <rect width="24" height="24" :fill="fillIcon()" />
    </g>
  </svg>
</template>

<script>
import { THEMES, THEME_COLOR } from '../../utils/view_options'

export default {
  name: 'DeleteIconComponent',
  props: {
    theme: {
      required: true,
      type: String, // THEMES.DARK | THEMES.LIGHT,
    },
  },
  methods: {
    fillIcon() {
      switch (this.theme) {
        case THEMES.DARK:
          return THEME_COLOR.DARK
        case THEMES.LIGHT:
          return THEME_COLOR.LIGHT
        case THEMES.PROFILE:
          return THEME_COLOR.PROFILE
        default:
          return THEME_COLOR.LIGHT
      }
    },
    maskId() {
      return 'mask0_10782_26935_' + this.$.uid
    },
  },
}
</script>

<style scoped>
svg {
  cursor: pointer;
}
</style>
