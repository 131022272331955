<template>
  <div
    v-if="message.length > 0"
    class="alert alert-dismissible"
    :class="getAlertType()"
    role="alert"
  >
    {{ message }}
    <button
      type="button"
      class="close close-notification"
      @click.prevent="closeAlerts"
    >
      <CloseIcon theme="light" />
    </button>
  </div>
</template>

<script>
import CloseIcon from '../../shared/components/icons/Close'
import { EMITTERS } from '../../shared/utils/emitters'

export default {
  name: 'FlashNotificationComponent',
  components: {
    CloseIcon,
  },
  props: {
    site: {
      required: true,
      type: String, // SITES.PROFILE | SITES.WATCH
    },
    customClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      siteResource: this.site,
      message: '',
      type: 'success',
    }
  },
  mounted() {
    this.timerId = null
    this.emitter.on(EMITTERS.SHOW_NOTIFICATION_EMITTER, (data) => {
      this.type = 'success'
      this.message = data.message
      if (data.site) this.siteResource = data.site

      this.initCloseAlert()
    })

    this.emitter.on(EMITTERS.SHOW_ERROR_NOTIFICATION_EMITTER, (data) => {
      this.type = 'error'
      this.message = data.message
      if (data.site) this.siteResource = data.site

      this.initCloseAlert()
    })
  },
  methods: {
    closeAlerts() {
      this.message = ''
    },
    initCloseAlert() {
      clearTimeout(this.timerId)
      this.timerId = setTimeout(this.closeAlerts, 4000)
    },
    getAlertType() {
      if (this.customClass === '')
        return this.siteResource + ' alert-' + this.type

      return this.siteResource + ' ' + this.customClass + ' alert-' + this.type
    },
  },
}
</script>

<style lang="scss" scoped>
.alert-success {
  border-width: 1px;
  text-align: left !important;
}

.alert-error {
  border-width: 1px;
  text-align: left !important;
}

.alert .close {
  padding-right: 0;
}

.alert-dismissible {
  width: 500px;
  height: auto;
  z-index: 9999;
  border-radius: 8px !important;
  font-size: 14px !important;
}

.alert {
  position: fixed !important;
}

.watch {
  &.alert {
    right: 50%;
    margin-right: -680px;
    top: 87px;

    @media (max-width: 1440px) {
      &.alert {
        right: 40px;
        margin-right: 0;
      }
    }
  }

  &.alert-success {
    border-color: #21a18a;
    color: $white;
    background-color: #204f46;
  }
}

.profile {
  &.alert {
    top: 85px;
  }

  &.alert-success {
    border-color: #aae9dd;
    color: #21a18a;
    background-color: #f2fffd;
  }

  &.alert-error {
    border-color: #e9a8b1;
    color: #d35163;
    background-color: #ffe8eb;
  }

  &.alert-dismissible {
    right: 48px;
  }
}

.admin {
  &.alert .close {
    margin-left: auto;
    padding: 0;
    margin-bottom: 0;
    background: transparent;
    border: none;
    box-shadow: none;
  }

  &.alert {
    padding: 15px 20px;
    display: flex;
    align-items: center;
    right: 4%;
  }
}
</style>
