<template :class="theme">
  <div class="bookmark-order__wrapper">
    <div class="bookmark-order__input" @click="toggleDropdown">
      {{ $vueTranslate('study_tools.elements.order_select.label') }}
      {{ selectedOption.name }}
      <ArrowDownIcon :theme="theme" />
    </div>

    <div v-if="dropdownOpen" class="bookmark-order__dropdown">
      <div
        v-for="orderOption in availableOptions"
        :key="orderOption"
        class="bookmark-order__option-wrapper"
      >
        <div class="bookmark-order__option" @click="changeOrder(orderOption)">
          {{ orderOption.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EMITTERS } from '../../../shared/utils/emitters'
import ArrowDownIcon from '../../../shared/components/icons/ArrowDown'

export default {
  name: 'OrderSelectComponent',
  components: { ArrowDownIcon },
  props: {
    theme: {
      required: true,
      type: String, // THEMES.DARK | THEMES.LIGHT,
    },
  },
  emits: [EMITTERS.BOOKMARK_ORDER_EMITTER],
  data() {
    return {
      dropdownOpen: false,

      BOOKMARK_ORDER_LABEL: 'Order by:',
      BOOKMARK_ORDER_OPTIONS: [
        { name: 'Descending', param: 'desc' },
        { name: 'Ascending', param: 'asc' },
      ],
      selectedOption: null,
    }
  },
  computed: {
    availableOptions() {
      return this.BOOKMARK_ORDER_OPTIONS.filter(
        (option) => option !== this.selectedOption
      )
    },
  },
  created() {
    this.selectedOption = this.BOOKMARK_ORDER_OPTIONS[0]
  },
  methods: {
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen
    },
    changeOrder(orderOption) {
      this.selectedOption = orderOption
      this.dropdownOpen = false
      this.$emit(EMITTERS.BOOKMARK_ORDER_EMITTER, orderOption.param)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../shared/assets/styles/global';

.bookmark-order__wrapper {
  color: $white;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  margin: auto 0;
}

.bookmark-order__input {
  display: flex;
  margin-left: auto;
}

.bookmark-order__input,
.bookmark-order__dropdown {
  background-color: #444050;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 200px;
  margin-bottom: 3px;
}

.bookmark-order__dropdown {
  position: absolute;
  z-index: 2;
}

.bookmark-order__input,
.bookmark-order__option {
  padding: 7px 16px;
  cursor: pointer;
}
</style>
