<template>
  <div v-if="isLinkValid()">
    <div v-if="linkData && !loading" class="link-preview" @click="openLink()">
      <div class="content">
        <div class="website">
          {{ linkData.siteTitle }}
        </div>
        <div class="title">
          {{ linkData.contentTitle }}
        </div>
        <div class="description">
          {{ linkData.description }}
        </div>
      </div>
      <img :src="linkData.image" :alt="linkData.contentTitle" />
    </div>

    <div v-if="loading" class="link-preview">
      <div class="content">
        <div class="website pulse" />
        <div class="title pulse" />
        <div class="description pulse" />
      </div>
      <div class="fake-img pulse" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LinkPreview',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      link: null,
      linkData: null,
    }
  },

  mounted() {
    this.extractLinkFromText()
    this.mount()
  },
  methods: {
    openLink() {
      window.open(this.link, '_blank')
    },
    extractLinkFromText() {
      const urlRegex = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=+$,\w]+@)?[A-Za-z0-9.\-]+|(?:www\.|[\-;:&=+$,\w]+@)[A-Za-z0-9.\-]+)((?:\/[+~%\/.\w\-_]*)?\??[\-+=&;%@.\w_]*#?[.!\/\\\w]*)?)/
      const match = this.text.match(urlRegex)
      this.link = match ? match[0] : null
    },

    async fetchLinkData() {
      try {
        const response = await fetch(this.link)
        const html = await response.text()
        const parser = new DOMParser()
        const doc = parser.parseFromString(html, 'text/html')
        const siteTitle = doc.title

        const imageElement = doc.querySelector('meta[property="og:image"]')
        const image = imageElement ? imageElement.content : null

        const contentTitleElement = doc.querySelector(
          'meta[property="og:title"]'
        )
        const contentTitle = contentTitleElement
          ? contentTitleElement.content
          : null

        const descriptionElement = doc.querySelector(
          'meta[property="og:description"]'
        )
        const description = descriptionElement
          ? descriptionElement.content
          : null

        return {
          image,
          siteTitle,
          contentTitle,
          description,
        }
      } catch (error) {
        return null
      }
    },

    async mount() {
      if (this.isLinkValid()) {
        this.linkData = await this.fetchLinkData()
        this.loading = false
      }
    },

    isLinkValid() {
      return !!this.link
    },
  },
}
</script>

<style lang="scss">
.link-preview {
  display: flex;
  justify-content: space-between;
  border-left: 1px solid #d18f42;
  padding-left: 12px;
  cursor: pointer;
}

.link-preview img {
  width: 148px;
  height: auto;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 4px;
}

.content {
  display: flex;
  flex-direction: column;
  width: 385px;

  .website {
    color: var(--primary-steel-blue-hover, #598aa0);
    font-family: DM Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }

  .title {
    margin-top: 2px;
    color: var(--text-secondary, #5c5c5c);
    font-family: DM Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }

  .description {
    margin-top: 4px;
    color: var(--text-secondary, #5c5c5c);
    font-family: DM Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}

.pulse {
  $from: #f5f5f5;
  $to: scale-color($from, $lightness: -10%);

  height: 16px;
  width: 100%;
  background: linear-gradient(-90deg, #efefef 0%, #fcfcfc 50%, #efefef 100%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0 0;
    }
    .100% {
      background-position: -135% 0%;
    }
  }
}

.fake-img {
  width: 148px;
  height: auto;
  margin-right: 10px;
  border-radius: 4px;
}
</style>
