import { STUDY_TOOLS_ROUTES } from '../shared/utils/routes'
import createAppRouter from './create_router'

const studyToolsRouter = createAppRouter(STUDY_TOOLS_ROUTES, {
  redirect: false,
  history: false,
})

window.studyToolsRouter = studyToolsRouter
studyToolsRouter.beforeEach((to, _from) => {
  if (to.path !== '/') {
    localStorage.setItem('studyToolsCurrentPath', to.path)
  }
})

// Restore the saved path from local storage on app initialization
studyToolsRouter.isReady().then(() => {
  const savedPath = localStorage.getItem('studyToolsCurrentPath')
  if (savedPath) {
    studyToolsRouter.replace(savedPath)
  }
})

export default studyToolsRouter
