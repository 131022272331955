<template>
  <div class="field">
    <div class="field-name">{{ field.label }}</div>
    <input type="url" v-model="field.value">
  </div>
</template>

<script>
import { EMITTERS } from '../../shared/utils/emitters'

export default {
  name: 'LinkFieldComponent',
  props: {
    field: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      EMITTERS,
    }
  },
  watch: {
    'field.value'() {
      this.emitValueUpdate()
    }
  },
  emits: ['update:value', 'change'],
  methods: {
    emitValueUpdate() {
      this.$emit('change', this.field)
      this.$emit('update:value', this.field)
    },
  },
}
</script>

<style lang="scss" scoped>
.field {
  display: flex;
  align-items: baseline;
  margin-bottom: 20px;
  .field-name {
    width: 150px;
    text-align: right;
    margin-right: 10px;
  }
  input, textarea {
    max-width: 250px;
  }
}
</style>
