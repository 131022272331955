//
// jarallax.js
//

import { jarallax, jarallaxVideo } from 'jarallax'

document.addEventListener('turbolinks:load', () => {
  jarallaxVideo()

  jarallax(document.querySelectorAll('.jarallax'), {
    speed: 0.5,
  })
})

document.addEventListener('turbolinks:before-cache', () => {
  jarallax(document.querySelectorAll('.jarallax'), 'destroy')
})
