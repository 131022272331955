<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    class="circle-clear-icon"
  >
    <circle
      :opacity="getOpacity()"
      class="circle-clear"
      cx="8"
      cy="8"
      r="8"
      :fill="fillCircleIcon()"
    />
    <path
      class="circle-clear-path"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.8895 5.64498C11.037 5.49743 11.037 5.25821 10.8895 5.11066C10.7419 4.96311 10.5027 4.96311 10.3552 5.11066L7.99995 7.46587L5.64498 5.11089C5.49743 4.96335 5.25821 4.96335 5.11066 5.11089C4.96311 5.25844 4.96311 5.49766 5.11066 5.64521L7.46563 8.00018L5.11081 10.355C4.96327 10.5025 4.96327 10.7418 5.11081 10.8893C5.25836 11.0369 5.49758 11.0369 5.64513 10.8893L7.99995 8.5345L10.355 10.8896C10.5025 11.0371 10.7418 11.0371 10.8893 10.8896C11.0369 10.742 11.0369 10.5028 10.8893 10.3552L8.53426 8.00018L10.8895 5.64498Z"
      :fill="fillCrossIcon()"
    />
  </svg>
</template>

<script>
import { THEMES } from '../../utils/view_options'

export default {
  name: 'CircleClose',
  props: {
    circleColor: {
      required: false,
      type: String,
      default: null,
    },
    crossColor: {
      required: false,
      type: String,
      default: null,
    },
    theme: {
      required: true,
      type: String, // THEMES.DARK | THEMES.LIGHT,
    },
  },
  data() {
    return {
      CROSS_DARK_THEME: '#D1D3D4',
      CROSS_LIGHT_THEME: '#414042',
      CIRCLE_DARK_THEME: '#444050',
      CIRCLE_LIGHT_THEME: '#414042',
    }
  },
  methods: {
    getOpacity() {
      return this.theme === THEMES.DARK ? 1 : 0.1
    },
    fillCrossIcon() {
      return this.crossColor || this.defineCrossColor()
    },
    fillCircleIcon() {
      return this.circleColor || this.defineCircleColor()
    },
    defineCrossColor() {
      return this.theme === THEMES.DARK
        ? this.CROSS_DARK_THEME
        : this.CROSS_LIGHT_THEME
    },
    defineCircleColor() {
      return this.theme === THEMES.DARK
        ? this.CIRCLE_DARK_THEME
        : this.CIRCLE_LIGHT_THEME
    },
  },
}
</script>

<style scoped>
svg {
  cursor: pointer;
}
</style>
