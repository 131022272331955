<template>
  <img
    src="../../shared/assets/images/loading.gif"
    alt="Page loading"
    class="page__loader"
  />
</template>

<script>
export default {
  name: 'LoaderComponent',
}
</script>

<style>
.page__loader {
  width: 64px;
  display: flex;
  margin: auto;
}
</style>
