<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_23748_215119)">
      <path
        d="M14 6L13.28 4.55C13.11 4.21 12.76 4 12.38 4H6C5.45 4 5 4.45 5 5V20C5 20.55 5.45 21 6 21C6.55 21 7 20.55 7 20V14H12L12.72 15.45C12.89 15.79 13.24 16 13.61 16H19C19.55 16 20 15.55 20 15V7C20 6.45 19.55 6 19 6H14ZM18 14H14L13 12H7V6H12L13 8H18V14Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_23748_215119">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'FlagIcon',
}
</script>

<style scoped>
svg {
  cursor: pointer;
}
</style>
