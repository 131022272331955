import axios from 'axios'

import { API_ROUTES, BASE_API_CONFIG } from '../../../shared/utils/api'

export default {
  DEFAULT_ORDER: 'desc',

  getAllLinks(optional_params = {}) {
    return axios.request({
      ...BASE_API_CONFIG,
      method: 'GET',
      url: API_ROUTES.links(optional_params.episode_id),
    })
  },

  createLink(link, optional_params = {}) {
    const data = {
      link: link,
    }

    return axios.request({
      ...BASE_API_CONFIG,
      method: 'POST',
      url: API_ROUTES.links(optional_params.episode_id),
      data,
    })
  },

  deleteLink(link, optional_params = {}) {
    return axios.request({
      ...BASE_API_CONFIG,
      method: 'DELETE',
      url: API_ROUTES.link(optional_params.episode_id, link.id),
    })
  },

  updateLink(link, optional_params = {}) {
    return axios.request({
      ...BASE_API_CONFIG,
      method: 'PUT',
      url: API_ROUTES.link(optional_params.episode_id, link.id),
      data: {
        link,
      },
    })
  },
}
