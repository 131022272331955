<template>
  <div class="field">
    <div class="field-name">
      <div class="button_with_label">
        <div class="icon-button">
          <i class="icon fa-fw fa-info fas" title="Test"></i>
        </div>
        <label>Save changes to ensure banner preview is correct</label>
      </div>
      {{ field.label }}
    </div>

    <div>
      <div class="field_wrapper">
        <label for="label">{{ field.label }} label</label>
        <input type="text" name="label" :maxlength="field.max_length" v-model="field.value.label">
      </div>

      <div class="field_wrapper">
        <label for="link">{{ field.label }} link</label>
        <input type="url" name="link" v-model="field.value.link">
      </div>
    </div>
  </div>
</template>

<script>
import { EMITTERS } from '../../shared/utils/emitters'

export default {
  name: 'LinkButtonComponent',
  props: {
    field: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      EMITTERS,
    }
  },
  watch: {
    'field.value.label'() {
      this.emitValueUpdate()
    },
    'field.value.link'() {
      this.emitValueUpdate()
    },
  },
  emits: ['update:value', 'change'],
  methods: {
    emitValueUpdate() {
      this.$emit('change', this.field)
      this.$emit('update:value', this.field)
    },
  },
}
</script>

<style lang="scss" scoped>
.field {
  display: flex;
  align-items: baseline;
  margin-bottom: 20px;
  .field-name {
    width: 150px;
    text-align: right;
    margin-right: 10px;
  }
  input, textarea {
    max-width: 250px;
  }
}

.field_wrapper {
  margin-bottom: 5px;
  label {
    color: grey;
  }
}
</style>
