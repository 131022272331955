<template>
  <Carousel :images="images" :roku-link="roku_link" />
</template>

<script>
import Carousel from './components/Carousel'
import Slide_1 from './assets/images/TVslide-1.png'
import Slide_2 from './assets/images/TVslide-2.png'
import Slide_3 from './assets/images/TVslide-3.png'
import Slide_4 from './assets/images/TVslide-4.png'

export default {
  name: 'RokuAppSlider',
  components: {
    Carousel,
  },
  data() {
    return {
      images: [Slide_1, Slide_2, Slide_3, Slide_4],
      roku_link: 'https://roku.com',
    }
  },
}
</script>
