<template>
  <div class="print-schedule-component">
    <div class="schedule__button" @click="toggleDatepicker">
      <Printer v-if="!visible" theme="light" class="mr-1" />
      <CloseIconComponent
        v-else
        class="print-calendar-button-close__icon"
        :theme="'light'"
        :color="'#6998ae'"
      />
      <span>
        {{
          visible
            ? $vueTranslate('schedule.print_schedule.close_button')
            : $vueTranslate('schedule.print_schedule.button_title')
        }}
      </span>
    </div>
    <div v-if="visible">
      <date-picker
        :value="dateRangeDefault"
        :open="true"
        :range="true"
        :confirm="true"
        :clearable="true"
        :disabled-date="disabledDates"
        :append-to-body="false"
        :confirm-text="$vueTranslate('schedule.print_schedule.confirm_text')"
        prefix-class="print"
        popup-class="schedule-popup"
        type="date"
        @confirm="rangeApplied"
      />
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css'

import Printer from '../../shared/components/icons/Printer'
import CloseIconComponent from '../../shared/components/icons/Close'
import { formatDateToString } from '../utils/time_utils'
import { API_ROUTES } from '../../shared/utils/api'
import { EMITTERS } from '../../shared/utils/emitters'

export default {
  name: 'PrintSchedule',
  components: { CloseIconComponent, Printer, DatePicker },
  props: {
    selectedDate: {
      required: true,
      type: Date,
    },
    timezone: {
      type: String,
      required: true,
    },
    availableDates: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
    }
  },
  computed: {
    dateRangeDefault() {
      return [this.selectedDate, this.selectedDate]
    },
  },
  mounted() {
    this.emitter.on(EMITTERS.SCHEDULE_POP_UP_OPENED, (name) => {
      if (name !== this.$options.name) this.visible = false
    })
  },
  methods: {
    toggleDatepicker() {
      this.visible = !this.visible
      if (this.visible)
        this.emitter.emit(EMITTERS.SCHEDULE_POP_UP_OPENED, this.$options.name)
    },
    disabledDates(date) {
      const formattedDate = formatDateToString(date)
      return !this.availableDates.includes(formattedDate)
    },
    rangeApplied(dates) {
      const dateRange = dates
      if (dateRange.length === 1) dateRange.push(dateRange[0])
      const [dateFrom, dateTo] = dateRange.map((date) =>
        formatDateToString(date)
      )

      const pdfUrl = API_ROUTES.print_schedule(dateFrom, dateTo, this.timezone)
      window.open(pdfUrl, '_blank')

      this.visible = false
    },
  },
}
</script>

<style lang="scss">
.print-schedule-component {
  @media (max-width: 768px) {
    display: none;
  }
}

.schedule__button {
  width: 164px;
  height: 32px;
  background: $white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-family: 'DM Sans', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6998ae;
  cursor: pointer;
}

$namespace: 'print';

@import '~vue-datepicker-next/scss/var.scss';
@import '~vue-datepicker-next/scss/icon.scss';
@import '~vue-datepicker-next/scss/btn.scss';
@import '~vue-datepicker-next/scss/scrollbar.scss';
@import '~vue-datepicker-next/scss/animation.scss';

.#{$namespace}-btn-icon-double-left,
.#{$namespace}-btn-icon-double-right {
  display: none;
  pointer-events: none;
  width: 0;
  height: 0;
}

.#{$namespace}-btn-close-range-selector {
  font-family: 'DM Sans', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  padding-left: 4px;
  color: #6998ae;
}

.#{$namespace}-datepicker {
  position: relative;
}

.#{$namespace}-datepicker-popup {
  position: absolute !important;
  top: 12px !important;
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%) !important;
  z-index: 1;

  @media (max-width: 768px) {
    left: -120px !important;
  }
}

.#{$namespace}-datepicker-inline {
  width: 318px;
}

.#{$namespace}-input-wrapper {
  display: none;
  pointer-events: none;
}

.#{$namespace}-icon-calendar,
.#{$namespace}-icon-clear {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  font-size: 16px;
  line-height: 1;
  color: rgba(0, 0, 0, 0.5);
  vertical-align: middle;
}

.#{$namespace}-icon-clear {
  cursor: pointer;

  &:hover {
    color: rgba(0, 0, 0, 0.8);
  }
}

.#{$namespace}-datepicker-main {
  font: 14px/1.5 'Helvetica Neue', Helvetica, Arial, 'Microsoft Yahei',
    sans-serif;
  color: $default-color;
  background: $white;
  box-shadow: 0 4px 30px rgba(98, 98, 101, 0.4);
  border-radius: 8px;
}

.#{$namespace}-datepicker-sidebar {
  float: left;
  box-sizing: border-box;
  width: $sidebar-margin-left;
  padding: 6px;
  overflow: auto;
}

.#{$namespace}-datepicker-sidebar + .#{$namespace}-datepicker-content {
  margin-left: $sidebar-margin-left;
  border-left: 1px solid $border-color;
}

.#{$namespace}-datepicker-body {
  position: relative;
  user-select: none;
}

.#{$namespace}-btn-shortcut {
  display: block;
  padding: 0 6px;
  line-height: 24px;
}

.#{$namespace}-datepicker-header {
  padding: 6px 8px;
  border-bottom: 1px solid $border-color;
}

.#{$namespace}-datepicker-footer {
  margin-top: 16px;
  padding: 12px 16px;
  text-align: right;
  border-top: 1px solid $border-color;

  button.#{$namespace}-datepicker-btn-confirm {
    background: #6998ae;
    color: $white;
    box-shadow: 0 2px 15px rgba(73, 73, 73, 0.2);
    border-radius: 8px 0;
    height: 48px;
    width: 180px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;

    &:hover {
      color: $white;
    }
  }
}

.#{$namespace}-calendar-range,
.#{$namespace}-time-range {
  display: flex;
  @media (max-width: 750px) {
    flex-direction: column;
  }
  padding-bottom: 30px;
}

.#{$namespace}-calendar {
  box-sizing: border-box;
  width: 308px;
  padding: 12px;
}

.#{$namespace}-calendar-header {
  box-sizing: border-box;
  height: 34px;
  line-height: 34px;
  text-align: center;
  overflow: hidden;
  margin-left: 6px;
  margin-right: 6px;
  position: relative;
  margin-bottom: 16px;
}

.#{$namespace}-btn-icon-left {
  position: absolute;
  left: 2%;
}

.#{$namespace}-icon-left:before {
  content: '';
  position: relative;
  top: -1px;
  display: inline-block;
  width: 14px;
  height: 14px;
  vertical-align: middle;
  border-style: solid;
  border-color: #323232;
  border-width: 3px 0 0 3px;
  border-radius: 1px;
  box-sizing: border-box;
  transform-origin: center;
  transform: rotate(-45deg) scale(0.7);
}

.#{$namespace}-icon-right:before {
  content: '';
  position: relative;
  top: -1px;
  display: inline-block;
  width: 14px;
  height: 14px;
  vertical-align: middle;
  border-style: solid;
  border-color: #323232;
  border-width: 3px 0 0 3px;
  border-radius: 1px;
  box-sizing: border-box;
  transform-origin: center;
  transform: rotate(135deg) scale(0.7);
}

.#{$namespace}-btn-icon-right {
  position: absolute;
  left: 89%;
}

.#{$namespace}-calendar-header-label {
  font-size: 14px;
  position: absolute;
  left: 33%;
}

.#{$namespace}-calendar-decade-separator {
  margin: 0 2px;

  &:after {
    content: '~';
  }
}

.#{$namespace}-calendar-content {
  position: relative;
  height: 100%;
  box-sizing: border-box;

  .cell {
    cursor: pointer;
    width: 40px;
    height: 35px;
    color: #323232;

    &.active {
      font-family: 'DM Sans', serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: white;
      background: #4c7c93;
      border-radius: 4px;
    }

    &.in-range,
    &.hover-in-range {
      color: #323232;
      background-color: $calendar-in-range-background-color;
      font-family: 'DM Sans', serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      border-radius: 4px;
      border: 1px solid white;
    }

    &.disabled {
      cursor: not-allowed;
      color: $disabled-color;
      background-color: $disabled-background-color;
    }
  }
}

.#{$namespace}-calendar-week-mode {
  .#{$namespace}-date-row {
    cursor: pointer;

    &:hover {
      background-color: $calendar-hover-background-color;
    }

    &.#{$namespace}-active-week {
      background-color: $calendar-in-range-background-color;
    }

    .cell {
      &:hover {
        color: inherit;
        background-color: transparent;
      }

      &.active {
        color: inherit;
        background-color: transparent;
      }
    }
  }
}

.#{$namespace}-week-number {
  opacity: 0.5;
}

.#{$namespace}-table {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  text-align: center;

  th {
    padding: 0;
    font-weight: 500;
    vertical-align: middle;
  }

  td {
    padding: 0;
    vertical-align: middle;
  }
}

.#{$namespace}-table-date {
  td,
  th {
    font-family: 'DM Sans', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #323232;
  }
}

.print-datepicker-main .cell.not-current-month {
  color: #ccc;
  background: none; // cover the in-range style
}

.#{$namespace}-btn-current-month,
.#{$namespace}-btn-current-year {
  font-family: 'DM Sans', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  background-color: white;
  border: none;
  pointer-events: none;
  float: left;
  color: #000000;
  padding-top: 6px;
}
</style>
