<template>
  <TranscriptPreview
    v-if="currentTranscript"
    ref="transcriptPreview"
    class="episode-transcript-preview hidden"
    :theme="theme"
    :transcript="currentTranscript"
  />
</template>

<script>
import TranscriptPreview from '../admin/transcripts/components/TranscriptPreview'
import { THEMES } from '../shared/utils/view_options'
import TranscriptsApiService from '../admin/transcripts/services/transcriptsApi.service'

export default {
  name: 'EpisodeTranscriptAppComponent',
  components: {
    TranscriptPreview,
  },
  data() {
    return {
      theme: THEMES.DARK,
      publicTranscripts: [],
      currentTranscript: null,
      loading: true,
    }
  },
  mounted() {
    this.fetchPublicTranscripts()
  },
  methods: {
    toggleTranscript() {
      this.$el.classList.toggle('hidden')
      document.getElementById('toggleTranscriptButton').classList.toggle('active')
      document.getElementById('suggestedEpisodesContainer').classList.toggle('hidden')
      document.getElementById('nextEpisodeContainer').classList.toggle('hidden')
    },
    fetchPublicTranscripts() {
      this.loading = true

      TranscriptsApiService.getPublicTranscripts(this.optional_params)
        .then((response) => {
          const responseData = response.data

          this.publicTranscripts = responseData.transcripts

          if (this.publicTranscripts.length > 0) {
            this.currentTranscript = this.publicTranscripts[0]
            const toggleTranscriptButton = document.getElementById(
              'toggleTranscriptButton'
            )

            if (toggleTranscriptButton) {
              toggleTranscriptButton.classList.remove('hidden')
              toggleTranscriptButton.addEventListener('click', () => {
                this.toggleTranscript()
              })
            }
          }
        })
        .finally(() => (this.loading = false))
    },
  },
}
</script>

<style lang="scss">
.episode-transcript-preview {
  display: block;

  &.hidden {
    display: none;
  }
}
</style>
