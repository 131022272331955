<template>
  <NewsBookmarks />
</template>

<script>
import NewsBookmarks from './components/NewsBookmarks'

export default {
  name: 'NewsBookmarksAppComponent',
  components: {
    NewsBookmarks,
  },
}
</script>
