<template>
  <div :class="[theme, lockedClass]" class="note-form">
    <div v-if="!hideTitle" class="notes-back-button__container">
      <ScrollUpIcon @click="scrollToVideo" />
    </div>
    <div v-if="!hideTitle" class="notes-header study-tools-title-area">
      <span class="notes-title">
        <span class="title-text">
          {{ $vueTranslate('study_tools.note_form.title') }}
        </span>
      </span>
    </div>

    <div class="notes-container">
      <div class="notes-title-wrapper">
        <textarea
          ref="newNotesArea"
          v-model="note.title"
          type="text"
          wrap="hard"
          class="new-notes-area"
          :maxlength="NOTE_TITLE_LIMIT"
          :placeholder="
            $vueTranslate('study_tools.note_form.title_placeholder')
          "
          @keydown.enter.exact.prevent
          @keyup.enter.exact.prevent
          @keydown.enter.shift.exact.prevent
        />
        <TutorialStep
          v-if="shouldShowTutorial"
          :step-positions="[12, 13, 14, 15, 16, 17, 18, 19]"
          :step-number="tutorialStep"
          @[EMITTERS.CHANGE_TUTORIAL_STEP_EMITTER]="propagateStepChange"
        />
        <form class="note-actions" @submit.prevent="submitForm()">
          <input v-model="note.title" type="hidden" />
          <input v-model="note.content" type="hidden" />

          <button
            v-if="isActive"
            class="note__cancel-button"
            type="button"
            @submit.prevent
            @click="cancel()"
          >
            {{ $vueTranslate('study_tools.note_form.cancel_btn') }}
          </button>
          <button
            class="note__save-button"
            type="submit"
            :disabled="isInvalidNote"
          >
            {{ $vueTranslate('study_tools.note_form.save_btn') }}
          </button>
        </form>
      </div>

      <RichText
        ref="note"
        v-model:content="note.content"
        :content-updated="noteReloadTrigger"
        :theme="theme"
        placeholder="Enter a note"
      />

      <div class="note-tags">
        <span class="note-tags__title">
          {{ $vueTranslate('study_tools.note_form.tags_title') }}
        </span>
        <div
          v-for="tag of filterTags(note.tags)"
          :key="tag.name"
          class="note-tags__tag"
        >
          <span class="note-tags__tag-name">{{ tag.name }}</span>
          <CloseIcon
            class="note-tags__tag-remove"
            :theme="theme"
            @click="removeTag(tag)"
          />
        </div>
        <TagsSelect
          v-if="canAddMoreTags"
          :close-dropdown-trigger="undefined"
          :tag="tagInput"
          :theme="theme"
          :attached-tags="note.tags"
          :has-same-tag="hasSameTag"
          @[EMITTERS.TAG_ADDED_EMITTER]="addTag"
        />
        <div v-else class="tags-limit-message">
          {{ $vueTranslate('study_tools.note_form.tags_limit') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sortBy } from 'lodash'

import { CloseIcon, ScrollUpIcon } from '../../shared/components/icons'
import RichText from '../../shared/components/RichText'
import TagsSelect from './elements/TagsSelect'
import { EMITTERS } from '../../shared/utils/emitters'
import {
  NOTE_CREATED,
  NOTE_UPDATED,
} from '../../shared/utils/notificationMessages'
import {
  BOOKMARK_MODES as MODES,
  SITES,
  THEMES,
} from '../../shared/utils/view_options'
import { NotesApiService } from '../services'
import TutorialStep from './elements/TutorialStep'

export default {
  name: 'NoteFormComponent',
  components: {
    RichText,
    ScrollUpIcon,
    TagsSelect,
    CloseIcon,
    TutorialStep,
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: THEMES.DARK,
    },
    cancelWithRedirectTo: {
      type: String,
      default: undefined,
    },
    showTutorial: {
      type: Boolean,
      default: false,
    },
    tutorialStep: {
      type: Number,
      default: null,
    },
  },
  emits: [
    'update:active',
    EMITTERS.NOTE_UPDATED_EMITTER,
    EMITTERS.NOTE_CREATED_EMITTER,
    EMITTERS.CHANGE_TUTORIAL_STEP_EMITTER,
  ],
  data() {
    return {
      MODES,
      EMITTERS,
      loading: false,
      count: 0,
      tagInput: {
        name: '',
      },
      NOTE_TITLE_LIMIT: 65,
      TAGS_LIMIT: 10,
    }
  },
  computed: {
    canAddMoreTags() {
      return this.filterTags(this.note.tags).length < this.TAGS_LIMIT
    },
    hasSameTag() {
      return !!this.note.tags.find(
        (tag) => tag.name === this.tagInput.name && tag._destroy !== true
      )
    },
    note() {
      return this.$store.state.note
    },
    noteReloadTrigger() {
      return this.$store.state.noteResetTrigger
    },
    isEditMode() {
      return !!this.note.id
    },
    isCreateMode() {
      return !this.note.id
    },
    isInvalidNote() {
      if (this.note.id && !this.$store.state.noteChanged) {
        return true
      }

      return _.trim(this.note.content) === ''
    },
    isActive() {
      if (this.note.id) return true
      if (this.note.title.length > 0 || this.note.content.length > 0)
        return true

      return this.note.tags.length > 0 || this.tagInput.name.length > 0
    },
    lockedClass() {
      return this.loading ? 'locked' : ''
    },
    shouldShowTutorial() {
      if (!this.showTutorial) return false
      if (!this.isCreateMode) return false

      const tagsSelectOpened =
        !this.tagInput || this.isEmptyString(this.tagInput.name)
      if (this.tutorialStep === 18 && tagsSelectOpened) return false

      return true
    },
  },
  watch: {
    isActive() {
      this.$emit('update:active', this.isActive)
    },
    'note.content'() {
      if (
        this.isActive &&
        this.shouldShowTutorial &&
        this.tutorialStep === 13
      ) {
        this.$emit(EMITTERS.CHANGE_TUTORIAL_STEP_EMITTER, {
          number: this.tutorialStep,
        })
      }
    },
    'tagInput.name'() {
      if (
        this.shouldShowTutorial &&
        this.tutorialStep === 17 &&
        !this.isEmptyString(this.tagInput.name)
      ) {
        this.$emit(EMITTERS.CHANGE_TUTORIAL_STEP_EMITTER, {
          number: this.tutorialStep,
        })
      }

      if (
        this.tutorialStep === 18 &&
        this.note.tags.length > 0 &&
        this.showTutorial &&
        this.isCreateMode
      ) {
        this.$emit(EMITTERS.CHANGE_TUTORIAL_STEP_EMITTER, {
          number: this.tutorialStep,
        })
      }
    },
  },
  created() {
    this.$emit('update:active', this.isActive)
  },
  methods: {
    scrollToVideo() {
      $('html, body').animate(
        {
          scrollTop: $('.watch-episode').offset().top,
        },
        500
      )
    },
    submitForm() {
      if (this.isCreateMode) this.createNote()
      if (this.isEditMode) this.updateNote()
    },
    resetNote() {
      this.$store.dispatch('resetNote')
    },
    cancel() {
      this.clearForm()

      if (this.cancelWithRedirectTo) {
        this.$router.push({ name: this.cancelWithRedirectTo })
      }
    },
    clearForm() {
      this.resetNote()
    },
    createNote() {
      this.loading = true
      NotesApiService.createNote(this.note, this.optional_params)
        .then((_response) => {
          this.created()
          this.loading = false
        })
        .catch(console.error)
    },
    updateNote() {
      this.loading = false
      NotesApiService.updateNote(this.note, this.optional_params)
        .then((_response) => {
          this.updated()
          this.loading = false
        })
        .catch(console.error)
    },
    created() {
      if (this.shouldShowTutorial && this.tutorialStep === 19) {
        this.$emit(EMITTERS.CHANGE_TUTORIAL_STEP_EMITTER, {
          number: this.tutorialStep,
        })
      }
      this.resetWithEmit(NOTE_CREATED, EMITTERS.NOTE_CREATED_EMITTER)
    },
    updated() {
      this.resetWithEmit(NOTE_UPDATED, EMITTERS.NOTE_UPDATED_EMITTER)
    },
    resetWithEmit(notificationMessage, emitter) {
      this.emitter.emit(EMITTERS.SHOW_NOTIFICATION_EMITTER, {
        message: notificationMessage,
        site: SITES.WATCH,
      })
      this.$emit(emitter)

      this.clearForm()
    },
    removeTag(tag) {
      if (this.isCreateMode) {
        this.note.tags = this.note.tags.filter((element) => element !== tag)
      }

      if (this.isEditMode) {
        const removedTag = this.note.tags.find((element) => element === tag)

        removedTag.st_taggable_id = null
        removedTag.st_taggable_type = null
        removedTag._destroy = true
      }
    },
    addTag(tag) {
      this.note.tags.push({ name: tag.name })
      this.resetTagsInput()
    },
    filterTags(tags) {
      if (!tags) return []
      const filteredTags = tags.filter((tag) => !(tag.st_taggable_id === null))
      return sortBy(filteredTags, ['name', 'created_at'])
    },
    resetTagsInput() {
      this.tagInput = {
        name: '',
      }
    },
    propagateStepChange(event) {
      this.$emit(EMITTERS.CHANGE_TUTORIAL_STEP_EMITTER, event)
    },
    isEmptyString(string) {
      return !string || /^\s*$/.test(string)
    },
  },
}
</script>

<style lang="scss">
.notes-container {
  .rich-text-container .ql-editor {
    height: 200px;
    padding: 18px;
  }
  .rich-text-container .ql-editor:before {
    left: 18px;
  }
}
</style>

<style lang="scss" scoped>
@import '../../shared/assets/styles/global';

.locked {
  pointer-events: none;
  opacity: 0.6;
}

.notes-header {
  font-weight: 500;
  display: flex;
  justify-content: space-between;

  .title-text {
    font-size: 20px;
    margin-right: 4px;
  }

  .counter {
    font-size: 14px;
  }
}
.notes-title {
  margin: auto 0;
}

.note-form {
  font-size: 14px;
}

.notes-back-button__container {
  position: relative;
  width: 100%;

  .bookmark-back-button {
    position: absolute;
    right: -28px;
    top: -98px;
    font-size: 1.125rem;
  }
}

.notes-container {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding-inline-start: 0;
  overflow: hidden;

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 3px;

    &:hover {
      width: 10px;
    }
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}
.new-notes-area,
.notes-title {
  background: transparent;
  border: none;
  resize: none;
  display: flex;
  width: 85%;
  flex-basis: 100%;
  overflow-wrap: anywhere;

  &:focus {
    outline: none;
  }
}
.notes-title-wrapper {
  padding: 12px 16px;
  display: flex;
  align-items: baseline;
}
.new-notes-area {
  height: 32px;

  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.note-actions,
.note-actions form {
  display: flex;
  margin-left: auto;
  justify-content: end;
  width: 205px;
  margin-right: 3px;
}

.note__action-button {
  border-radius: 4px;
  border: none;
  font-size: 14px;
  height: 32px;
  width: 85px;
}
.note__cancel-button {
  @extend .note__action-button;
  margin-left: auto;
}
.note__save-button {
  @extend .note__action-button;
  margin: 0 8px;
}

.tags-select__wrapper {
  position: inherit;
}

.tags-button {
  padding: 3px 12px;
  font-size: 14px;
  border-radius: 25px;
}

.note-tags {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 auto;
  margin-top: 10px;
  padding: 12px 18px;
}

.note-tags__title {
  font-size: 14px;
  flex-grow: 0.02;
  margin: auto 0;
}

.note-tags__tag {
  display: flex;
  padding: 4px 10px;
  margin: 3px 3px;
  border-radius: 20px;
  font-size: 12px;
  justify-content: space-between;
  flex-grow: 0.02;
}

.note-tags__tag-remove {
  margin: auto 0;
}

.tags-limit-message {
  font-size: 14px;
  line-height: 18px;
  margin: auto 3px;
}

// theme
// LIGHT
//#6998ae;
$tags-limit-color-light: #8f8a9b;
$tag-border-light: #d1d3d4;
$tags-white: #ffffff;
$notes-blue: #6998ae;
$notes-text-light: #323232;
$title-border-light: #eaedee;
$title-hovered-background-light: #f9f9f9;
// DARK
$edit-tags-color-light: #5c5c5c;
$edit-tags-color-dark: #d1d3d4;
$tag-border-dark: #444050;
$tags-limit-color-dark: #8f8a9b;
$tags-button-dark: #3b3946;
$title-hovered-background-dark: #32303c;

.light {
  .notes-header {
    .title-text {
      font-size: 20px;
      color: $notes-text-light;
      margin-right: 4px;
    }

    .counter {
      font-size: 14px;
      color: #a6a6a6;
    }
  }
  .notes-container {
    background-color: $tags-white;
    &::-webkit-scrollbar-thumb {
      background-color: $tags-limit-color-dark;
      border: 4px solid $tags-limit-color-dark;
    }
  }
  .new-notes-area,
  .notes-title {
    color: $notes-text-light;
  }
  .notes-title-wrapper {
    border-bottom: 1px $title-border-light solid;
  }
  .notes-title-wrapper:hover {
    background: $title-hovered-background-light;
  }
  .new-notes-area:before {
    color: $tags-limit-color-dark;
  }
  .note__action-button {
    color: $notes-text-light;
  }
  .note__cancel-button {
    background-color: $tags-white;
    color: $notes-blue;
    box-shadow: 0 1px 10px rgba(29, 32, 35, 0.15);
    border-radius: 4px;
  }
  .note__save-button {
    background-color: #6998ae;
    color: $tags-white;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);

    &:disabled,
    &[disabled] {
      color: $tags-limit-color-dark;
      background: #f9f9f9;
    }
  }
  .tags-button {
    background-color: $tags-button-dark;
    border: 1px solid $tag-border-dark;
    color: $tags-white;
  }
  .note-tags {
    border-top: 1px $tag-border-dark solid;
  }
  .note-tags__title {
    color: $edit-tags-color-light;
  }

  .note-tags__tag-name {
    color: $edit-tags-color-light;
  }

  .note-tags__tag {
    border: 1px solid $tag-border-light;
  }

  .tags-limit-message {
    color: $edit-tags-color-light;
  }
}

.dark {
  .notes-header {
    .title-text {
      font-size: 20px;
      color: $tags-white;
      margin-right: 4px;
    }

    .counter {
      font-size: 14px;
      color: #a6a6a6;
    }
  }
  .notes-container {
    background-color: #2b2834;
    &::-webkit-scrollbar-thumb {
      background-color: $tags-limit-color-dark;
      border: 4px solid $tags-limit-color-dark;
    }
  }
  .new-notes-area,
  .notes-title {
    color: $tags-white;
  }
  .notes-title-wrapper {
    border-bottom: 1px $tag-border-dark solid;
  }
  .notes-title-wrapper:hover {
    background: $title-hovered-background-dark;
  }
  .new-notes-area:before {
    color: $tags-limit-color-dark;
  }
  .note__action-button {
    color: $tags-white;
  }
  .note__cancel-button {
    background-color: $tag-border-dark;
  }
  .note__save-button {
    background-color: #6998ae;
    &:disabled,
    &[disabled] {
      color: $tags-limit-color-dark;
      background-color: $tags-button-dark;
    }
  }
  .tags-button {
    background-color: $tags-button-dark;
    border: 1px solid $tag-border-dark;
    color: $tags-white;
  }
  .note-tags {
    border-top: 1px $tag-border-dark solid;
  }
  .note-tags__title {
    color: $edit-tags-color-dark;
  }

  .note-tags__tag-name {
    color: $edit-tags-color-dark;
  }

  .note-tags__tag {
    border: 1px solid $tag-border-dark;
  }

  .tags-limit-message {
    color: $tags-limit-color-dark;
  }
}
</style>
