import { Quill } from '@vueup/vue-quill'

import { WrapperBlot, WrappedBlot } from './blot_wrapper'

const leftIcon =
  '<svg viewBox="0 0 18 18" style="display: inline-block; width: 24px; height: 24px; background: white; border: 1px solid rgb(153, 153, 153); vertical-align: middle;"> <line class="ql-stroke" x1="3" x2="15" y1="9" y2="9"></line> <line class="ql-stroke" x1="3" x2="13" y1="14" y2="14"></line> <line class="ql-stroke" x1="3" x2="9" y1="4" y2="4"></line> </svg>'
const rightIcon =
  '<svg viewBox="0 0 18 18" style="display: inline-block; width: 24px; height: 24px; background: white; border: 1px solid rgb(153, 153, 153); vertical-align: middle;"> <line class="ql-stroke" x1="15" x2="3" y1="9" y2="9"></line> <line class="ql-stroke" x1="15" x2="5" y1="14" y2="14"></line> <line class="ql-stroke" x1="15" x2="9" y1="4" y2="4"></line> </svg>'
const centerIcon =
  '<svg viewBox="0 0 18 18" style="display: inline-block; width: 24px; height: 24px; background: white; border: 1px solid rgb(153, 153, 153); vertical-align: middle;"> <line class="ql-stroke" x1="15" x2="3" y1="9" y2="9"></line> <line class="ql-stroke" x1="14" x2="4" y1="14" y2="14"></line> <line class="ql-stroke" x1="12" x2="6" y1="4" y2="4"></line> </svg>'

function initExtendedImage() {
  let icons = Quill.import('ui/icons')

  icons['extended-image'] =
    '<div class="icon-extended-image" aria-hidden="true">' +
    '<svg viewBox="0 0 18 18"> <rect class="ql-stroke" height="10" width="12" x="3" y="4"></rect> <circle class="ql-fill" cx="6" cy="7" r="1"></circle> <polyline class="ql-even ql-fill" points="5 12 5 11 7 9 8 10 11 7 13 9 13 12 5 12"></polyline> </svg>' +
    'Description</div>'

  class ExtendedImageContainer extends WrapperBlot {
    static create() {
      let node = super.create()

      $('.ql-image').click()
      let description = document.createElement('P')
      description.classList.add('extended-image-paragraph')
      node.appendChild(description)

      return node
    }
  }

  ExtendedImageContainer.blotName = 'extended-image'
  ExtendedImageContainer.tagName = 'aside'
  ExtendedImageContainer.defaultChild = 'extended-image-paragraph'
  ExtendedImageContainer.className = 'ad-extended-image'

  class ExtendedImageParagraph extends WrappedBlot {
    static create() {
      let node = super.create()
      node.classList.add('ql-align-center')
      return node
    }
  }

  ExtendedImageParagraph.blotName = 'extended-image-paragraph'
  ExtendedImageParagraph.className = 'extended-image-paragraph'
  ExtendedImageParagraph.tagName = 'p'
  ExtendedImageParagraph.parentName = 'extended-image'

  ExtendedImageContainer.allowedChildren = [ExtendedImageParagraph]
  ExtendedImageParagraph.requiredContainer = ExtendedImageContainer

  Quill.register(ExtendedImageContainer)
  Quill.register(ExtendedImageParagraph)
}

function updateAlignOverlays(quill) {
  let overlaysWrapper = document.getElementById(
    'extended-image-overlay-wrapper'
  )
  if (overlaysWrapper) overlaysWrapper.remove()
  overlaysWrapper = document.createElement('div')
  overlaysWrapper.id = 'extended-image-overlay-wrapper'

  let images = $(quill.container).find('.ad-extended-image')
  images.each((_index, container) => {
    const overlay = overlayElement(container)
    overlaysWrapper.appendChild(overlay)
  })
  quill.container.appendChild(overlaysWrapper)
}

function overlayElement(node) {
  const overlay = document.createElement('div')
  overlay.classList.add('extended-image-overlay')
  overlay.classList.add('hidden')

  $(node)
    .find('img')
    .click(() => {
      overlay.classList.remove('hidden')
    })
  node.addEventListener(
    'click',
    () => {
      overlay.classList.remove('hidden')
    },
    { ones: true }
  )

  const left = document.createElement('div')
  const right = document.createElement('div')
  const center = document.createElement('div')
  left.innerHTML = leftIcon
  right.innerHTML = rightIcon
  center.innerHTML = centerIcon

  left.addEventListener('click', () => {
    if (node.style.float && node.style.float === 'left') {
      node.style.float = ''
      left.classList.remove('selected')
      return
    }
    node.classList.remove('centered')
    node.style.float = 'left'
  })
  right.addEventListener('click', () => {
    if (node.style.float && node.style.float === 'right') {
      node.style.float = ''
      right.classList.remove('selected')
      return
    }
    node.classList.remove('centered')
    node.style.float = 'right'
  })

  center.addEventListener('click', () => {
    if (node.classList.contains('centered')) {
      node.classList.remove('centered')
      right.classList.remove('selected')
      return
    }
    node.style.float = ''
    node.classList.add('centered')
  })

  const currentFloat = node.style.float
  switch (currentFloat) {
    case 'left':
      left.classList.add('selected')
      break
    case 'right':
      right.classList.add('selected')
      break
  }
  if (node.classList.contains('centered')) {
    center.classList.add('selected')
  }

  overlay.appendChild(left)
  overlay.appendChild(center)
  overlay.appendChild(right)

  return overlay
}

export { initExtendedImage, updateAlignOverlays }
