<template>
  <div class="news-bookmarks__container">
    <div class="news-bookmarks__header">Bookmarks</div>
    <div v-if="authorized" class="news-bookmarks__filters">
      <div class="news-bookmarks__filter-title">Show archived</div>
      <SwitchButton @change="toggleArchived"></SwitchButton>
    </div>
    <div
      v-if="!loading && authorized && newsBookmarks.length > 0"
      class="news-bookmarks__list"
    >
      <NewsBookmark
        v-for="newsBookmark of newsBookmarks"
        :key="newsBookmark.id"
        :news-bookmark="newsBookmark"
        :active-bookmark-id="activeBookmarkId"
        :mode="MODES.SHOW"
        :theme="THEMES.LIGHT"
        @[EMITTERS.BOOKMARK_DELETED_EMITTER]="deleteBookmark"
        @[EMITTERS.BOOKMARK_UPDATED_EMITTER]="updateBookmark"
      />
    </div>
    <div
      v-if="!loading && newsBookmarks.length === 0 && authorized"
      class="news-bookmarks__placeholder"
    >
      <span
        >You have not created any bookmarks. Highlight needed text in article
        and choose icon
      </span>
      <div class="news-bookmarks__bookmark-icon" />
      <span>from the menu</span>
    </div>
    <div v-if="!authorized" class="news-bookmarks__placeholder">
      <Authorization :theme="THEMES.LIGHT" />
    </div>
  </div>
</template>

<script>
import { remove } from 'lodash'

import { EMITTERS } from '../../shared/utils/emitters'
import NewsBookmarksApiService from '../services/newsBookmarksApi.service'
import NewsBookmark from './NewsBookmark'
import Authorization from '../../study_tools/components/Authorization'
import {
  THEMES,
  BOOKMARK_MODES as MODES,
} from '../../shared/utils/view_options'
import SwitchButton from '../../shared/components/SwitchButton.vue'

export default {
  name: 'NewsBookmarksComponent',
  components: {
    Authorization,
    NewsBookmark,
    SwitchButton,
  },
  emits: [],
  data() {
    return {
      THEMES,
      EMITTERS,
      MODES,
      loading: true,
      newsBookmarks: [],
      activeBookmarkId: 0,
      onlyActive: true,
    }
  },
  computed: {
    authorized() {
      return !!this.optional_params.user_id
    },
  },
  created() {
    this.emitter.on(EMITTERS.HIGHLIGHT_NEWS_BOOKMARK, this.highlightBookmark)
    this.emitter.on(EMITTERS.CLEAR_SELECTION_NEWS_BOOKMARK, this.clearSelection)
    this.emitter.on(EMITTERS.BOOKMARK_CREATED_EMITTER, this.fetchNewsBookmarks)
    this.emitter.on(
      EMITTERS.HIGHLIGHT_FROM_PROFILE_EMITTER,
      this.highlightFromProfile
    )
    this.fetchNewsBookmarks()
  },
  methods: {
    toggleArchived() {
      this.onlyActive = !this.onlyActive
      this.fetchNewsBookmarks()
    },
    maybeActive() {
      return this.onlyActive ? '' : 'active'
    },
    deleteBookmark(bookmark) {
      remove(this.newsBookmarks, (element) => element.id === bookmark.id)
      this.count--
    },
    updateBookmark(bookmark) {
      const storedBookmark = this.newsBookmarks.find(
        (element) => element.id === bookmark.id
      )
      Object.assign(storedBookmark, bookmark)
    },
    highlightBookmark(bookmark) {
      this.activeBookmarkId = bookmark.id
    },
    clearSelection() {
      this.activeBookmarkId = 0
    },
    fetchNewsBookmarks() {
      const filterParams = { only_active: this.onlyActive }
      const params = Object.assign({}, this.optional_params, filterParams)
      NewsBookmarksApiService.getAllBookmarks(params)
        .then((response) => {
          const responseData = response.data

          this.newsBookmarks = responseData.news_bookmarks
          this.count = responseData.count
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false
        })

      return null
    },
    highlightFromProfile() {
      const bookmarkId = parseInt(
        localStorage.getItem('highlightNewsBookmarkId')
      )
      if (bookmarkId === 0) return

      this.activeBookmarkId = bookmarkId
      const activeBookmark = this.newsBookmarks.find(
        (element) => element.id === bookmarkId
      )
      this.emitter.emit(EMITTERS.HIGHLIGHT_NEWS_BOOKMARK, activeBookmark)
      localStorage.setItem('highlightNewsBookmarkId', '0')
    },
  },
}
</script>

<style lang="scss" scoped>
.news-bookmarks__header {
  color: #323232;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 16px;
  padding-left: 24px;
  display: inline-block;
}

.news-bookmarks__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 1200px;
}

.news-bookmarks__bookmark-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 5H4.71429C4.25963 5 3.82359 5.1873 3.5021 5.5207C3.18061 5.8541 3 6.30628 3 6.77778V21L9 16.5556L15 21V12.1111' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.5 3.375C17.141 3.375 16.85 3.66601 16.85 4.025V7.04208H14.025C13.666 7.04208 13.375 7.33309 13.375 7.69208C13.375 8.05106 13.666 8.34208 14.025 8.34208H16.85V10.975C16.85 11.334 17.141 11.625 17.5 11.625C17.859 11.625 18.15 11.334 18.15 10.975V8.34208H20.975C21.334 8.34208 21.625 8.05106 21.625 7.69208C21.625 7.3331 21.334 7.04208 20.975 7.04208H18.15V4.025C18.15 3.66601 17.859 3.375 17.5 3.375Z' fill='black'/%3E%3C/svg%3E%0A");
  width: 24px;
  height: 24px;
  display: inline;
  padding-bottom: 4px;
  padding-left: 24px;
  vertical-align: baseline;
}

.news-bookmarks__placeholder {
  border-radius: 4px;
  border-top: 4px solid #d18f42;
  padding: 20px;
  display: block;
  font-size: 14px;
  line-height: 20px;
  background: #f3f4f5;
  margin-left: 16px;
  min-width: 320px;
}

.news-bookmarks__filters {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.news-bookmarks__filter-title {
  color: var(--text-primary, #323232);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.news-bookmarks__container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>
