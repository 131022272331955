import { BlobServiceClient } from '@azure/storage-blob'

export async function uploadFile(
  storageAccount,
  sasToken,
  container,
  file,
  onUpdate,
  onError,
  onSuccess
) {
  const blobServiceClient = new BlobServiceClient(
    `https://${storageAccount}.blob.core.windows.net?${sasToken}`
  )
  const containerClient = blobServiceClient.getContainerClient(container)
  const blockBlobClient = containerClient.getBlockBlobClient(file.name)

  const chunkSize = 1024 * 1024 // 1MB chunks
  const totalBytes = file.size
  let offset = 0
  let blockIdPrefix = 'block'
  let blockIds = []

  const readChunk = (file, start, end) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      const blob = file.slice(start, end)

      reader.onload = () => resolve(reader.result)
      reader.onerror = () => reject(reader.error)

      reader.readAsArrayBuffer(blob)
    })
  }

  const uploadChunk = async (blockBlobClient, chunk, blockId) => {
    await blockBlobClient.stageBlock(blockId, chunk, chunk.byteLength)
  }

  try {
    while (offset < totalBytes) {
      const chunk = await readChunk(
        file,
        offset,
        Math.min(offset + chunkSize, totalBytes)
      )
      const blockId = btoa(
        blockIdPrefix + (offset / chunkSize).toString().padStart(6, '0')
      )
      blockIds.push(blockId)

      await uploadChunk(blockBlobClient, chunk, blockId)

      offset += chunkSize

      const progress = (offset / totalBytes) * 100
      onUpdate(progress)
    }

    await blockBlobClient.commitBlockList(blockIds)

    onSuccess()
  } catch (error) {
    onError(error)
  }
}
