$(document).on('turbolinks:load', function () {
  const icon1 = $('#burger-icon')
  const icon2 = $('#cross-menu-icon')
  const collapse = $('#navbar_supported_content')

  collapse.on('show.bs.collapse', function () {
    icon1.hide()
    icon2.show()
  })

  collapse.on('hide.bs.collapse', function () {
    icon2.hide()
    icon1.show()
  })

  const watchSubMenu = $('#watch-sub-menu-block')
  const watchNavItemLink = $('.dropdown-toggle-watch')
  watchNavItemLink.append(
    '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="watch-arrow-right"> <path d="M9 18L15 12L9 6" stroke="#D1D3D4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>'
  )

  $('#watch-sub-menu__back-btn').click(() => {
    watchSubMenu.hide()
  })

  watchNavItemLink.click(() => {
    const windowWidth = window.innerWidth
    if (windowWidth < 1199.98) {
      event.preventDefault()
      watchSubMenu.show()
    }
  })
})
