<template>
  <div class="message-wrapper">
    <div
      v-for="(messageGroup, date) in groupedMessages"
      :key="date"
      class="message-group"
    >
      <div v-if="showDateTitle" class="message-date-label">
        {{ formatDate(date) }}
      </div>

      <Message
        v-for="(message, index) in messageGroup"
        :key="message"
        :message="message"
        :index="index"
      />
    </div>
  </div>
</template>

<script>
import Message from './Message'

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'MessagesComponent',
  components: {
    Message,
  },
  props: {
    messages: {
      required: true,
      type: Array,
    },
    showDateTitle: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    groupedMessages() {
      return this.messages.reduce((groups, message) => {
        let date = message.sent_at.split('T')[0]
        if (!groups[date]) {
          groups[date] = []
        }
        groups[date].push(message)
        return groups
      }, {})
    },
  },

  methods: {
    formatDate(date) {
      const dateToCompare = new Date()
      if (date === this.dateShort(dateToCompare)) return 'Today'
      dateToCompare.setDate(dateToCompare.getDate() - 1)
      if (date === this.dateShort(dateToCompare)) return 'Yesterday'
      return date
    },
    dateShort(date) {
      return date.toISOString().split('T')[0]
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../shared/assets/styles/global';
.message-wrapper {
  max-width: 667px;
}
.message-date-label {
  font-size: 14px;
  color: #5c5c5c;
  text-align: center;
  font-weight: 500;
  margin: 8px 0;
}
</style>
