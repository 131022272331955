<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="#F0F0F0"/>
    <path d="M19.9998 9.99992C19.9998 6.03531 17.6926 2.60969 14.3477 0.992188V19.0076C17.6926 17.3902 19.9998 13.9645 19.9998 9.99992Z" fill="#D80027"/>
    <path d="M0 9.99992C0 13.9645 2.30723 17.3902 5.65219 19.0077V0.992188C2.30723 2.60969 0 6.03531 0 9.99992Z" fill="#D80027"/>
    <path d="M11.7408 11.3044L13.4799 10.4348L12.6104 10V9.13045L10.8712 10L11.7408 8.26088H10.8712L10.0017 6.95654L9.13211 8.26088H8.26254L9.13211 10L7.39297 9.13045V10L6.52344 10.4348L8.26254 11.3044L7.82777 12.1739H9.56691V13.4783H10.4364V12.1739H12.1756L11.7408 11.3044Z" fill="#D80027"/>
  </svg>
</template>

<script>
export default {
  name: "CanadianFlag"
}
</script>
