import Flickity from 'vendor/flickity'

const WIDE_SLIDER_SELECTOR = '.trending_items .wide_slider'

// Adding this for hiding the prev/next buttons when they are not needed
// https://github.com/metafizzy/flickity/issues/566#issuecomment-469782124
const setSingleSlideStyles = (slider) => {
  slider.slides.length < 4
    ? slider.element.classList.add('single-slide')
    : slider.element.classList.remove('single-slide')
}

document.addEventListener('turbolinks:load', () => {
  // using `function` as a handler below to capture the correct `this` from jQuery
  $(WIDE_SLIDER_SELECTOR).on('ready.flickity', function () {
    const flickity = Flickity.data(this)

    setSingleSlideStyles(flickity)

    flickity.on('resize', () => setSingleSlideStyles(flickity))
  })
})
