<template>
  <div
    class="news-bookmark__container"
    :class="[maybeActive(), maybeArchived(), theme]"
  >
    <div class="news-bookmark__text" @click="highlightBookmark">
      {{ newsBookmark.text }}
    </div>
    <div class="news-bookmark__actions">
      <a class="bookmark-action edit-action">
        <EditIcon :theme="theme" @click="editMode" />
      </a>

      <a class="bookmark-action delete-action">
        <DeleteIcon :theme="theme" @click="openDialog" />
      </a>

      <div class="tags-area news-bookmark__tags-area">
        <Tags
          v-if="tagsOpen"
          :tags="newsBookmark.tags"
          :theme="theme"
          @[EMITTERS.TOGGLE_TAG_BLOCK_EMITTER]="toggleTagBlock"
        />
        <button
          v-if="newsBookmark.tags && newsBookmark.tags.length"
          type="button"
          :data-id="newsBookmark.id"
          class="tags-button bookmark-action"
          @click.prevent="toggleTagBlock({ hide: false })"
        >
          Tags
        </button>
      </div>
    </div>
  </div>
  <ConfirmationDialog
    v-if="showDialog"
    :theme="theme"
    :entity="newsBookmark"
    :confirmation-text="DELETE_CONFIRMATION_TEXT"
    :delete-function="BOOKMARK_DELETE_FUNCTION"
    :on-deleted-emitter="EMITTERS.BOOKMARK_DELETED_EMITTER"
    @[EMITTERS.CLOSE_DIALOG_EMITTER]="closeDialog"
    @[EMITTERS.BOOKMARK_DELETED_EMITTER]="bookmarkDeleted"
  />
</template>
<script>
import { EMITTERS } from '../../shared/utils/emitters'
import {
  BOOKMARK_MODES as MODES,
  THEMES,
} from '../../shared/utils/view_options'
import { DeleteIcon, EditIcon } from '../../shared/components/icons'
import Tags from '../../study_tools/components/Tags'
import ConfirmationDialog from '../../study_tools/components/ConfirmationDialog'
import NewsBookmarksApiService from '../services/newsBookmarksApi.service'
import {
  BOOKMARK_ARCHIVED,
  BOOKMARK_DELETED,
} from '../../shared/utils/notificationMessages'
export default {
  name: 'ManageNewsBookmarkComponent',
  components: {
    EditIcon,
    DeleteIcon,
    Tags,
    ConfirmationDialog,
  },
  props: {
    newsBookmark: {
      id: Number | null,
      match_number: Number,
      text: String,
      tags: Array,
      is_highlightable: Boolean,
    },
    activeBookmarkId: {
      type: Number,
    },
    mode: {
      required: true,
      type: String, // MODES.EDIT | MODES.SHOW | MODES.CREATE,
    },
    theme: {
      required: true,
      type: String, // THEMES.DARK | THEMES.LIGHT,
      default: THEMES.LIGHT,
    },
    href: {
      required: false,
      type: String,
      default: '#',
    },
  },
  emits: [EMITTERS.TOGGLE_TAG_BLOCK_EMITTER],
  data() {
    return {
      THEMES,
      EMITTERS,
      currentMode: this.mode,
      tagsOpen: false,
      showDialog: false,
      storedBookmark: {},
      BOOKMARK_DELETE_FUNCTION: NewsBookmarksApiService.deleteBookmark,
      DELETE_CONFIRMATION_TEXT: 'Delete this bookmark?',
    }
  },
  computed: {
    isEditMode() {
      return this.currentMode === MODES.EDIT
    },
    isShowMode() {
      return this.currentMode === MODES.SHOW
    },
  },
  methods: {
    maybeArchived() {
      return this.newsBookmark.is_highlightable ? '' : 'archived'
    },
    editMode() {
      this.emitter.emit(EMITTERS.BOOKMARK_EDIT_EMITTER, this.newsBookmark)
      this.$router.push({
        name: 'profile_bookmark_edit',
        params: { id: this.newsBookmark.id },
      })
    },
    openDialog() {
      this.showDialog = true
    },
    closeDialog() {
      this.showDialog = false
    },
    bookmarkDeleted(bookmark) {
      this.$emit(EMITTERS.BOOKMARK_DELETED_EMITTER, bookmark)
      this.emitter.emit(EMITTERS.SHOW_NOTIFICATION_EMITTER, {
        message: BOOKMARK_DELETED,
      })
    },
    highlightBookmark() {
      if (this.href !== '#') {
        localStorage.setItem('highlightNewsBookmarkId', this.newsBookmark.id)
        window.open(this.href, '_blank')
      } else if (this.newsBookmark.is_highlightable) {
        this.emitter.emit(EMITTERS.HIGHLIGHT_NEWS_BOOKMARK, this.newsBookmark)
      } else {
        this.emitter.emit(
          EMITTERS.SHOW_ERROR_NOTIFICATION_EMITTER,
          BOOKMARK_ARCHIVED
        )
      }
    },
    maybeActive() {
      return this.activeBookmarkId === this.newsBookmark.id ? 'active' : ''
    },
    toggleTagBlock(data) {
      this.tagsOpen = data.hide ? false : !this.tagsOpen
    },
  },
}
</script>

<style lang="scss" scoped>
.news-bookmark__container {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  padding: 16px 0 16px 16px;
  border-bottom: 1px solid #d1d3d4;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  &.archived {
    background-color: #f9f9f9;

    .bookmark-action {
      display: flex;
    }

    .news-bookmark__text {
      opacity: 0.5;
    }

    &:hover {
      .news-bookmark__text {
        opacity: 0.6;
        max-height: 900px;
        transition-duration: 300ms;
      }
    }
  }

  &.active {
    background-color: #f3f4f5;
    opacity: 0.9;

    .news-bookmark__text {
      max-height: 900px;
      transition-duration: 300ms;
    }

    .bookmark-action {
      display: flex;
    }

    &:hover {
      opacity: 1;
    }
  }

  &:hover {
    opacity: 1;
    background-color: #f3f4f5;

    .bookmark-action {
      display: flex;
    }

    .news-bookmark__text {
      max-height: 900px;
      transition-duration: 300ms;
    }
  }
}

.bookmark-action {
  margin: 0 5px;
  cursor: pointer;
}

.tags-button {
  padding: 3px 12px;
  font-size: 14px;
  border-radius: 25px;
  border: 1px solid #a6a6a6;
  color: #3b3946;
  background-color: transparent;
}

.news-bookmark__actions {
  display: flex;
  margin-left: auto;
  gap: 10px;
  min-width: 64px;
  height: 100%;
  flex-direction: column;
  align-items: center;
}

.news-bookmark__text {
  cursor: pointer;
  min-width: 258px;
  max-height: 72px;
  overflow: hidden;
  transition-duration: 300ms;
}

.tags-area {
  position: relative;
}

.profile {
  .news-bookmark__actions {
    margin-left: auto;
    gap: 6px;
    min-width: 120px;
    height: 100%;
    flex-direction: row;
    align-items: center;
  }

  .edit-action {
    order: 2;
  }

  .delete-action {
    order: 3;
  }

  .news-bookmark__tags-area {
    order: 1;
  }

  .tags-area {
    min-width: 50px;
  }

  .news-bookmark__text {
    width: 558px;
    max-height: 54px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .news-bookmark__container {
    padding-left: 0;
  }
}
</style>
