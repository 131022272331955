<template>
  <RichText
      v-model:content="article.body"
      :content-updated="true"
      :theme="THEMES.LIGHT"
      :viewOnly="true"
  />
</template>

<script>
import { THEMES } from '../../shared/utils/view_options'
import { EMITTERS } from '../../shared/utils/emitters'
import RichText from '../../shared/components/RichText.vue'

export default {
  name: 'ArticleContentComponent',
  components: {
    RichText
  },
  props: {
    article: {
      title: String,
      body: String,
    },
  },
  data() {
    return {
      THEMES,
      EMITTERS,
    }
  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
@import '../../shared/assets/styles/global';

</style>
