import Flickity from 'vendor/flickity'

function setSliderStyles(flickity) {
  const $slider = $(flickity.element)

  if ($slider.innerWidth() >= totalSlidesWidth(flickity)) {
    $slider.addClass('slider-spacious')
  } else {
    $slider.removeClass('slider-spacious')
  }
}

function totalSlidesWidth(flickity) {
  const slides = flickity.getCellElements()

  return slides
    .map((slide) => $(slide).outerWidth(true))
    .reduce((sum, current) => sum + current, 0)
}

function isMovingForward(prevCellIndex, currentCellIndex) {
  return currentCellIndex > prevCellIndex
}

function isMovingBackward(prevCellIndex, currentCellIndex) {
  return currentCellIndex < prevCellIndex
}

function isSecondToLastCellSelected(flickity, prevSelectedCellIndex) {
  const cells = flickity.getCellElements()
  const lastCellIndex = cells.length - 1
  const currentCellIndex = flickity.selectedIndex

  return (
    prevSelectedCellIndex == lastCellIndex &&
    isMovingBackward(prevSelectedCellIndex, currentCellIndex)
  )
}

function isSecondCellSelected(flickity, prevSelectedCellIndex) {
  const currentCellIndex = flickity.selectedIndex

  return prevSelectedCellIndex == 0 && currentCellIndex == 1
}

function selectPreviousCell(flickity) {
  const cellsInViewport = cellsInViewportCount(flickity)

  if (cellsInViewport === 1) return

  const lastCellIndex = flickity.getCellElements().length - 1
  const cellAlign = flickity.options.cellAlign

  let cellIndex

  if (cellAlign === 'center') {
    cellIndex = lastCellIndex - cellsInViewport + 1
  } else {
    cellIndex = lastCellIndex - cellsInViewport
  }

  flickity.select(cellIndex)
}

function selectNextCell(flickity) {
  const cellAlign = flickity.options.cellAlign

  if (cellAlign !== 'center') return

  const cellsInViewport = cellsInViewportCount(flickity)

  if (cellsInViewport > 1) {
    flickity.select(cellsInViewportCount(flickity) - 1)
  }
}

function cellsInViewportCount(flickity) {
  const firstCell = flickity.getCellElements()[0]
  // All cells have same width so it's safe to use width of the first one
  const cellWidth = $(firstCell).outerWidth(true)
  const sliderWidth = flickity.element.getBoundingClientRect().width

  return Math.floor(sliderWidth / cellWidth)
}

function isLastCellVisible(flickity, prevSelectedCellIndex) {
  const cells = flickity.getCellElements()
  const lastCell = cells[cells.length - 1]
  const lastCellLeft = lastCell.getBoundingClientRect().left
  const slider = flickity.element
  const sliderRight = slider.getBoundingClientRect().right
  const currentCellIndex = flickity.selectedIndex

  return (
    isMovingForward(prevSelectedCellIndex, currentCellIndex) &&
    lastCellLeft <= sliderRight
  )
}

function isFirstCellVisible(flickity, prevSelectedCellIndex) {
  const firstCell = flickity.getCellElements()[0]
  const firstCellRight = firstCell.getBoundingClientRect().right
  const slider = flickity.element
  const sliderLeft = slider.getBoundingClientRect().left
  const currentCellIndex = flickity.selectedIndex

  return (
    isMovingBackward(prevSelectedCellIndex, currentCellIndex) &&
    firstCellRight >= sliderLeft
  )
}

function selectLastCell(flickity) {
  const cellsLength = flickity.getCellElements().length

  flickity.select(cellsLength - 1)
}

function selectFirstCell(flickity) {
  flickity.select(0)
}

function bindFlickityOnResize(flickity) {
  flickity.on('resize', () => setSliderStyles(flickity))
}

function bindFlickityOnChange(flickity) {
  // We need this variable to track slider selection direction,
  // i.e. if prevSelectedCellIndex < current cell index then it means
  // slider is being moved from left to right
  let prevSelectedCellIndex = flickity.selectedIndex

  // "change" event happens before actual movement happens
  flickity.on('change', () => {
    if (isSecondToLastCellSelected(flickity, prevSelectedCellIndex)) {
      selectPreviousCell(flickity)
    } else if (isSecondCellSelected(flickity, prevSelectedCellIndex)) {
      selectNextCell(flickity)
    } else if (isLastCellVisible(flickity, prevSelectedCellIndex)) {
      selectLastCell(flickity)
    } else if (isFirstCellVisible(flickity, prevSelectedCellIndex)) {
      selectFirstCell(flickity)
    }

    prevSelectedCellIndex = flickity.selectedIndex
  })
}

function bindFlickityEvents(flickity) {
  bindFlickityOnResize(flickity)
  bindFlickityOnChange(flickity)
}

export function flickityContainNavigation(sliderSelector) {
  $(sliderSelector).on('ready.flickity', (event) => {
    const flickity = Flickity.data(event.currentTarget)

    setSliderStyles(flickity)
    bindFlickityEvents(flickity)
  })
}
