<template>
  <div>
    <UploadComponent
      class="upload-button"
      fill-color="#333b51cc"
      @click="openForm()"
    />
    <MediaForm
      v-if="formVisibility"
      :type="type"
      @[EMITTERS.ADMIN_UPLOAD_VIDEO_CLOSE_MODAL]="closeModal"
    />
  </div>
</template>

<script>
import MediaForm from './components/MediaForm'
import UploadComponent from '../../shared/components/icons/Upload'
import { EMITTERS } from '../../shared/utils/emitters'

export default {
  name: 'App',
  components: { UploadComponent, MediaForm },
  data() {
    return {
      EMITTERS,
      formVisibility: false,
      type: this.optional_params.type,
    }
  },
  methods: {
    openForm() {
      this.formVisibility = true
    },
    closeModal(error) {
      this.formVisibility = false

      if (!error)
        window.location.href = `/admin/${this.type}?filter%5Bmisc%5D=recent`
    },
  },
}
</script>
