import { totalNavbarHeight } from 'theme/navbar'
import { stylizeWatchGrids } from 'watch/watch_grid'

const RESULTS_LOAD_TRIGGER_GAP_PX = 20

function getFooter$() {
  return $('.footer')
}

function footerHeight() {
  return getFooter$().height()
}

function footerBottom() {
  return getFooter$()[0].getBoundingClientRect().bottom
}

function getSearchResultsContainer$() {
  return $('.search_results_page .search_results_container')
}

function nextPageNumber($searchResultsContainer) {
  // Using direct `.attr()` call here to modify the page HTML directly to make it properly work
  // upon Turbolinks navigations: all `.data()` assignments will be lost between navigations
  // but not the page HTML state.
  return $searchResultsContainer.attr('data-next-page')
}

function requestNextPage(nextPage) {
  return $.get(nextPage, { format: 'js' }).then(() => {
    stylizeWatchGrids() // proper .watch_grid positioning and styles
  })
}

function newPageNeeded() {
  return (
    $('.body').scrollTop() >=
    $(document).height() -
      $('.body').height() -
      totalNavbarHeight() -
      footerHeight() -
      RESULTS_LOAD_TRIGGER_GAP_PX
  )
}

async function loadResultsUntilFooterOutOfViewport() {
  const $searchResultsContainer = getSearchResultsContainer$()
  if ($searchResultsContainer.length === 0) return

  const nextPage = nextPageNumber($searchResultsContainer)
  if (!nextPage) return

  while (footerBottom() <= $(window).height()) {
    const nextPage = nextPageNumber($searchResultsContainer)

    if (nextPage) await requestNextPage(nextPage)
  }
}

async function onBodyScroll(done) {
  const $searchResultsContainer = getSearchResultsContainer$()
  if ($searchResultsContainer.length === 0) return

  if (newPageNeeded()) {
    const nextPage = nextPageNumber($searchResultsContainer)

    if (nextPage) {
      await requestNextPage(nextPage)
    }
  }
  done()
}

$(document).on('turbolinks:load', () => {
  loadResultsUntilFooterOutOfViewport()

  let requestingNextPage = false // de-bouncing flag

  document.querySelector('.body').addEventListener(
    'scroll',
    () => {
      if (requestingNextPage) return

      requestingNextPage = true

      onBodyScroll(() => {
        requestingNextPage = false
      })
    },
    false
  )
})
