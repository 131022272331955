<template>
  <div>
    <div
      class="bg-blur"
      :class="{ 'disabled-action': uploading }"
      @click="closeUploadForm()"
    />
    <div class="upload-video-modal">
      <div class="header-section">
        <span>
          {{ $vueTranslate('admin.medias.form.modal_title') }}
        </span>
      </div>
      <div v-if="!uploading && !uploadingComplete" class="body-section">
        <div class="input-block">
          <label for="videoName">
            {{ $vueTranslate('admin.medias.form.modal_field_title') }}
            <abbr title="required">*</abbr>
          </label>
          <input
            id="videoName"
            v-model="fields.title"
            :class="{ 'validation-error': errors.title }"
            autofocus="autofocus"
            type="text"
            name="videoName"
            @input="validateField('title', fields.title)"
          />
          <span v-if="errors.title" class="validation-error__text">
            {{ errors.title }}
          </span>
        </div>
        <div v-if="isVideosType()" class="input-block">
          <label for="sku">
            {{ $vueTranslate('admin.medias.form.modal_field_sku')
            }}<abbr title="required">*</abbr>
          </label>
          <input
            id="sku"
            v-model="fields.sku"
            :class="{ 'validation-error': errors.sku }"
            autofocus="autofocus"
            type="text"
            :placeholder="sku"
            name="sku"
            @input="validateField('sku', fields.sku)"
          />
          <span v-if="errors.sku" class="validation-error__text">
            {{ errors.sku }}
          </span>
        </div>
        <div v-if="isVideosType()" class="input-block">
          <label for="description">
            {{ $vueTranslate('admin.medias.form.modal_field_description') }}
          </label>
          <input
            id="description"
            v-model="fields.description"
            :class="{ 'validation-error': errors.description }"
            autofocus="autofocus"
            type="text"
            :placeholder="description"
            name="description"
          />
          <span v-if="errors.description" class="validation-error__text">
            {{ errors.description }}
          </span>
        </div>
        <div class="upload-block">
          <div class="custom-file">
            <input
              id="customFile"
              type="file"
              :accept="getFileType()"
              class="custom-file-input"
              @change="handleFileChange"
            />
            <label
              :class="{ 'validation-error': errors.file }"
              class="custom-file-label"
              for="customFile"
            >
              {{ getSelectButton() }}
            </label>
            <span v-if="errors.file" class="validation-error__text">
              {{ errors.file }}
            </span>
          </div>
        </div>
        <div class="submit-button" @click="submitForm()">
          {{ $vueTranslate('admin.medias.form.modal_submit_btn') }}
        </div>
      </div>
      <div v-else class="body-section uploading-block">
        <LoaderComponent v-if="uploading" class="loader" />
        <div id="status" class="status">
          {{ uploadStatus }}
        </div>
        <div class="progress">
          <div
            id="progress-bar"
            class="progress-bar"
            :style="{ width: uploadProgress + '%' }"
          ></div>
        </div>
        <div id="step" class="step">
          {{ getStep() }}
        </div>
        <div v-if="!uploadingComplete" class="alert alert-warning">
          {{ $vueTranslate('admin.medias.form.upload.warning') }}
        </div>
        <div
          v-if="uploadingComplete"
          class="submit-button close-modal"
          @click="closeUploadForm()"
        >
          {{ $vueTranslate('admin.medias.form.modal_close_btn') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import uploadFileService from '../services/uploadFile.service'
import { validateField } from '../utils/form'
import LoaderComponent from '../../../shared/components/Loader'
import { EMITTERS } from '../../../shared/utils/emitters'
import { uploadFile } from '../../../shared/utils/azure_blob'

export default {
  name: 'MediaForm',
  components: { LoaderComponent },
  props: {
    type: {
      type: String,
      required: true,
      default: 'videos',
    },
  },
  data() {
    return {
      uploading: false,
      uploadingComplete: false,
      uploadProgress: null,
      uploadStatus: '',
      jobId: null,
      step: 1,
      maxSteps: 2,
      fields: {
        title: '',
        selectedFile: null,
        sku: '',
        file: null,
        description: '',
      },
      errors: {
        title: '',
        file: '',
        sku: '',
        description: '',
      },
      schedulerInterval: null,
    }
  },
  methods: {
    closeUploadForm() {
      this.$emit(
        EMITTERS.ADMIN_UPLOAD_VIDEO_CLOSE_MODAL,
        this.completeWithError()
      )
    },
    handleFileChange(event) {
      this.fields.file = event.target.files[0]
    },
    async submitForm() {
      await this.validateField('title')
      await this.validateField('file')

      if (this.isVideosType()) {
        await this.validateField('sku')
      }

      if (this.hasErrors()) return

      this.uploading = true
      this.uploadStatus = this.$vueTranslate(
        'admin.medias.form.upload.upload_status_2'
      )

      uploadFileService.fetchAzureParamsParams().then((response) => {
        this.uploadFileToAzure(this, response.data)
      })
    },
    async uploadFileToAzure(instance, params) {
      this.uploading = true

      await uploadFile(
        params.storage,
        params.sas,
        params.container,
        this.fields.file,
        (progress) => {
          this.onAzureUpdateProgress(instance, progress)
        },
        () => {
          this.onAzureError(instance)
        },
        () => {
          this.onAzureFinish(instance, params.uuid, params.container)
        }
      )
    },
    onAzureError(instance) {
      instance.$vueTranslate(`admin.medias.form.upload.upload_status_error`)
    },
    onAzureUpdateProgress(instance, progress) {
      instance.uploadProgress = progress
      instance.uploadStatus = `${instance.$vueTranslate(
        'admin.medias.form.upload.upload_status_2'
      )} (${parseInt(progress)}%)`
    },
    onAzureFinish(instance, uuid, container) {
      instance.uploadProgress = 100
      instance.nextStep()
      instance.processToMediaKind(uuid, container)
    },
    async processToMediaKind(uuid, container) {
      uploadFileService
        .uploadMedia(
          uuid,
          container,
          this.fields.file.name,
          this.fields.title,
          this.fields.sku,
          this.fields.description,
          this.type,
          this.optional_params
        )
        .then((response) => {
          const jobId = response.data?.jod_id

          if (!jobId) {
            this.uploadStatus = this.$vueTranslate(
              'admin.medias.form.upload.upload_status_error'
            )
            return
          }

          this.uploadProgress = 0
          this.uploadStatus = this.$vueTranslate(
            'admin.medias.form.upload.upload_status_3'
          )
          this.jobId = jobId
          this.checkActivity()
        })
    },
    checkActivity() {
      this.schedulerInterval = setInterval(() => {
        if (this.uploading) {
          this.checkJob()
        } else {
          clearInterval(this.schedulerInterval)
        }
      }, 1500)
    },
    getFileType() {
      return this.type === 'videos' ? '.mp4' : '.mp3'
    },
    checkJob() {
      uploadFileService.checkJobStatus(this.jobId).then((response) => {
        const rawImportData = response.data
        this.uploadProgress = rawImportData.progress

        this.uploadStatus =
          this.uploadProgress !== 0
            ? this.$vueTranslate(
                `admin.medias.form.upload.${rawImportData.status}`
              )
            : this.$vueTranslate('admin.medias.form.upload.upload_status_2')

        if (this.uploadProgress === 100) {
          this.uploadingComplete = true
          this.uploading = false
        }
      })
    },
    getSelectButton() {
      const selectedFileName = this.fields.file?.name
      return selectedFileName
        ? `File selected: ${selectedFileName}`
        : this.$vueTranslate('admin.medias.form.modal_choose_file_btn')
    },
    async validateField(field) {
      const data = await validateField(field, this.fields[field])
      this.errors[field] = data.error ? this.$vueTranslate(data.error) : ''
    },
    hasErrors() {
      return Object.values(this.errors).some((error) => error !== '')
    },
    completeWithError() {
      return (
        this.uploadStatus ===
        this.$vueTranslate(`admin.medias.form.upload.upload_status_error`)
      )
    },
    getStep() {
      return `Step (${this.step}/${this.maxSteps})`
    },
    nextStep() {
      this.step += 1
    },
    isVideosType() {
      return this.type === 'videos'
    },
  },
}
</script>

<style scoped lang="scss">
.bg-blur {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #323232;
  opacity: 0.9;
  z-index: 9999;
}

.disabled-action {
  cursor: not-allowed;
  pointer-events: none;
}

.upload-video-modal {
  position: fixed;
  display: flex;
  top: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 10000;
  flex-direction: column;
  width: 462px;
  min-height: 459px;
  border-radius: 3px;
  box-shadow: 0 8px 16px rgba(35, 35, 35, 0.5);
  background-color: #f7f7f7;
}

.header-section {
  height: 36px;
  padding: 0 8px;
  line-height: 36px;
  background: #214166;
  color: #fff;
  font-weight: normal;
  font-size: 1em;
  margin-top: -2px;

  span {
    display: flex;
    justify-content: left;
    text-align: left;
    font-weight: bold;
    padding: 0 4px;
    margin-right: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-font-smoothing: antialiased;
  }
}

.body-section {
  padding: 32px;

  .input-block {
    display: flex;
    flex-direction: column;

    label {
      display: flex;
      width: 100%;
      justify-content: left;
    }

    input {
      display: flex;
      width: 100%;
      justify-content: left;
    }
  }
}

.submit-button {
  display: flex;
  width: 100%;
  height: 36px;
  padding: 0 8px;
  line-height: 36px;
  background: #0058c2;
  color: #fff;
  border-radius: 4px;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
}

.upload-block {
  margin-bottom: 8px;

  .file-upload {
    line-height: 36px;
    background: green;
    color: #fff;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
  }

  .file-upload span {
    display: inline-block;
    padding: 0 8px;
    cursor: pointer;
    width: 100%;
  }
}

.custom-file-input {
  position: relative;
  overflow: hidden;
}

.custom-file-input input {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}

.custom-file-label {
  display: inline-block;
  margin-top: 2px;
}

.custom-file-input {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-file {
  width: 100%;
  position: relative;
  display: inline-block;
}

.custom-file-input {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}

.custom-file-label {
  width: 100%;
  display: inline-block;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  white-space: break-spaces;
}

.validation-error {
  border-color: red !important;
  margin-bottom: 0 !important;
}

.validation-error__text {
  font-size: 12px;
  color: red;
  display: flex;
  margin-bottom: 8px;
}

.error-block {
  padding: 40px 50px 0 50px;

  @media (max-width: 767.98px) {
    padding: 40px 25px 0 25px;
  }

  .message {
    min-height: 60px;
    height: auto;
    padding: 30px 20px;
    background-color: rgb(255, 214, 201);
    border-radius: 4px;

    font-size: 14px;
    line-height: 18px;
    color: red;
    margin: 0;
  }
}

.uploading-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .loader {
    display: flex;
    width: 32px;
    height: 32px;
  }

  .status {
    margin-top: 8px;
    width: 100%;
    margin-bottom: 8px;
  }

  .step {
    width: 100%;
    margin-bottom: 8px;
  }

  .progress {
    width: 100%;
  }
}

.alert {
  margin-top: 16px;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-warning {
  width: 100%;
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.close-modal {
  margin-top: 8px;
}
</style>
