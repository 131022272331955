<template>
  <LikeButton />
</template>

<script>
import LikeButton from './components/LikeButton'

export default {
  name: 'App',
  components: { LikeButton },
}
</script>

<style scoped lang="scss"></style>
