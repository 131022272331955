import axios from 'axios'

import { API_ROUTES, API_URL, FORM_HEADERS } from '../utils/api'

export default {
  fetchSettings() {
    const config = {
      baseURL: API_URL,
      headers: FORM_HEADERS,
    }
    return axios.get(API_ROUTES.settings, config)
  },
}
