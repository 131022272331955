<template>
  <div>
    <AuthorizationPopUp
      v-if="authorizationPopupVisibility"
      @[EMITTERS.AUTHORIZATION_CLOSE_MODAL]="closeModel"
    />
    <span v-if="shouldDisplayTooltip('like-tooltip')" class="like-tooltip">
      New
    </span>
    <div
      class="like-block"
      :class="{
        'like-gap': hasLikes(),
      }"
      @click="click"
    >
      <LikeIcon :active="liked" />
      <span v-if="hasLikes()" class="count">
        {{ formatLikeCount() }}
      </span>
    </div>
  </div>
</template>

<script>
import { EMITTERS } from '../../../shared/utils/emitters'
import LikeIcon from '../../../shared/components/icons/LikeIcon'
import likesApiService from '../utils/likesApi.service'
import AuthorizationPopUp from './NoAuthorizedPopUp'
import { shouldDisplayTooltip } from '../../../shared/utils/tooltip_util'

export default {
  name: 'LikeButton',
  components: { AuthorizationPopUp, LikeIcon },
  data() {
    return {
      EMITTERS,
      authorizationPopupVisibility: false,
      likesCount: parseInt(this.optional_params.likes) || 0,
      liked:
        this.optional_params.episode_liked.toLowerCase() === 'true' || false,
    }
  },
  computed: {
    authorized() {
      return !!this.optional_params.user_id
    },
  },
  methods: {
    shouldDisplayTooltip,
    closeModel() {
      this.authorizationPopupVisibility = false
    },
    click() {
      if (!this.authorized) {
        this.authorizationPopupVisibility = true
        return
      }

      this.liked ? this.removeLike() : this.addLike()
    },
    addLike() {
      likesApiService.addLike(this.optional_params).then(() => {
        this.likesCount += 1
        this.liked = true
      })
    },
    removeLike() {
      likesApiService.removeLike(this.optional_params).then(() => {
        this.likesCount -= 1
        this.liked = false
      })
    },
    hasLikes() {
      return this.likesCount >= 1
    },
    formatLikeCount() {
      if (this.likesCount >= 1000) return this.formatK(this.likesCount)

      return this.likesCount
    },
    formatK(value) {
      const formattedValue =
        Math.abs(value) > 999
          ? Math.sign(value) * (Math.abs(value) / 1000).toFixed(1) + 'k'
          : Math.sign(value) * Math.abs(value)

      return formattedValue.toString()
    },
  },
}
</script>

<style scoped lang="scss">
.like-block {
  display: flex;
  flex-direction: row;
  background: #2b2834;
  min-width: 51px;
  padding: 4px 6px 4px 6px;
  width: auto;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: #3b3946;
  }

  .count {
    font-family: DM Sans, serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
  }

  .active-icon {
    path {
      fill: #ffffff;
    }
  }
}

.like-gap {
  gap: 10px;
}

.like-tooltip {
  position: absolute;
  top: -18px;
  left: 3px;
  display: flex;
  align-items: center;
  background-color: #b56941;
  color: white;
  font-family: DM Sans, serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: center;
  width: 44px;
  height: 18px;
  padding: 4px 10px 4px 10px;
  gap: 4px;
  border-radius: 5px 5px 0 0;
}
</style>
