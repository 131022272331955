<template>
  <div v-if="hasPermission()">
    <input
      id="schedule_file"
      type="file"
      name="schedule_file"
      accept=".csv"
      hidden
      @change="sendFile"
    />
    <div class="schedule__import-button" @click="selectFile">
      <Upload class="mr-1" />
      {{ $vueTranslate('schedule.import_schedule.button_title') }}
    </div>
  </div>
</template>

<script>
import Upload from '../../shared/components/icons/Upload'
import ProgramsApiService from '../services/programsApi.service'
import { EMITTERS } from '../../shared/utils/emitters'

export default {
  name: 'ImportSchedule',
  components: { Upload },
  emits: [
    EMITTERS.SCHEDULE_IMPORT_STARTED,
    EMITTERS.SHOW_NOTIFICATION_EMITTER,
    EMITTERS.SHOW_ERROR_NOTIFICATION_EMITTER,
    EMITTERS.SCHEDULE_DATA_IMPORTED,
  ],
  data() {
    return {
      scheduleId: 'schedule_file',
    }
  },
  methods: {
    selectFile() {
      document.getElementById(this.scheduleId).click()
    },
    sendFile() {
      const formData = new FormData()
      const scheduleFile = document.getElementById(this.scheduleId)
      formData.append('program', scheduleFile.files[0])
      this.emitter.emit(EMITTERS.SCHEDULE_IMPORT_STARTED)

      ProgramsApiService.importPrograms(formData)
        .then((response) => {
          this.emitter.emit(EMITTERS.SHOW_NOTIFICATION_EMITTER, {
            message: response.data.message,
          })
        })
        .catch((error) => {
          this.emitter.emit(
            EMITTERS.SHOW_ERROR_NOTIFICATION_EMITTER,
            error.response.data.message
          )
        })
        .finally(() => {
          scheduleFile.value = ''
          this.emitter.emit(EMITTERS.SCHEDULE_DATA_IMPORTED)
        })
    },
    hasPermission() {
      const scheduleBlock = document.getElementById('satellite-schedule-app')
      const userRole = scheduleBlock.dataset.userRole

      if (!userRole) return false

      return userRole === 'admin' || userRole === 'editor'
    },
  },
}
</script>

<style>
.schedule__import-button {
  width: 106px;
  height: 32px;
  top: calc(50% - 32px / 2 - 886px);
  background: #6998ae;
  box-shadow: 0 2px 15px rgba(73, 73, 73, 0.2);
  border-radius: 4px;
  font-family: 'DM Sans', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  cursor: pointer;
}
</style>
