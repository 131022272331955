<template>
  <div v-if="isSpecialEpisode" class="special-episode-labels-container">
    <div v-if="episode.premiering" class="special-episode-label premiering">
      {{ $vueTranslate('shared.special_episode_labels.premiering_label') }}
    </div>
    <div v-if="episode.exclusive" class="special-episode-label exclusive">
      {{ $vueTranslate('shared.special_episode_labels.exclusive_label') }}
    </div>
    <div v-if="episode.live_at_label" class="special-episode-label live_in">
      {{ episode.live_at_label }}
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_21160_90325)">
          <path
            d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6 8V6"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6 4H6.005"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_21160_90325">
            <rect width="12" height="12" fill="white" />
          </clipPath>
        </defs>
      </svg>

      <div
        v-if="episode.countdown_tooltip"
        class="special-episode-tooltip-wrapper"
      >
        <div
          class="special-episode-tooltip"
          :class="{ 'search-tooltip': isSearch }"
        >
          {{ episode.countdown_tooltip }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpecialEpisodeLabels',
  props: {
    episode: {
      required: true,
      type: Object,
      default: () => {},
      null: false,
    },
    isSearch: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      EXCLUSIVE_LABEL: 'Exclusive',
      PREMIERING_LABEL: 'Premiere',
    }
  },
  computed: {
    isSpecialEpisode() {
      if (this.episode.premiering) return true
      if (this.episode.exclusive) return true
      if (this.episode.live_at_label) return true

      return false
    },
  },
}
</script>

<style lang="scss" scoped>
.special-episode-labels-container {
  display: flex;
  margin: 6px 0;
}
.special-episode-label {
  font-size: 12px;
  font-weight: 400;
  padding: 4px 5px;
  margin-right: 2px;
  border-radius: 6px;
  color: #fff;

  &.premiering {
    background: #d83f76;
  }
  &.exclusive {
    background: #309967;
  }
  &.live_in {
    background: #5a3cd2;
  }
  .special-episode-tooltip-wrapper {
    position: relative;
    .special-episode-tooltip {
      display: none;
      position: absolute;
      bottom: 60px;
      left: 50%;
      transform: translateX(-50%);
      background: #2b2834;
      width: 276px;
      max-width: 48vw;
      padding: 16px 12px 20px 16px;
      border-radius: 8px;
      border: 1px;
      border: 1px solid #6998ae;
      z-index: 5;
      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -28px;
        width: 0;
        height: 0;
        border: 14px solid transparent;
        border-top-color: #6998ae;
      }
      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -27px;
        width: 0;
        height: 0;
        border: 14px solid transparent;
        border-top-color: #2b2834;
      }
    }
  }
}
.special-episode-label:hover {
  .special-episode-tooltip {
    display: block;
  }
}

.search-tooltip {
  margin-bottom: -25px;
  margin-left: 35%;
}
</style>
