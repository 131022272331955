import axios from 'axios'

import { API_ROUTES, BASE_API_CONFIG } from '../../../shared/utils/api'

export default {
  DEFAULT_ORDER: 'desc',

  selectEbook(ebook_id) {
    return axios.request({
      ...BASE_API_CONFIG,
      method: 'GET',
      url: API_ROUTES.alchemy_ebook(ebook_id),
    })
  },
}
