function validateEpisodePasscode() {
  const authorization = localStorage.getItem('AD-Authorization')

  if (!authorization) return

  $.ajax({
    url: Routes.api_passcode_requests_path(),
    type: 'POST',
    data: convertFormToJSON(),
    headers: {
      'AD-Authorization': authorization,
      Accept: 'application/json',
    },
    dataType: 'json',
    success: () => {
      correctEpisodePasscode()
    },
    error: () => {
      errorEpisodePasscode()
    },
  })
}

function correctEpisodePasscode() {
  location.reload()
}

function errorEpisodePasscode() {
  $('.apply-passcode-error-text').removeClass('hidden')
  $('input.passcode-input').addClass('error')
}

function convertFormToJSON() {
  const array = $('form#episode-passcode-form').serializeArray() // Encodes the set of form elements as an array of names and values.
  const json = {}
  $.each(array, function () {
    json[this.name] = this.value || ''
  })
  return json
}

$(document).on('turbolinks:load', () => {
  $('form#episode-passcode-form').on('submit', (event) => {
    event.preventDefault()
    validateEpisodePasscode()
  })

  $('input.passcode-input').on('input', () => {
    $('.apply-passcode-error-text').addClass('hidden')
    $('input.passcode-input').removeClass('error')
  })
})
