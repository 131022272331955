<template>
  <div class="date-selector-component">
    <div class="calendar-button" @click="toggleDatepicker">
      <Calendar v-if="!visible" />
      <CloseIconComponent
        v-else
        class="select-calendar-button-close__icon"
        :theme="'light'"
        :color="'#ffffff'"
      />
      <span>
        {{
          visible
            ? $vueTranslate('schedule.date_selector.close_button')
            : $vueTranslate('schedule.date_selector.button_title')
        }}
      </span>
    </div>
    <div v-if="visible">
      <date-picker
        :value="selectedDate"
        :open="true"
        :disabled-date="disabledDates"
        :append-to-body="false"
        prefix-class="program"
        type="date"
        @pick="datePicked"
      />
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue-datepicker-next'

import Calendar from '../../shared/components/icons/Calendar'
import { EMITTERS } from '../../shared/utils/emitters'
import CloseIconComponent from '../../shared/components/icons/Close'
import { formatDateToString } from '../utils/time_utils'

export default {
  name: 'DateSelector',
  components: { CloseIconComponent, Calendar, DatePicker },
  props: {
    selectedDate: {
      required: true,
      type: Date,
    },
    timezone: {
      type: String,
      required: true,
    },
    availableDates: {
      type: Array,
      required: true,
    },
  },
  emits: [EMITTERS.DATE_PICKED],
  data() {
    return {
      closeButton: 'Close',
      buttonTitle: 'Choose date',
      visible: false,
    }
  },
  mounted() {
    this.emitter.on(EMITTERS.SCHEDULE_POP_UP_OPENED, (name) => {
      if (name !== this.$options.name) this.visible = false
    })
  },
  methods: {
    toggleDatepicker() {
      this.visible = !this.visible
      if (this.visible)
        this.emitter.emit(EMITTERS.SCHEDULE_POP_UP_OPENED, this.$options.name)
    },
    disabledDates(date) {
      const formattedDate = formatDateToString(date)
      return !this.availableDates.includes(formattedDate)
    },
    datePicked(date) {
      this.emitter.emit(EMITTERS.DATE_PICKED, date)
      this.visible = false
    },
  },
}
</script>

<style lang="scss">
.date-selector-component {
  position: relative;
  margin-right: 10px;
}
.calendar-button {
  width: 158px;
  height: 32px;
  background: #6998ae;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-family: 'DM Sans', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  cursor: pointer;
  @media (max-width: 767px) {
    margin-top: 30px;
  }
}

.calendar-button-close__text {
  font-family: 'DM Sans', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: none;
  align-items: center;
  color: #6998ae;

  @media (max-width: 767px) {
    display: block;
    padding-left: 6px;
  }
}

$namespace: 'program';

@import '~vue-datepicker-next/index.css';
@import '~vue-datepicker-next/scss/var.scss';
@import '~vue-datepicker-next/scss/icon.scss';
@import '~vue-datepicker-next/scss/btn.scss';
@import '~vue-datepicker-next/scss/scrollbar.scss';
@import '~vue-datepicker-next/scss/animation.scss';

.#{$namespace}-btn-icon-double-left,
.#{$namespace}-btn-icon-double-right {
  display: none;
  pointer-events: none;
  width: 0;
  height: 0;
}

.#{$namespace}-datepicker {
  position: relative;
}

.#{$namespace}-datepicker-popup {
  position: absolute !important;
  top: 12px !important;
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%) !important;
  z-index: 1;

  @media (max-width: 767px) {
    left: -60px !important;
    top: 36px !important;
  }
}

.#{$namespace}-datepicker-inline {
  width: 318px;
}

.#{$namespace}-input-wrapper {
  display: none;
  pointer-events: none;
}

.#{$namespace}-icon-calendar,
.#{$namespace}-icon-clear {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  font-size: 16px;
  line-height: 1;
  color: rgba(0, 0, 0, 0.5);
  vertical-align: middle;
}

.#{$namespace}-icon-clear {
  cursor: pointer;

  &:hover {
    color: rgba(0, 0, 0, 0.8);
  }
}

.#{$namespace}-datepicker-main {
  font: 14px/1.5 'Helvetica Neue', Helvetica, Arial, 'Microsoft Yahei',
    sans-serif;
  color: $default-color;
  background: $white;
  box-shadow: 0 4px 30px rgba(98, 98, 101, 0.4);
  border-radius: 8px;
}

.#{$namespace}-datepicker-sidebar {
  float: left;
  box-sizing: border-box;
  width: $sidebar-margin-left;
  padding: 6px;
  overflow: auto;
}

.#{$namespace}-datepicker-sidebar + .#{$namespace}-datepicker-content {
  margin-left: $sidebar-margin-left;
  border-left: 1px solid $border-color;
}

.#{$namespace}-datepicker-body {
  position: relative;
  user-select: none;
}

.#{$namespace}-btn-shortcut {
  display: block;
  padding: 0 6px;
  line-height: 24px;
}

.#{$namespace}-datepicker-header {
  padding: 6px 8px;
  border-bottom: 1px solid $border-color;
}

.#{$namespace}-datepicker-footer {
  padding: 6px 8px;
  text-align: right;
  border-top: 1px solid $border-color;
}

.#{$namespace}-calendar-range,
.#{$namespace}-time-range {
  display: flex;
  @media (max-width: 750px) {
    flex-direction: column;
  }
}

.#{$namespace}-calendar {
  box-sizing: border-box;
  width: 308px;
  padding: 12px;
}

.#{$namespace}-calendar-header {
  box-sizing: border-box;
  height: 34px;
  line-height: 34px;
  text-align: center;
  overflow: hidden;
  margin-left: 6px;
  margin-right: 6px;
}

.#{$namespace}-btn-icon-left {
  position: absolute;
  left: 74%;
}

.#{$namespace}-icon-left:before {
  content: '';
  position: relative;
  top: -1px;
  display: inline-block;
  width: 14px;
  height: 14px;
  vertical-align: middle;
  border-style: solid;
  border-color: #323232;
  border-width: 3px 0 0 3px;
  border-radius: 1px;
  box-sizing: border-box;
  transform-origin: center;
  transform: rotate(-45deg) scale(0.7);
}

.#{$namespace}-icon-right:before {
  content: '';
  position: relative;
  top: -1px;
  display: inline-block;
  width: 14px;
  height: 14px;
  vertical-align: middle;
  border-style: solid;
  border-color: #323232;
  border-width: 3px 0 0 3px;
  border-radius: 1px;
  box-sizing: border-box;
  transform-origin: center;
  transform: rotate(135deg) scale(0.7);
}

.#{$namespace}-btn-icon-right {
  position: absolute;
  left: 84%;
}

.#{$namespace}-calendar-header-label {
  font-size: 14px;
}

.#{$namespace}-calendar-decade-separator {
  margin: 0 2px;

  &:after {
    content: '~';
  }
}

.#{$namespace}-calendar-content {
  position: relative;
  height: 100%;
  box-sizing: border-box;

  .cell {
    cursor: pointer;

    &.active {
      font-family: 'DM Sans', serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: white;
      background: #4c7c93;
      border-radius: 4px;
      width: 40px;
      height: 40px;
    }

    &.in-range,
    &.hover-in-range {
      color: $calendar-in-range-color;
      background-color: $calendar-in-range-background-color;
    }

    &.disabled {
      cursor: not-allowed;
      color: $disabled-color;
      background-color: $disabled-background-color;
    }
  }
}

.#{$namespace}-calendar-week-mode {
  .#{$namespace}-date-row {
    cursor: pointer;

    &:hover {
      background-color: $calendar-hover-background-color;
    }

    &.#{$namespace}-active-week {
      background-color: $calendar-in-range-background-color;
    }

    .cell {
      &:hover {
        color: inherit;
        background-color: transparent;
      }

      &.active {
        color: inherit;
        background-color: transparent;
      }
    }
  }
}

.#{$namespace}-week-number {
  opacity: 0.5;
}

.#{$namespace}-table {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  text-align: center;

  th {
    padding: 0;
    font-weight: 500;
    vertical-align: middle;
  }

  td {
    padding: 0;
    vertical-align: middle;
  }
}

.#{$namespace}-table-date {
  td,
  th {
    height: 40px;
    font-family: 'DM Sans', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #323232;
  }
}

.#{$namespace}-btn-current-month,
.#{$namespace}-btn-current-year {
  font-family: 'DM Sans', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  background-color: white;
  border: none;
  pointer-events: none;
  float: left;
  color: #000000;
  padding-top: 6px;
}
</style>
