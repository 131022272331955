<template>
  <div class="transcript__preview-block">
    <video
      id="episode_player"
      ref="videoPlayer"
      class="transcript-preview video-js vjs-paused vjs-controls-enabled vjs-workinghover vjs-v7 vjs-user-active vjs_video_3-dimensions"
      controls
    ></video>
  </div>
</template>

<script>
import videojs from 'video.js'

import { EMITTERS } from '../../../shared/utils/emitters'

export default {
  props: {
    videoUrl: {
      type: String,
    },
  },
  data() {
    return {
      currentTime: 0,
    }
  },
  mounted() {
    this.player = videojs(this.$refs.videoPlayer, {
      techOrder: ['html5'],
    })

    this.player.src({
      src: this.videoUrl,
      type: 'application/x-mpegURL',
    })

    this.player.on('ready', () => {
      this.player.volume(0.3)
      this.emitter.emit(EMITTERS.VIDEO_PLAYER_READY, this.player)
    })

    this.player.on('timeupdate', () => {
      this.currentTime = this.player.currentTime()
    })
  },

  beforeDestroy() {
    // Dispose of the Video.js player when the component is destroyed
    if (this.player) {
      this.player.dispose()
    }
  },
}
</script>

<style lang="scss">
.transcript__preview-block {
  position: relative;
  width: 400px;
  height: 225px;

  .transcript-preview.video-js {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }

  .transcript-preview.video-js button.vjs-big-play-button {
    margin-left: 36%;
    margin-top: 18%;
  }

  .transcript-preview.video-js .vjs-time-control {
    display: block;
    padding-left: 3px;
    padding-right: 3px;
    min-width: 3px;
  }

  .transcript-preview.video-js .vjs-remaining-time {
    display: none;
  }
}

</style>