<template>
  <div data-turbolinks="false">
    <div
      class="outside-area"
      :class="{ 'outside-area__visible': search_displayed }"
      @click="hideSearch()"
    />
    <div class="search-bar">
      <MagnifyingGlass
        class="search-btn"
        :theme="getTheme()"
        @click="toggleBar()"
      />

      <div
        v-if="search_displayed"
        class="search-bar-block"
        :class="`search-bar-block__${getTheme()}`"
      >
        <ArrowLeftIconComponent
          class="search-back-button"
          :color="getTheme() === THEMES.LIGHT ? '#8f8a9b' : '#fff'"
          @click="toggleBar()"
        />
        <SiteLogo :theme="getTheme()" class="search-logo" />
        <div
          class="search-input-block"
          :class="`search-input-block__${getTheme()}`"
        >
          <MagnifyingGlass :custom-color="getSearchIconColor()" />
          <input
            v-model="search_query"
            type="text"
            :placeholder="SEARCH_PLACEHOLDER"
            :class="`search-input__${getTheme()}`"
            @keyup.enter="onEnter()"
          />
          <div
            v-if="search_query.length >= 1"
            class="clean-btn"
            :class="`clean-btn__${getTheme()}`"
            @click="clearSearch()"
          >
            {{ CLEAR_BTN }}
          </div>
          <CircleClose
            v-if="search_query.length >= 1"
            class="circle-clear-all"
            :theme="getTheme()"
            @click="clearSearch()"
          />
        </div>
        <CloseIconComponent
          class="close-search-bar"
          :class="`close-search-bar__${getTheme()}`"
          :theme="getTheme()"
          @click="toggleBar()"
        />
      </div>
      <div
        v-if="search_displayed && authorized()"
        class="recent-search-section"
        :class="`recent-search-section__${getTheme()}`"
      >
        <RecentSearch
          ref="recentSearch"
          :theme="getTheme()"
          :settings="settings"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MagnifyingGlass from '../../shared/components/icons/MagnifyingGlass'
import CloseIconComponent from '../../shared/components/icons/Close'
import { THEMES } from '../../shared/utils/view_options'
import SiteLogo from '../../shared/components/SiteLogo'
import RecentSearch from './RecentSearch'
import ArrowLeftIconComponent from '../../shared/components/icons/ArrowLeft'
import CircleClose from '../../shared/components/icons/CircleClose'

export default {
  name: 'SearchBar',
  components: {
    CircleClose,
    ArrowLeftIconComponent,
    RecentSearch,
    SiteLogo,
    CloseIconComponent,
    MagnifyingGlass,
  },
  props: {
    settings: {
      required: true,
      default: () => ({
        watch_host: '',
        main_host: '',
        events_host: '',
      }),
      type: Object,
    },
  },
  data() {
    return {
      search_displayed: false,
      search_query: '',
      CLEAR_BTN: 'Clear',
      SEARCH_PLACEHOLDER: 'Search',
      THEMES,
    }
  },
  methods: {
    toggleBar() {
      this.search_displayed = !this.search_displayed
    },
    onEnter() {
      window.location.href = `${this.getTab()}?query=${this.search_query}`
    },
    clearSearch() {
      this.search_query = ''
    },
    getTab() {
      const location = window.location
      const path = location.pathname
      const host = location.host
      const settings = this.settings

      if (path.includes('prophecy-news')) {
        return '/search#/search/prophetic-news'
      }

      if (host.includes(settings.watch_host)) {
        return '/search#/search/videos'
      }

      if (path.includes('read')) {
        return '/search#/search/articles'
      }

      if (path.includes('ebooks')) {
        return '/search#/search/ebooks'
      }

      return '/search#/search'
    },
    getTheme() {
      return window.location.hostname !== this.settings.watch_host
        ? THEMES.LIGHT
        : THEMES.DARK
    },
    getSearchIconColor() {
      return this.getTheme() === THEMES.DARK ? '#A6A6A6' : '#8B8B8B'
    },
    authorized() {
      return !!this.optional_params.user_id
    },
    hideSearch() {
      this.search_displayed = false
    },
  },
}
</script>

<style lang="scss" scoped>
.search-btn {
  margin-right: 32px;
  cursor: pointer;

  path {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 991.98px) {
    width: 24px;
    height: 24px;
  }
}

.search-logo {
  width: 145px;
  height: 32px;
  margin-left: auto;

  @media (max-width: 991.98px) {
    display: none;
  }
}

.search-back-button {
  display: flex;
  justify-content: start;

  @media (min-width: 992px) {
    display: none;
  }

  @media (max-width: 575.98px) {
    width: 45px;
    height: 45px;
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    width: 32px;
    height: 32px;
  }
}

.search-bar-block {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 93px;
  z-index: 10;
  gap: 168px;

  @media (max-width: 991.98px) {
    justify-content: center;
    gap: 16px;
  }
}

.search-bar-block__light {
  height: 84.8px;
  background-color: #ffffff;
}

.search-bar-block__dark {
  background-color: #32303c;
}

.close-search-bar {
  display: flex;
  cursor: pointer;
  margin-left: 70px;
  margin-right: auto;

  @media (max-width: 991.98px) {
    display: none;
  }
}

.close-search-bar__light {
  path {
    width: 24px;
    height: 24px;
    fill: #598aa0;
  }
}

.close-search-bar__dark path {
  width: 24px;
  height: 24px;
  fill: #ffffff;
}

.search-input-block {
  display: flex;
  flex-direction: row;
  width: 685px;
  height: 40px;
  align-items: center;
  margin-top: -15px;

  @media (max-width: 991.98px) {
    margin-right: 16px;
  }

  input {
    margin-left: 10px;
    width: 100%;
    height: 21px;
    border: none;
    background: none;
    font-family: DM Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  input::placeholder {
    color: var(--text-input, #a6a6a6);

    font-family: DM Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .search-input__light {
    color: #323232;
    caret-color: #323232;
  }

  .search-input__dark {
    color: #ffffff;
    caret-color: #ffffff;
  }

  input:focus {
    outline: none;
  }

  .clean-btn {
    display: none;
    cursor: pointer;
    font-family: DM Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    @media (min-width: 992px) {
      display: block;
    }
  }

  .clean-btn__light {
    color: #323232;
    caret-color: #323232;
  }

  .clean-btn__dark {
    color: #ffffff;
    caret-color: #ffffff;
  }
}

.search-input-block__light {
  border-bottom: 1px #598aa0 solid;

  path {
    margin-left: 4px;
    width: 24px;
    height: 24px;
    fill: #5c5c5c;
  }
}

.search-input-block__dark {
  border-bottom: 1px #8f8a9b solid;

  path {
    margin-left: 4px;
    width: 24px;
    height: 24px;
    fill: #a6a6a6;
  }
}

.recent-search-section {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  top: 93px;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 10;
  gap: 168px;
  -webkit-transform: translateZ(0);

  @media (max-width: 991.98px) {
    justify-content: center;
  }

  -webkit-box-shadow: 0 5px 5px 0 rgba(157, 156, 170, 0.3);
  -moz-box-shadow: 0 5px 5px 0 rgba(157, 156, 170, 0.3);
  box-shadow: 0 5px 5px 0 rgba(157, 156, 170, 0.3);
}

.recent-search-section__dark {
  background-color: #32303c;
}

.recent-search-section__light {
  top: 84px;
  background-color: #fff;
}

.circle-clear-all {
  display: none;
  width: 16px;
  height: 16px;

  @media (max-width: 991.98px) {
    display: block;
  }
}

.outside-area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
}

.outside-area__visible {
  pointer-events: auto;
}
</style>
