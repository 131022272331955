const THUMBNAIL_WIDTH_TO_HEIGHT_RATIO = 1.78

function offsetGridsTitles() {
  const $watchGridContainers = $('.watch_grid_container')
  if ($watchGridContainers.length === 0) return

  $watchGridContainers.each((_idx, element) => offsetGridTitle($(element)))
}

function offsetGridTitle($watchGridContainer) {
  const $firstWatchGridItem = $watchGridContainer.find('.watch_grid_item')

  if ($firstWatchGridItem.length === 0) return

  const firstGridItemLeft = $firstWatchGridItem.offset().left

  $watchGridContainer
    .find('.watch_grid_title')
    .css('padding-left', firstGridItemLeft)
}

function padWatchGrid($watchGrid) {
  $watchGrid.find('.watch_grid_spacer').remove() // remove any existing spacers first

  const $watchGridItems = $watchGrid.find('.watch_grid_item')
  if ($watchGridItems.length === 0) return

  const cardWidth = $watchGridItems.first().outerWidth()
  const gridWidth = $watchGrid.innerWidth()
  const cardsNumber = $watchGridItems.length
  const rowSize = Math.floor(gridWidth / cardWidth)
  const spacersNumber = rowSize - (cardsNumber % rowSize) + 1 // adding extra spacer for edge cases

  // we don't return if spacersNumber === rowSize:
  // it prevents us from inserting the need amount of spacers when the rowSize is calculated wrongly

  // having .watch_grid_item on spacers to avoid adding them over and over again
  for (let i = 0; i < spacersNumber; i++) {
    $watchGrid.append(
      '<div class="watch_grid_item watch_grid_spacer watch-grid-spacer"></div>'
    )
  }
}

function forEachWatchGrid(callback) {
  const $watchGrids = $('.watch_grid')
  if ($watchGrids.length === 0) return

  $watchGrids.each((_idx, element) => callback($(element)))
}

function setThumbnailsHeights($watchGrid) {
  const gridItemWidth = $watchGrid.find('.thumbnail').outerWidth()
  const proportionalHeight = gridItemWidth / THUMBNAIL_WIDTH_TO_HEIGHT_RATIO

  $watchGrid.find('.episode-label').css('top', proportionalHeight - 30)
  $watchGrid.find('.thumbnail').css('height', proportionalHeight)
}

function stylizeWatchGrids() {
  forEachWatchGrid(padWatchGrid)
  offsetGridsTitles()
  forEachWatchGrid(setThumbnailsHeights)
}

$(document).on('turbolinks:load', stylizeWatchGrids)
$(window).resize(stylizeWatchGrids)

export { stylizeWatchGrids }
