'use strict'

import $_ from 'jquery'

import Flickity from 'vendor/flickity'
import { once } from 'utils'

const initFlickitySliders = () => {
  Flickity.init($_)
}

const destroyFlickitySliders = () => {
  document.querySelectorAll('[data-flickity]').forEach((elem) => {
    Flickity.data(elem).destroy()
  })
}

// Adding this mainly for the "slides equal height hack" https://codepen.io/desandro/pen/ZXEGVq
const addFlickityResizeUtilityClass = () => {
  Flickity.prototype._createResizeClass = function () {
    this.element.classList.add('flickity-resize')
  }

  Flickity.createMethods.push('_createResizeClass')

  const resize = Flickity.prototype.resize
  Flickity.prototype.resize = function () {
    this.element.classList.remove('flickity-resize')
    resize.call(this)
    this.element.classList.add('flickity-resize')
  }
}

const addFlickityResizeUtilityClassOnce = once(addFlickityResizeUtilityClass)

document.addEventListener('turbolinks:load', () => {
  if (typeof Flickity === 'undefined') return

  addFlickityResizeUtilityClassOnce()
  initFlickitySliders()
})

document.addEventListener('turbolinks:before-cache', () => {
  if (typeof Flickity === 'undefined') return

  destroyFlickitySliders()
})

$_(document).on('watch-section:load', () => {
  if (typeof Flickity === 'undefined') return

  addFlickityResizeUtilityClassOnce()
  initFlickitySliders()
})
