<template>
  <div :id="`comment-${id}`" class="comment-text">
    <span
      v-if="isReadVisible"
      :id="`comment-${id}-truncated`"
      v-html="truncatedText"
    />
    <span v-else :id="`comment-${id}-fulltext`" v-html="fullText" />
    <div v-if="isReadVisible" class="read-more-link" @click="showFullText">
      {{ $vueTranslate('study_tools.comments.read_more') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoCommentText',
  props: {
    id: {
      type: Number,
      required: true,
      default: 0,
    },
    text: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      fullText: '',
      truncatedText: '',
      isReadVisible: true,
    }
  },
  mounted() {
    this.makeLinksClickable()
    this.addReadMoreLink()
  },
  methods: {
    showFullText() {
      this.isReadVisible = false
    },
    makeLinksClickable() {
      let text = this.text
      const urlRegex = /(?:^|[\s(])((https?:\/\/)?(?:www\.)?(?:adtv\.watch|amazingdiscoveries\.org|events\.amazingdiscoveries\.org|campmeeting\.amazingdiscoveries\.org|new\.amazingdiscoveries\.org|events\.amazingdiscoveries\.com)\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))/gi

      const replaceURLsWithLinks = (match, url) => {
        if (/<a\b[^>]*>/.test(url)) return match

        let link =
          url.startsWith('https://') || url.startsWith('http://')
            ? url
            : `https://${url}`

        return ` <a href="${link}" target="_blank">${url}</a>`
      }

      text = text.replace(urlRegex, replaceURLsWithLinks)
      this.fullText = text
    },
    addReadMoreLink() {
      const maxLength = 100

      const text = this.fullText.trim()
      this.isReadVisible = text.length > maxLength

      if (this.isReadVisible)
        this.truncatedText = text.slice(0, maxLength) + '...'
    },
  },
}
</script>

<style scoped lang="scss"></style>
