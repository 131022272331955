import videojs from 'video.js'

import { videoJSPlayer } from 'video/player'

document.addEventListener('turbolinks:load', () => {
  $('[data-fancyvideo]').click((e) => {
    e.preventDefault()

    const clickTarget = e.currentTarget
    const $videoDataElement = $(clickTarget).find('[data-video-src]')
    const essenceId = $videoDataElement.data('essence-id')
    const videoElementId = `essence_video_${essenceId}`

    const options = {
      videoElementId,
      mimeType: $videoDataElement.data('mime-type'),
      videoSrc: $videoDataElement.data('video-src'),
    }

    $.fancybox.open({
      src: `<video id="${videoElementId}" class="video-js vjs-default-skin" controls>`,
      type: 'html',
      opts: {
        video: {
          // It's important to keep this false to prevent Fancybox to autoplay
          // videos. We have to handle autoplay logic manually due
          // to strict autoplay policies on mobile devices.
          // Reference: https://blog.videojs.com/autoplay-best-practices-with-video-js/
          autoStart: false,
        },
        afterShow: () => setupPlayer(options),
        beforeClose: () => disposePlayer(videoElementId),
      },
    })
  })
})

function setupPlayer(options) {
  const { videoElementId, mimeType, videoSrc } = options

  if (videojs.getPlayer(videoElementId)) return // player has been already set up

  const sources = [
    {
      type: mimeType,
      src: videoSrc,
    },
  ]

  const player = videoJSPlayer(videoElementId, { sources })
  forceAutoplay(player, mimeType)
}

function disposePlayer(videoElementId) {
  const player = videojs.getPlayer(videoElementId)

  if (player) player.dispose()
}

function forceAutoplay(player, mimeType) {
  const bigPlayButton = player.getChild('bigPlayButton')
  bigPlayButton.hide()

  // We have to handle autoplay logic manually due to strict autoplay policies
  // on mobile devices.
  // Reference: https://blog.videojs.com/autoplay-best-practices-with-video-js/
  if (mimeType == 'video/youtube') {
    // Just set autoplay to true for Youtube videos.
    // If it's allowed Youtube will play the video without problems.
    // If it's forbidden Youtube will show its red button.
    player.autoplay(true)
  } else {
    // For videos other than Youtube we need to check if autoplay is allowed.
    // If it's not then we show video.js play button.
    player.one('loadedmetadata', () => tryAutoplay(player, bigPlayButton))
  }
}

function tryAutoplay(player, bigPlayButton) {
  let promise = player.play()

  if (promise !== undefined) {
    // if promise is rejected then autoplay is disabled in the browser
    promise.then(
      () => {},
      () => {
        bigPlayButton.show()
      }
    )
  } else {
    bigPlayButton.show()
  }
}
