$(document).on('turbolinks:load', () => {
  initPasswordTooltip()
})

$(document).on('password_tooltip:load', () => {
  initPasswordTooltip()
})

function initPasswordTooltip() {
  const $passwordTooltipContainer = $('.password-tooltip__container')
  if ($passwordTooltipContainer.length === 0) return

  const $passwordInput = $passwordTooltipContainer.parent().find('input')
  const $length = $('.password_length')
  const $digit = $('.password_digit')
  const $capital = $('.password_capital')

  $passwordInput.focus(() => {
    $passwordTooltipContainer.show()
  })

  $passwordInput.focusout(() => {
    $passwordTooltipContainer.hide()
  })

  $passwordInput.keyup(() => {
    password = $passwordInput.val()

    checkLength(password)
      ? $length.addClass('valid')
      : $length.removeClass('valid')

    checkUppercase(password)
      ? $capital.addClass('valid')
      : $capital.removeClass('valid')

    checkDigitAndSymbols(password)
      ? $digit.addClass('valid')
      : $digit.removeClass('valid')
  })
}

function checkUppercase(str) {
  for (var i = 0; i < str.length; i++) {
    if (
      str.charAt(i) === str.charAt(i).toUpperCase() &&
      str.charAt(i).match(/[a-z]/i)
    ) {
      return true
    }
  }
  return false
}

function checkLength(str) {
  return str.length <= 20 && str.length >= 8
}

function checkDigitAndSymbols(str) {
  if (str.length === 0) return false

  return !/^[a-zA-Z]+$/.test(str)
}
