<template>
  <ScrollUpButton />
  <div v-if="authorized()">
    <NoteForm
      v-model:active="isLocked"
      :show-tutorial="showTutorial"
      :tutorial-step="tutorialStep"
      @[EMITTERS.NOTE_CREATED_EMITTER]="reloadList()"
      @[EMITTERS.NOTE_UPDATED_EMITTER]="reloadList()"
      @[EMITTERS.CHANGE_TUTORIAL_STEP_EMITTER]="stepChanged"
    />
    <NotesList
      :reload-trigger="reloadListTrigger"
      :is-locked="isLocked"
      :show-tutorial="showTutorial"
      :tutorial-step="tutorialStep"
      @[EMITTERS.CHANGE_TUTORIAL_STEP_EMITTER]="stepChanged"
    />
  </div>
  <div v-else>
    <Authorization></Authorization>
  </div>
</template>

<script>
import NoteForm from './NoteForm'
import NotesList from './NotesList'
import { EMITTERS } from '../../shared/utils/emitters'
import Authorization from './Authorization'
import ScrollUpButton from './elements/ScrollUpButton'
import { TutorialApiService } from '../services'

export default {
  name: 'NotesComponent',
  components: {
    NoteForm,
    NotesList,
    Authorization,
    ScrollUpButton,
  },
  emits: [EMITTERS.CHANGE_TUTORIAL_STEP_EMITTER],
  data() {
    return {
      EMITTERS,
      loading: true,
      reloadListTrigger: false,
      isLocked: false,
      tutorialStep: 11,
      tutorialActive: false,
    }
  },
  computed: {
    showTutorial() {
      return this.tutorialActive
    },
  },
  created() {
    this.getTutorial()
  },
  methods: {
    authorized() {
      return !!this.optional_params.user_id
    },
    getTutorial() {
      TutorialApiService.getTutorial()
        .then((response) => {
          // noinspection JSUnresolvedVariable
          this.tutorialActive = response.data.tutorial_active
          this.tutorialStep = response.data.tutorial_step

          if (this.tutorialActive && this.tutorialStep === 11) {
            this.stepChanged({ number: 11 }).then(() => {
              this.$emit(
                EMITTERS.CHANGE_TUTORIAL_STEP_EMITTER,
                this.tutorialStep
              )
            })
          }
        })
        .catch(console.error)
    },
    reloadList() {
      this.reloadListTrigger = !this.reloadListTrigger
    },
    stepChanged(data) {
      if (this.tutorialStep > data.number) return
      const nextStep = data.number + 1
      return TutorialApiService.updateTutorial({
        tutorial_step: nextStep,
      })
        .then((_response) => {
          this.tutorialStep = nextStep
        })
        .catch(console.error)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../shared/assets/styles/global';
</style>
