<template>
  <div class="copyright-radio-block">
    <div class="radio-block">
      <input
        id="without-copyright"
        v-model="copyright"
        type="radio"
        name="copyright"
        value="none"
      />
      <label class="radio-label" for="without-copyright">
        {{ $vueTranslate('admin.image_copyright.selector.without_label') }}
      </label>
    </div>
    <div class="radio-block">
      <input
        id="ad-copyright"
        v-model="copyright"
        type="radio"
        name="copyright"
        value="ad"
      />
      <label class="radio-label" for="ad-copyright">
        {{ $vueTranslate('admin.image_copyright.selector.ad_label') }}
      </label>
    </div>
    <div class="radio-custom-block">
      <input
        id="custom-copyright"
        v-model="copyright"
        type="radio"
        name="copyright"
        value="custom"
      />
      <label class="custom-label" for="custom-copyright">
        <input
          v-model="customCopyright"
          type="text"
          class="custom-copyright-input"
          :maxlength="maxLength"
          :placeholder="
            $vueTranslate(
              'admin.image_copyright.selector.custom_input_placeholder'
            )
          "
          :disabled="isCustomFrozen"
        />
        <div class="custom-input-messages">
          <span v-if="isCustomCopyrightShort" class="error-message">
            {{ $vueTranslate('admin.image_copyright.selector.error_message') }}
          </span>
          <span class="counter">
            {{ customCopyright.length }}/{{ maxLength }}
          </span>
        </div>
      </label>
    </div>
  </div>
</template>

<script>
import { EMITTERS } from '../../../shared/utils/emitters'

export default {
  name: 'ImageCopyright',
  props: {
    selectedCopyright: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      maxLength: 50,
      copyright: '',
      customCopyright: '',
      isCustomCopyrightShort: false,
      isCustomFrozen: true,
      EMITTERS,
    }
  },
  watch: {
    copyright(newValue) {
      this.$emit(
        EMITTERS.IMAGE_COPYRIGHT_CHANGED,
        this.fetchCopyrightInfo(newValue)
      )
      this.isCustomFrozen = newValue !== 'custom'

      if (newValue !== 'custom') {
        this.isCustomCopyrightShort = false
        this.customCopyright = ''
      }
    },
    customCopyright() {
      if (this.copyright === 'custom') {
        this.isCustomCopyrightShort = false
        this.$emit(EMITTERS.IMAGE_COPYRIGHT_CHANGED, this.fetchCopyrightInfo())
      }
    },
  },
  mounted() {
    this.copyright = this.fetchSelectedCopyright()
    if (this.isCustom()) this.customCopyright = this.selectedCopyright

    this.emitter.on(EMITTERS.IMAGE_COPYRIGHT_SHOW_ERROR, this.showError)
    this.emitter.on(EMITTERS.AFTER_IMAGE_CHANGE_EMITTER, () => {
      this.customCopyright = ''
    })
  },
  methods: {
    fetchSelectedCopyright() {
      switch (this.selectedCopyright) {
        case '':
          return 'none'
        case this.$vueTranslate('admin.image_copyright.selector.ad_label'):
          return 'ad'
        default:
          return 'custom'
      }
    },
    fetchCopyright() {
      switch (this.copyright) {
        case 'ad':
          return this.$vueTranslate('admin.image_copyright.selector.ad_label')
        case 'custom':
          return this.customCopyright
        default:
          return ''
      }
    },
    fetchCopyrightInfo(type = this.fetchSelectedCopyright()) {
      return {
        copyright: this.fetchCopyright(),
        type: type,
      }
    },
    showError() {
      this.isCustomCopyrightShort = true
    },
    isCustom() {
      return this.copyright === 'custom'
    },
  },
}
</script>

<style scoped lang="scss">
.copyright-radio-block {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.radio-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 26px;

  .radio-label {
    display: flex;
    align-items: center;
    margin-left: 8px;
    margin-right: 24px;
    color: var(--text-primary, #323232);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: DM Sans, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
}

.radio-custom-block {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin-top: -16px;
}

.custom-copyright-input {
  width: 236px;
  border-radius: 2px;
  border: 1px solid #d1d3d4;
  background: #fff;
  color: var(--text-primary, #323232);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: DM Sans, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  &::placeholder {
    color: #a6a6a6;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: DM Sans, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
}

input[type='radio']:after {
  width: 24px;
  height: 24px;
  border-radius: 15px;
  top: -7px;
  right: 5px;
  position: relative;
  background-color: #ffffff;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 3px solid #ffffff;
  outline: 1px solid #d1d3d4;
}

input[type='radio']:checked:after {
  width: 24px;
  height: 24px;
  border-radius: 15px;
  top: -7px;
  right: 5px;
  position: relative;
  background-color: #414042;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 3px solid #ffffff;
  outline: 1px solid #414042;
}

.error-message {
  display: flex;
  justify-content: start;
  color: var(--system-status-error-icon-fill, #d35163);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: DM Sans, serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.custom-label {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.custom-input-messages {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.counter {
  display: flex;
  margin-left: auto;
  font-size: 12px;
  line-height: 12px;
  text-align: right;
  color: #a6a6a6;
  font-family: 'DM Sans', serif;
  font-style: normal;
}
</style>
