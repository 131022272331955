import axios from 'axios'

import { API_ROUTES, BASE_API_CONFIG } from '../../shared/utils/api'

export default {
  getFeaturedVideos(articleId, objectType) {
    return axios.request({
      ...BASE_API_CONFIG,
      method: 'GET',
      url: API_ROUTES.featured_video(articleId),
      params: {
        type: objectType,
      },
    })
  },
}
