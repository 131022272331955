<template>
  <div class="mt-5">
    <div v-if="!loading">
      <Program
        v-for="(prog, index) in programs"
        :key="index"
        :program="prog"
        :next-program="programs[index + 1]"
        :display-donate="index === donateBlockPosition"
        :timezone="timezone"
      />
    </div>
    <Loader v-else />
  </div>
</template>

<script>
import Program from './Program'
import ProgramsApiService from '../services/programsApi.service'
import Loader from '../../shared/components/Loader'
import { EMITTERS } from '../../shared/utils/emitters'
import { currentDate } from '../utils/time_utils'

export default {
  name: 'ProgramsComponent',
  components: { Loader, Program },
  props: {
    selectedDate: {
      required: true,
      type: [String, Date],
    },
    timezone: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      donateBlockPosition: 4,
      programs: null,
      loading: true,
    }
  },
  watch: {
    timezone: function () {
      this.fetchPrograms(this.selectedDate, this.timezone)
    },
    selectedDate: function () {
      this.fetchPrograms(this.selectedDate, this.timezone)
    },
  },
  mounted() {
    this.emitter.on(EMITTERS.SCHEDULE_IMPORT_STARTED, () => {
      this.loading = true
    })

    this.emitter.on(EMITTERS.SCHEDULE_DATA_IMPORTED, () => {
      this.fetchPrograms(this.selectedDate, this.timezone)
    })

    this.fetchPrograms(currentDate(), this.timezone)
  },
  methods: {
    fetchPrograms(dateSelected, timezone) {
      this.loading = true
      const date = this.formatDateSelected(dateSelected)
      ProgramsApiService.getPrograms(date, timezone).then((response) => {
        this.programs = response.data.programs

        this.loading = false
      })
    },

    formatDateSelected(date) {
      if (typeof date === 'string') return date

      const pad = (number) => `${Math.floor(Math.abs(number))}`.padStart(2, '0')
      return (
        date.getFullYear() +
        '-' +
        pad(date.getMonth() + 1) +
        '-' +
        pad(date.getDate())
      )
    },
  },
}
</script>

<style lang="scss">
.selected-date {
  margin-left: 20px;
  font-family: 'DM Sans', serif;
  font-weight: 500;
  font-size: 24px;
  color: #323232;
}
</style>
