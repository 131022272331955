<template>
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="mr-1"
  >
    <path
      d="M13.8333 2.33341H13V0.666748H11.3333V2.33341H4.66667V0.666748H3V2.33341H2.16667C1.24167 2.33341 0.508333 3.08341 0.508333 4.00008L0.5 15.6667C0.5 16.5834 1.24167 17.3334 2.16667 17.3334H13.8333C14.75 17.3334 15.5 16.5834 15.5 15.6667V4.00008C15.5 3.08341 14.75 2.33341 13.8333 2.33341ZM13.8333 15.6667H2.16667V7.33342H13.8333V15.6667ZM5.5 10.6667H3.83333V9.00008H5.5V10.6667ZM8.83333 10.6667H7.16667V9.00008H8.83333V10.6667ZM12.1667 10.6667H10.5V9.00008H12.1667V10.6667ZM5.5 14.0001H3.83333V12.3334H5.5V14.0001ZM8.83333 14.0001H7.16667V12.3334H8.83333V14.0001ZM12.1667 14.0001H10.5V12.3334H12.1667V14.0001Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'CalendarComponent',
}
</script>

<style scoped>
svg {
  cursor: pointer;
}
</style>
