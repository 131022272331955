import axios from 'axios'

import { API_ROUTES, BASE_API_CONFIG } from '../../shared/utils/api'

function getEpisodeId() {
  const episode_ids = new URL(document.location.href).pathname.match(/[^/]+/g)
  return episode_ids.slice(-1)
}

export default {
  DEFAULT_ORDER: 'desc',

  getAllBookmarks(order, optional_params = {}) {
    const required_params = {
      episode_id: getEpisodeId(),
      order,
    }

    return axios.request({
      ...BASE_API_CONFIG,
      method: 'GET',
      url: API_ROUTES.bookmarks,
      params: {
        ...optional_params,
        ...required_params,
      },
    })
  },

  createBookmark(bookmark, optional_params = {}) {
    bookmark.tags_attributes = bookmark.tags
    const required_params = {
      bookmark: bookmark,
      episode_id: getEpisodeId(),
    }

    const data = {
      ...optional_params,
      ...required_params,
    }

    return axios.request({
      ...BASE_API_CONFIG,
      method: 'POST',
      url: API_ROUTES.bookmarks,
      data,
    })
  },

  deleteBookmark(bookmark, optional_params = {}) {
    return axios.request({
      ...BASE_API_CONFIG,
      method: 'DELETE',
      url: API_ROUTES.bookmark(bookmark.id),
      params: optional_params,
    })
  },

  updateBookmark(bookmark, optional_params = {}) {
    bookmark.tags_attributes = bookmark.tags

    return axios.request({
      ...BASE_API_CONFIG,
      method: 'PUT',
      url: API_ROUTES.bookmark(bookmark.id),
      data: {
        bookmark,
        ...optional_params,
      },
    })
  },
}
