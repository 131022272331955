<template>
  <FlashNotification :site="SITES.PROFILE" />
</template>

<script>
import FlashNotification from './components/FlashNotification'
import { SITES } from '../shared/utils/view_options'

export default {
  name: 'NotificationAppComponent',
  components: {
    FlashNotification,
  },
  data() {
    return {
      SITES,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../shared/assets/styles/global';
</style>
