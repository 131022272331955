<template>
  <div :class="theme" class="text-input__form-control">
    <input
      v-model="input.value"
      type="text"
      class="text-input__form-input"
      :maxlength="80"
      :placeholder="placeholder"
      @keydown.enter.exact.prevent
      @keyup.enter.exact.prevent
      @keydown.enter.shift.exact.prevent
    />
    <span class="text-input__error video-resources__form-error">
      {{ error }}
    </span>
    <span v-if="maxLength" class="text-input__counter">
      {{ value.length }}/{{ maxLength }}
    </span>
  </div>
</template>

<script>
import { THEMES } from '../../../../shared/utils/view_options'

export default {
  name: 'TextInput',
  props: {
    maxLength: {
      type: Number,
      required: false,
      default: null,
    },
    value: {
      type: String,
      required: true,
    },
    error: {
      type: String,
      default: null,
    },
    theme: {
      type: String,
      require: false,
      default: THEMES.LIGHT,
    },
    placeholder: String,
  },
  emits: ['update:value'],
  data() {
    return {
      input: {
        value: this.value,
      },
    }
  },
  watch: {
    'input.value'() {
      this.emitValueUpdate()
    },
    value() {
      if (this.input.value !== this.value) {
        this.input.value = this.value
      }
    },
  },
  methods: {
    emitValueUpdate() {
      this.$emit('update:value', this.input.value)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../shared/assets/styles/video_resources';

.text-input__form-input {
  font-size: 14px;
  line-height: 18px;
  color: #323232;
  width: 100%;

  &::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #a6a6a6;
  }
}

.text-input__counter {
  font-size: 12px;
  line-height: 12px;
  text-align: right;
  color: #a6a6a6;
  font-family: 'DM Sans';
  font-style: normal;
}

.text-input__form-control {
  display: flex;
  width: 517px;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 6px;
}
.text-input__error {
  position: absolute;
  top: 36px;
}
</style>
