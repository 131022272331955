<template>
  <label class="switch-button">
    <input type="checkbox" />
    <i></i>
  </label>
</template>

<script>
export default {
  name: 'SwitchButton',
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['update:value'],
  data() {
    return {
      input: {
        value: this.value,
      },
    }
  },
  watch: {
    'input.value'() {
      this.emitValueUpdate()
    },
    value() {
      if (this.input.value !== this.value) {
        this.input.value = this.value
      }
    },
  },
  methods: {
    emitValueUpdate() {
      this.$emit('update:value', this.input.value)
    },
  },
}
</script>

<style lang="scss" scoped>
label {
  margin-bottom: 0;
}

.switch-button {
  display: inline-block;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.switch-button i {
  position: relative;
  display: inline-block;
  margin-right: 0.5rem;
  width: 46px;
  height: 26px;
  background-color: #a6a6a6;
  border-radius: 23px;
  vertical-align: text-bottom;
  transition: all 0.3s linear;
}
.switch-button i::before {
  content: '';
  position: absolute;
  left: 0;
  width: 42px;
  height: 22px;
  background-color: #fff;
  border-radius: 11px;
  transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
  transition: all 0.25s linear;
}
.switch-button i::after {
  content: '';
  position: absolute;
  left: 0;
  width: 22px;
  height: 22px;
  background-color: #a6a6a6;
  border-radius: 11px;
  transform: translate3d(2px, 2px, 0);
  transition: all 0.2s ease-in-out;
}
.switch-button:active i::after {
  width: 28px;
  transform: translate3d(2px, 2px, 0);
}
.switch-button:active input:checked + i::after {
  transform: translate3d(16px, 2px, 0);
}
.switch-button input {
  display: none;
}
.switch-button input:checked + i {
  background-color: #6998ae;
}
.switch-button input:checked + i::before {
  background-color: #fff;
  transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
}
.switch-button input:checked + i::after {
  background-color: #fff;
  transform: translate3d(22px, 2px, 0);
}
</style>
