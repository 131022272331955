<template>
  <div :class="theme">
    <div v-if="!readOnlyMode" class="documents__title">
      {{ $vueTranslate('admin.ebooks.title') }}
    </div>
    <div class="documents__container">
      <div v-if="!readOnlyMode" class="documents__create">
        <div class="documents__secondary-title">{{ secondaryTitle() }}</div>
        <Ebook
          :key="currentEbook.id"
          :mode="currentMode"
          :ebook="currentEbook"
          @[EMITTERS.EBOOK_CREATED_EMITTER]="ebookCreated"
          @[EMITTERS.EBOOK_UPDATED_EMITTER]="ebookUpdated"
          @[EMITTERS.EBOOK_CANCEL_EMITTER]="ebookUpdateCanceled"
        />
      </div>
      <div class="documents__index">
        <div v-if="!readOnlyMode" class="documents__secondary-title">
          {{ $vueTranslate('admin.ebooks.created_btn') }}
        </div>
        <div
          v-if="!loading"
          ref="ebookList"
          class="documents__list ebooks__list"
        >
          <Ebook
            v-for="ebook of ebooks"
            :key="ebook.id"
            :active="activeEbookId"
            :ebook="ebook"
            :mode="MODES.SHOW"
            :theme="theme"
            :read-only-mode="readOnlyMode"
            @[EMITTERS.EBOOK_DELETED_EMITTER]="ebookDeleted"
            @[EMITTERS.EBOOK_EDIT_EMITTER]="ebookEdit"
          />
        </div>
        <img
          v-else
          src="../../../shared/assets/images/loading.gif"
          alt="loading"
          class="documents-loader"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, remove } from 'lodash'

import Ebook from './Ebook'
import {
  THEMES,
  VIDEO_RESOURCES_MODES as MODES,
} from '../../../shared/utils/view_options'
import EbooksApiService from '../services/ebooksApi.service'
import { EMITTERS } from '../../../shared/utils/emitters'

const EMPTY_EBOOK = {
  id: null,
  ebook_id: null,
  name: '',
  author: '',
  description: '',
  document: File,
  cover: File,
}

export default {
  name: 'EbooksComponent',
  components: {
    Ebook,
  },
  props: {
    readOnlyMode: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: THEMES.LIGHT,
    },
  },
  data() {
    return {
      MODES,
      EMITTERS,
      ebooks: [],
      currentMode: MODES.CREATE,
      currentEbook: cloneDeep(EMPTY_EBOOK),
      activeEbookId: 0,
      loading: true,
    }
  },
  created() {
    this.fetchEbooks()
  },
  methods: {
    fetchEbooks() {
      EbooksApiService.getAllEbooks(this.optional_params)
        .then((response) => {
          const responseData = response.data

          this.ebooks = responseData.ebooks
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false
        })
    },
    ebookCreated() {
      Object.assign(this.currentEbook, EMPTY_EBOOK)
      this.fetchEbooks()
    },
    ebookDeleted(ebook) {
      remove(this.ebooks, (element) => element.id === ebook.id)
      if (ebook.id === this.activeEbookId) {
        this.currentMode = MODES.CREATE
        this.currentEbook = cloneDeep(EMPTY_EBOOK)
        this.activeEbookId = 0
      }
      this.count--
    },
    updateEbook(ebook) {
      const storedEbook = this.ebooks.find((element) => element.id === ebook.id)
      Object.assign(storedEbook, ebook)
    },
    ebookUpdated(ebook) {
      this.currentMode = MODES.CREATE
      this.currentEbook = cloneDeep(EMPTY_EBOOK)
      this.updateEbook(ebook)
      this.activeEbookId = 0
    },
    ebookEdit(ebook) {
      this.currentMode = MODES.EDIT
      this.currentEbook = cloneDeep(ebook)
      this.activeEbookId = ebook.id
    },
    ebookUpdateCanceled() {
      this.currentMode = MODES.CREATE
      this.activeEbookId = 0
      this.currentEbook = cloneDeep(EMPTY_EBOOK)
    },
    secondaryTitle() {
      return this.currentMode === MODES.CREATE ? 'Add e-book' : 'Edit e-book'
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../shared/assets/styles/documents';

.documents__list.ebooks__list {
  justify-content: flex-start;
  gap: 2%;
}
</style>
