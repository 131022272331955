$(document).on('turbolinks:load', () => {
  if ($('.sign-in')[0]) {
    return localStorage.removeItem('welcome_tooltip_shown')
  }
  if (tooltipSkipCondition()) return

  showTooltip()
  $('.login-welcome-tooltip__close').on('click', closeTooltip)
  setTimeout(closeTooltip, 5000)
})

function showTooltip() {
  getTooltip().css('display', 'flex')
  getTooltip().css('visibility', 'visible')
  getTooltip().css('opacity', '1')

  return null
}

function getTooltip() {
  return $('.login-welcome-tooltip__container')
}

function closeTooltip() {
  getTooltip().remove()
  localStorage.setItem('welcome_tooltip_shown', '1')

  return null
}

function tooltipSkipCondition() {
  if (!$('.login-welcome-tooltip__container')[0]) return true

  return localStorage.getItem('welcome_tooltip_shown')
}
