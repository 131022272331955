import axios from 'axios'

import { API_ROUTES, BASE_API_CONFIG } from '../../shared/utils/api'

function getNewsId() {
  const news_ids = new URL(document.location.href).pathname.match(/[^/]+/g)
  return news_ids.slice(-1)
}

export default {
  getAllBookmarks(optional_params = {}) {
    const required_params = {
      news_id: getNewsId(),
    }

    return axios.request({
      ...BASE_API_CONFIG,
      method: 'GET',
      url: API_ROUTES.news_bookmarks,
      params: {
        ...optional_params,
        ...required_params,
      },
    })
  },

  createBookmark(news_bookmark, optional_params = {}) {
    news_bookmark.tags_attributes = news_bookmark.tags
    const required_params = {
      news_bookmark: news_bookmark,
      news_id: getNewsId(),
    }

    const data = {
      ...optional_params,
      ...required_params,
    }

    return axios.request({
      ...BASE_API_CONFIG,
      method: 'POST',
      url: API_ROUTES.news_bookmarks,
      data,
    })
  },

  deleteBookmark(news_bookmark, optional_params = {}) {
    return axios.request({
      ...BASE_API_CONFIG,
      method: 'DELETE',
      url: API_ROUTES.news_bookmark(news_bookmark.id),
      params: optional_params,
    })
  },

  updateBookmark(news_bookmark, optional_params = {}) {
    news_bookmark.tags_attributes = news_bookmark.tags

    return axios.request({
      ...BASE_API_CONFIG,
      method: 'PUT',
      url: API_ROUTES.news_bookmark(news_bookmark.id),
      data: {
        news_bookmark,
        ...optional_params,
      },
    })
  },
}
