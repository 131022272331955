<template>
  <div class="donate__block" v-if="showBanner">
    <div class="donate__block-container">
      <div class="donate__question">
        <span class="donate__question-title" field-name="title">
          {{ title }}
        </span>
        <span class="donate__question-description" field-name="body">
          {{ body }}
        </span>
      </div>
      <div class="donate__buttons">
        <a v-if="button_1.label && button_1.label !== ''"
          class="donate__button-us ga-tracking-button"
           :ga-page-type="gaTrackingInfo.pageType"
           :ga-page-id="gaTrackingInfo.pageId"
           :ga-banner-description="body"
          :href="button_1.link"
          field-name="button_1"
          target="_blank">
          {{ button_1.label }}
        </a>
        <a v-if="button_2.label && button_2.label !== ''"
           class="donate__button-ca ga-tracking-button"
           :ga-page-type="gaTrackingInfo.pageType"
           :ga-page-id="gaTrackingInfo.pageId"
           :ga-banner-description="body"
           :href="button_2.link"
           field-name="button_2"
           target="_blank">
          {{ button_2.label }}
        </a>
        <a v-if="button_3.label && button_3.label !== ''"
           class="donate__button-explore-projects ga-tracking-button"
           :ga-page-type="gaTrackingInfo.pageType"
           :ga-page-id="gaTrackingInfo.pageId"
           :ga-banner-description="body"
           field-name="button_3"
           :href="button_3.link">
          {{ button_3.label }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DonateBlock',
  props: {
    banner: {
      type: Object,
      default: null,
    },
    gaTrackingInfo: {
      type: Object,
      default: null,
    },
  },
  computed: {
    title() {
      return this.findField('title') || this.$vueTranslate('schedule.donate_block.question_title')
    },
    body() {
      return this.findField('body') || this.$vueTranslate('schedule.donate_block.question_description')
    },
    button_1() {
      return this.findField('button_1') || {
        label: this.$vueTranslate('schedule.donate_block.buttons.american'),
        link: this.$vueTranslate(`schedule.donate_block.stores.american`)
      }
    },
    button_2() {
      return this.findField('button_2') || {
        label: this.$vueTranslate('schedule.donate_block.buttons.canadian'),
        link: this.$vueTranslate(`schedule.donate_block.stores.canadian`)
      }
    },
    button_3() {
      return this.findField('button_3') || {
        label: this.$vueTranslate('schedule.donate_block.buttons.explore_projects'),
        link: this.$vueTranslate(`schedule.donate_block.stores.explore_projects`)
      }
    },
    showBanner() {
      if (this.banner.published) return true

      return (/admin\/banners/i.test(window.location.href))
    },
  },
  methods: {
    findField(name) {
      if (!this.banner) return null

      return this.banner.fields.filter((field) => field.name === name)[0].value
    }
  },
}
</script>

<style lang="scss">
.donate__block {
  background: #f3f4f5;
  border-radius: 4px;
  border-left: solid #d18f42 4px;
  font-family: 'DM Sans', sans-serif;
  margin: 12px 0;

  @media (max-width: 991px) {
    border-left: none;
    border-top: solid #d18f42 4px;
  }
}

.donate__block-container {
  max-width: 838px;
  padding: 32px 44px 40px;

  * {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  @media (max-width: 768px) {
    margin-right: 16px;
    margin-left: 16px;
  }

  @media (max-width: 640px) {
    padding: 24px 8px 20px;
  }
}

.donate__question {
  font-style: normal;
  color: #323232;
  margin-bottom: 8px;
}

.donate__question-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}

.donate__question-description {
  width: 700px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 24px;
  white-space: pre-line;
}

.donate__buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  align-content: space-between;
}

.donate__button-us,
.donate__button-ca,
.donate__button-explore-projects {
  width: 217px;
  height: 48px;
  cursor: pointer;
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px 0;
  @media (max-width: 991px) {
    width: 100%;
  }
}

.donate__button-us,
.donate__button-ca {
  background: #6998ae;
  box-shadow: 0 2px 15px rgba(73, 73, 73, 0.2);
  color: $white;
  &:hover {
    color: $white;
  }
}

.donate__button-explore-projects {
  background: $white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  color: #6998ae;
}
</style>