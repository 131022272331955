<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="close-icon"
  >
    <path
      d="M3.80238 2.86128C3.54203 2.60093 3.11992 2.60093 2.85957 2.86128C2.59922 3.12163 2.59922 3.54374 2.85957 3.80409L7.01518 7.9597L2.85932 12.1156C2.59898 12.3759 2.59898 12.798 2.85932 13.0584C3.11967 13.3187 3.54178 13.3187 3.80213 13.0584L7.95799 8.90251L12.1133 13.0578C12.3736 13.3182 12.7958 13.3182 13.0561 13.0578C13.3165 12.7975 13.3165 12.3754 13.0561 12.115L8.9008 7.9597L13.0559 3.80464C13.3162 3.54429 13.3162 3.12218 13.0559 2.86183C12.7955 2.60148 12.3734 2.60148 12.113 2.86183L7.95799 7.01689L3.80238 2.86128Z"
      fill-rule="evenodd"
      clip-rule="evenodd"
      class="close-icon__element"
      :fill="fillIcon()"
    />
  </svg>
</template>

<script>
import { THEMES } from '../../utils/view_options'

export default {
  name: 'CloseIconComponent',
  props: {
    color: {
      required: false,
      type: String,
      default: null,
    },
    theme: {
      required: true,
      type: String, // THEMES.DARK | THEMES.LIGHT,
    },
  },
  data() {
    return {
      DARK_THEME: '#D1D3D4',
      LIGHT_THEME: '#A6A6A6',
    }
  },
  methods: {
    fillIcon() {
      return this.color || this.defineColor()
    },
    defineColor() {
      return this.theme === THEMES.DARK ? this.DARK_THEME : this.LIGHT_THEME
    },
  },
}
</script>

<style scoped>
svg {
  cursor: pointer;
}
</style>
