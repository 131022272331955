//
// fancybox.js
//

document.addEventListener('turbolinks:load', () => {
  if (jQuery().fancybox) {
    $.fancybox.defaults.image.preload = false
    $.fancybox.defaults.toolbar = false
    $.fancybox.defaults.clickContent = false
  }
})
