<template>
  <img
    v-if="loading"
    src="../../shared/assets/images/loading.gif"
    alt="loading"
    width="40"
    class="bookmarks-loader"
  />
  <div v-if="!activeBookmark.id" class="news-component__wrapper">
    <div v-if="isNoBookmarks()" class="news-placeholder">
      <div class="news-block">
        <div class="placeholder-border"></div>
        <div class="primary-placeholder-title">
          {{ BOOKMARKS_NONE }}
        </div>
        <div class="secondary-placeholder-title">
          {{ BOOKMARK_CREATE_FIRST }}
        </div>
        <a :href="news_landing_page" class="placeholder-button btn btn-primary">
          {{ PROPHECY_NEWS_LANDING }}
        </a>
      </div>
    </div>

    <div
      v-if="!loading && (!isNoBookmarks() || hasFilter())"
      class="bookmarks-title-row"
    >
      <span class="bookmarks-title">
        <span class="title-text">{{ BOOKMARKS_TITLE }}</span>
        <span class="counter">({{ count }})</span>
      </span>
    </div>

    <div v-if="!isNoBookmarks()" class="bookmarks-filter-block">
      <BookmarkSearch />
      <BookmarkFilter
        v-if="tagsPresent()"
        :news="newsList"
        :tags="tags"
        :tags-selected="selectedTags"
      />
    </div>

    <BookmarkFilterTags
      v-if="!loading && tagsPresent()"
      :tags-selected="selectedTags"
    />

    <div v-if="!loading" class="main-block row">
      <News
        v-for="news in newsList"
        :key="news.news_bookmarks.length"
        ref="el => setRef(el, news.id)"
        :news="news"
        @[EMITTERS.BOOKMARK_DELETED_EMITTER]="deleteNews"
      />

      <div v-if="isEmptyFilterResults()" class="filter-bookmark__no-result row">
        {{ NO_FILTER_RESULTS }}
      </div>
    </div>
  </div>
  <div v-else class="news-component__wrapper">
    <div class="main-block row news-edit">
      <div class="news-container row">
        <div class="news-thumbnail-container">
          <NewsThumbnail :news="activeNews" />
          <div class="news-bookmarks__delete-button" @click="openDialog">
            <DeleteIcon class="delete-icon" :theme="THEMES.PROFILE" />
            <span class="delete-button-text">
              Delete Bookmark
            </span>
          </div>
        </div>

        <div class="news-bookmarks-container col">
          <ManageNewsBookmark
            :key="activeBookmark.id"
            :news-bookmark="activeBookmark"
            :mode="MODES.EDIT"
            :headless="true"
            @[EMITTERS.BOOKMARK_UPDATED_EMITTER]="bookmarkUpdated"
            @[EMITTERS.NEWS_BOOKMARK_CHANGED]="newsBookmarkEdited"
            @[EMITTERS.CLOSE_DIALOG_EMITTER]="cancelEdit"
          />
        </div>

        <ConfirmationDialog
          v-if="showDialog"
          :theme="THEMES.LIGHT"
          :entity="activeBookmark"
          :confirmation-text="DELETE_CONFIRMATION_TEXT"
          :delete-function="BOOKMARK_DELETE_FUNCTION"
          :on-deleted-emitter="EMITTERS.BOOKMARK_DELETED_EMITTER"
          @[EMITTERS.CLOSE_DIALOG_EMITTER]="closeDialog"
          @[EMITTERS.BOOKMARK_DELETED_EMITTER]="bookmarkDeleted"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { remove } from 'lodash'

import News from './News'
import ApiService from '../services/newsApi.service'
import NewsBookmarksTagsApiService from '../services/newsBookmarksTagsApi.service'
import { EMITTERS } from '../../shared/utils/emitters'
import BookmarkFilter from '../../study_tools/components/BookmarkFilter'
import BookmarkSearch from '../../study_tools/components/BookmarkSearch'
import BookmarkFilterTags from '../../study_tools/components/BookmarkFilterTags'
import ManageNewsBookmark from '../../news_bookmarks/components/ManageNewsBookmark'
import {
  BOOKMARK_MODES as MODES,
  THEMES,
} from '../../shared/utils/view_options'
import NewsThumbnail from './NewsThumbnail'
import NewsBookmarksApiService from '../../news_bookmarks/services/newsBookmarksApi.service'
import ConfirmationDialog from '../../study_tools/components/ConfirmationDialog'
import { DeleteIcon } from '../../shared/components/icons'
import { BOOKMARK_DELETED } from '../../shared/utils/notificationMessages'

const EMPTY_NEWS_BOOKMARK = {
  id: null,
  text: '',
  match_number: 0,
  tags: [],
}
export default {
  name: 'NewsListComponent',
  components: {
    NewsThumbnail,
    ManageNewsBookmark,
    BookmarkFilterTags,
    BookmarkSearch,
    BookmarkFilter,
    News,
    ConfirmationDialog,
    DeleteIcon,
  },
  data() {
    return {
      count: 0,
      newsList: [],
      tags: [],
      selectedTags: [],
      loading: true,
      searchEnded: false,
      loadedByWatch: false,
      activeBookmark: EMPTY_NEWS_BOOKMARK,
      newsBookmarkChanged: false,
      activeNews: {},
      newsListRefs: {},
      news_landing_page: window.location.origin + '/prophecy-news',
      EMITTERS,
      MODES,
      THEMES,
      BOOKMARKS_TITLE: 'Prophecy News Bookmarks',
      BOOKMARK_CREATE_FIRST:
        'To start creating your first bookmark, choose the news you’d like to read on the Prophecy News Page',
      BOOKMARKS_NONE: "You haven't created any bookmarks yet",
      PROPHECY_NEWS_LANDING: 'Prophecy News Page',
      NO_FILTER_RESULTS: 'There are no results for your request',
      BOOKMARK_DELETE_FUNCTION: NewsBookmarksApiService.deleteBookmark,
      DELETE_CONFIRMATION_TEXT: 'Delete this bookmark?',
      showDialog: false,
    }
  },
  watch: {
    $route(newRoute, oldRoute) {
      this.updateBreadcrumbs(newRoute)

      this.loadedByWatch = true
      if (newRoute.name === 'profile_bookmarks_list') {
        this.newsBookmarkChanged = false
        this.activeBookmark.id = null
        this.fetchNewsByParams(newRoute, oldRoute)
      }
      if (
        !(this.activeBookmark && this.activeBookmark.id) &&
        newRoute.name === 'profile_bookmark_edit'
      ) {
        return this.cancelEdit()
      }
      if (newRoute.name !== 'profile_bookmarks_list') return true
      if (
        this.activeBookmark &&
        this.activeBookmark.id &&
        oldRoute.name !== 'profile_bookmark_edit'
      ) {
        return this.$router.push({
          name: 'profile_bookmark_edit',
          params: { id: this.activeBookmark.id },
        })
      }
    },
  },
  mounted() {
    this.emitter.on(EMITTERS.TAG_ADDED_EMITTER, this.fetchTags)
    this.emitter.on(EMITTERS.FILTER_BOOKMARK_TAG_UPDATED, (tags) => {
      this.selectedTags = tags
    })
    this.emitter.on(EMITTERS.BOOKMARK_EDIT_EMITTER, this.bookmarkEdit)
    this.checkRender()
  },
  created() {
    this.fetchTags()
    this.setupLeaveConfirmation()
  },
  methods: {
    setRef(el, id) {
      if (el) {
        this.$set(this.newsListRefs, id, el)
      }
    },
    updateBreadcrumbs(route) {
      const breadcrumbs = [
        {
          title: 'News Bookmarks',
          to: '/',
        },
      ]
      if (route.name === 'profile_bookmarks_list') {
        this.$globalStore.dispatch('updateBreadcrumbs', breadcrumbs)
      } else if (route.name === 'profile_bookmark_edit') {
        breadcrumbs.push({
          title: 'Edit',
          to: route.path,
          params: route.params,
        })
        this.$globalStore.dispatch('updateBreadcrumbs', breadcrumbs)
      }
    },
    hasUnsavedChanges() {
      if (!this.$route) return false
      if (this.$route.name !== 'profile_bookmark_edit') return false
      if (!this.activeBookmark.id) return false

      return this.newsBookmarkChanged
    },
    confirmLeavePage(event) {
      if (!this.hasUnsavedChanges()) return true
      const $sideBarBtns = $('.profile_sidebar .nav-link')

      if (confirm('Do you want to leave?\nYou have unsaved changes')) {
        $sideBarBtns.removeClass('prevented')
        return true
      } else {
        $sideBarBtns.addClass('prevented')
        $sideBarBtns.removeClass('active')
        $('.news-button').addClass('active')
        event.stopPropagation()
        event.preventDefault()
        return false
      }
    },
    setupLeaveConfirmation() {
      if (!this.$store.state.leaveConfirmationCallbackAdded) {
        $('a, .profile_sidebar .nav-link').on('click', this.confirmLeavePage)
        window.addEventListener('beforeunload', (event) => {
          if (!this.hasUnsavedChanges()) return true

          event.preventDefault()
          event.returnValue = ''
          return false
        })
        this.$store.dispatch('leaveConfirmationCallbackAdded')
      }
    },
    deleteNews(obj) {
      if (obj.bookmarks.length === 0) {
        remove(this.newsList, (element) => element.id === obj.news.id)
      }
      this.count--
      this.fetchTags()
    },
    fetchNews(custom_params = null) {
      if (custom_params) this.loading = true

      const params = custom_params ? custom_params : this.optional_params

      ApiService.getAllNews(params)
        .then((response) => {
          const responseData = response.data

          this.newsList = responseData.news
          this.count = responseData.count
        })
        .catch((e) => {
          this.errors.push(e)
          console.error(this.errors)
        })
        .finally(() => {
          this.loading = false
        })
    },
    hasFilter() {
      const urlParams = this.$route.query
      return 'query' in urlParams || 'tags' in urlParams
    },
    isEmptyFilterResults() {
      const { loading, newsList, hasFilter } = this
      return !loading && newsList.length === 0 && hasFilter()
    },
    isNoBookmarks() {
      const { loading, newsList, hasFilter } = this
      return !loading && newsList.length === 0 && !hasFilter()
    },
    fetchTags() {
      NewsBookmarksTagsApiService.getAllTags().then((response) => {
        this.tags = response.data.tags
      })
    },
    fetchNewsByParams(newRoute, oldRoute) {
      const urlParams = (newRoute && newRoute.query) || this.$route.query
      const tagsParam = urlParams.tags
      const queryParam = urlParams.query || ''

      const params = {}

      if (tagsParam) {
        const selectedTags = tagsParam.split(',')
        params.tags = selectedTags
        this.selectedTags = selectedTags
      }

      if (queryParam && queryParam.length >= 3) {
        params.query = queryParam
        this.searchEnded = false
      }

      if (queryParam.length < 3 && !this.searchEnded) {
        this.searchEnded = true
        this.fetchNews(params)
        return
      }

      const newTags = (newRoute && newRoute.query.tags) || ''
      const oldTags = (oldRoute && oldRoute.query.tags) || ''

      if (this.searchEnded && newTags === oldTags) return

      this.fetchNews(params)
    },
    checkRender() {
      setTimeout(() => {
        if (!this.loadedByWatch) {
          this.fetchNewsByParams(null, null)
          this.loadedByWatch = false
        }
      }, 1500)
    },
    tagsPresent() {
      return this.tags.length > 0
    },
    bookmarkEdit(bookmark) {
      this.activeNews = this.newsList.find(
        (element) => element.id === bookmark.news_id
      )
      Object.assign(this.activeBookmark, bookmark)
    },
    bookmarkUpdated() {
      this.updateNewsList()
      this.newsBookmarkChanged = false
      this.activeBookmark.id = null
      this.fetchTags()
      this.emitter.emit(EMITTERS.BOOKMARK_UPDATED_EMITTER)
      this.$router.push({
        name: 'profile_bookmarks_list',
      })
    },
    cancelEdit() {
      this.updateNewsList()
      this.newsBookmarkChanged = false
      this.activeBookmark.id = null
      this.$router.push({
        name: 'profile_bookmarks_list',
      })
    },
    newsBookmarkEdited() {
      this.newsBookmarkChanged = true
    },
    openDialog() {
      this.showDialog = true
    },
    closeDialog() {
      this.showDialog = false
    },
    bookmarkDeleted() {
      this.emitter.emit(EMITTERS.BOOKMARK_DELETED_EMITTER, this.activeBookmark)
      this.cancelEdit()
      this.fetchTags()
      this.emitter.emit(EMITTERS.SHOW_NOTIFICATION_EMITTER, {
        message: BOOKMARK_DELETED,
      })
    },
    updateNewsList() {
      let news = this.newsList.find(
        (news) => news.id === this.activeBookmark.news_id
      )
      if (news) {
        let bookmark = news.news_bookmarks.find(
          (bookmark) => bookmark.id === this.activeBookmark.id
        )

        if (bookmark) {
          bookmark.tags = this.activeBookmark.tags.filter(
            (tag) => !tag._destroy
          )
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../shared/assets/styles/global';

.news-block {
  width: 41.75rem;
  height: 21.625rem;
  left: 32.125rem;
  top: 18.438rem;
  background: #ffffff;
  box-shadow: 0 0.25rem 2.5rem rgba(167, 167, 167, 0.15);
  border-radius: 0.75rem;
  padding: 6.563rem 4.875rem 6.563rem 4.875rem;
  text-align: center;
  margin-right: 3.2rem;
}

.main-block {
  width: 100%;
  padding: 0 16px 0 32px;
  margin-bottom: 72px;
  display: flex;
}

.bookmarks-title-row {
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13px;
  margin-right: 48px;

  .bookmarks-title {
    height: 42px;
    vertical-align: middle;
    display: flex;
  }

  .title-text {
    font-size: 24px;
    color: #323232;
  }

  .counter {
    font-size: 14px;
    margin: auto 4px;
    color: #a6a6a6;
  }
}

.news-placeholder {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  justify-content: center;
  height: 624px;
  .news-block {
    overflow: hidden;
  }
  .placeholder-border {
    position: relative;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -6.563rem;
      left: -4.875rem;
      width: 41.75rem;
      height: 4px;
      background: #d18f42;
    }
  }
}

.placeholder-button {
  height: 56px;
  width: 300px;
}

.primary-placeholder-title {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0;
  text-align: center;
  color: #5c5c5c;
}

.secondary-placeholder-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0;
  text-align: center;
  color: #323232;
  margin-bottom: 20px;
  margin-top: 12px;
}

.bookmarks-search {
  font-size: 12px;
  color: #5c5c5c;
  background-color: #dbebf0;
  padding: 4px 12px;
  font-weight: 400;
}

.bookmarks-filter-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-height: 32px;
  height: auto;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-right: 48px;
}

.filter-bookmark__no-result {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;

  color: var(--text-secondary, #5c5c5c);
  font-size: 14px;
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-top: 16px;
}

.news-container {
  width: 100%;
  min-height: 220px;
  background-color: #ffffff;
  margin-bottom: 16px;
  padding: 16px;
}

.news-bookmarks-container {
  padding-left: 24px;
  padding-right: 24px;
}

.news-thumbnail-container {
  margin-top: 62px;
}

.news-edit {
  min-height: 370px;
}

.news-bookmark__delete {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--Primary-Steel-Blue-Default, #6998ae);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  box-shadow: 0px 1px 10px 0px rgba(29, 32, 35, 0.15);
  padding: 4px 0;
  margin-top: 10px;
}

.news-bookmarks__delete-button {
  display: flex;
  align-items: center;
  height: 32px;
  gap: 8px;
  color: var(--Primary-Steel-Blue-Default, #6998ae);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  border-radius: 4px;
  background: var(--Backgrounds-White, #ffffff);
  box-shadow: 0px 1px 10px 0px rgba(29, 32, 35, 0.15);
  padding: 9px 8px;
  cursor: pointer;
}

.delete-icon {
  width: 20px;
}
</style>
