<template>
  <div
    ref="scrollButton"
    class="scroll-top-button-container"
    @click="scrollToTop()"
  >
    <div class="scroll-top-button">
      <i data-feather="arrow-up"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScrollToTop',
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    scrollToTop() {
      $('html, body').animate({ scrollTop: 0 }, 500)
    },
    handleScroll(_event) {
      if (window.scrollY > 100) {
        this.$refs.scrollButton.classList.add('fixed')
      } else {
        this.$refs.scrollButton.classList.remove('fixed')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.scroll-top-button-container {
  position: relative;
  display: none;
  z-index: 999;

  .scroll-top-button {
    position: fixed;
    right: 20%;
    bottom: 115px;
    color: #6998ae;
    background: white;
    border-radius: 50%;
    box-shadow: 0 2px 10px rgb(73 73 73 / 15%);
    padding: 10px;
    line-height: 0;
    cursor: pointer;

    svg {
      height: 24px;
      width: 24px;
    }
  }
  &.fixed {
    display: block;
  }
}

@media (max-width: 992px) {
  .scroll-top-button-container {
    display: none !important;
  }
}
</style>
