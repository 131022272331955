<template>
  <RichText
      v-model:content="content"
      :content-updated="true"
      :theme="THEMES.LIGHT"
      :extendedToolbar="false"
      :viewOnly="true"
  />
</template>

<script>
import { EMITTERS } from '../shared/utils/emitters'
import { THEMES } from '../shared/utils/view_options'
import RichText from '../shared/components/RichText'

export default {
  name: 'MobileArticleAppComponent',
  components: {
    RichText,
  },
  data() {
    return {
      EMITTERS,
      THEMES,
      content: this.optional_params.content,
    }
  },
}
</script>

<style lang="scss" scoped>
  .rich-text-container {
    border: none;
  }
</style>
