<template>
  <div v-if="isEbook" class="preview__document-viewer-container">
    <img
      v-if="showCover"
      class="preview__cover"
      :src="entity.cover.thumbnailUrl"
      alt="Cover Preview"
    />
    <div v-if="showCover" class="preview__read-button" @click="switchToBook">
      {{ $vueTranslate('admin.document_viewer.read_btn') }}
    </div>
    <iframe
      v-if="preview && !showCover"
      :src="file.previewLink"
      :type="fileType"
      width="100%"
      height="500px"
    />

    <div v-if="!preview && !showCover" class="preview__empty">
      <span class="preview__empty-title">
        {{ $vueTranslate('admin.document_viewer.no_preview') }}
      </span>
    </div>
  </div>

  <div v-if="isDocument && preview">
    <img
      v-if="preview.type.startsWith('image/')"
      :src="preview.content"
      alt="Image Preview"
    />
    <iframe
      v-else
      :src="preview.content"
      :type="entity.document.type"
      width="100%"
      height="500px"
    />
  </div>
  <div v-if="isDocument && !preview" class="preview__empty">
    <span class="preview__empty-title">
      {{ $vueTranslate('admin.document_viewer.no_preview') }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    entity: Object,
  },
  data() {
    return {
      preview: null,
      fileText: null,
      file: this.entity.document,
      fileType: this.entity.document.type,
      showCover: true,
    }
  },
  computed: {
    isEbook() {
      return this.entity.type === 'ebook'
    },
    isDocument() {
      return (
        this.entity.type === 'document' ||
        this.entity.type === 'guide' ||
        this.entity.type === 'transcript'
      )
    },
  },
  mounted() {
    if (!this.fileType) {
      return
    }
    if (
      this.fileType.startsWith('image/') ||
      this.fileType.startsWith('application/pdf') ||
      this.fileType.startsWith('text/vtt')
    ) {
      this.preview = {
        type: this.fileType,
        content: this.file.previewLink,
      }
    }
  },
  methods: {
    switchToBook() {
      if (this.isEbook) {
        return window.open(this.entity.document.previewLink, '_blank')
      }
      this.showCover = false
    },
  },
}
</script>

<style lang="scss" scoped>
img,
iframe {
  width: 100%;
  border-radius: 4px;
  object-fit: cover;
  border: none;
}

.preview__cover {
  object-fit: cover;
  height: 500px;
}

.preview__empty {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
}

.preview__read-button {
  align-self: center;
  margin-top: -45px;
  display: flex;
  width: 93px;
  height: 32px;
  padding: 9px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #6998ae;
  color: #ffffff;
  box-shadow: 0px 1px 10px 0px rgba(29, 32, 35, 0.15);

  &:hover {
    cursor: pointer;
    box-shadow: 0px 2px 15px 0px rgba(29, 32, 35, 0.15);
  }
}

.preview__document-viewer-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
@media (max-width: 991px) {
  .preview__cover {
    height: 60vh;
  }
  .preview__read-button {
    margin-top: 20px;
  }
}
</style>
