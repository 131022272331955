<template>
  <img
    v-if="loading"
    src="../../shared/assets/images/loading.gif"
    alt="loading"
    width="40"
    class="notes-loader"
  />
  <div v-if="!loading" class="episodes-component__wrapper">
    <div v-if="episodes_by_date.length === 0" class="episodes-placeholder">
      <div class="episodes-block">
        <div class="placeholder-border"></div>
        <div class="primary-placeholder-title">
          {{ HISTORY_NONE }}
        </div>
        <div class="secondary-placeholder-title">
          {{ NONE_SECONDARY }}
        </div>
        <a :href="watch_page" class="placeholder-button btn btn-primary">
          {{ WATCH_PAGE_LINK }}
        </a>
      </div>
    </div>

    <div v-if="episodes_by_date.length > 0" class="notes-title-row">
      <span class="notes-title">
        <span class="title-text">{{ HISTORY_TITLE }}</span>
        <span class="counter">({{ count }})</span>
      </span>
    </div>

    <div class="main-block row">
      <template v-for="episodes in episodes_by_date">
        <div class="episodes_date">
          {{ episodes.date }}
        </div>
        <Episode
          v-for="episode in episodes.episodes"
          :key="episode.id"
          :episode="episode"
          @[EMITTERS.WATCH_HISTORY_EPISODE_DELETED]="deleteEpisode"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { remove, find } from 'lodash'

import Episode from './Episode'
import ApiService from '../services/episodesApi.service'
import { EMITTERS } from '../../shared/utils/emitters'
import { WATCH_HISTORY_EPISODE_DELETED } from '../../shared/utils/notificationMessages'

export default {
  name: 'HistoryComponent',
  components: {
    Episode,
  },
  data() {
    return {
      count: 0,
      episodes_by_date: [],
      loading: true,
      EMITTERS,
      HISTORY_TITLE: 'Watch history',
      NONE_SECONDARY: 'Select a video you’d like to watch on the Watch Page',
      HISTORY_NONE: "You haven't watched any videos",
      WATCH_PAGE_LINK: 'Watch Page',
      watch_page: window.location.origin,
    }
  },
  watch: {
    $route(to, from) {},
  },
  created() {
    this.fetchEpisodes()
  },
  methods: {
    deleteEpisode(obj) {
      const episodesWithDate = find(this.episodes_by_date, (episodes) => {
        const deletedEpisode = find(
          episodes.episodes,
          (episode) => episode.id === obj.deletedEpisode.id
        )
        return !!deletedEpisode
      })
      if (episodesWithDate)
        episodesWithDate.episodes = episodesWithDate.episodes.filter(
          (episode) => episode.id !== obj.deletedEpisode.id
        )

      if (episodesWithDate.episodes.length === 0) {
        remove(
          this.episodes_by_date,
          (element) => element.date === episodesWithDate.date
        )
      }
      this.emitter.emit(EMITTERS.SHOW_NOTIFICATION_EMITTER, {
        message: WATCH_HISTORY_EPISODE_DELETED,
      })
      this.count--
    },
    fetchEpisodes() {
      ApiService.getHistory(this.optional_params)
        .then((response) => {
          const responseData = response.data

          this.episodes_by_date = responseData.episodes_by_date
          this.count = responseData.count
          this.watch_page = responseData.watch_page
        })
        .catch((e) => {
          this.errors.push(e)
          console.error(this.errors)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../shared/assets/styles/global';

.episodes-block {
  width: 41.75rem;
  height: 21.625rem;
  left: 32.125rem;
  top: 18.438rem;
  background: #ffffff;
  box-shadow: 0 0.25rem 2.5rem rgba(167, 167, 167, 0.15);
  border-radius: 0.75rem;
  padding: 6.563rem 4.875rem 6.563rem 4.875rem;
  text-align: center;
  margin-right: 3.2rem;
}

.main-block {
  width: 770px;
  padding: 0 16px 0 32px;
  margin-bottom: 72px;
  display: flex;
  flex-direction: column;
}

.notes-title-row {
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13px;
  margin-right: 48px;

  .notes-title {
    height: 42px;
    vertical-align: middle;
    display: flex;
  }

  .title-text {
    font-size: 24px;
    color: #323232;
  }

  .counter {
    font-size: 14px;
    margin: auto 4px;
    color: #a6a6a6;
  }
}

.episodes-placeholder {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  justify-content: center;
  height: 624px;
  .episodes-block {
    overflow: hidden;
  }
  .placeholder-border {
    position: relative;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -6.563rem;
      left: -4.875rem;
      width: 41.75rem;
      height: 4px;
      background: #d18f42;
    }
  }
}

.placeholder-button {
  height: 56px;
  width: 300px;
}

.episodes_date {
  font-size: 16px;
  margin-left: -16px;
  margin-bottom: 10px;
  color: #8f8a9b;
  margin-top: 15px;
  &:first-of-type {
    margin-top: 0;
  }
}

.primary-placeholder-title {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0;
  text-align: center;
  color: #5c5c5c;
}

.secondary-placeholder-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0;
  text-align: center;
  color: #323232;
  margin-bottom: 20px;
  margin-top: 12px;
}
</style>
