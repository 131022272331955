import { emailConfirmationAlert } from './profile-shared'

document.addEventListener('DOMContentLoaded', () => {
  const $sideBarBtns = $('.profile_sidebar .nav-link')

  $sideBarBtns.on('click', (e) => {
    if (!$sideBarBtns.hasClass('prevented')) {
      $sideBarBtns.removeClass('active')
      $(e.target).closest('.nav-link').addClass('active')
    }
  })

  resolveTooltipState(
    '.dashboard-bookmark-tooltip',
    '.dashboard-bookmark-tooltip__close, .profile_sidebar .nav-link',
    'dashboardTooltipClosedAt'
  )

  emailConfirmationAlert($('.ec-icon-show'), $('.ec-popup-show'))
})

$(document).on('template:profile_show:load', function () {
  emailConfirmationAlert($('.ec-icon-show'), $('.ec-popup-show'))
})

$(document).on('turbolinks:load', function () {
  resolveTooltipState(
    '.dashboard-bookmark-tooltip',
    '.dashboard-bookmark-tooltip__close, .profile_sidebar .nav-link',
    'dashboardTooltipClosedAt'
  )
})

function resolveTooltipState(blockSelector, closeButtonSelector, storageKey) {
  const closeActions = $(closeButtonSelector)
  const block = $(blockSelector)
  if (!localStorage.getItem(storageKey)) {
    block.addClass('active')
  }

  closeActions.on('click', () => {
    block.css('visibility', 'hidden')
    const closedAt = new Date().getTime()
    localStorage.setItem(storageKey, closedAt)
  })
}
