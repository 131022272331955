<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M8 16C5.76667 16 3.875 15.225 2.325 13.675C0.775 12.125 0 10.2333 0 8C0 5.76667 0.775 3.875 2.325 2.325C3.875 0.775 5.76667 0 8 0C9.15 0 10.25 0.2375 11.3 0.7125C12.35 1.1875 13.25 1.86667 14 2.75V1C14 0.716667 14.0958 0.479167 14.2875 0.2875C14.4792 0.0958333 14.7167 0 15 0C15.2833 0 15.5208 0.0958333 15.7125 0.2875C15.9042 0.479167 16 0.716667 16 1V6C16 6.28333 15.9042 6.52083 15.7125 6.7125C15.5208 6.90417 15.2833 7 15 7H10C9.71667 7 9.47917 6.90417 9.2875 6.7125C9.09583 6.52083 9 6.28333 9 6C9 5.71667 9.09583 5.47917 9.2875 5.2875C9.47917 5.09583 9.71667 5 10 5H13.2C12.6667 4.06667 11.9375 3.33333 11.0125 2.8C10.0875 2.26667 9.08333 2 8 2C6.33333 2 4.91667 2.58333 3.75 3.75C2.58333 4.91667 2 6.33333 2 8C2 9.66667 2.58333 11.0833 3.75 12.25C4.91667 13.4167 6.33333 14 8 14C9.13333 14 10.1708 13.7125 11.1125 13.1375C12.0542 12.5625 12.7833 11.7917 13.3 10.825C13.4333 10.5917 13.6208 10.4292 13.8625 10.3375C14.1042 10.2458 14.35 10.2417 14.6 10.325C14.8667 10.4083 15.0583 10.5833 15.175 10.85C15.2917 11.1167 15.2833 11.3667 15.15 11.6C14.4667 12.9333 13.4917 14 12.225 14.8C10.9583 15.6 9.55 16 8 16Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'ReloadIcon',
}
</script>

<style scoped>
svg {
  cursor: pointer;
}
</style>
