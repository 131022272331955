<template>
  <div class="message-app-wrapper">
    <div v-if="!loading" class="message-app-wrapper-main">
      <div v-if="notifications && notifications.length">
        <Messages :messages="notifications" :show-date-title="true" />
      </div>
      <div v-else>
        <NoMessages></NoMessages>
      </div>
    </div>

    <div v-else class="message-loader">
      <Loader />
    </div>
  </div>
</template>

<script>
import Messages from './components/Messages'
import NoMessages from './components/NoMessages'
import ApiService from './services/messagesApi.service'
import Loader from '../shared/components/Loader'

export default {
  name: 'MessageCenterAppComponent',
  components: {
    NoMessages,
    Messages,
    Loader,
  },
  data() {
    return {
      notifications: [],
      loading: true,
    }
  },
  mounted() {
    this.$cable.subscribe({ channel: 'MessageCenterChannel' })
    this.renderMessages()
  },
  channels: {
    MessageCenterChannel: {
      received(data) {
        if (!data.message_read) this.notifications.unshift(data.notification)
      },
    },
  },
  methods: {
    renderMessages() {
      ApiService.getAllMessages()
        .then((response) => {
          const responseData = response.data
          this.loading = true
          this.notifications = responseData.notifications
        })
        .catch((e) => {
          this.errors.push(e)
          console.error(this.errors)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../shared/assets/styles/global';
.message-app-wrapper {
  height: 83vh;
  overflow: scroll;
}
.message-loader img {
  width: 42px;
  margin-left: 0;
}
</style>
