<template>
  <FlashNotification
    custom-class="admin"
    site="profile"
    :theme="'light'"
  />
  <div class="main-block-wrapper">
    <div class="main-block">
      <div class="transcripts__sidebar">
        Transcripts
      </div>
      <div class="transcripts__container">
        <Transcripts />
      </div>
    </div>
  </div>
</template>

<script>
import { VIDEO_RESOURCES_ADMIN_ROUTES } from '../../shared/utils/routes'
import {EMITTERS} from "../../shared/utils/emitters"
import Transcripts from "./components/Transcripts.vue"
import FlashNotification from "../../notification/components/FlashNotification.vue"

export default {
  name: 'VideoResourcesAppComponent',
  components: {
    Transcripts,
    FlashNotification,
  },
  data() {
    return {
      VIDEO_RESOURCES_ADMIN_ROUTES,
      EMITTERS,
      episodeId: this.optional_params.episode_id,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../shared/assets/styles/global';

.main-block-wrapper {
  position: relative;
  width: 100%;
  font-family: 'DM Sans', sans-serif;
}

.main-block {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}

.transcripts__sidebar {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 17%;
  padding-right: 16px;
  gap: 8px;
}

.transcripts__container {
  width: 83%;
  display: flex;
  flex-direction: column;
}
.transcripts__tab {
  display: flex;
  a {
    padding: 6px 12px;
    border-radius: 3px;
    color: #323232;
    text-decoration: none;

    &.router-link-active {
      background: #414042;
      color: #ffffff;
    }
  }
}

.transcripts__title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #323232;
}
</style>
