import Flickity from 'vendor/flickity'
import { collapsibleText } from 'watch/collapsible_text'

$(document).on('turbolinks:load', () => {
  const $watchSeriesBanners = $('.watch_series_banner')
  if ($watchSeriesBanners.length === 0) return

  $watchSeriesBanners.each((_idx, element) => {
    const $watchSeriesBanner = $(element)
    const $slider = $watchSeriesBanner.find('.slider')
    const $autoscrollBanner = $('#autoscroll-banner')

    // Setup collapsible texts when slider is initialized
    $slider.on('ready.flickity', () => {
      const slider = $slider[0]

      collapsibleText(slider)
      // We need to resize the slider because text container shrinks in height
      // after applying collapsible text logic.
      Flickity.data(slider).resize()
    })

    // Different slides height on IE11 fix.
    // We use "settle.flickity" event instead of "ready.flickity" because
    // the "ready" is fired when the slider is in inconsistent state,
    // for example, when the slides haven't received their final height.
    $slider.on('settle.flickity', () => {
      const $contents = $slider.find('.content_wrapper')
      const contentHeights = $contents
        .map((_idx, element) => $(element).outerHeight())
        .get() // convert the result into array

      const allContentsSameHeight = contentHeights.every(
        (el) => el === contentHeights[0]
      )

      // If all contents have same height then we don't need the fix.
      // Basically it means we are not on IE and everything should work nicely.
      if (allContentsSameHeight) return

      const maxHeight = Math.max.apply(null, contentHeights) // max of the array
      $contents.each((_idx, element) => {
        // Using "min-height" to allow a slide to grow in height when
        // collapsible text has been opened.
        $(element).css('min-height', maxHeight)
      })
    })

    // Resize the slider when collapsible text has been shown, hidden.
    $watchSeriesBanner.on(
      'shown.bs.collapse hidden.bs.collapse',
      '.collapsible-text',
      () => Flickity.data($slider[0]).resize()
    )

    $slider.on('change.flickity', () => {
      if ($autoscrollBanner.is(':checked')) {
        Flickity.data($slider[0]).player.play()
      }
    })

    $autoscrollBanner.on('change', (event) => {
      if ($(event.target).is(':checked')) {
        Flickity.data($slider[0]).player.play()
      } else {
        Flickity.data($slider[0]).player.stop()
      }
    })
  })
})
