import axios from 'axios'

import {
  API_ROUTES,
  FILE_UPLOAD_CONFIG,
  config,
} from '../../../shared/utils/api'

export default {
  DEFAULT_ORDER: 'desc',

  getAllGuides(optional_params = {}) {
    return axios.request({
      ...FILE_UPLOAD_CONFIG,
      method: 'GET',
      url: API_ROUTES.guides(optional_params.episode_id),
    })
  },

  async createGuide(guide, optional_params, onProgress) {
    return new Promise(async (resolve, reject) => {
      await axios
        .post(
          API_ROUTES.guides(optional_params.episode_id),
          this.formData(guide),
          config(onProgress)
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error))
    })
  },

  deleteGuide(guide, optional_params = {}) {
    return axios.request({
      ...FILE_UPLOAD_CONFIG,
      method: 'DELETE',
      url: API_ROUTES.guide(optional_params.episode_id, guide.id),
    })
  },

  async updateGuide(guide, optional_params = {}, onProgress) {
    return new Promise(async (resolve, reject) => {
      await axios
        .put(
          API_ROUTES.guide(optional_params.episode_id, guide.id),
          this.formData(guide),
          config(onProgress)
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error))
    })
  },

  formData(guide) {
    const formData = new FormData()

    if (guide.document && guide.document.size !== undefined) {
      const guideName = guide.title + '.' + guide.document.name.split('.').pop()
      formData.append('document', guide.document, guideName)
    }
    formData.append('title', guide.title)
    formData.append('description', guide.description)

    return formData
  },
}
