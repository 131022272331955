import { totalNavbarHeight } from 'theme/navbar'

const scrollToTarget = (scrollTargetSelector) => {
  const $scrollTarget = $(scrollTargetSelector)

  window.scrollTo({
    top: $scrollTarget.offset().top - totalNavbarHeight(),
    behavior: 'smooth',
  })
}

export { scrollToTarget }
