<template>
  <div>
    <FlashNotification site="profile" :theme="'light'" />
    <div class="row mt-8">
      <div
        v-if="!optional_params.headless"
        class="col-auto mr-auto schedule__title-wrapper"
      >
        <span class="schedule__title">
          {{ title }}
        </span>
      </div>
      <div class="col-auto import-schedule__wrapper">
        <ImportSchedule />
      </div>
    </div>
    <div class="schedule-controls">
      <TimezoneSelector :timezone="selectedTimezone" />
      <div class="schedule__buttons-container">
        <DateSelector
          :selected-date="selectedDate"
          :timezone="selectedTimezone"
          :available-dates="availableDates"
        />
        <PrintSchedule
          :selected-date="selectedDate"
          :timezone="selectedTimezone"
          :available-dates="availableDates"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-12 pl-0 pr-0 pl-md-3 pr-md-3">
        <HorizontalCalendar
          :selected-date="selectedDate"
          :available-dates="availableDates"
          :timezone="selectedTimezone"
        ></HorizontalCalendar>
      </div>
    </div>

    <div class="row mb-12">
      <div class="col-12 pl-0 pr-0 pl-md-3 pr-md-3">
        <Programs :selected-date="selectedDate" :timezone="selectedTimezone" />
      </div>
    </div>
  </div>
</template>

<script>
import ImportSchedule from './components/ImportSchedule'
import PrintSchedule from './components/PrintSchedule'
import DateSelector from './components/DateSelector'
import Programs from './components/Programs'
import { EMITTERS } from '../shared/utils/emitters'
import FlashNotification from '../notification/components/FlashNotification'
import TimezoneSelector from './components/TimezoneSelector'
import { getCurrentTimeZone } from './utils/time_utils'
import programsService from './services/programsApi.service'
import HorizontalCalendar from '../shared/components/HorizontalCalendar'

export default {
  name: 'ScheduleApp',
  components: {
    TimezoneSelector,
    FlashNotification,
    Programs,
    DateSelector,
    PrintSchedule,
    ImportSchedule,
    HorizontalCalendar,
  },
  data() {
    return {
      selectedDate: new Date(),
      availableDates: [],
      minDate: null,
      maxDate: null,
      title: 'ADTV Schedule',
      possibleDaysBack: 60,
      selectedTimezone: getCurrentTimeZone(),
    }
  },
  mounted() {
    this.fetchDates()

    this.emitter.on(EMITTERS.DATE_PICKED, (date) => {
      this.selectedDate = date
    })

    this.emitter.on(EMITTERS.TIMEZONE_SELECTED, (timezone) => {
      this.selectedTimezone = timezone
      this.fetchDates()
    })
  },
  methods: {
    fetchDates() {
      programsService
        .fetchAvailableDates(this.selectedTimezone)
        .then((response) => {
          const { dates, min, max } = response.data

          this.availableDates = dates.sort()
          this.minDate = min
          this.maxDate = max
        })
    },
  },
}
</script>

<style lang="scss">
.schedule__title {
  font-family: 'DM Sans', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  color: #323232;
}

.schedule__title-wrapper {
  margin-bottom: 32px;
  @media (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }
}

.schedule-controls {
  display: flex;
  justify-content: space-between;
  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }
}

.timezone-selector,
.schedule__buttons-container {
  @media (max-width: 1024px) {
    margin: 0 auto;
  }
}
.import-schedule__wrapper {
  @media (max-width: 768px) {
    display: none;
  }
}
.schedule__buttons-container {
  display: flex;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }
}
</style>
