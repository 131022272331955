<template>
  <div class="modal-b" @click="closeModal">
    <div class="modal-c" @click.stop>
      <div class="video-resources__header">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click="closeModal"
        >
          <path
            d="M15 6L9 12L15 18"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div class="video-resources__title">
          {{ $vueTranslate('admin.image_modal.title') }}
        </div>
      </div>
      <div class="image-content">
        <div class="modal-image-wrapper">
          <img :src="image.url" />
        </div>
        <div class="modal-text-wrapper">
          <CloseIcon theme="dark" @click="closeModal" />
          <div
            class="image-description"
            :style="descriptionStyles"
            v-html="formattedText"
          ></div>
          <div class="image-source">
            {{ image.copyright }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EMITTERS } from '../../../shared/utils/emitters'
import { CloseIcon } from '../../../shared/components/icons'

export default {
  name: 'ImageModalComponent',
  components: {
    CloseIcon,
  },
  props: {
    image: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      message: '',
      MODAL_MAX_HEIGHT: 750,
      TEXT_PADDINGS: 50,
      URL_REGEX: /(https?:\/\/[^\s]+)/g,
      MAX_LINK_LENGTH: 28,
    }
  },
  computed: {
    formattedText() {
      let description = this.image.description
      const self = this

      description = description.replace(this.URL_REGEX, function (url) {
        let text = url
        if (url.length > self.MAX_LINK_LENGTH) {
          text = text.substring(0, self.MAX_LINK_LENGTH)
          text += '...'
        }
        return '<a href="' + url + '" target="_blank">' + text + '</a>'
      })

      return description
    },
    descriptionStyles() {
      let maxHeight = this.image.image.image_file_height
      maxHeight =
        maxHeight > this.MODAL_MAX_HEIGHT ? this.MODAL_MAX_HEIGHT : maxHeight
      return {
        'max-height': maxHeight - this.TEXT_PADDINGS + 'px',
      }
    },
  },
  methods: {
    closeModal() {
      this.$emit(EMITTERS.VIDEO_RESOURCES_CLOSE_IMAGE_MODAL_EMITTER)
    },
  },
}
</script>

<style lang="scss" scoped>
.video-resources__header {
  display: none;
}
.modal-b {
  display: flex;
  position: fixed;
  z-index: 3001;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(28, 25, 35, 0.8);

  .modal-c {
    background: #32303c;
    box-shadow: 0 4px 30px rgba(23, 24, 28, 0.6);
    border-radius: 8px;
    display: inline-block;
  }

  .image-content {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  .modal-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    img {
      max-width: 900px;
      max-width: calc(100vw - 350px);
      max-height: 750px;
      border-radius: 8px 0 0 8px;
    }
  }

  .modal-text-wrapper {
    width: 275px;
    padding-bottom: 25px;
  }

  .image-description {
    color: #ffffff;
    font-family: 'DM Sans';
    clear: both;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-left: 15px;
    padding-right: 35px;

    &::-webkit-scrollbar {
      background-color: transparent;
      width: 3px;

      &:hover {
        width: 10px;
      }
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #a6a6a6;
      border-radius: 0.25rem;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }
  }

  .image-source {
    margin-top: 20px;
    color: var(--text-disabled, #d1d3d4);
    font-family: DM Sans, serif;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 18px;
    padding-left: 15px;
    padding-right: 35px;
  }
}

.modal-text-wrapper svg {
  float: right;
  margin-right: 10px;
  margin-top: 10px;
}

@media (max-width: 600px) {
  .modal-c {
    height: calc(100vh - constant(safe-area-inset-top));
  }
}

@media (max-width: 991px) {
  .video-resources__header {
    padding: 15px;
    display: flex;
    background: #1c1923;
    .video-resources__title {
      justify-self: center;
      margin: auto;
    }
  }
  .modal-text-wrapper svg {
    display: none;
  }
  .modal-c {
    border-radius: 0;
    width: 100vw;
    height: 100vh;
  }
  .image-content {
    flex-direction: column;
    padding-bottom: 20px;
    max-height: calc(100% - 60px);
    overflow-y: scroll;
  }
  .modal-b {
    .modal-image-wrapper {
      display: block;
      overflow: unset;
      img {
        width: 100vw;
        height: auto;
        max-width: unset;
        border-radius: 0;
      }
    }
    .image-description {
      width: 100vw;
      max-width: unset;
      margin-top: 10px;
    }
  }
}
</style>
