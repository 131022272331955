import axios from 'axios'

import { API_ROUTES, API_URL, FORM_HEADERS } from '../../shared/utils/api'

export default {
  getPrograms(scheduled_date, timezone) {
    const config = {
      baseURL: API_URL,
      headers: FORM_HEADERS,
      params: {
        scheduled_date,
        timezone,
      },
    }
    return axios.get(API_ROUTES.programs, config)
  },
  importPrograms(formData) {
    return axios.post(API_ROUTES.programs, formData, {
      baseURL: API_URL,
      headers: FORM_HEADERS,
    })
  },
  fetchAvailableDates(timezone) {
    const config = {
      baseURL: API_URL,
      headers: FORM_HEADERS,
      params: {
        timezone,
      },
    }
    return axios.get(API_ROUTES.programsDates, config)
  },
}
