import objectFitImages from 'object-fit-images'

const imgSelector = 'img'

document.addEventListener('turbolinks:load', () => {
  // No "auto mode": using the explicit selector
  // to avoid object-fit-images adding a 'load' event handler under the hood
  objectFitImages(imgSelector, { watchMQ: true })
})

document.addEventListener('turbolinks:before-cache', () => {
  // cleanup
  $(imgSelector).each((_index, img) => {
    $(img).replaceWith(
      `<img class=${img.getAttribute('class')} src=${img.src} width=${
        img.width
      } height=${img.height} />`
    )
  })
})
