<template>
  <router-link
    :to="{
      name: `${ROUTE_PREFIX}${tabName}`,
      query: { query: query },
    }"
    class="search-nav__item"
    :class="{
      'search-nav__item-light': isLight,
      'search-nav__item-active': isActive(),
    }"
  >
    {{ getTabName() }}
  </router-link>
</template>

<script>
import { SEARCH_TABS } from '../../utils/name_util'

export default {
  name: 'NavItem',
  props: {
    isLight: {
      required: true,
      default: false,
      type: Boolean,
    },
    tabName: {
      type: String,
      default: 'videos',
      required: true,
    },
    query: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {
      ROUTE_PREFIX: 'search-',
    }
  },
  methods: {
    getTabName() {
      return SEARCH_TABS[this.tabName]
    },
    isActive() {
      return this.$route.name === `${this.ROUTE_PREFIX}${this.tabName}`
    },
  },
}
</script>

<style scoped>
.search-nav__item {
  display: flex;
  align-items: center;
  height: 48px;
  padding: 4px 25px 0 25px;
  color: #fff;
  text-align: center;
  font-family: DM Sans, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.search-nav__item-light {
  color: #323232;
}

.search-nav__item-active {
  border-bottom: 1px #6998ae solid;
}
</style>
