<template>
  <div class="store-item-card-block" @click="preview">
    <div class="store-item-card-link" :title="item.title">
      <img
        class="thumbnail"
        :alt="item.title"
        :class="{ 'no-image': isThumbnailEmpty() }"
        :src="isThumbnailEmpty() ? NO_IMAGE : item.thumbnail_url"
        @error="handleImageError"
      />
      <span
        class="store-item-card-title"
        :class="{ 'store-item-card-title__light': isLight }"
      >
        {{ item.title }}
      </span>
    </div>
    <span
      v-if="item.author"
      class="card-description"
      :class="{ 'store-item-card-description__light': isLight }"
    >
      {{ item.author }}
    </span>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'

import { EMITTERS } from '../../shared/utils/emitters'

export default {
  name: 'StoreItemCard',
  props: {
    item: {
      type: Object,
      default: () => ({
        title: '',
        author: '',
        description: '',
        thumbnail_url: '',
        usd_store_name: '',
        usd_store_link: '',
        cad_store_name: '',
        cad_store_link: '',
      }),
      required: true,
    },
    isLight: {
      required: true,
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      NO_IMAGE: window.NO_IMAGE_FALLBACK,
    }
  },
  methods: {
    isThumbnailEmpty() {
      return isEmpty(this.item.thumbnail_url)
    },
    preview() {
      this.emitter.emit(EMITTERS.STORE_ITEM_PREVIEW, this.item)
    },
    handleImageError(event) {
      event.target.src = this.NO_IMAGE
      event.target.style.objectFit = 'contain'
    },
  },
}
</script>

<style lang="scss" scoped>
.store-item-card-block {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  max-width: 240px;
  flex: 1;

  .thumbnail {
    height: 140px;
    width: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;', serif;
    margin-bottom: 8px;
  }
}

.store-item-card-title {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  font-family: 'DM Sans', sans-serif !important;
  width: 100%;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.store-item-card-title__light {
  color: #323232;
}

.card-description {
  margin-top: 4px;
  color: #a6a6a6;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.01em;
  font-family: 'DM Sans', sans-serif !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.store-item-card-description__light {
  color: #5c5c5c;
}

.store-item-card-link {
  color: inherit;
  white-space: break-spaces;
  line-height: 0;
}

.no-image {
  object-fit: contain !important;
}
</style>
