<template>
  <div class="recent-activities-wrapper">
    <h2 class="title study-tools-title-area">
      <span class="title-text">
        {{ $vueTranslate('study_tools.recent_activities.title') }}
      </span>
    </h2>

    <div class="recent-activities-container">
      <ul v-if="recent_activities && recent_activities.length" />
      <div v-else class="recent-activities-placeholder">
        <div class="child">
          {{ $vueTranslate('study_tools.recent_activities.no_activities') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecentActivitiesComponent',
  data() {
    return {
      recent_activities: [],
      count: 0,
      errors: [],
    }
  },
  created() {},
}
</script>

<style lang="scss" scoped>
@import '../../shared/assets/styles/global';

h2.title {
  font-weight: 500;
  line-height: 1.6;
  display: flex;
  align-items: center;

  .title-text {
    font-size: 20px;
    color: $white;
  }
}
.recent-activities-container {
  background-color: #2b2834;
  border-radius: 8px;
  margin-bottom: 30px;
  height: 300px;
}

.recent-activities-placeholder {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.child {
  font-size: 16px;
  font-weight: 400;
  color: #8f8a9b;
}
</style>
