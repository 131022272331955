<template>
  <div v-if="storeItems.length > 0">
    <div class="info-block">
      <span class="title">
        {{ `${$vueTranslate('store_items.preview_store_item.header')}s` }}
      </span>
      <div v-if="total_count > 4" class="link" @click="goToStoreItems()">
        {{ `${$vueTranslate('store_items.preview_store_item.view_all')} >` }}
      </div>
    </div>
    <EntriesCarousel
      :items="storeItems"
      :total-count="total_count"
      @[EMITTERS.STORE_ITEM_PREVIEW_ALL]="goToStoreItems"
    />
    <PreviewStoreItem
      v-if="showPreview"
      :theme="theme"
      :entity="storeItem"
      class="store-item-preview"
      @[EMITTERS.CLOSE_DIALOG_EMITTER]="closePreview"
    />
  </div>
</template>

<script>
import EntriesCarousel from './components/EntriesCarousel'
import PreviewStoreItem from './components/PreviewStoreItem'
import { THEMES } from '../shared/utils/view_options'
import { EMITTERS } from '../shared/utils/emitters'
import storeItemsApiService from './services/storeItemsApi.service'

export default {
  name: 'App',
  components: { PreviewStoreItem, EntriesCarousel },
  data() {
    return {
      EMITTERS,
      showPreview: false,
      theme: THEMES.LIGHT,
      storeItem: {},
      storeItems: [],
      total_count: 0,
    }
  },
  mounted() {
    this.emitter.on(EMITTERS.STORE_ITEM_PREVIEW, this.openPreview)
    this.fetchItems()
  },
  methods: {
    goToStoreItems() {
      const currentPath = window.location.pathname
      window.location.href = `${currentPath.replace(
        '/ebooks',
        '/read'
      )}/store_items`
    },
    fetchItems() {
      storeItemsApiService.fetch(this.optional_params).then((response) => {
        this.storeItems = response.data.entries
        this.total_count = response.data.total_count
      })
    },
    openPreview(item) {
      this.storeItem = item
      this.showPreview = true
    },
    closePreview() {
      this.storeItem = {}
      this.showPreview = false
    },
  },
}
</script>

<style scoped lang="scss">
.info-block {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;

  .title {
    font-family: DM Sans, serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    color: #323232;
  }

  .link {
    font-family: DM Sans, serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #a6a6a6;
    cursor: pointer;
  }
}
</style>
