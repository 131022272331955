// TODO: extract this logic into "critical JS" (separate pack)
//   because the navbar is the top-most rendered section
//   and it should be interactive from the start
//   https://coda.io/d/Amazing-Discoveries-shared_daD69i5U8P9/Tech-debt-sink_suy3x#Tech-debt-tasks_tuJWy/r89
// TODO: replace CSS classes in selectors with JS-oriented class names
// TODO: separate code handing top navbar for the Main and Watch site: it could be much simpler
// for Watch plus separate the #top_navbar selectors

/// Navbar ///

const TOP_NAVBAR_SELECTOR = '#top_navbar'

function makeNavbarFilled(navbar) {
  navbar.classList.remove('navbar-dark')
  navbar.classList.add('navbar-light')
}

function makeNavbarTransparent(navbar) {
  navbar.classList.remove('navbar-light')
  navbar.classList.add('navbar-dark')
}

function getScrollTop() {
  return window.pageYOffset
}

function getNavbars() {
  return document.querySelectorAll('.navbar')
}

function getNavbarProperties(navbarElement) {
  return {
    isCollapsed: navbarElement
      .querySelector('.navbar-collapse')
      .classList.contains('show'),
    isLight: navbarElement.classList.contains('navbar-light'),
    isTogglable: navbarElement.classList.contains('navbar-togglable'),
  }
}

function toggleOnWindowEvent(navbar) {
  const scrollTop = getScrollTop()
  const { isTogglable, isCollapsed } = getNavbarProperties(navbar)

  if (isTogglable && !isCollapsed) {
    scrollTop > 0 ? makeNavbarFilled(navbar) : makeNavbarTransparent(navbar)
  }
}

function toggleOnCollapseEvent(e, navbar) {
  const scrollTop = getScrollTop()
  const { isTogglable, isLight } = getNavbarProperties(navbar)

  if (!isTogglable) return

  if (e.type === 'show') {
    makeNavbarFilled(navbar)
  }

  if (
    e.type === 'hidden' &&
    ((!isTogglable && !isLight) || (isTogglable && !scrollTop))
  ) {
    makeNavbarTransparent(navbar)
  }
}

function addBodyTopNavbarOffset() {
  const navbar = document.querySelector(TOP_NAVBAR_SELECTOR)

  if (!navbar) return

  const body = document.querySelector('body')
  const { isTogglable } = getNavbarProperties(navbar)

  if (isTogglable) {
    body.style.paddingTop = `${announcementHeight()}px`
  } else {
    body.style.paddingTop = `${totalNavbarHeight()}px`
  }
}

function clearBodyTopOffset() {
  document.querySelector('body').style.paddingTop = `0px`
}

const totalNavbarHeight = () => {
  return $(`${TOP_NAVBAR_SELECTOR}`).outerHeight()
}

function bindNavbarCollapsingEventHandlers() {
  window.addEventListener('scroll', () => {
    getNavbars().forEach((navbar) => {
      toggleOnWindowEvent(navbar)
    })
  })

  document.addEventListener('turbolinks:load', () => {
    getNavbars().forEach((navbar) => {
      toggleOnWindowEvent(navbar)
    })

    addBodyTopNavbarOffset()
  })

  $(document).on(
    'show.bs.collapse hidden.bs.collapse',
    '.navbar-collapse',
    (e) => {
      getNavbars().forEach((navbar) => {
        toggleOnCollapseEvent(e, navbar)
      })
    }
  )

  document.addEventListener('turbolinks:before-cache', () => {
    // collapse menus before Turbolinks caching because
    // they will appear open upon "back" navigation otherwise
    getNavbars().forEach((navbar) => {
      const collapse = navbar.querySelector('.navbar-collapse')
      $(collapse).collapse('hide')
    })
  })
}

/// Announcements ///

const announcementHeight = () =>
  $(`${TOP_NAVBAR_SELECTOR} .announcement_container`).outerHeight()

const bindAnnouncementsEventHanders = () => {
  // Bootstrap `close.bs.alert` and `closed.bs.alert` events don't work
  $(document).on('click', '.announcement_alert .close', function () {
    addBodyTopNavbarOffset() // recalculate body top navbar offset upon announcement dismissal

    const id = this.parentNode.dataset.announcementId // we need parent node (alert itself)
    $.post(Routes.dismiss_api_announcement_path(id))
  })

  $(document).on('turbolinks:before-cache', () => {
    if (document.querySelectorAll('.profile-page').length > 0) return
    // removing announcement to avoid it being rendered from cache and then disappearing when navigating back
    clearBodyTopOffset()
    $('.announcement_container').remove()
  })
}

/// Common functionality ///

function bindNavbarEventHandlers() {
  bindNavbarCollapsingEventHandlers()
  bindAnnouncementsEventHanders()
}

export { bindNavbarEventHandlers, totalNavbarHeight }
