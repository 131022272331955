import axios from 'axios'

import { API_ROUTES, BASE_API_CONFIG } from '../../../shared/utils/api'

export default {
  DEFAULT_ORDER: 'desc',

  getAllImages(optional_params = {}) {
    return axios.request({
      ...BASE_API_CONFIG,
      method: 'GET',
      url: API_ROUTES.images(optional_params.episode_id),
    })
  },

  createImage(image, optional_params = {}) {
    const data = {
      image: image,
    }

    return axios.request({
      ...BASE_API_CONFIG,
      method: 'POST',
      url: API_ROUTES.images(optional_params.episode_id),
      data,
    })
  },

  deleteImage(image, optional_params = {}) {
    return axios.request({
      ...BASE_API_CONFIG,
      method: 'DELETE',
      url: API_ROUTES.image(optional_params.episode_id, image.id),
    })
  },

  updateImage(image, optional_params = {}) {
    return axios.request({
      ...BASE_API_CONFIG,
      method: 'PUT',
      url: API_ROUTES.image(optional_params.episode_id, image.id),
      data: {
        image,
      },
    })
  },
}
