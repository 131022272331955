$(document).on('turbolinks:load page:change', () => {
  initTabs()
})

function initTabs() {
  $('.custom-tabs .tab-item').click((event) => {
    const tab = $(event.target)
    const container = tab.parent().parent()

    container.find('.tab-item').removeClass('active')
    container.find('.tab-content').removeClass('active')

    const selectedTab = container.find(`.tab-contents #${tab.attr('tab')}`)
    selectedTab.addClass('active')
    tab.addClass('active')
  })
}
