<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="feather feather-x-twitter"
  >
    <path
      d="M0.0689481 1.05455C0.0591121 1.0693 0.855833 2.15619 1.83452 3.46438C2.81321 4.77258 4.89354 7.55619 6.45747 9.64635L9.3001 13.448L9.11813 13.6545C9.01485 13.7677 6.92469 16.025 4.47551 18.6709C2.02632 21.3218 0.0148498 23.5054 0.00993176 23.53C9.56934e-05 23.5545 0.427965 23.5742 1.04272 23.5693L2.09026 23.5644L2.43452 23.1857C2.62633 22.9791 4.40665 21.0562 6.39354 18.907C8.38042 16.7578 10.0624 14.9382 10.1312 14.8595L10.2542 14.7168L11.2378 16.0349C11.7788 16.7578 13.2689 18.7545 14.5476 20.466L16.8788 23.5742H20.4444C23.4099 23.5742 24.005 23.5644 23.9853 23.5103C23.9706 23.4709 23.0657 22.2513 21.9739 20.7906C20.8821 19.3349 19.6329 17.6627 19.2001 17.0824C18.7624 16.5021 17.4788 14.7759 16.3378 13.2562C15.1968 11.7316 14.2624 10.4627 14.2624 10.4431C14.2624 10.4086 20.2919 3.86274 22.3427 1.67422L22.928 1.04963H21.8509L20.7788 1.05455L19.5247 2.41192C18.8362 3.15946 17.1493 4.98897 15.7722 6.4693L13.2788 9.16438L10.2542 5.12176L7.22469 1.07422L3.65911 1.04963C1.69682 1.03487 0.0787842 1.03979 0.0689481 1.05455ZM8.51813 5.41192C9.6837 6.97586 11.4837 9.38078 12.5165 10.7627C13.5493 12.1398 15.8411 15.2086 17.6116 17.5742C19.3821 19.9447 20.8476 21.907 20.8722 21.9414C20.9017 21.9857 20.5526 22.0004 19.2788 22.0004H17.6509L14.4247 17.6824C6.97878 7.73324 3.22141 2.70701 3.17714 2.63324C3.14764 2.58897 3.48206 2.57422 4.76567 2.57422H6.39354L8.51813 5.41192Z"
      fill="#A6A6A6"
    />
  </svg>
</template>

<script>
export default {
  name: 'XTwitterIcon',
}
</script>

<style scoped></style>
