import { Quill } from '@vueup/vue-quill'

import { WrapperBlot, WrappedBlot } from './blot_wrapper'
import { QuoteContainer } from './quote'

function initCommentary() {
  let icons = Quill.import('ui/icons')

  icons['commentary'] =
    '<div class="icon-commentary" aria-hidden="true">Commentary</div>'

  class CommentaryContainer extends WrapperBlot {
    optimize(context) {
      super.optimize(context)
      this.clearTitle()
    }

    replace(target) {
      super.replace(target)
      this.clearTitle()
    }

    clearTitle() {
      while (
        this.domNode.getElementsByClassName('commentary-title').length > 1
      ) {
        const titles = this.domNode.getElementsByClassName('commentary-title')
        titles[titles.length - 1].remove()
      }
    }

    static create() {
      let node = super.create()
      let title = document.createElement('P')
      title.classList.add('commentary-title')
      title.innerText = 'Commentary'
      node.appendChild(title)

      return node
    }
  }
  CommentaryContainer.blotName = 'commentary'
  CommentaryContainer.tagName = 'aside'
  CommentaryContainer.defaultChild = 'commentary-paragraph'
  CommentaryContainer.className = 'ad-commentary'

  class CommentaryParagraph extends WrappedBlot {}
  CommentaryParagraph.blotName = 'commentary-paragraph'
  CommentaryParagraph.className = 'commentary-paragraph'
  CommentaryParagraph.tagName = 'p'
  CommentaryParagraph.parentName = 'commentary'

  class CommentaryTitle extends WrappedBlot {
    replaceWith() {}
  }

  CommentaryTitle.blotName = 'commentary-title'
  CommentaryTitle.className = 'commentary-title'
  CommentaryTitle.tagName = 'p'
  CommentaryTitle.parentName = 'commentary'

  CommentaryContainer.allowedChildren = [
    CommentaryParagraph,
    QuoteContainer,
    CommentaryTitle,
  ]
  CommentaryParagraph.requiredContainer = CommentaryContainer
  CommentaryTitle.requiredContainer = CommentaryContainer

  Quill.register(CommentaryContainer)
  Quill.register(CommentaryParagraph)
  Quill.register(CommentaryTitle)
}

export { initCommentary }
