import axios from 'axios'

import { API_ROUTES, BASE_API_CONFIG } from '../../shared/utils/api'

function getNewsId() {
  const news_ids = new URL(document.location.href).pathname.match(/[^/]+/g)
  return news_ids.slice(-1)
}

export default {
  getNewsContent(params = {}) {
    return axios.request({
      ...BASE_API_CONFIG,
      method: 'GET',
      url: API_ROUTES.newsContent(getNewsId()),
      params: params,
    })
  },
}
