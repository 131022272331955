export function emailConfirmationAlert(icon, popup) {
  icon.hover(
    function () {
      popup.show()
    },
    function () {
      popup.hide()
    }
  )
}
