<template>
  <div
    class="bookmark-wrapper"
    :class="[theme, { 'create-mode': isCreateMode }]"
  >
    <div class="bookmark">
      <div class="bookmark-main">
        <a
          class="bookmark-timestamp"
          :href="href"
          @click="handleTimestampClick"
        >
          {{ timestamp }}
        </a>

        <div class="bookmark-title-wrapper">
          <span
            v-if="isShowMode"
            class="bookmark-title"
            @click="handleTimestampClick"
          >
            {{ localBookmark.title }}
          </span>

          <textarea
            v-if="isModifyMode"
            ref="newBookmarkArea"
            v-model="localBookmark.title"
            type="text"
            wrap="hard"
            class="new-bookmark-area"
            :maxlength="BOOKMARK_TITLE_LIMIT"
            :placeholder="
              $vueTranslate('study_tools.bookmark.title_placeholder')
            "
            @keydown.enter.exact.prevent
            @keyup.enter.exact.prevent
            @keydown.enter.shift.exact.prevent
            @input="inputBookmarkTextarea"
            @focus="focusBookmarkTextarea"
          />
          <span
            v-if="isModifyMode && isCounterEnabled"
            class="bookmark-counter"
          >
            {{ localBookmark.title.length }}/{{ BOOKMARK_TITLE_LIMIT }}
            characters
          </span>

          <TutorialStep
            v-if="shouldShowTutorial"
            :step-positions="[2, 3, 9]"
            :step-number="tutorialStep"
            @[EMITTERS.CHANGE_TUTORIAL_STEP_EMITTER]="stepChanged"
          />
        </div>

        <div v-if="isShowMode" class="bookmark-actions">
          <div class="tags-area">
            <button
              v-if="localBookmark.tags && localBookmark.tags.length"
              type="button"
              :data-id="localBookmark.id"
              class="tags-button bookmark-action"
              @click="toggleTagBlock({ hide: false })"
            >
              {{ $vueTranslate('study_tools.bookmark.tags_title') }}
            </button>
            <Tags
              v-if="tagsOpen"
              :tags="localBookmark.tags"
              :theme="theme"
              @[EMITTERS.TOGGLE_TAG_BLOCK_EMITTER]="toggleTagBlock"
            />
          </div>

          <a class="bookmark-action edit-action">
            <EditIcon :theme="theme" @click="editMode" />
          </a>

          <a class="bookmark-action delete-action">
            <DeleteIcon :theme="theme" @click="openDialog" />
          </a>
        </div>

        <div v-if="isModifyMode" class="bookmark-edit-actions">
          <form @submit.prevent="submitForm()">
            <input v-model="localBookmark.timestamp_seconds" type="hidden" />
            <input v-model="localBookmark.title" type="hidden" />

            <button
              v-if="tagsOpen || isEditMode"
              class="bookmark__cancel-button"
              type="button"
              @submit.prevent
              @click="clearForm()"
            >
              {{ $vueTranslate('study_tools.bookmark.cancel_btn') }}
            </button>
            <button
              v-if="tagsOpen || isEditMode"
              class="bookmark__save-button"
              type="submit"
              :disabled="!localBookmark.title.length"
            >
              {{ $vueTranslate('study_tools.bookmark.save_btn') }}
            </button>

            <TutorialStep
              v-if="shouldShowTutorial"
              :step-positions="[7, 8, 10]"
              :step-number="tutorialStep"
              @[EMITTERS.CHANGE_TUTORIAL_STEP_EMITTER]="stepChanged"
            />
          </form>
        </div>
      </div>

      <div v-if="isModifyMode && tagsOpen" class="bookmark-edit-tags">
        <span class="bookmark-edit-tags__title">
          {{ $vueTranslate('study_tools.bookmark.tags_title') }}
        </span>
        <div
          v-for="tag of filterTags(localBookmark.tags)"
          :key="tag.name"
          class="bookmark-edit-tags__tag"
        >
          <span class="bookmark-edit-tags__tag-name">{{ tag.name }}</span>
          <CloseIcon
            class="bookmark-edit-tags__tag-remove"
            :theme="theme"
            @click="removeTag(tag)"
          />
        </div>

        <TagsSelect
          v-if="canAddMoreTags"
          :close-dropdown-trigger="closeDropdownTrigger"
          :tag="tagInput"
          :theme="theme"
          :attached-tags="localBookmark.tags"
          :has-same-tag="hasSameTag"
          @[EMITTERS.TAG_ADDED_EMITTER]="addTag"
          @[EMITTERS.CHANGE_TUTORIAL_STEP_EMITTER]="stepChanged"
        />
        <div v-else class="tags-limit-message">
          {{ $vueTranslate('study_tools.bookmark.tags_limit') }}
        </div>
        <TutorialStep
          v-if="shouldShowTutorial"
          :step-positions="[4, 5, 6]"
          :step-number="tutorialStep"
          @[EMITTERS.CHANGE_TUTORIAL_STEP_EMITTER]="stepChanged"
        />
      </div>
      <ConfirmationDialog
        v-if="showDialog"
        :theme="theme"
        :entity="bookmark"
        :confirmation-text="
          $vueTranslate('study_tools.bookmark.delete_confirmation_text')
        "
        :delete-function="BOOKMARK_DELETE_FUNCTION"
        :on-deleted-emitter="EMITTERS.BOOKMARK_DELETED_EMITTER"
        @[EMITTERS.CLOSE_DIALOG_EMITTER]="closeDialog"
        @[EMITTERS.BOOKMARK_DELETED_EMITTER]="bookmarkDeleted"
      />
    </div>
  </div>
</template>

<script>
import videojs from 'video.js'
import { cloneDeep, sortBy } from 'lodash'

import { CloseIcon, DeleteIcon, EditIcon } from '../../shared/components/icons'
import { EMITTERS } from '../../shared/utils/emitters'
import { VIDEO_PLAYER, VIDEO_PLAYER_ID } from '../../shared/utils/elements'
import {
  BOOKMARK_MODES as MODES,
  SITES,
  THEMES,
} from '../../shared/utils/view_options'
import {
  BOOKMARK_DELETED,
  BOOKMARK_EDITED,
} from '../../shared/utils/notificationMessages'
import { BookmarksApiService, TutorialApiService } from '../services'
import ConfirmationDialog from './ConfirmationDialog'
import Tags from './Tags'
import TagsSelect from './elements/TagsSelect'
import TutorialStep from './elements/TutorialStep'

const EMPTY_BOOKMARK = {
  id: null,
  title: '',
  timestamp_seconds: 0,
  tags: [],
}

export default {
  name: 'BookmarkComponent',
  components: {
    ConfirmationDialog,
    EditIcon,
    DeleteIcon,
    CloseIcon,
    Tags,
    TagsSelect,
    TutorialStep,
  },
  props: {
    href: String,
    mode: {
      required: true,
      type: String, // MODES.EDIT | MODES.SHOW | MODES.CREATE,
    },
    theme: {
      required: true,
      type: String, // THEMES.DARK | THEMES.LIGHT,
    },
    bookmark: {
      id: Number | null,
      title: String,
      timestamp_seconds: Number,
      tags: Array,
    },
  },
  emits: [
    EMITTERS.BOOKMARK_CREATED_EMITTER,
    EMITTERS.BOOKMARK_DELETED_EMITTER,
    EMITTERS.BOOKMARK_UPDATED_EMITTER,
    EMITTERS.TOGGLE_TAG_BLOCK_EMITTER,
  ],
  data() {
    return {
      EMITTERS,
      tagsOpen: false,
      currentMode: this.mode,
      showDialog: false,
      localBookmark: EMPTY_BOOKMARK,
      tagInput: { name: '' },
      closeDropdownTrigger: false,

      tutorialActive: false,
      tutorialStep: 1,

      BOOKMARK_DELETE_FUNCTION: BookmarksApiService.deleteBookmark,
      BOOKMARK_TITLE_LIMIT: 150,
      BOOKMARK_INPUT_MIN_HEIGHT: 32,
      BOOKMARK_TIMESTAMP_DEFAULT: '00:00:00',
      TAGS_LIMIT: 10,
    }
  },
  computed: {
    shouldShowTutorial() {
      return (
        this.tutorialActive && this.isCreateMode && this.theme === THEMES.DARK
      )
    },
    canAddMoreTags() {
      return this.filterTags(this.localBookmark.tags).length < this.TAGS_LIMIT
    },
    hasSameTag() {
      return !!this.localBookmark.tags.find(
        (tag) => tag.name === this.tagInput.name && tag._destroy !== true
      )
    },
    isModifyMode() {
      return this.isEditMode || this.isCreateMode
    },
    isEditMode() {
      return this.currentMode === MODES.EDIT
    },
    isShowMode() {
      return this.currentMode === MODES.SHOW
    },
    isCreateMode() {
      return this.currentMode === MODES.CREATE
    },
    isCounterEnabled() {
      return this.localBookmark.title.length > 80
    },
    timestamp() {
      const timestamp_seconds = this.localBookmark.timestamp_seconds
      if (!timestamp_seconds) return this.BOOKMARK_TIMESTAMP_DEFAULT
      return new Date(timestamp_seconds * 1000).toISOString().slice(-13, -5)
    },
  },
  watch: {
    currentMode(newMode, _oldMode) {
      this.$nextTick(() => {
        if (newMode === MODES.EDIT) this.updateTextareaHeight()
      })
    },
    bookmark() {
      this.reloadBookmark()
    },
  },
  mounted() {
    if (document.querySelector(VIDEO_PLAYER_ID)) this.subscribeToEpisodePlayer()
    const createBookmarkButton = document.getElementById('createBookmarkButton')
    if (createBookmarkButton)
      createBookmarkButton.addEventListener('click', () => {
        this.stepChanged({ number: 1 })
      })
    this.createBookmarkInputFocus()
  },
  created() {
    this.reloadBookmark()
    this.getTutorial()
  },
  methods: {
    getTutorial() {
      TutorialApiService.getTutorial()
        .then((response) => {
          // noinspection JSUnresolvedVariable
          this.tutorialActive = response.data.tutorial_active
          this.tutorialStep = response.data.tutorial_step
        })
        .catch(console.error)
    },
    removeTag(tag) {
      if (this.isCreateMode) {
        this.localBookmark.tags = this.localBookmark.tags.filter(
          (element) => element !== tag
        )
      }

      if (this.isEditMode) {
        const removedTag = this.localBookmark.tags.find(
          (element) => element === tag
        )
        removedTag.st_taggable_id = null
        removedTag.st_taggable_type = null
        removedTag._destroy = true
      }
    },
    closeDialog() {
      this.showDialog = false
    },
    toggleTagBlock(data) {
      this.endTutorial()
      this.tagsOpen = data.hide ? false : !this.tagsOpen
    },
    endTutorial() {
      this.stepChanged({ number: 8 })
    },
    bookmarkDeleted(bookmark) {
      this.$emit(EMITTERS.BOOKMARK_DELETED_EMITTER, bookmark)
      this.emitter.emit(EMITTERS.SHOW_NOTIFICATION_EMITTER, {
        message: BOOKMARK_DELETED,
        site: SITES.WATCH,
      })
    },
    openDialog() {
      this.endTutorial()
      this.showDialog = true
    },
    editMode() {
      this.endTutorial()
      this.tagsOpen = true
      this.currentMode = MODES.EDIT
    },
    showMode() {
      this.currentMode = MODES.SHOW
    },
    resetTextAreaHeight() {
      if (this.$refs.newBookmarkArea)
        this.$refs.newBookmarkArea.style.height =
          this.BOOKMARK_INPUT_MIN_HEIGHT + 'px'
    },
    inputBookmarkTextarea() {
      this.updateTextareaHeight()
      this.startBookmarksAreaTutorial()
    },
    startBookmarksAreaTutorial() {
      this.hardRemoveTutorialStep({ number: 1 })
      this.stepChanged({ number: 2 })
    },
    hardRemoveTutorialStep(data) {
      const selector = `[data-step='${data.number}']`
      const element = document.querySelector(selector)
      if (element) element.style.display = 'none'
    },
    updateTextareaHeight() {
      this.resetTextAreaHeight()
      if (this.$refs.newBookmarkArea)
        this.$refs.newBookmarkArea.style.height =
          this.$refs.newBookmarkArea.scrollHeight + 'px'
    },
    subscribeToEpisodePlayer() {
      this.player = videojs(VIDEO_PLAYER)

      if (!this.isCreateMode) return

      this.player.ready(() => {
        this.player.on('timeupdate', () => {
          this.localBookmark.timestamp_seconds = parseInt(
            this.player.currentTime()
          )
        })
      })
    },
    clearForm() {
      this.resetTextAreaHeight()
      this.closeTagsDropdown()
      this.reloadBookmark()
      this.tagsOpen = false
      if (this.isEditMode) this.showMode()
    },
    submitForm() {
      if (this.isCreateMode) this.createBookmark()
      if (this.isEditMode) this.updateBookmark()
    },
    closeTagsDropdown() {
      this.closeDropdownTrigger = !this.closeDropdownTrigger
    },
    addTag(tag) {
      this.localBookmark.tags.push({ name: tag.name })
      this.stepChanged({ number: 6 })
      this.resetTagsInput()
    },
    updateBookmark() {
      BookmarksApiService.updateBookmark(
        this.localBookmark,
        this.optional_params
      )
        .then((response) => {
          this.$emit(EMITTERS.BOOKMARK_UPDATED_EMITTER, response.data.bookmark)
          this.clearForm()
          this.showMode()
          this.emitter.emit(EMITTERS.SHOW_NOTIFICATION_EMITTER, {
            message: BOOKMARK_EDITED,
            site: SITES.WATCH,
          })
        })
        .catch(console.error)
    },
    createBookmark() {
      BookmarksApiService.createBookmark(
        this.localBookmark,
        this.optional_params
      )
        .then((_response) => {
          this.$emit(EMITTERS.BOOKMARK_CREATED_EMITTER)
          this.clearForm()
          this.stepChanged({ number: 7 })
        })
        .catch(console.error)
        .finally(() => {
          this.localBookmark.timestamp_seconds = parseInt(
            this.player.currentTime()
          )
        })
    },
    deleteBookmark() {
      BookmarksApiService.deleteBookmark(
        this.localBookmark,
        this.optional_params
      )
        .then((_response) => {
          this.$emit(EMITTERS.BOOKMARK_DELETED_EMITTER, this.localBookmark)
        })
        .catch(console.error)
    },
    filterTags(tags) {
      if (!tags) return []
      const filteredTags = tags.filter((tag) => !(tag.st_taggable_id === null))
      return sortBy(filteredTags, ['name', 'created_at'])
    },
    focusBookmarkTextarea() {
      this.fetchVideoTimestamp()
      this.tagsOpen = true
      this.startBookmarksAreaTutorial()
      if (this.tutorialActive) this.getTutorial()
    },
    fetchVideoTimestamp() {
      if (this.player) this.player.pause()
    },
    handleTimestampClick(event) {
      if (!this.player) return (window.location.href = this.href)

      event.preventDefault()

      this.player.play()
      this.player.pause()
      this.player.currentTime(this.localBookmark.timestamp_seconds)

      document.body.scrollTop = document.documentElement.scrollTop = 0
    },
    reloadBookmark() {
      const bookmark = this.isCreateMode ? EMPTY_BOOKMARK : this.bookmark
      this.localBookmark = cloneDeep(bookmark)
      this.resetTagsInput()
    },
    resetTagsInput() {
      this.tagInput.name = ''
    },
    stepChanged(data) {
      if (this.tutorialStep > data.number) return
      const nextStep = data.number + 1
      TutorialApiService.updateTutorial({
        tutorial_step: nextStep,
      })
        .then((_response) => {
          this.tutorialStep = nextStep
          if (data.number === 8) this.hardRemoveTutorialStep({ number: 8 })
        })
        .catch(console.error)
    },
    createBookmarkInputFocus() {
      if (!this.$route) return
      if (this.$route.params.new === 'new' && this.isModifyMode) {
        if (this.$refs.newBookmarkArea) {
          this.$refs.newBookmarkArea.focus()
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../shared/assets/styles/global';

.bookmark-wrapper .bookmark {
  margin: 0 16px;
  padding: 12px 0;
  border-bottom: 1px #444050 solid;
}

.create-mode .bookmark {
  margin-left: 0;
  margin-right: 0;
  padding: 12px 16px;
}

.bookmark-wrapper.dark:hover {
  background-color: #32303c;
}

.bookmark {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
}

.bookmark-main {
  display: flex;
  flex: 0 0 100%;
}

.bookmark-timestamp {
  width: 100px;
  height: 26px;
  padding: 2px 12px;
  color: #d1d3d4;
  border-radius: 25px;
  border: 1px solid #444050;
  font-weight: 500;
  text-align: center;
}

.bookmark-title {
  cursor: pointer;
}

.bookmark-title-wrapper {
  position: relative;
  margin-left: 10px;
  color: $white;
  line-height: 26px;
  width: 100%;
}

.dark .new-bookmark-area {
  padding-top: 0;
}

.light .new-bookmark-area {
  padding-top: 4px;
}

.new-bookmark-area {
  cursor: default;
  padding-left: 0;

  &:focus {
    outline: none;
  }
}

.bookmark-title {
  cursor: pointer;
}

.new-bookmark-area,
.bookmark-title {
  background: transparent;
  border: none;
  color: $white;
  resize: none;
  display: flex;
  width: 85%;
  flex-basis: 100%;
  overflow-wrap: anywhere;

  &:focus {
    outline-width: 0;
    cursor: auto;
  }
}

.bookmark-counter {
  justify-content: flex-end;
  display: flex;
}

.bookmark-actions {
  height: 30px;
}

.bookmark-action {
  margin: 0 5px;
}

.delete-action {
  margin-right: 16px;
}

.tags-button {
  padding: 3px 12px;
  font-size: 14px;
  border-radius: 25px;
  background-color: #3b3946;
  border: 1px solid #444050;
  color: $white;
}

.bookmark-edit-actions {
  position: relative;
}

.bookmark-edit-actions,
.bookmark-edit-actions form,
.bookmark-actions {
  display: flex;
  margin-left: auto;
  justify-content: end;
  width: 205px;
}

.bookmark__action-button {
  border-radius: 4px;
  border: none;
  font-size: 14px;
  height: 32px;
  width: 85px;
  color: $white;
}

.bookmark__save-button {
  @extend .bookmark__action-button;
  background-color: #6998ae;
  margin: 0 8px;

  &:disabled,
  &[disabled] {
    color: #8f8a9b;
    background-color: #3b3946;
  }
}

.bookmark__cancel-button {
  @extend .bookmark__action-button;
  background-color: #444050;
  margin-left: auto;
}

.light .bookmark {
  margin: 0;
  padding: 12px 0;
  position: relative;
  border-bottom: 1px #eaedee solid;

  &:hover {
    background-color: #f9f9f9;

    .bookmark-edit-tags__tag {
      background-color: $white;
    }
  }

  .bookmark-timestamp {
    color: #5c5c5c;
    border: 1px solid #d1d3d4;
  }

  .bookmark-title-wrapper {
    color: #323232;

    .new-bookmark-area,
    .bookmark-title {
      color: #323232;
      line-height: 18px;
    }
  }

  .tags-button {
    background-color: $white;
    border: 1px solid #d1d3d4;
    color: #5c5c5c;
    height: 26px;
    width: 54px;
    border-radius: 12px;
    padding: 2px 12px;
    vertical-align: center;
    font-size: 14px;
    line-height: 18px;
  }

  .tags-area {
    position: relative;
  }

  .bookmark-actions {
    color: #5c5c5c;
    align-items: center;
  }

  .bookmark__action-button {
    color: $white;
  }

  .bookmark__save-button {
    @extend .bookmark__action-button;
    background-color: #6998ae;
    margin: 0 8px;

    &:disabled,
    &[disabled] {
      color: $quick-silver;
      background: #f9f9f9;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    }
  }

  .bookmark__cancel-button {
    box-shadow: 0 2px 10px rgba(73, 73, 73, 0.1);
    border-radius: 4px;
    background-color: $white;
    color: #6998ae;
  }
}

.bookmark-edit-tags {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 100%;
  margin-top: 10px;
}

.bookmark-edit-tags__title {
  font-size: 14px;
  flex-grow: 0.02;
  margin: auto 0;
}

.bookmark-edit-tags__tag {
  display: flex;
  padding: 4px 10px;
  margin: 3px 3px;
  border-radius: 20px;
  font-size: 12px;
  justify-content: space-between;
  flex-grow: 0.02;
}

.bookmark-edit-tags__tag-remove {
  margin: auto 0;
}

.tags-limit-message {
  font-size: 14px;
  line-height: 18px;
  margin: auto 3px;
}

// theme
$edit-tags-color-light: #5c5c5c;
$tag-border-light: #d1d3d4;
$tags-limit-color-light: #8f8a9b;
$edit-tags-color-dark: #d1d3d4;
$tag-border-dark: #444050;
$tags-limit-color-dark: #8f8a9b;

.light {
  .bookmark-edit-tags__title {
    color: $edit-tags-color-light;
  }

  .bookmark-edit-tags__tag-name {
    color: $edit-tags-color-light;
  }

  .bookmark-edit-tags__tag {
    border: 1px solid $tag-border-light;
  }

  .tags-limit-message {
    color: $edit-tags-color-light;
  }

  .bookmark-title {
    margin-top: 4px;
  }
}

.dark {
  .bookmark-edit-tags__title {
    color: $edit-tags-color-dark;
  }

  .bookmark-edit-tags__tag-name {
    color: $edit-tags-color-dark;
  }

  .bookmark-edit-tags__tag {
    border: 1px solid $tag-border-dark;
  }

  .tags-limit-message {
    color: $tags-limit-color-dark;
  }

  .new-bookmark-area {
    height: 32px;
  }
}

.bookmark-edit-tags {
  padding-left: 5px;
}
</style>
