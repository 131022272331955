<template>
  <div class="video-resources__toggle-wrapper">
    <div class="video-resources__toggle" @click="showVideoResources">
      <div>
        {{ $vueTranslate('study_tools.mobile_video_resource_app.title') }}
      </div>
      <svg
        width="8"
        height="14"
        viewBox="0 0 8 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 13L7 7L0.999999 1"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>
  <div v-if="isShowVideoResources" class="video-resources-mobile__content">
    <div class="video-resources__header">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click="hideVideoResources"
      >
        <path
          d="M15 6L9 12L15 18"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <div class="video-resources__title">
        {{ $vueTranslate('study_tools.mobile_video_resource_app.title') }}
      </div>
    </div>

    <router-view :theme="THEMES.DARK" :read-only-mode="true"></router-view>
  </div>
</template>

<script>
import { RouterLink, RouterView } from 'vue-router'

import { THEMES } from '../../shared/utils/view_options'
import { EMITTERS } from '../../shared/utils/emitters'
import { VIDEO_RESOURCES_ROUTES } from '../../shared/utils/routes'

export default {
  name: 'MobileVideoResourcesAppComponent',
  components: {
    RouterLink,
    RouterView,
  },
  data() {
    return {
      EMITTERS,
      VIDEO_RESOURCES_ROUTES,
      loading: true,
      routes: [],
      THEMES: THEMES,
      isShowVideoResources: false,
    }
  },
  created() {
    this.defaultRedirect()
  },
  methods: {
    defaultRedirect() {
      const imageId = this.getImageIdFromUrlParams()
      if (imageId) {
        this.isShowVideoResources = true
        return this.$router.push('/video_resources/images')
      }
      this.$router.push('/video_resources')
    },
    getImageIdFromUrlParams() {
      const urlParams = new URLSearchParams(window.location.search)
      return urlParams.get('video-resources-image')
    },
    showVideoResources() {
      this.defaultRedirect()
      this.isShowVideoResources = true
    },
    hideVideoResources() {
      this.isShowVideoResources = false
    },
  },
}
</script>

<style lang="scss" scoped>
.video-resources__toggle-wrapper {
  width: 100%;
  padding: 0 20px;
  .video-resources__toggle {
    font-size: 18px;
    padding: 15px 20px;
    background: #2b2834;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      margin-right: 15px;
    }
  }
}
.video-resources__header {
  padding: 15px;
  display: flex;
  .video-resources__title {
    justify-self: center;
    margin: auto;
  }
}

.video-resources-mobile__content {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  z-index: 3000;
  top: 0;
  left: 0;
  background: #1c1923;
}
</style>
