$(document).on('turbolinks:load', () => {
  const SCROLL_SIZE = 190
  const CONTAINER_CLASS = '.scrolling-items-container'

  $(CONTAINER_CLASS).each((_index, container) => {
    const itemsOnPage = $(container).attr('items-on-page') || 4

    if ($(container).find('.scrolling-item').length > itemsOnPage) {
      $(container).parent().find('.scroll-right').show()
    }
  })

  $('.scroll-left').on('click', (event) => {
    const elements = $(event.target).parent().find(CONTAINER_CLASS)
    const scrollSize = getScrollSize(elements) || SCROLL_SIZE

    const scroll = elements.scrollLeft() - scrollSize
    const computedScroll = scroll <= 0 ? 0 : scroll
    elements.scrollLeft(computedScroll)
    if (computedScroll === 0) elements.parent().find('.scroll-left').hide()
    elements.parent().find('.scroll-right').show()
  })

  $('.scroll-right').on('click', (event) => {
    const elements = $(event.target).parent().find(CONTAINER_CLASS)
    const scrollSize = getScrollSize(elements) || SCROLL_SIZE

    const scroll = elements.scrollLeft() + scrollSize
    const maxScrollLeft =
      elements.get(0).scrollWidth - elements.get(0).clientWidth
    const computedScroll = scroll >= maxScrollLeft ? maxScrollLeft : scroll
    elements.scrollLeft(computedScroll)
    if (computedScroll === maxScrollLeft) {
      elements.parent().find('.scroll-right').hide()
    }
    elements.parent().find('.scroll-left').show()
  })
})

function getScrollSize(elements) {
  if (elements.attr('scroll-size')) return null
  parseInt(elements.attr('scroll-size'))
}
