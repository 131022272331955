<template>
  <div :class="theme">
    <div class="documents__container">
      <div v-if="!readOnlyMode" class="documents__create">
        <div class="documents__secondary-title">Add transcript</div>
        <div class="transcripts__description">
          You can attach only one transcript. If you generated transcript file
          then to upload new file with edits you should delete autogenerated
          file.
        </div>
        <Transcript
          :key="currentTranscript.id"
          ref="transcriptButtons"
          :mode="currentMode"
          :transcript="currentTranscript"
          :disabled="!showTranscripts()"
          @[EMITTERS.TRANSCRIPT_CREATED_EMITTER]="transcriptCreated"
          @[EMITTERS.TRANSCRIPT_GENERATE_EMITTER]="transcriptGenerated"
          @[EMITTERS.TRANSCRIPT_UPDATED_EMITTER]="transcriptUpdated"
          @[EMITTERS.TRANSCRIPT_CANCEL_EMITTER]="transcriptUpdateCanceled"
          @[EMITTERS.TRANSCRIPT_SELECTED_EMITTER]="showProcessing"
          @[EMITTERS.TRANSCRIPT_FAILED_EMITTER]="restoreTranscript"
        />
      </div>
      <div
        v-if="transcripts.length > 0 && showTranscripts()"
        class="documents__index"
      >
        <div v-if="!readOnlyMode" class="documents__secondary-title">
          {{ transcriptsHeader() }}
        </div>
        <div ref="transcriptList" class="documents__list">
          <Transcript
            :key="currentTranscript.id"
            :active="activeTranscriptId"
            :transcript="currentTranscript"
            :mode="MODES.SHOW"
            :read-only-mode="readOnlyMode"
            @[EMITTERS.TRANSCRIPT_DELETED_EMITTER]="transcriptDeleted"
            @[EMITTERS.TRANSCRIPT_EDIT_EMITTER]="transcriptEdit"
            @[EMITTERS.TRANSCRIPT_UPDATED_EMITTER]="transcriptUpdated"
          />
        </div>
      </div>
      <div v-if="!showTranscripts()" class="transcripts__loader-container">
        <img
          src="../../../shared/assets/images/loading.gif"
          alt="loading"
          class="documents-loader"
        />
        <span class="transcripts__loader-text">Processing...</span>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, remove } from 'lodash'

import Transcript from './Transcript'
import {
  THEMES,
  VIDEO_RESOURCES_MODES as MODES,
} from '../../../shared/utils/view_options'
import TranscriptsApiService from '../services/transcriptsApi.service'
import { EMITTERS } from '../../../shared/utils/emitters'

const EMPTY_TRANSCRIPT = {
  id: null,
  title: '',
  document: File,
  description: '',
  public: false,
}

export default {
  name: 'TranscriptsComponent',
  components: {
    Transcript,
  },
  props: {
    readOnlyMode: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: THEMES.LIGHT,
    },
  },
  data() {
    return {
      MODES,
      EMITTERS,
      transcripts: [],
      currentMode: MODES.CREATE,
      currentTranscript: cloneDeep(EMPTY_TRANSCRIPT),
      activeTranscriptId: 0,
      loading: true,
    }
  },
  created() {
    this.fetchTranscripts()
  },
  methods: {
    showProcessing() {
      this.loading = true

      if (this.$refs.transcriptButtons) {
        this.$refs.transcriptButtons.disableButtons()
      }
    },
    showResults() {
      this.loading = false

      if (this.$refs.transcriptButtons) {
        this.$refs.transcriptButtons.enableButton()
      }
    },
    restoreTranscript(storedTranscript) {
      Object.assign(this.currentTranscript, storedTranscript)
      this.showResults()
    },
    fetchTranscripts() {
      this.showProcessing()

      TranscriptsApiService.getAllTranscripts(this.optional_params)
        .then((response) => {
          const responseData = response.data

          this.transcripts = responseData.transcripts

          if (this.transcripts.length > 0) {
            Object.assign(this.currentTranscript, this.transcripts[0])
            this.activeTranscriptId = this.currentTranscript.id
            this.currentMode = MODES.EDIT
          }
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false

          if (this.showTranscripts()) {
            clearInterval(this.timer)
            this.showResults()
          } else if (!this.timer) {
            this.timer = setInterval(() => {
              this.fetchTranscripts()
            }, 30000)
          }
        })
    },
    transcriptCreated() {
      this.currentTranscript = cloneDeep(EMPTY_TRANSCRIPT)
      this.fetchTranscripts()
    },
    transcriptDeleted(transcript) {
      this.showResults()
      remove(this.transcripts, (element) => element.id === transcript.id)
      if (transcript.id === this.activeTranscriptId) {
        this.currentMode = MODES.CREATE
        this.currentTranscript = cloneDeep(EMPTY_TRANSCRIPT)
        this.activeTranscriptId = 0
      }
      this.count--
    },
    updateTranscript(transcript) {
      Object.assign(this.currentTranscript, transcript)
      this.showResults()
    },
    transcriptUpdated(transcript) {
      this.updateTranscript(transcript)
    },
    transcriptEdit(transcript) {
      this.currentMode = MODES.EDIT
      this.currentTranscript = cloneDeep(transcript)
      this.activeTranscriptId = transcript.id
    },
    transcriptUpdateCanceled() {
      this.currentMode = MODES.CREATE
      this.activeTranscriptId = 0
      this.currentTranscript = cloneDeep(EMPTY_TRANSCRIPT)
    },
    transcriptsHeader() {
      return this.currentTranscript.public
        ? 'Attached autogenerated transcript'
        : 'Generated transcript'
    },
    showTranscripts() {
      const transcriptPresent =
        this.currentTranscript.id === null ||
        !!this.currentTranscript.document.type

      return transcriptPresent && !this.loading
    },
    transcriptGenerated() {
      this.fetchTranscripts()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../shared/assets/styles/documents';
@import '../../../shared/assets/styles/video_resources';

.transcripts__loader-container {
  display: flex;
  flex-direction: column;
  max-width: 351px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
}

.transcripts__loader-text {
  color: #a1a1a1;
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.transcripts__container {
  .documents-loader {
    margin: auto;
    width: 24px;
    display: flex;
    vertical-align: middle;
  }

  .documents__container {
    min-height: 215px;
  }

  .documents__secondary-title {
    color: #5c5c5c;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  .documents__create {
    border: 0;
    padding-bottom: 4px;
  }

  .documents__index {
    padding-top: 4px;
  }
}

.transcripts__description {
  max-width: 441px;
  height: 19px;
  color: #5c5c5c;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 28px;
}

.transcripts__attach {
  width: 171px;
  padding: 6px 12px;
}
</style>
