<template>
  <TextInput
    v-if="isCreateMode || isEditMode"
    v-model:value="localDocument.title"
    :max-length="80"
    placeholder="Title"
    :error="errors.title.join()"
  />

  <div
    v-if="isCreateMode || isEditMode"
    class="video-resources__form-control document__document"
  >
    <DragAndDrop
      :key="localDocument.document"
      :attachment="localDocument.document"
      :progress="progress"
      :uploading="uploading"
      placeholder="Upload file"
      theme=""
      @[EMITTERS.FILE_SELECTED_EMITTER]="documentSelected"
    />
    <span class="document__file-error video-resources__form-error">
      {{ errors.document.join() }}
    </span>
  </div>

  <TextAreaInput
    v-if="isCreateMode || isEditMode"
    v-model:value="localDocument.description"
    :max-length="800"
    placeholder="Description"
    :error="errors.description.join()"
  />

  <div
    v-if="isCreateMode || isEditMode"
    class="document__form-buttons video-resources__control-buttons"
  >
    <button
      :disabled="isButtonDisabled"
      class="video-resources__control-button"
      @click="submitForm"
    >
      {{ submitButtonTitle }}
    </button>

    <button
      v-if="isEditMode"
      class="video-resources__control-button"
      @click="cancelUpdate"
    >
      {{ $vueTranslate('admin.document.cancel_btn') }}
    </button>
  </div>

  <div
    v-if="isShowMode"
    ref="documentContainer"
    class="document__container"
    :class="[maybeActive]"
    @click="documentEdit"
  >
    <div class="video-resources__file__icon">
      <div v-if="readOnlyMode" class="video-resources__file__format">
        {{ fileFormat }}
      </div>
    </div>
    <span
      :class="{ document__text__wrapped: readOnlyMode }"
      class="document__text"
    >
      {{ fileTitle }}
    </span>
    <div class="document__control-spacer" />
    <a
      v-if="readOnlyMode"
      :href="localDocument.document.downloadLink"
      download
      target="_blank"
      class="video-resources_download"
      @click.stop
    >
      <DownloadIcon v-if="readOnlyMode" />
      <div class="video-resources__filesize">
        {{ localDocument.document.file_size }}
      </div>
    </a>
    <div class="video-resources__controls">
      <a
        v-if="!readOnlyMode"
        class="video-resources__preview"
        @click.stop="openPreview"
      >
        <PreviewIcon />
      </a>
      <div
        v-if="!readOnlyMode"
        class="document__delete"
        @click.stop="deleteDocument"
      >
        <div class="document__delete-icon" />
      </div>
    </div>
  </div>
  <PreviewDialog
    v-if="showPreview"
    :theme="theme"
    :entity="document"
    @[EMITTERS.CLOSE_DIALOG_EMITTER]="closePreview"
  />
</template>
<script>
import { cloneDeep } from 'lodash'

import DocumentsApiService from '../services/documentsApi.service'
import {
  THEMES,
  VIDEO_RESOURCES_MODES as MODES,
} from '../../../shared/utils/view_options'
import { EMITTERS } from '../../../shared/utils/emitters'
import DragAndDrop from './DragAndDrop'
import DownloadIcon from '../../../shared/components/icons/Download'
import TextInput from './lib/TextInput'
import TextAreaInput from './lib/TextAreaInput'
import PreviewIcon from '../../../shared/components/icons/Preview'
import PreviewDialog from './PreviewDialog'

export default {
  name: 'DocumentComponent',
  components: {
    DragAndDrop,
    DownloadIcon,
    TextInput,
    TextAreaInput,
    PreviewIcon,
    PreviewDialog,
  },
  props: {
    theme: {
      default: THEMES.DARK,
      type: String,
    },
    active: {
      type: Number,
      default: null,
    },
    // eslint-disable-next-line vue/require-default-prop
    document: {
      id: Number | null,
      title: String,
      description: String,
      document: File,
    },
    mode: {
      type: String,
      default: MODES.SHOW,
    },
    readOnlyMode: {
      type: Boolean,
      default: false,
    },
    previewMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    EMITTERS.DOCUMENT_CREATED_EMITTER,
    EMITTERS.DOCUMENT_EDIT_EMITTER,
    EMITTERS.DOCUMENT_UPDATED_EMITTER,
    EMITTERS.DOCUMENT_DELETED_EMITTER,
    EMITTERS.DOCUMENT_CANCEL_EMITTER,
  ],
  data() {
    return {
      EMITTERS,
      localDocument: this.document,
      isButtonDisabled: false,
      uploading: false,
      progress: 0,
      showPreview: false,
      errors: {
        title: [],
        document: [],
        description: [],
      },
    }
  },
  computed: {
    isEditMode() {
      return this.mode === MODES.EDIT
    },
    isCreateMode() {
      return this.mode === MODES.CREATE
    },
    isShowMode() {
      return this.mode === MODES.SHOW
    },
    submitButtonTitle() {
      return this.isCreateMode
        ? this.$vueTranslate('admin.document.add_btn')
        : this.$vueTranslate('admin.document.update_btn')
    },
    maybeActive() {
      return this.active === this.document.id ? 'active' : ''
    },
    fileTitle() {
      if (!this.readOnlyMode) return this.document.title
      return this.document.title.replace(/\..+/gi, '')
    },
    fileFormat() {
      if (!this.document.document.name) return ''
      if (this.document.document.name.split('.').length === 1) return ''

      return this.document.document.name.match(/\.\w+/gi).pop().replace('.', '')
    },
  },
  methods: {
    async updateDocument() {
      if (this.isCreateMode) return
      this.isButtonDisabled = true
      this.uploading = true

      try {
        const response = await DocumentsApiService.updateDocument(
          this.localDocument,
          this.optional_params,
          (progress) => {
            this.progress = progress
          }
        )
        this.clearForm()
        this.clearErrors()
        this.$emit(EMITTERS.DOCUMENT_UPDATED_EMITTER, response.document)
      } catch (error) {
        this.updateErrors(error)
      } finally {
        this.uploading = false
        this.enableButton()
      }
    },
    async createDocument() {
      this.uploading = true
      this.isButtonDisabled = true

      try {
        const response = await DocumentsApiService.createDocument(
          this.localDocument,
          this.optional_params,
          (progress) => {
            this.progress = progress
          }
        )
        this.clearForm()
        this.clearErrors()
        this.$emit(EMITTERS.DOCUMENT_CREATED_EMITTER, response.document)
      } catch (error) {
        this.updateErrors(error)
      } finally {
        this.uploading = false
        this.enableButton()
      }
    },
    deleteDocument() {
      DocumentsApiService.deleteDocument(
        this.localDocument,
        this.optional_params
      )
        .then(() => {
          this.$emit(EMITTERS.DOCUMENT_DELETED_EMITTER, this.localDocument)
        })
        .catch(console.error)
    },
    clearForm() {
      this.localDocument.title = ''
      this.localDocument.document = File
      this.localDocument.description = ''
      this.localDocument.id = null
      this.progress = 0
    },
    clearErrors() {
      this.errors.title = []
      this.errors.document = []
      this.errors.description = []
    },
    submitForm() {
      if (this.isCreateMode) this.createDocument()
      if (this.isEditMode) this.updateDocument()
    },
    updateErrors(e) {
      this.errors.title = e.response.data.errors.title || []
      this.errors.document = e.response.data.errors.document || []
      this.errors.description = e.response.data.errors.description || []
    },
    documentSelected(file) {
      this.localDocument.document = file
    },
    documentEdit() {
      if (this.readOnlyMode) return this.openPreview()

      const documentProps = cloneDeep(this.localDocument)
      this.$emit(EMITTERS.DOCUMENT_EDIT_EMITTER, documentProps)
    },
    enableButton() {
      this.isButtonDisabled = false
    },
    cancelUpdate() {
      this.$emit(EMITTERS.DOCUMENT_CANCEL_EMITTER)
    },
    openPreview() {
      this.showPreview = true
    },
    closePreview() {
      this.showPreview = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../shared/assets/styles/video_resources';
@import '../../../shared/assets/styles/document';
</style>
