<template>
  <div class="bookmarks-filter">
    <div class="tags-filter-selector">
      <div class="tags-filter-button" @click="toggleDropdown">
        <span class="tags-filter-button__text">
          {{ $vueTranslate('study_tools.bookmarks_filter.filter_btn') }}
        </span>
        <ArrowDownIcon class="tags-filter-button__icon" :theme="theme" />
      </div>

      <div v-if="showDropdown" ref="dropdown" class="tags-filter-dropdown">
        <div class="tags-filter-dropdown__wrapper">
          <div
            v-for="(group, groupName) in sortedTagsGroups"
            :key="groupName"
            class="tags-filter__group-block"
          >
            <div class="tags-filter__group-title">{{ groupName }}</div>
            <div
              v-for="tag in group"
              :key="tag"
              class="tags-filter__group-option"
              :class="{
                'tags-filter__group-option__selected': isTagSelected(tag),
              }"
              @click="selectTag(tag)"
            >
              {{ tag }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowDownIcon from '../../shared/components/icons/ArrowDown'
import { EMITTERS } from '../../shared/utils/emitters'
import { THEMES } from '../../shared/utils/view_options'

export default {
  name: 'BookmarkFilter',
  components: { ArrowDownIcon },
  props: {
    episodes: {
      type: Array,
      default: () => [],
    },
    tags: {
      type: Array,
      default: () => [],
    },
    tagsSelected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedTags: this.tagsSelected,
      showDropdown: false,
      theme: THEMES.LIGHT,
    }
  },
  computed: {
    sortedTagsGroups() {
      const groupedTags = {}
      for (const tag of this.tags) {
        const firstCharacter = tag.charAt(0)
        const groupName = firstCharacter.replace(/\d/, '#').toUpperCase()

        if (groupedTags[groupName]) groupedTags[groupName].push(tag)
        else groupedTags[groupName] = [tag]
      }

      const sortedGroups = {}
      Object.keys(groupedTags)
        .sort()
        .forEach((key) => {
          sortedGroups[key] = groupedTags[key]
        })

      return sortedGroups
    },
  },
  mounted() {
    window.addEventListener('click', this.handleWindowClick)

    this.emitter.on(EMITTERS.FILTER_BOOKMARK_TAG_UPDATED, (tags) => {
      this.selectedTags = tags
      this.updateTagsState()
    })
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown
    },
    selectTag(tag) {
      if (this.selectedTags.includes(tag)) return

      this.selectedTags.push(tag)

      this.updateTagsState()
    },
    isTagSelected(tag) {
      return this.selectedTags.includes(tag)
    },
    updateTagsState() {
      this.$router.push({
        name: 'profile_bookmarks_list',
        query: { ...this.$route.query, tags: this.selectedTags.join(',') },
      })
    },
    handleWindowClick(event) {
      const elementClasses =
        '.tags-filter-dropdown,' +
        ' .tags-filter-button__text,' +
        ' .tags-filter-dropdown__wrapper,' +
        ' .tags-filter__group-block,' +
        ' .tags-filter__group-title,' +
        ' .tags-filter__group-option,' +
        ' .tags-filter-button'
      if (!event.target.matches(elementClasses)) this.showDropdown = false
    },
  },
}
</script>

<style lang="scss" scoped>
.tags-filter-selector {
  display: flex;
  justify-content: end;
  align-items: end;
  position: relative;
}

.tags-filter-button {
  display: inline-flex;
  height: 32px;
  padding: 9px 16px;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  border-radius: 4px;
  background: white;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.tags-filter-button__text {
  color: #323232;
  font-size: 14px;
  font-family: DM Sans, serif;
  font-weight: 500;
  line-height: 18px;
  margin-top: -2px;
}

.tags-filter-button__icon {
  color: #a6a6a6;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
}

.tags-filter-dropdown {
  position: absolute;
  min-width: 230px;
  width: auto;
  top: 36px;
  right: 0;
  z-index: 5;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0 1px 10px 0 rgba(29, 32, 35, 0.15);
  padding: 16px 6px 16px 16px;
}

.tags-filter-dropdown__wrapper {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 650px;
  padding-right: 7px;
}

.tags-filter-dropdown__wrapper::-webkit-scrollbar {
  width: 3px;
  height: 250px;
}

.tags-filter-dropdown__wrapper::-webkit-scrollbar-thumb {
  background: #d1d3d4;
  border-radius: 0.25rem;
}

.tags-filter__group-block {
  margin-bottom: 8px;
}

.tags-filter__group-title {
  color: #323232;
  font-size: 20px;
  font-family: DM Sans, serif;
  font-weight: 500;
  line-height: 28px;
  border-bottom: solid #d1d3d4 1px;
  margin-bottom: 12px;
  pointer-events: none;
}

.tags-filter__group-option {
  border-radius: 12px;
  border: 1px solid #d1d3d4;
  background: #fff;
  font-size: 12px;
  font-family: DM Sans, serif;
  line-height: 16px;
  display: flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin-bottom: 6px;
  cursor: pointer;
}

.tags-filter__group-option:hover {
  background-color: #f5f5f5;
}

.tags-filter__group-option__selected {
  border: 1px solid #6998ae;
  //pointer-events: none;
}

.tags-list-block {
  display: flex;
  flex-direction: row;
  justify-content: left;
  width: 100%;
  min-height: 24px;
  gap: 6px;
  margin-top: 8px;
}

.selected-tags {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
}

.selected-tag {
  display: flex;
  flex-wrap: wrap;
  height: 24px;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 12px;
  border: 1px solid var(--text-disabled, #d1d3d4);
  background: var(--backgrounds-white, #fff);
  color: var(--text-secondary, #5c5c5c);
  text-align: center;
  font-size: 12px;
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.clear-tags-button {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 70px;
  height: 24px;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid var(--primary-steel-blue-default, #6998ae);
  background: var(--primary-steel-blue-default, #6998ae);
  color: var(--backgrounds-white, #fff);
  text-align: center;

  font-size: 12px;
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  cursor: pointer;
}

.bookmarks-filter {
  display: flex;
  flex-direction: column;
}
</style>
