<template>
  <div class="thumbnail-container">
    <a :href="news.news_path">
      <img
        class="thumbnail"
        :alt="news.title"
        loading="lazy"
        :src="news.image_link"
      />
    </a>

    <div class="text-content">
      <a class="title" :href="news.news_path">{{ news.title }}</a>
    </div>

    <a class="logo-link" :href="news.source_link">
      <img
        class="logo-thumbnail"
        alt="source"
        loading="lazy"
        :src="news.source_logo"
      />
    </a>
  </div>
</template>

<script>

export default {
  name: 'NewsThumbnail',
  props: {
    news: {
      title: String,
      id: Number,
      thumbnail: String,
      image_link: String,
      news_path: String,
      source_link: String,
      source_logo: String,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../shared/assets/styles/global';

.thumbnail-container {
  width: 180px;
  height: 210px;
  position: relative;

  .thumbnail {
    object-fit: cover;
    font-size: 16px;
  }

  .logo-thumbnail {
    width: auto;
    height: 35px;
    object-fit: contain;
    object-position: top left;
    font-size: 14px;
  }

  img {
    width: 180px;
    height: 90px;
  }
}

.text-content {
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 8px;
}

.title {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 180px;
  max-height: 54px;
  margin-top: 6px;
  font-size: 14px;
  line-height: 18px;
  color: #323232;
}

.footnote {
  line-height: 14px;
  margin-top: 3px;
  width: 180px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  a {
    color: #5c5c5c;
    font-size: 14px;
    text-decoration: none;
  }
}
</style>
