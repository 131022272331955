<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.0469 8.20312C13.0134 8.20312 13.7969 7.41962 13.7969 6.45312C13.7969 5.48663 13.0134 4.70312 12.0469 4.70312C11.0804 4.70312 10.2969 5.48663 10.2969 6.45312C10.2969 7.41962 11.0804 8.20312 12.0469 8.20312ZM12.0469 13.7979C13.0134 13.7979 13.7969 13.0143 13.7969 12.0479C13.7969 11.0814 13.0134 10.2979 12.0469 10.2979C11.0804 10.2979 10.2969 11.0814 10.2969 12.0479C10.2969 13.0143 11.0804 13.7979 12.0469 13.7979ZM13.7969 17.6436C13.7969 18.6101 13.0134 19.3936 12.0469 19.3936C11.0804 19.3936 10.2969 18.6101 10.2969 17.6436C10.2969 16.6771 11.0804 15.8936 12.0469 15.8936C13.0134 15.8936 13.7969 16.6771 13.7969 17.6436Z"
      :fill="fillIcon()"
    />
  </svg>
</template>

<script>
import { THEMES } from '../../utils/view_options'

export default {
  name: 'MoreIcon',
  props: {
    color: {
      required: false,
      type: String,
      default: null,
    },
    theme: {
      required: true,
      type: String, // THEMES.DARK | THEMES.LIGHT,
    },
  },
  data() {
    return {
      DARK_THEME: '#8F8A9B',
      LIGHT_THEME: '#A6A6A6',
    }
  },
  methods: {
    fillIcon() {
      return this.color || this.defineColor()
    },
    defineColor() {
      return this.theme === THEMES.DARK ? this.DARK_THEME : this.LIGHT_THEME
    },
  },
}
</script>

<style scoped>
svg {
  cursor: pointer;
}
</style>
