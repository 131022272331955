import uploadFileService from '../services/uploadFile.service'
export async function validateField(field_name, field, type = 'medias') {
  if (!field) {
    return { error: `admin.${type}.form.validation.${field_name}_blank` }
  }

  if (field_name !== 'file' && !getRegex(field_name).test(field)) {
    return {
      error: `admin.${type}.form.validation.${field_name}_invalid_format`,
    }
  }

  if (field_name === 'sku') {
    if (field.length > 10)
      return {
        error: `admin.${type}.form.validation.${field_name}_invalid_length`,
      }
    let valid = false
    await uploadFileService.checkSku(field).then((response) => {
      valid = response.data?.uniq || false
    })

    if (!valid)
      return {
        error: `admin.${type}.form.validation.${field_name}_not_uniq`,
      }
  }

  return { error: '' }
}

function getRegex(validationField) {
  switch (validationField) {
    case 'sku':
      return /^[0-9a-zA-Z_-]+$/
    case 'title':
      return /^[0-9a-zA-Z_ -]+$/
    default:
      return null
  }
}
