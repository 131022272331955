<template>
  <div class="bookmark-back-button">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 19V5" stroke="#6998AE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M5 12L12 5L19 12" stroke="#6998AE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'ScrollUpIconComponent',
}
</script>

<style lang="scss" scoped>
.bookmark-back-button {
  background: #32303C;
  box-shadow: 0px 4px 30px rgba(23, 24, 28, 0.6);
  border-radius: 100px;
  padding: 14px 16px;
  cursor: pointer;
  height: 56px;
}
</style>
