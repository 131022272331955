<template>
  <div :class="theme">
    <div ref="previewTitle" class="transcript-preview__title">
      <span>Transcript</span>
      <div
        v-if="!autoscroll"
        class="transcript-preview__resume"
        @click="resumeAutoscroll"
      >
        <div class="transcript-preview__resume-icon"></div>
        Resume
      </div>
    </div>
    <div
      ref="transcriptContainer"
      class="transcript-preview"
      @scroll="debounce(pauseAutoscroll, 200)"
    >
      <TranscriptPreviewRow
        v-for="group in groupedRows"
        :key="group.id"
        ref="groupRow"
        class="transcript-preview__row"
        :theme="theme"
        :group="group"
        :current-time="currentTime"
        :player="player"
        :autoscroll="autoscroll"
        @[EMITTERS.TRANSCRIPT_AUTOSCROLL]="transcriptAutoscroll"
        @[EMITTERS.TRANSCRIPT_CURRENT]="transcriptCurrent"
      />
    </div>
  </div>
</template>

<script>
import videojs from 'video.js'

import { EMITTERS } from '../../../shared/utils/emitters'
import TranscriptPreviewRow from './TranscriptPreviewRow.vue'
import { THEMES } from '../../../shared/utils/view_options'
import { VIDEO_PLAYER, VIDEO_PLAYER_ID } from '../../../shared/utils/elements'

export default {
  name: 'TranscriptPreviewComponent',
  components: {
    TranscriptPreviewRow,
  },
  props: {
    transcript: {
      type: Object,
    },
    theme: {
      type: String,
      default: THEMES.LIGHT,
    },
  },
  emits: [EMITTERS.TRANSCRIPT_AUTOSCROLL],
  data() {
    return {
      transcriptRows: [],
      vttText: '',
      currentTime: 0,
      player: {},
      groupedRows: [],
      autoscroll: true,
      currentGroupId: 1,
      EMITTERS,
      containerOffset: 0,
    }
  },
  mounted() {
    this.loadRemoteVTTFile()
    if (document.querySelector(VIDEO_PLAYER_ID)) {
      const player = videojs(VIDEO_PLAYER)
      this.subscribeToEpisodePlayer(player)
    }

    this.emitter.on(EMITTERS.VIDEO_PLAYER_READY, this.subscribeToEpisodePlayer)
  },
  methods: {
    transcriptCurrent(id) {
      this.currentGroupId = id
    },
    transcriptAutoscroll(targetPosition) {
      this.$refs.transcriptContainer.scrollTo({
        top: targetPosition,
        behavior: 'smooth',
      })

      this.debounce(this.startScrolling, 1000)
    },
    resumeAutoscroll() {
      this.player.play()

      this.$refs.groupRow[this.currentGroupId - 1].scrollToGroup()
    },
    startScrolling() {
      this.autoscroll = true
    },
    debounce(method, delay) {
      clearTimeout(method._tId)
      method._tId = setTimeout(function () {
        method()
      }, delay)
    },
    pauseAutoscroll() {
      const offsetHeight = Math.abs(
        this.containerOffset - this.$refs.transcriptContainer.scrollTop
      )

      this.containerOffset = this.$refs.transcriptContainer.scrollTop

      if (offsetHeight > 200) {
        this.autoscroll = false
      }
    },
    subscribeToEpisodePlayer(player) {
      this.player = player

      this.player.on('timeupdate', () => {
        this.currentTime = this.player.currentTime()
      })

      this.player.on('seeked', () => {
        this.$refs.groupRow[this.currentGroupId - 1].scrollToGroup()
      })
    },
    isCurrentTranscript(transcript) {
      return (
        transcript.startAt <= this.currentTime &&
        this.currentTime <= transcript.endAt
      )
    },
    async loadRemoteVTTFile() {
      const vttFileUrl = this.transcript.document.previewLink
      try {
        const response = await fetch(vttFileUrl)
        if (!response.ok) {
          throw new Error(`Failed to fetch VTT file: ${response.statusText}`)
        }
        this.vttText = await response.text()
        this.parseVTT()
        this.groupTranscriptRows()
      } catch (error) {
        console.error('Error loading VTT file:', error)
      }
    },
    parseVTT() {
      const transcriptList = []
      const lines = this.vttText.trim().split(/\n\s*\n/)
      let id = 1

      for (const line of lines) {
        const parts = line.split('\n')
        const timestamp = parts[0].trim().split(' --> ')
        const startAt = this.formatTimestamp(timestamp[0])
        const endAt = this.formatTimestamp(timestamp[1])
        const text = parts.slice(1).join(' ').trim() || '(Music)'

        const transcriptObject = {
          id,
          startAt: startAt,
          endAt: endAt,
          text: text,
        }

        transcriptList.push(transcriptObject)
        id++
      }

      transcriptList[0].endAt = transcriptList[1].startAt

      this.transcriptRows = transcriptList
    },
    groupTranscriptRows() {
      const groupSize = 4
      const groupedRows = []
      let id = 2

      const introGroup = {
        id: 1,
        startAt: this.transcriptRows[0].startAt,
        endAt: this.transcriptRows[0].endAt,
        rows: [this.transcriptRows[0]],
      }

      groupedRows.push(introGroup)

      for (let i = 1; i < this.transcriptRows.length; i += groupSize) {
        const rows = this.transcriptRows.slice(i, i + groupSize)
        const startAt = rows[0].startAt
        const endAt = rows[rows.length - 1].endAt

        const groupedRow = {
          id: id,
          startAt: startAt,
          endAt: endAt,
          rows: rows,
        }

        groupedRows.push(groupedRow)
        id++
      }

      this.groupedRows = groupedRows
    },
    formatTimestamp(timestamp) {
      if (!timestamp) return 0

      const parts = timestamp.split(/[:.]/).map(Number)
      let seconds = 0

      if (parts.length === 2) {
        seconds = parts[0] + parts[1] * 0.001
      } else if (parts.length === 3) {
        seconds = parts[0] * 60 + parts[1] + parts[2] * 0.001
      } else if (parts.length === 4) {
        seconds = parts[0] * 3600 + parts[1] * 60 + parts[2] + parts[3] * 0.001
      }

      return seconds
    },
    maybeHighlighted(transcript) {
      return this.isCurrentTranscript(transcript) ? 'highlight' : ''
    },
  },
}
</script>

<style scoped lang="scss">
.transcript-preview__title {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 20px;
  display: flex;
}

.transcript-preview {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 450px;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 7px;
    right: -30px;

    &:hover {
      width: 13px;
    }
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: #8f8a9b;
    border-radius: 4px;
    border: 4px solid #8f8a9b;
    height: 40px;
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {
    display: none;
  }
}
.transcript-preview__resume {
  display: inline-flex;
  padding: 6px 8px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 4px;
  background: #6998ae;
  box-shadow: 0px 24px 44px 0px rgba(29, 68, 86, 0.16);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.021px;
  cursor: pointer;
  margin-left: auto;
  margin-right: 10px;
}

.transcript-preview__resume-icon {
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cg clip-path='url(%23clip0_23077_47802)'%3E%3Cpath d='M8.66583 12.667V5.22031L11.9192 8.47365C12.1792 8.73365 12.6058 8.73365 12.8658 8.47365C13.1258 8.21365 13.1258 7.79365 12.8658 7.53365L8.4725 3.14031C8.2125 2.88031 7.7925 2.88031 7.5325 3.14031L3.1325 7.52698C2.8725 7.78698 2.8725 8.20698 3.1325 8.46698C3.3925 8.72698 3.8125 8.72698 4.0725 8.46698L7.3325 5.22031V12.667C7.3325 13.0336 7.6325 13.3336 7.99917 13.3336C8.36583 13.3336 8.66583 13.0336 8.66583 12.667Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_23077_47802'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
}

.dark {
  .transcript-preview {
    height: 775px;
    overflow: auto;

    &::-webkit-scrollbar {
      background-color: transparent;
      width: 7px;

      &:hover {
        width: 10px;
      }
    }
  }
}

.light {
  .transcript-preview__resume {
    color: #ffffff;
  }
}
</style>
