export const ACTION_TYPES = {
  SKIP: 'SKIP',
  NEXT: 'NEXT',
  COMPLETE: 'COMPLETE',
  GO_TO_DASHBOARD: 'GO_TO_DASHBOARD',
}

const COMPLETE_ACTION = {
  type: ACTION_TYPES.COMPLETE,
  label: 'Complete',
  active: true,
}

const SKIP_ACTION = {
  type: ACTION_TYPES.SKIP,
  label: 'Skip intro',
  active: true,
}

const NEXT_ACTION_ACTIVE = {
  type: ACTION_TYPES.NEXT,
  label: 'Next',
  active: true,
}

const NEXT_ACTION_INACTIVE = {
  type: ACTION_TYPES.NEXT,
  label: 'Next',
  active: false,
}

const GO_TO_DASHBOARD_ACTION = {
  type: ACTION_TYPES.GO_TO_DASHBOARD,
  label: 'Go to Dashboard',
  active: true,
}
export const STEPS = [
  {
    number: 1,
    title:
      'Click to create a bookmark. You can do this after pausing the video or while it is playing. The video will then pause automatically',
    // Duplicated at app/views/watch/episodes/widgets/_create_bookmark_widget.html.erb
    width: 306,
    leftMargin: -62,
    actions: [SKIP_ACTION],
  },
  {
    number: 2,
    title: 'Start typing the bookmark’s name. Once finished, click “Next”',
    topMargin: 15,
    leftMargin: -30,
    actions: [NEXT_ACTION_INACTIVE, SKIP_ACTION],
  },
  {
    number: 3,
    title: 'Start typing the bookmark’s name. Once finished, click “Next”',
    topMargin: 15,
    leftMargin: -30,
    actions: [NEXT_ACTION_ACTIVE, SKIP_ACTION],
  },
  {
    number: 4,
    title:
      'A tag helps you organize bookmarks into specific groupings. For instance, if you want to keep track of bookmarks you\'ve saved that cover the topic of the second coming, create a tag "second coming" and all your bookmarks with that tag can be grouped together to share with others',
    width: 433,
    topMargin: 48,
    leftMargin: 20,
    actions: [NEXT_ACTION_ACTIVE, SKIP_ACTION],
  },
  {
    number: 5,
    title:
      'To add a tag, start typing its name. You can add as much tags as you want',
    topMargin: 48,
    leftMargin: 20,
    actions: [NEXT_ACTION_ACTIVE, SKIP_ACTION],
  },
  {
    number: 6,
    title:
      'Start typing a tag’s name and you will be able to choose from preset options or click create your own tag after typing the tag name. You can add multiple tags',
    topMargin: 36,
    leftMargin: 352,
    actions: [NEXT_ACTION_ACTIVE, SKIP_ACTION],
  },
  {
    number: 7,
    title:
      'Once your done naming your bookmark and tag, make sure to click "Save" to complete the process',
    topMargin: 57,
    leftMargin: -200,
    actions: [SKIP_ACTION],
  },
  {
    number: 8,
    title:
      'By clicking on the writing icon, you can edit your bookmark or tag. Clicking the trash icon will delete your saved bookmark and tag',
    topMargin: 100,
    leftMargin: -220,
    actions: [NEXT_ACTION_ACTIVE, SKIP_ACTION],
  },
  {
    number: 9,
    title:
      'You can also quickly create a bookmark by typing on this title field. The video will pause when you start typing.',
    actions: [NEXT_ACTION_ACTIVE, SKIP_ACTION],
  },
  {
    number: 10,
    title:
      'After saving your bookmarks, you can visit the Dashboard to view all bookmarks you have saved on this site.',
    topMargin: 57,
    leftMargin: -200,
    width: 306,
    actions: [COMPLETE_ACTION, GO_TO_DASHBOARD_ACTION],
  },
  {
    number: 11,
    title: 'Click on the Notes tab to create your first note',
    topMargin: -161,
    leftMargin: 50,
    width: 279,
    actions: [SKIP_ACTION],
  },
  {
    number: 12,
    title:
      'Note title is an optional field. Fill it or head to the note body text. Once finished, click “Next“',
    topMargin: 57,
    leftMargin: -10,
    width: 306,
    actions: [NEXT_ACTION_ACTIVE, SKIP_ACTION],
  },
  {
    number: 13,
    title: 'Start typing the note’s text. Once finished, click “Next“',
    topMargin: 105,
    leftMargin: -10,
    width: 306,
    actions: [NEXT_ACTION_INACTIVE, SKIP_ACTION],
  },
  {
    number: 14,
    title: 'Start typing the note’s text. Once finished, click “Next“',
    topMargin: 105,
    leftMargin: -10,
    width: 306,
    actions: [NEXT_ACTION_ACTIVE, SKIP_ACTION],
  },
  {
    number: 15,
    title:
      'Use formatting options to edit your text. Try changing the font size, font weight, and other text styles. Once finished, click “Next“',
    topMargin: 130,
    leftMargin: 585,
    width: 306,
    actions: [NEXT_ACTION_ACTIVE, SKIP_ACTION],
  },
  {
    number: 16,
    title:
      'A tag helps you organize notes into specific groupings. For instance, if you want to keep track of notes you\'ve saved that cover the topic of the Second Coming, create a tag "Second Coming" and all your notes with that tag can be grouped together to share with others',
    topMargin: 50,
    leftMargin: -10,
    width: 306,
    actions: [NEXT_ACTION_ACTIVE, SKIP_ACTION],
  },
  {
    number: 17,
    title:
      'To add a tag, start typing its name. You can add as many tags as you want',
    topMargin: 150,
    leftMargin: 26,
    width: 306,
    actions: [NEXT_ACTION_ACTIVE, SKIP_ACTION],
  },
  {
    number: 18,
    title:
      'You can choose from preset options, or try creating your own tag by clicking Create tag',
    topMargin: 350,
    leftMargin: 350,
    width: 306,
    actions: [NEXT_ACTION_ACTIVE, SKIP_ACTION],
  },
  {
    number: 19,
    title:
      'Оnce you are done editing your note and tag, make sure to click "Save" to complete the process',
    topMargin: 57,
    leftMargin: 600,
    width: 306,
    actions: [SKIP_ACTION],
  },
  {
    number: 20,
    title:
      'By clicking on the note element, you can edit you note or tag. Clicking the trash icon will delete your saved note and tag',
    topMargin: 0,
    leftMargin: 960,
    width: 306,
    actions: [NEXT_ACTION_ACTIVE, SKIP_ACTION],
  },
  {
    number: 21,
    title:
      'After saving your note, you can visit the Dashboard to view all notes you have saved on this site.',
    topMargin: 0,
    leftMargin: 960,
    width: 306,
    actions: [COMPLETE_ACTION, GO_TO_DASHBOARD_ACTION],
  },
]
