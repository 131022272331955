import axios from 'axios'

import { API_ROUTES, BASE_API_CONFIG } from '../../shared/utils/api'

function getEpisodeId() {
  const episode_ids = new URL(document.location.href).pathname.match(/[^/]+/g)
  return episode_ids.slice(-1)
}

export default {
  DEFAULT_ORDER: 'desc',

  getAllNotes(episodeId, optional_params = {}) {
    const required_params = {
      episode_id: episodeId || getEpisodeId(),
    }

    return axios.request({
      ...BASE_API_CONFIG,
      method: 'GET',
      url: API_ROUTES.notes,
      params: {
        ...optional_params,
        ...required_params,
      },
    })
  },

  createNote(note, optional_params = {}) {
    note.tags_attributes = note.tags
    const required_params = {
      note: note,
      episode_id: getEpisodeId(),
    }

    const data = {
      ...optional_params,
      ...required_params,
    }

    return axios.request({
      ...BASE_API_CONFIG,
      method: 'POST',
      url: API_ROUTES.notes,
      data,
    })
  },

  deleteNote(note, optional_params = {}) {
    return axios.request({
      ...BASE_API_CONFIG,
      method: 'DELETE',
      url: API_ROUTES.note(note.id),
      params: optional_params,
    })
  },

  updateNote(note, optional_params = {}) {
    note.tags_attributes = note.tags

    return axios.request({
      ...BASE_API_CONFIG,
      method: 'PUT',
      url: API_ROUTES.note(note.id),
      data: {
        note,
        ...optional_params,
      },
    })
  },
}
