$(document).on('turbolinks:load', () => {
  if (tooltipSkipCondition()) return

  setTimeout(showTooltip, 1000)
})

function showTooltip() {
  getTooltip().css('visibility', 'visible')
  getTooltip().css('opacity', '1')
  updateTooltipData()

  return null
}

function getTooltip() {
  return $('.transcript-tooltip')
}

function updateTooltipData() {
  let currentTimestamp = new Date().getTime()
  let tooltipShownAt = parseInt(localStorage.transcript_tooltip_shown_at) || 0
  let tooltipCount = localStorage.transcript_tooltip_count || 0

  if (tooltipShownAt + 24 * 60 * 1000 < currentTimestamp) {
    localStorage.setItem('transcript_tooltip_shown_at', currentTimestamp)
    localStorage.setItem('transcript_tooltip_count', ++tooltipCount)
  }

  return null
}

function tooltipSkipCondition() {
  const tooltipMaxCount = parseInt(getTooltip().data('tooltipMaxCount'))
  const tooltipCurrentCount =
    parseInt(localStorage.getItem('transcript_tooltip_count')) || 0

  return getTooltip().length === 0 || tooltipCurrentCount >= tooltipMaxCount
}
