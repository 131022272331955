<template>
  <img
    v-if="loading"
    src="../../shared/assets/images/loading.gif"
    alt="loading"
    width="40"
    class="notes-loader"
  />
  <div v-if="!loading" class="episodes-component__wrapper">
    <div
      v-if="episodes.length === 0 && !noteEditing"
      class="episodes-placeholder"
    >
      <div class="episodes-block">
        <div class="placeholder-border"></div>
        <div class="primary-placeholder-title">
          {{ $vueTranslate('profile_notes.notes.notes_none') }}
        </div>
        <div class="secondary-placeholder-title">
          {{ $vueTranslate('profile_notes.notes.note_create_first') }}
        </div>
        <a :href="watch_page" class="placeholder-button btn btn-primary">
          {{ $vueTranslate('profile_notes.notes.watch_page_link') }}
        </a>
      </div>
    </div>

    <div v-if="episodes.length > 0 && !noteEditing" class="notes-title-row">
      <span class="notes-title">
        <span class="title-text">{{
          $vueTranslate('profile_notes.notes.notes_title')
        }}</span>
        <span class="counter">({{ count }})</span>
      </span>

      <span class="notes-search">
        {{ $vueTranslate('profile_notes.notes.note_search_stub') }}
      </span>
    </div>

    <div v-if="!noteEditing" class="main-block row">
      <NotesEpisode
        v-for="episode in episodes"
        :key="episode.id"
        :episode="episode"
        @[EMITTERS.NOTE_DELETED_EMITTER]="deleteEpisode"
      />
    </div>
    <div v-if="noteEditing" class="main-block row">
      <NotesEpisode
        :key="episodeForEdit.id"
        :episode="episodeForEdit"
        @[EMITTERS.NOTE_DELETED_EMITTER]="deleteEpisode"
      />
    </div>
  </div>
</template>

<script>
import { remove, find } from 'lodash'

import NotesEpisode from './NotesEpisode'
import ApiService from '../services/episodesApi.service'
import { EMITTERS } from '../../shared/utils/emitters'

export default {
  name: 'EpisodesComponent',
  components: {
    NotesEpisode,
  },
  data() {
    return {
      count: 0,
      episodes: [],
      loading: true,
      watch_page: window.location.origin,
      EMITTERS,
    }
  },
  computed: {
    noteForEdit() {
      return this.$store.state.note
    },
    noteEditing() {
      if (!this.noteForEdit) return false

      return !!this.noteForEdit.id
    },
    episodeForEdit() {
      const episode = this.episodes.find((episode) => {
        return !!episode.notes.find((note) => this.noteForEdit.id === note.id)
      })
      if (!episode) {
        this.$store.dispatch('resetNote')
      }
      if (this.noteForEdit.isBlank) {
        const note = episode.notes.find(
          (note) => note.id === this.noteForEdit.id
        )
        this.$store.dispatch('editNote', note)
      }
      return episode
    },
  },
  watch: {
    $route(to, from) {
      this.updateBreadcrumbs(to)

      if (
        !(this.noteForEdit && this.noteForEdit.id) &&
        to.name === 'profile_note_edit'
      ) {
        const note = this.initNote(parseInt(this.$route.params.id))
        if (!note) return this.$router.push({ name: 'profile_note_list' })

        return this.$store.dispatch('editNote', note)
      }

      if (to.name !== 'profile_note_list') return true
      if (
        this.noteForEdit &&
        this.noteForEdit.id &&
        from.name !== 'profile_note_edit'
      ) {
        return this.$router.push({
          name: 'profile_note_edit',
          params: { id: this.noteForEdit.id },
        })
      }

      this.$store.dispatch('resetNote')
    },
  },
  created() {
    this.fetchEpisodes()
  },
  methods: {
    updateBreadcrumbs(route) {
      const breadcrumbs = [
        {
          title: 'Notes',
          to: '/',
        },
      ]
      if (route.name === 'profile_note_list') {
        this.$globalStore.dispatch('updateBreadcrumbs', breadcrumbs)
      } else if (route.name === 'profile_note_edit') {
        breadcrumbs.push({
          title: 'Edit note',
          to: route.path,
          params: route.params,
        })
        this.$globalStore.dispatch('updateBreadcrumbs', breadcrumbs)
      }
    },
    deleteEpisode(obj) {
      const episode = find(
        this.episodes,
        (element) => element.id === obj.episode.id
      )
      if (episode)
        episode.notes = episode.notes.filter(
          (note) => note.id !== obj.deletedNote.id
        )

      if (obj.notes.length === 0) {
        remove(this.episodes, (element) => element.id === obj.episode.id)
      }
      this.count--
    },
    fetchEpisodes() {
      ApiService.getAllEpisodes(this.optional_params)
        .then((response) => {
          const responseData = response.data

          this.episodes = responseData.episodes
          this.count = responseData.count
          this.watch_page = responseData.watch_page

          if (
            this.$route.name === 'profile_note_edit' &&
            !(this.noteForEdit && this.noteForEdit.id)
          ) {
            const note = this.initNote(parseInt(this.$route.params.id))
            if (!note) return this.$router.push({ name: 'profile_note_list' })

            this.$store.dispatch('editNote', note)
          }
        })
        .catch((e) => {
          this.errors.push(e)
          console.error(this.errors)
        })
        .finally(() => {
          this.loading = false
        })
    },
    initNote(id) {
      let note = null

      this.episodes.forEach((episode) => {
        const _note = episode.notes.find((n) => n.id === id)

        if (_note) note = _note
      })
      return note
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../shared/assets/styles/global';

.episodes-block {
  width: 41.75rem;
  height: 21.625rem;
  left: 32.125rem;
  top: 18.438rem;
  background: #ffffff;
  box-shadow: 0 0.25rem 2.5rem rgba(167, 167, 167, 0.15);
  border-radius: 0.75rem;
  padding: 6.563rem 4.875rem 6.563rem 4.875rem;
  text-align: center;
  margin-right: 3.2rem;
}

.main-block {
  width: 100%;
  padding: 0 16px 0 32px;
  margin-bottom: 72px;
  display: flex;
}

.notes-title-row {
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13px;
  margin-right: 48px;

  .notes-title {
    height: 42px;
    vertical-align: middle;
    display: flex;
  }

  .title-text {
    font-size: 24px;
    color: #323232;
  }

  .counter {
    font-size: 14px;
    margin: auto 4px;
    color: #a6a6a6;
  }
}

.episodes-placeholder {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  justify-content: center;
  height: 624px;
  .episodes-block {
    overflow: hidden;
  }
  .placeholder-border {
    position: relative;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -6.563rem;
      left: -4.875rem;
      width: 41.75rem;
      height: 4px;
      background: #d18f42;
    }
  }
}

.placeholder-button {
  height: 56px;
  width: 300px;
}

.primary-placeholder-title {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0;
  text-align: center;
  color: #5c5c5c;
}

.secondary-placeholder-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0;
  text-align: center;
  color: #323232;
  margin-bottom: 20px;
  margin-top: 12px;
}

.notes-search {
  font-size: 12px;
  color: #5c5c5c;
  background-color: #dbebf0;
  padding: 4px 12px;
  font-weight: 400;
}
</style>
