<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-chevron-up"
  >
    <polyline points="18 15 12 9 6 15" />
  </svg>
</template>

<script>
import { THEMES, THEME_COLOR } from '../../utils/view_options'

export default {
  name: 'ArrowUpIconComponent',
  props: {
    theme: {
      required: true,
      type: String, // THEMES.DARK | THEMES.LIGHT,
    },
  },
  methods: {
    fillIcon(theme) {
      return theme === THEMES.DARK ? THEME_COLOR.DARK : THEME_COLOR.LIGHT
    },
  },
}
</script>

<style scoped></style>
