import AdminLinks from '../../admin/video_resources/components/Links'
import AdminDocuments from '../../admin/video_resources/components/Documents'
import AdminGuides from '../../admin/video_resources/components/Guides'
import AdminImages from '../../admin/video_resources/components/Images'
import AdminEbooks from '../../admin/video_resources/components/Ebooks'
import Bookmarks from '../../study_tools/components/Bookmarks'
import VideoResources from '../../study_tools/components/VideoResources'
import Notes from '../../study_tools/components/Notes'
import Links from '../../study_tools/components/video_resources/Links'
import Donate from '../../study_tools/components/Donate'
import NotesList from '../../study_tools/components/NotesList'
import Episodes from '../../profile/components/Episodes'
import NewsList from '../../profile_news_bookmarks/components/NewsList'
import SearchPage from '../../search/views/SearchPage'
import VideoTab from '../../search/components/VideoTab'
import PropheticNewsTab from '../../search/components/PropheticNewsTab'
import AllTab from '../../search/components/AllTab'
import ArticleTab from '../../search/components/ArticleTab'
import VideoComments from '../../study_tools/components/VideoComments'
import EbookTab from '../../search/components/EbookTab'

export const VIDEO_RESOURCES_ADMIN_ROUTES = [
  { path: '/links', name: 'Links', component: AdminLinks },
  { path: '/documents', name: 'Documents', component: AdminDocuments },
  { path: '/guides', name: 'Study guides', component: AdminGuides },
  { path: '/images', name: 'Images', component: AdminImages },
  { path: '/ebooks', name: 'E-books', component: AdminEbooks },
]

export const VIDEO_RESOURCES_ROUTES = [
  { path: '/video_resources/links', name: 'Links', component: Links },
  {
    path: '/video_resources/documents',
    name: 'Documents',
    component: AdminDocuments,
  },
  {
    path: '/video_resources/guides',
    name: 'Study guides',
    component: AdminGuides,
  },
  {
    path: '/video_resources/images',
    name: 'Images',
    component: AdminImages,
  },
  {
    path: '/video_resources/ebooks',
    name: 'E-books',
    component: AdminEbooks,
  },
]

export const STUDY_TOOLS_ROUTES = [
  {
    path: '/comments',
    name: 'Comments',
    component: VideoComments,
    tooltip: 'New',
  },
  {
    path: '/video_resources',
    name: 'Video Resources',
    component: VideoResources,
    children: VIDEO_RESOURCES_ROUTES,
  },
  { path: '/bookmarks/:new?', name: 'Bookmarks', component: Bookmarks },
  { path: '/notes', name: 'Notes', component: Notes },
  { path: '/references', name: 'References', component: Donate },
  { path: '/egw_writings', name: 'EGW Writings', component: Donate },
  { path: '/bible', name: 'Bible', component: Donate },
]

export const STUDY_TOOLS_NOTES_ROUTES = [
  { path: '/', name: 'profile_note_list', component: NotesList },
  { path: '/:id', name: 'profile_note_edit', component: NotesList },
]

export const STUDY_TOOLS_BOOKMARKS_ROUTES = [
  { path: '/', name: 'profile_bookmarks_list', component: Episodes },
]

export const NEWS_BOOKMARKS_ROUTES = [
  { path: '/', name: 'profile_bookmarks_list', component: NewsList },
  { path: '/:id', name: 'profile_bookmark_edit', component: NewsList },
]
export const SEARCH_ROUTES = [
  {
    path: '/search',
    name: 'search',
    component: SearchPage,
    children: [
      { path: '', name: 'search-all', component: AllTab },
      { path: 'videos', name: 'search-videos', component: VideoTab },
      {
        path: 'prophetic-news',
        name: 'search-prophetic_news',
        component: PropheticNewsTab,
      },
      { path: 'articles', name: 'search-articles', component: ArticleTab },
      { path: 'ebooks', name: 'search-ebooks', component: EbookTab },
    ],
  },
]
