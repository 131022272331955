<template>
  <PreviewDialog
    v-if="ebook"
    :theme="theme"
    :entity="ebook"
    @[EMITTERS.CLOSE_DIALOG_EMITTER]="closePreview"
  />
</template>

<script>
import { THEMES } from '../shared/utils/view_options'
import { EMITTERS } from '../shared/utils/emitters'
import PreviewDialog from '../admin/video_resources/components/PreviewDialog'

export default {
  name: 'NewsContentAppComponent',
  components: {
    PreviewDialog,
  },
  data() {
    return {
      EMITTERS,
      theme: THEMES.LIGHT,
      ebook: null,
      ebooks: [],
    }
  },
  mounted() {
    this.ebooks = JSON.parse(this.optional_params.ebooks).map((ebook) =>
      JSON.parse(ebook)
    )
    const elements = document.querySelectorAll(
      `.${this.optional_params.element_class}`
    )
    elements.forEach((element) => {
      element.addEventListener('click', this.handleEbookClick)
    })
  },
  beforeUnmount() {
    const elements = document.querySelectorAll(
      `.${this.optional_params.element_class}`
    )
    elements.forEach((element) => {
      element.removeEventListener('click', this.handleEbookClick)
    })
  },
  methods: {
    handleEbookClick(event) {
      event.preventDefault()
      event.stopPropagation()

      const ebookId = event.currentTarget.getAttribute('ebook-id')
      this.showEbook(ebookId)
    },
    showEbook(ebookId) {
      const ebook = this.ebooks.find((ebook) => ebook.id === parseInt(ebookId))
      if (ebook) {
        this.ebook = ebook
      }
    },
    closePreview() {
      this.ebook = null
    },
  },
}
</script>
