import axios from 'axios'

import { API_ROUTES, BASE_API_CONFIG } from '../../shared/utils/api'

export default {
  DEFAULT_ORDER: 'desc',

  getBanner(id) {
    return axios.request({
      ...BASE_API_CONFIG,
      method: 'GET',
      url: API_ROUTES.banners(id),
    })
  },

  updateBanner(id, banner) {
    return axios.request({
      ...BASE_API_CONFIG,
      method: 'PUT',
      url: API_ROUTES.banners(id),
      data: {
        banner,
      },
    })
  },
}
