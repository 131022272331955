<template>
  <div class="search-nav" :class="{ 'search-nav__light': isLight }">
    <NavItem tab-name="all" :is-light="isLight" :query="query" />
    <NavItem tab-name="videos" :is-light="isLight" :query="query" />
    <NavItem tab-name="prophetic_news" :is-light="isLight" :query="query" />
    <NavItem tab-name="articles" :is-light="isLight" :query="query" />
    <NavItem tab-name="ebooks" :is-light="isLight" :query="query" />
  </div>
</template>

<script>
import NavItem from './NavItem'
import { THEMES } from '../../../shared/utils/view_options'
export default {
  name: 'NavBar',
  components: { NavItem },
  props: {
    isLight: {
      required: true,
      default: false,
      type: Boolean,
    },
    query: {
      type: String,
      default: '',
      required: true,
    },
  },
  methods: {
    isLightTheme() {
      return this.theme === THEMES.LIGHT
    },
  },
}
</script>

<style lang="scss" scoped>
.search-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  border-bottom: 1px #32303c solid;
  margin-bottom: 32px;

  @media (max-width: 463.98px) {
    overflow: auto;
    height: auto;

    &::-webkit-scrollbar {
      background-color: transparent;
      width: 3px;
      height: 2px;
      &:hover {
        width: 10px;
      }
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      height: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #8f8a9b;
      border-radius: 4px;
      border: 4px solid #8f8a9b;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }
  }
}

.search-nav__light {
  border-bottom: 1px #eaedee solid;
}
</style>
