<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_10782_13212"
      style="mask-type: alpha;"
      maskUnits="userSpaceOnUse"
      x="4"
      y="4"
      width="17"
      height="16"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.7401 4.99731C16.7218 4.05489 18.2769 4.07076 19.2392 5.03302L19.4102 5.204C20.4006 6.19443 20.3842 7.80522 19.3737 8.77524L11.1164 16.7023L7.40912 12.9951L15.7401 4.99731ZM5.96604 14.3804L4.80747 15.4926C4.61104 15.6812 4.5 15.9417 4.5 16.214V18.5009C4.5 19.0532 4.94771 19.5009 5.5 19.5009H7.79884C8.057 19.5009 8.30515 19.4011 8.49138 19.2223L9.6733 18.0877L5.96604 14.3804Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_10782_13212)">
      <rect x="1" width="24" height="24" :fill="fillIcon()" />
    </g>
  </svg>
</template>

<script>
import { THEMES, THEME_COLOR } from '../../utils/view_options'

export default {
  name: 'EditIconComponent',
  props: {
    theme: {
      required: true,
      type: String, // THEMES.DARK | THEMES.LIGHT,
    },
  },
  methods: {
    fillIcon() {
      return this.theme === THEMES.DARK ? THEME_COLOR.DARK : THEME_COLOR.LIGHT
    },
  },
}
</script>

<style scoped>
svg {
  cursor: pointer;
}
</style>
