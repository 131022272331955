import axios from 'axios'

import { API_ROUTES, BASE_API_CONFIG } from '../../shared/utils/api'

export default {
  getAllMessages(params = {}) {
    return axios.request({
      ...BASE_API_CONFIG,
      method: 'GET',
      url: API_ROUTES.notifications,
      params: params,
    })
  },

  markMessageAsSeen(notification_id) {
    return axios.request({
      ...BASE_API_CONFIG,
      method: 'PUT',
      url: `${API_ROUTES.notifications}/${notification_id}`,
      params: {
        seen_at: new Date(),
      },
    })
  },
}
