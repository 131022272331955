import { scrollToTarget } from 'utils'

$(document).on('turbolinks:load', () => initForm())
$(document).on('job_opportunity_form:load', () => initForm())

function initForm() {
  const $headerButton = $('.vacancy__header-button')
  const $vacancy = $('.vacancy')
  if ($vacancy.length === 0) return

  const $coverLetterInput = $('#cover_letter')
  const $cvInput = $('#cv')
  const $coverLetterLabel = $(
    '.job-form__attachment-button[for="cover_letter"]'
  )
  const $cvLabel = $('.job-form__attachment-button[for="cv"]')

  $coverLetterInput.on('change', () => {
    let filename = document.getElementById('cover_letter').files[0].name
    $coverLetterLabel.removeClass('invalid-input').html(filename)
  })

  $cvInput.on('change', () => {
    let filename = document.getElementById('cv').files[0].name
    $cvLabel.removeClass('invalid-input').html(filename)
  })

  $headerButton.on('click', () => {
    scrollToTarget('#job-form')
  })
}
