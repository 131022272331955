<template>
  <div class="s-block container" data-turbolinks="false">
    <img
      v-if="loading"
      src="../shared/assets/images/loading.gif"
      alt="loading"
      class="s-loader"
    />
    <router-view v-if="!loading" :settings="settings" />
  </div>
</template>

<script>
import settingsApiService from '../shared/services/settingsApi.service'

export default {
  name: 'App',
  data() {
    return {
      settings: null,
      loading: true,
    }
  },
  mounted() {
    this.fetchSettings()
  },
  methods: {
    fetchSettings() {
      settingsApiService
        .fetchSettings()
        .then((response) => {
          this.settings = response.data
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
.s-block {
  margin-bottom: 32px;
}
.s-loader {
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 64px;
}

.search-type-block {
  display: flex;
  margin-left: auto;
  flex-direction: row;
  gap: 24px;

  .radio-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 26px;

    .radio-label {
      display: flex;
      align-items: center;
      margin-left: 20px;
      margin-top: 5px;
      color: #ffffff;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: DM Sans, serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }

    .radio-label-white {
      color: #323232;
    }

    input[type='radio']:after {
      width: 24px;
      height: 24px;
      border-radius: 15px;
      top: -7px;
      right: 5px;
      position: relative;
      background-color: #131118;
      content: '';
      display: inline-block;
      visibility: visible;
      border: 3px solid #131118;
      outline: 1px solid #706c7a;
    }

    input[type='radio']:checked:after {
      width: 24px;
      height: 24px;
      border-radius: 15px;
      top: -7px;
      right: 5px;
      position: relative;
      background-color: #6998ae;
      content: '';
      display: inline-block;
      visibility: visible;
      border: 3px solid #131118;
      outline: 1px solid #6998ae;
    }
  }

  .radio-block-white {
    input[type='radio']:checked:after {
      border: 3px solid #ffffff;
    }

    input[type='radio']:after {
      background-color: #ffffff;
      border: 3px solid #ffffff;
    }
  }
}
</style>
