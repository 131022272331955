<template>
  <div class="document__preview-title">
    {{ headerTitle }}
  </div>
  <div ref="documentContainer" class="document__container">
    <div class="video-resources__file__icon">
      <div class="video-resources__file__format">
        {{ fileFormat }}
      </div>
    </div>
    <span :class="{ document__text__wrapped: true }" class="document__text">
      {{ fileTitle }}
    </span>
    <div class="document__control-spacer" />
    <a
      :href="entity.document.downloadLink"
      download
      target="_blank"
      class="video-resources_download"
    >
      <DownloadIcon :theme="theme" />
      <div class="video-resources__filesize">
        {{ entity.document.file_size }}
      </div>
    </a>
  </div>
  <div class="document__description">
    {{ entity.description }}
  </div>
</template>
<script>
import { THEMES } from '../../../shared/utils/view_options'
import DownloadIcon from '../../../shared/components/icons/Download'

export default {
  name: 'DocumentComponent',
  components: {
    DownloadIcon,
  },
  props: {
    theme: {
      type: String,
      default: THEMES.LIGHT,
    },
    active: {
      type: Number,
      default: null,
    },
    // eslint-disable-next-line vue/require-default-prop
    entity: {
      id: Number | null,
      title: String,
      description: String,
      document: File,
    },
  },
  data() {
    return {
      THEMES,
    }
  },
  computed: {
    fileTitle() {
      return this.entity.title.replace(/\..+/gi, '')
    },
    fileFormat() {
      if (!this.entity.document.name) return ''
      if (this.entity.document.name.split('.').length === 1) return ''

      return this.entity.document.name.match(/\.\w+/gi).pop().replace('.', '')
    },
    headerTitle() {
      if (this.entity.type === 'guide')
        return this.$vueTranslate('admin.document_preview.study_guide_title')
      if (this.entity.type === 'transcript') return 'Transcript'
      return this.$vueTranslate('admin.document_preview.document_title')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../shared/assets/styles/video_resources';
@import '../../../shared/assets/styles/document';

.document__container {
  width: 100%;
  padding: 0;
}

.document__text {
  width: 65%;
}

.light {
  .document__container {
    border: none;

    &:hover {
      background-color: #ffffff;
    }
  }
}

.dark {
  .document__container {
    border: none;
  }
}

.video-resources_download {
  &:hover {
    text-decoration: none;
  }
}

@media (max-width: 991px) {
  .document__preview-title {
    display: none;
  }
  .document__container.ebook__container {
    display: none;
  }
  .document__description {
    width: 100%;
  }
}
</style>
