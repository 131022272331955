import axios from 'axios'

import { API_ROUTES, BASE_API_CONFIG } from '../../shared/utils/api'

export default {
  getAllTags(query = '', optional_params = {}) {
    return axios.request({
      ...BASE_API_CONFIG,
      method: 'GET',
      url: API_ROUTES.tags,
      params: {
        query,
        ...optional_params,
      },
    })
  },
}
