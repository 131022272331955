import { Quill } from '@vueup/vue-quill'

import { WrapperBlot, WrappedBlot } from './blot_wrapper'

const CodeBlock = Quill.import('formats/code-block')
const ListBlock = Quill.import('formats/list')

class ExpandContainer extends WrapperBlot {
  optimize(context) {
    super.optimize(context)
    this.clearTitle()
  }

  replace(target) {
    super.replace(target)
    this.clearTitle()
  }

  clearTitle() {
    while (this.domNode.getElementsByClassName('expand-title').length > 1) {
      const titles = this.domNode.getElementsByClassName('expand-title')
      titles[titles.length - 1].remove()
    }
  }

  static create() {
    let node = super.create()
    let title = document.createElement('P')
    title.classList.add('expand-title')
    title.innerText = 'References'
    node.appendChild(title)

    return node
  }

  static initCollapse(node) {
    node.classList.add('collapsed')
    node.addEventListener('click', () => {
      if (node.classList.contains('collapsed')) {
        node.classList.remove('collapsed')
      } else {
        node.classList.add('collapsed')
      }
    })
  }
}

function initExpandSection() {
  let icons = Quill.import('ui/icons')

  icons['expand'] = '<div class="icon-expand" aria-hidden="true">Expand</div>'

  ExpandContainer.blotName = 'expand'
  ExpandContainer.tagName = 'aside'
  ExpandContainer.defaultChild = 'expand-paragraph'
  ExpandContainer.className = 'article-references'

  class ExpandParagraph extends WrappedBlot {}

  ExpandParagraph.blotName = 'expand-paragraph'
  ExpandParagraph.className = 'expand-paragraph'
  ExpandParagraph.tagName = 'p'
  ExpandParagraph.parentName = 'expand'

  class ExpandTitle extends WrappedBlot {
    replaceWith() {}
  }

  ExpandTitle.blotName = 'expand-title'
  ExpandTitle.className = 'expand-title'
  ExpandTitle.tagName = 'p'
  ExpandTitle.parentName = 'expand'

  ExpandContainer.allowedChildren = [
    ExpandParagraph,
    ExpandTitle,
    CodeBlock,
    ListBlock,
  ]
  ExpandParagraph.requiredContainer = ExpandContainer
  ExpandTitle.requiredContainer = ExpandContainer

  Quill.register(ExpandContainer)
  Quill.register(ExpandParagraph)
  Quill.register(ExpandTitle)
}

export { initExpandSection, ExpandContainer }
