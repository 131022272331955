<template>
  <div class="links__title">Links</div>
  <div class="links__container">
    <div class="links__create">
      <div class="links__secondary-title">
        {{ $vueTranslate('admin.links.add_btn') }}
      </div>
      <Link
        :mode="MODES.CREATE"
        @[EMITTERS.LINK_CREATED_EMITTER]="linkCreated"
      />
    </div>
    <div class="links__index">
      <div class="links__secondary-title">
        {{ $vueTranslate('admin.links.created_btn') }}
      </div>
      <Link
        v-for="link of links"
        :key="link.id"
        :link="link"
        :mode="MODES.EDIT"
        @[EMITTERS.LINK_DELETED_EMITTER]="linkDeleted"
      />
    </div>
  </div>
</template>

<script>
import Link from './Link.vue'
import { VIDEO_RESOURCES_MODES as MODES } from '../../../shared/utils/view_options'
import LinksApiService from '../services/linksApi.service'
import { EMITTERS } from '../../../shared/utils/emitters'

export default {
  name: 'LinksComponent',
  components: {
    Link,
  },
  data() {
    return {
      MODES,
      EMITTERS,
      links: [],
    }
  },
  created() {
    this.fetchLinks()
  },
  methods: {
    fetchLinks() {
      LinksApiService.getAllLinks(this.optional_params)
        .then((response) => {
          const responseData = response.data

          this.links = responseData.links
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false
        })
    },
    linkCreated() {
      this.fetchLinks()
    },
    linkDeleted() {
      this.fetchLinks()
    },
  },
}
</script>

<style lang="scss" scoped>
.links__container {
  background-color: #ffffff;
  width: 95%;
  min-height: 610px;
}

.links__title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #323232;
  margin-bottom: 16px;
}

.links__secondary-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #5c5c5c;
  padding-bottom: 10px;
}

.links__create,
.links__index {
  padding: 16px;
}

.links__create {
  border-bottom: 1px solid #eaedee;
}
</style>
