<template>
  <div v-if="items.length > 0 || !loading" class="entries-carousel-container">
    <div
      v-if="currentIndex !== 0"
      class="entries-carousel-button entries-carousel-button__left"
      :class="{ 'entries-carousel-button__light': isLight }"
      @click="scrollLeft"
    >
      {{ BTN_LEFT }}
    </div>
    <div class="entries-carousel" :style="carouselStyle">
      <StoreItemCard
        v-for="item in items"
        :key="item.id"
        :title="item.title"
        :sku="item.sku"
        :is-light="isLight"
        :cover-url="item.thumbnail_url"
        :author="item.author"
        :item-id="item.id"
        class="entries-carousel-item"
        @[EMITTERS.STORE_ITEM_RELOAD]="updateItem"
        @[EMITTERS.STORE_ITEM_DESTROY]="destroyItem"
      />
    </div>
    <div
      v-if="currentIndex !== items.length - skip && items.length > 3"
      class="entries-carousel-button entries-carousel-button__right"
      :class="{ 'entries-carousel-button__light': isLight }"
      @click="scrollRight"
    >
      {{ BTN_RIGHT }}
    </div>
  </div>
</template>

<script>
import StoreItemCard from './StoreItemCard'
import { EMITTERS } from '../../../shared/utils/emitters'

export default {
  name: 'EntriesCarousel',
  components: { StoreItemCard },
  props: {
    items: {
      default: () => [],
      type: Array,
      required: true,
    },
    isLight: {
      default: true,
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      EMITTERS,
      loading: false,
      storeItems: this.items,
      BTN_LEFT: '<',
      BTN_RIGHT: '>',
      skip: 3,
      currentIndex: 0,
      translateX: 0,
    }
  },
  computed: {
    carouselStyle() {
      return {
        transform: `translateX(${this.translateX}px)`,
      }
    },
  },
  watch: {
    currentIndex(newIndex) {
      if (newIndex < 0) {
        this.currentIndex = 0
      } else if (newIndex > this.storeItems.length - this.skip) {
        this.currentIndex = this.storeItems.length - this.skip
      }
    },
  },
  methods: {
    destroyItem(sku) {
      this.loading = true
      const index = this.storeItems.findIndex((item) => item?.sku === sku)

      if (index !== -1) this.storeItems.splice(index, 1)
      this.loading = false
      this.$emit(EMITTERS.STORE_ITEM_UPDATE, this.storeItems)
    },
    updateItem(sku, updateItem) {
      this.loading = true
      const index = this.storeItems.findIndex((item) => item?.sku === sku)

      if (index !== -1) this.storeItems[index] = updateItem

      this.loading = false
      this.$emit(EMITTERS.STORE_ITEM_UPDATE, this.storeItems)
    },
    scrollLeft() {
      if (this.currentIndex > 0) {
        this.currentIndex--
        this.translateX += this.getJumpSize()
      }
    },
    scrollRight() {
      if (this.currentIndex < this.storeItems.length - this.skip) {
        this.currentIndex++
        this.translateX -= this.getJumpSize()
      }
    },
    getJumpSize() {
      return this.getItemWidth() + this.getJumpByWidth()
    },
    getJumpByWidth() {
      const width = document.body.clientWidth

      if (width <= 991) return 64

      return 32
    },
    getItemWidth() {
      return this.$el.querySelector('.entries-carousel-item').offsetWidth
    },
  },
}
</script>

<style lang="scss" scoped>
.entries-carousel-container {
  max-width: 959px;
  overflow: hidden;
  position: relative;
  margin: 20px 0;
}

.entries-carousel {
  display: flex;
  flex-wrap: nowrap;
  transition: transform 0.3s ease-in-out;
  gap: 29px;
}

.entries-carousel-item {
  flex: 0 0 auto;
  width: calc((100% - 60px) / 3);
  max-width: 270px;
  min-width: 200px;
}

.entries-carousel-button {
  position: absolute;
  display: flex;
  cursor: pointer;
  width: 64px;
  height: 140px;
  top: 0;
  padding: 50px 22px;
  font-size: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #ffffff;
  background: rgba(11, 9, 15, 0.8);
  box-shadow: 0 4px 40px 0 rgba(167, 167, 167, 0.15);
  z-index: 10;
}

.entries-carousel-button__left {
  left: 0;
}

.entries-carousel-button__right {
  right: 0;
}

.entries-carousel-button__light {
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(4px);
  color: #131118;

  &:hover {
    background: rgba(255, 255, 255, 0.7);
  }
}

.all-card {
  flex: 0 0 24%;
  min-width: 200px;
  max-width: 260px;
  height: 140px;
  background-color: #32303c;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 128%;
}

.all-card__light {
  background-color: #8f8a9b;
}
</style>
