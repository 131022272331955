import axios from 'axios'

import {
  API_ROUTES,
  FILE_UPLOAD_CONFIG,
  config,
} from '../../../shared/utils/api'

export default {
  DEFAULT_ORDER: 'desc',

  generateTranscript(optional_params = {}) {
    return axios.request({
      ...FILE_UPLOAD_CONFIG,
      method: 'POST',
      url: API_ROUTES.generateTranscript(optional_params.episode_id),
    })
  },

  getAllTranscripts(optional_params = {}) {
    return axios.request({
      ...FILE_UPLOAD_CONFIG,
      method: 'GET',
      url: API_ROUTES.transcripts(optional_params.episode_id),
    })
  },

  getPublicTranscripts(optional_params = {}) {
    return axios.request({
      ...FILE_UPLOAD_CONFIG,
      method: 'GET',
      url: API_ROUTES.publicTranscripts(optional_params.episode_id),
    })
  },

  async createTranscript(transcript, optional_params, onProgress) {
    return new Promise(async (resolve, reject) => {
      await axios
        .post(
          API_ROUTES.transcripts(optional_params.episode_id),
          this.formData(transcript),
          config(onProgress)
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error))
    })
  },

  deleteTranscript(transcript, optional_params = {}) {
    return axios.request({
      ...FILE_UPLOAD_CONFIG,
      method: 'DELETE',
      url: API_ROUTES.transcript(optional_params.episode_id, transcript.id),
    })
  },

  async updateTranscript(transcript, optional_params = {}, onProgress) {
    return new Promise(async (resolve, reject) => {
      await axios
        .put(
          API_ROUTES.transcript(optional_params.episode_id, transcript.id),
          this.formData(transcript),
          config(onProgress)
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error))
    })
  },

  formData(transcript) {
    const formData = new FormData()
    if (transcript.document && transcript.document.size !== undefined) {
      const transcriptName = transcript.document.name
      formData.append('document', transcript.document, transcriptName)
    }
    if (transcript.document.name) {
      formData.append('title', this.documentName(transcript))
    }
    if (transcript.description) {
      formData.append('description', transcript.description)
    }
    formData.append('public', transcript.public)

    return formData
  },

  documentName(transcript) {
    return transcript.document.name.split('.').shift()
  },
}
