<template>
  <div>
    <BreadcrumbsComponent v-if="showBreadcrumbs" />
    <NewsList />
  </div>
</template>

<script>
import NewsList from './components/NewsList'
import BreadcrumbsComponent from '../shared/components/Breadcrumbs'

export default {
  name: 'ProfileNewsBookmarksAppComponent',
  components: {
    NewsList,
    BreadcrumbsComponent,
  },
  computed: {
    showBreadcrumbs() {
      return this.$route && this.$route.name === 'profile_bookmark_edit'
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../shared/assets/styles/global';
</style>
