<template>
  <div class="link-block">
    <div
      class="link__container"
      :class="{ 'link__with-description': description }"
      @click="description ? toggleModal() : null"
    >
      <span class="link__title">{{ title }}</span>
      <a
        target="_blank"
        class="link__link"
        :title="title"
        :href="linkUrl"
        :class="{ link__disabled: description }"
      >
        {{ link }}
      </a>
    </div>
    <LinkDescriptionModal
      v-if="isModalVisible"
      :title="title"
      :description="description"
      :link="linkUrl"
      :link-title="link"
      @[EMITTERS.VRL_CLOSE_MODAL]="toggleModal"
    />
  </div>
</template>

<script>
import { EMITTERS } from '../../../shared/utils/emitters'
import LinkDescriptionModal from './LinkDescriptionModal'

export default {
  name: 'LinkComponent',
  components: { LinkDescriptionModal },
  props: {
    title: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      isModalVisible: false,
      EMITTERS,
    }
  },
  computed: {
    linkUrl() {
      return this.link.startsWith('https') ? this.link : 'https://' + this.link
    },
  },
  methods: {
    toggleModal() {
      this.isModalVisible = !this.isModalVisible
    },
  },
}
</script>

<style lang="scss" scoped>
.link__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 53px;
  flex-wrap: wrap;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  justify-content: center;
  padding-left: 24px;
}

.link__with-description:hover {
  cursor: pointer;
  background: #32303c;
}

.link__disabled {
  pointer-events: none;
}

.link__link {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 520px;
}

.link__title {
  width: 520px;
  overflow: hidden;
}

@media (max-width: 991px) {
  .link__container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  .link__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  .link__link {
    max-width: 100%;
  }
}
</style>
