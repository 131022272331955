import { createStore } from 'vuex'

const studyToolsStore = createStore({
  state() {
    return {
      note: {
        title: '',
        content: '',
        tags: [],
      },
      noteResetTrigger: false,
      noteChanged: false,
      leaveConfirmationCallbackAdded: false,
    }
  },
  mutations: {
    resetNote(state, note) {
      Object.assign(state.note, note)
      state.noteResetTrigger = !state.noteResetTrigger
    },
    noteChanged(state, value) {
      state.noteChanged = value
    },
    leaveConfirmationCallbackAdded(state, _value) {
      state.leaveConfirmationCallbackAdded = true
    },
  },
  actions: {
    resetNote() {
      this.commit('resetNote', {
        id: undefined,
        title: '',
        content: '',
        tags: [],
      })
    },
    editNote(state, note) {
      this.commit('resetNote', note)
    },
    updateNoteChanged(state, value) {
      this.commit('noteChanged', value)
    },
    leaveConfirmationCallbackAdded() {
      this.commit('leaveConfirmationCallbackAdded')
    },
  },
})

export default studyToolsStore
