<template>
  <div class="episode-progress__back">
    <div
      :style="{ width: progressBar.percent + '%' }"
      class="episode-progress__percent"
    />
  </div>
</template>

<script>
export default {
  name: 'EpisodeProgressComponent',
  props: {
    progressBar: {
      percent: Number,
      start_time_seconds: Number,
    },
  },
}
</script>

<style scoped>
.episode-progress__back {
  width: 100%;
  height: 4px;
  background: rgba(209, 211, 212, 0.8);
}
.episode-progress__percent {
  max-width: 100%;
  height: 4px;
  background: #d18f42;
}
</style>
