// TODO: think of calculating .dropdown-menu hight dynamically from viewport size
// because 5 rows in a column can create too many columns.
// Example: https://monosnap.com/file/aNBNfYAsdjnWjRW3xjbcsZqmg7Owt8
// Tech debt item: https://coda.io/d/Amazing-Discoveries-shared_daD69i5U8P9/Tech-debt-sink_suy3x#Tech-debt-tasks_tuJWy/r169
// Finalization epic item: https://coda.io/d/Amazing-Discoveries-shared_daD69i5U8P9/Projects_suBaq#Projects-Source_tuiP0/r512
const DROPDOWN_MENU_SELECTOR = '.dropdown_menu'
const INITIAL_CSS_LEFT_KEY = 'initialCssLeft'

/**
 * Calculates dropdown menu width based on max width of its items
 * @param {jQuery object} $dropdownMenu Dropdown menu jQuery object
 * @returns Calculated menu width
 */
function calculateMenuWidth($dropdownMenu) {
  // WARNING: update this algorithm if horizontal margin or border
  // for the dropdown menu gets introduced
  const dropdownLeftPadding = parseInt($dropdownMenu.css('padding-left'))
  const dropdownRightPadding = parseInt($dropdownMenu.css('padding-right'))
  const $lastDropdownItem = $dropdownMenu.find('.dropdown_menu_item').last()
  const $firstDropdownItem = $dropdownMenu.find('.dropdown_menu_item').first()
  const lastDropdownItemMargin = parseInt($lastDropdownItem.css('margin-right'))

  return (
    dropdownLeftPadding +
    $lastDropdownItem.get(0).getBoundingClientRect().right +
    lastDropdownItemMargin -
    $firstDropdownItem.get(0).getBoundingClientRect().left + // use 'left' here for IE11 compat
    dropdownRightPadding
  )
}

/**
 * Set Dropdown menu CSS 'left' to make it fit into the viewport
 * @param {jQuery object} $dropdownMenu Dropdown menu jQuery object
 * @param {Number} menuWidth Calculated menu width
 */
function setDropdownMenuOffset($dropdownMenu, menuWidth) {
  // reset CSS 'left' to initial (being relative to the parent - sub-menu item)
  $dropdownMenu.css({ left: $dropdownMenu.data(INITIAL_CSS_LEFT_KEY) })

  const overlap =
    $dropdownMenu.offset().left + // relative to the viewport
    menuWidth -
    $(window).width() // viewport width

  if (overlap > 0)
    $dropdownMenu.css({
      left:
        $dropdownMenu.siblings('.dropdown_button').offset().left - // relative to the viewport
        $dropdownMenu.offset().left - // relative to the viewport
        overlap,
    })
}

$(document).on('turbolinks:load', () => {
  const $dropdownMenus = $(
    `.watch_landing_sub_menu.flat ${DROPDOWN_MENU_SELECTOR}`
  )

  $dropdownMenus.each((_idx, menu) => {
    const $menu = $(menu)
    $menu.data(INITIAL_CSS_LEFT_KEY, $menu.css('left'))
  })

  // HACK: this array exists to memoize a dropdown menu after setting its size, to set it only once.
  // Without this, every new time a dropdown menu is shown it gets a slightly different width due to
  // an unknown glitch.
  const initializedDropdownMenus = []

  // Set width for the dropdown menu.
  // Step 1.
  // We need to set its with JS because the dropdown container
  // has 'position: absolute' which makes it a block element
  // and its flex children cannot interact with its block context
  // and thus cannot influence the growth of its width.
  // See https://stackoverflow.com/a/30191695/437280.
  // Step 2.
  // We position the dropdown menu popup in a way it
  // doesn't go off the right screen edge, based on width of its portion
  // that goes off the screen and its initial 'left' offset relative to its sub-menu
  const navItem = $('.watch_landing_sub_menu .dropdown_block')
  navItem.on('shown.bs.dropdown', function () {
    $(this).closest('.nav-item').addClass('active')
    const $dropdownMenu = $(this).find(DROPDOWN_MENU_SELECTOR)
    const dropdownMenuNode = $dropdownMenu.get(0)
    if (initializedDropdownMenus.includes(dropdownMenuNode)) return

    const menuWidth = calculateMenuWidth($dropdownMenu)
    $dropdownMenu.css({ width: menuWidth })

    setDropdownMenuOffset($dropdownMenu, menuWidth)

    initializedDropdownMenus.push(dropdownMenuNode)
  })
  navItem.on('hidden.bs.dropdown', function () {
    $(this).closest('.nav-item').removeClass('active')
  })
})
