// TODO: make this configurable via 'data' attribute of the div[data=collapsible_text] parent block
const COLLAPSED_TEXT_MAX_VISIBLE_LINES = 3

function lineHeightInPixels($collapsibleTextContainer) {
  const lineHeightStyle = $collapsibleTextContainer.css('line-height')

  // Generally speaking, it's possible that +line-height+ could have
  // "normal" value, however +line-height+ is set explicitly on <body> by theme.
  // In this case, +line-height+ value will be read in pixels (i.e. "24px") so
  // it's safe to call +parseFloat+.
  return parseFloat(lineHeightStyle)
}

function textContainerMaxHeight($collapsibleTextContainer) {
  const lineHeight = lineHeightInPixels($collapsibleTextContainer)

  return COLLAPSED_TEXT_MAX_VISIBLE_LINES * lineHeight
}

function getToggle($collapsibleText) {
  return $collapsibleText.find('.collapsible-text-toggle')
}

function hideToggle($collapsibleText) {
  getToggle($collapsibleText).hide()
}

function showToggle($collapsibleText) {
  getToggle($collapsibleText).show()
}

function textContainer($collapsibleText) {
  return $collapsibleText.find('.collapsible-text-container')
}

function isAllTextVisible($collapsibleText) {
  const $collapsibleTextContainer = textContainer($collapsibleText)
  const textContainerHeight = $collapsibleTextContainer.prop('scrollHeight')

  // Rounding because "textContainerHeight" is rounded as well.
  const maxHeight = Math.round(
    textContainerMaxHeight($collapsibleTextContainer)
  )

  return textContainerHeight <= maxHeight
}

function removeHeightStyles($collapsibleText) {
  const collapsibleTextId = $collapsibleText.attr('id')
  $(`#${collapsibleTextId}_style`).remove()
}

function applyHeightStyles($collapsibleText) {
  const $collapsibleTextContainer = textContainer($collapsibleText)
  const height = textContainerMaxHeight($collapsibleTextContainer)
  const heightPx = `${height}px`

  // Remove existing style element
  removeHeightStyles($collapsibleText)

  const collapsibleTextId = $collapsibleText.attr('id')
  const $styleElement = $('<style></style>', {
    id: `${collapsibleTextId}_style`,
    class: 'collapsible_text_style',
  }).appendTo($('head'))

  const styleSheet = $styleElement[0].sheet

  // Reference: https://developer.mozilla.org/en-US/docs/Web/API/CSSStyleSheet/insertRule
  // Set rule to apply fixed height to the visible part of collapsible text
  styleSheet.insertRule(
    `#${collapsibleTextId} .collapse:not(.show) { height:${heightPx} }`,
    styleSheet.cssRules.length
  )

  // Set rule to apply fixed height when collapsible text is collapsing,
  // in other words when "Show less" has been pressed.
  styleSheet.insertRule(
    `#${collapsibleTextId} .collapsing { height:${heightPx} }`,
    styleSheet.cssRules.length
  )
}

function setCollapsibleTextHeight($collapsibleText) {
  if (isAllTextVisible($collapsibleText)) {
    hideToggle($collapsibleText)
    // We have to remove any applied previously styles otherwise height of
    // the collapsible text will be incorrect
    removeHeightStyles($collapsibleText)
  } else {
    showToggle($collapsibleText)
    applyHeightStyles($collapsibleText)
  }
}

function setAttributes($collapsibleText, idx) {
  $collapsibleText.attr('id', `collapsibleText_${idx + 1}`)

  const collapsibleTextContainerId = `collapsibleTextContainer_${idx + 1}`
  textContainer($collapsibleText).attr('id', collapsibleTextContainerId)

  getToggle($collapsibleText)
    .attr('data-target', `#${collapsibleTextContainerId}`)
    .attr('aria-controls', `#${collapsibleTextContainerId}`)
}

function setCollapsibleTextsHeight($collapsibleTexts) {
  $collapsibleTexts.each((idx, element) => {
    const $collapsibleText = $(element)

    setAttributes($collapsibleText, idx)
    setCollapsibleTextHeight($collapsibleText)
  })
}

export function collapsibleText(scope) {
  const $scope = $(scope)
  const $collapsibleTexts = $scope.find('.collapsible-text')

  if ($collapsibleTexts.length === 0) return

  setCollapsibleTextsHeight($collapsibleTexts)

  $(window).on('resize', () => setCollapsibleTextsHeight($collapsibleTexts))
}

export function destroyAllCollapsibleTexts() {
  $('.collapsible_text_style').remove()
}
