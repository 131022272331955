<template>
  <div>
    <div class="comments-header study-tools-title-area">
      <span class="comments-title">
        <span class="title-text">
          {{ $vueTranslate('study_tools.comments.title') }}
        </span>
        <span class="counter">({{ count }})</span>
      </span>
    </div>

    <div class="comments-container">
      <div class="comments-list">
        <VideoComment
          v-if="!loading"
          :mode="MODES.CREATE"
          :theme="theme"
          :report-reasons="reportReasons"
          class="comments-form-container"
          @[EMITTERS.COMMENT_CREATED_EMITTER]="commentCreated"
          @[EMITTERS.COMMENT_CANCEL_EMITTER]="updateComments"
        />
        <div v-if="!loading">
          <VideoComment
            v-for="comment of comments"
            :key="comment"
            :comment="comment"
            :mode="MODES.SHOW"
            :theme="theme"
            :report-reasons="reportReasons"
            class="comments-form-container"
            @[EMITTERS.COMMENT_UPDATED_EMITTER]="updateComment"
            @[EMITTERS.COMMENT_DELETE_EMITTER]="deleteComment"
            @[EMITTERS.COMMENT_CANCEL_EMITTER]="updateComments"
          />
        </div>
        <LoaderComponent v-else class="comments-loader" />

        <span v-if="!loading && comments.length <= 0" class="no-comments">
          {{ $vueTranslate('study_tools.comments.empty') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { remove } from 'lodash'

import VideoComment from './VideoComment'
import LoaderComponent from '../../shared/components/Loader'
import { COMMENT_MODES as MODES, THEMES } from '../../shared/utils/view_options'
import { EMITTERS } from '../../shared/utils/emitters'
import commentsApiService from '../services/commentsApi.service'

export default {
  name: 'VideoComments',
  components: { LoaderComponent, VideoComment },
  data() {
    return {
      EMITTERS,
      MODES,
      theme: THEMES.DARK,
      loading: false,
      comments: [],
      reportReasons: [],
      count: 0,
    }
  },
  created() {
    this.fetchComments()
    this.emitter.on(EMITTERS.COMMENT_REPORTED, this.deleteComment)
  },
  methods: {
    commentCreated() {
      this.fetchComments()
    },
    fetchComments() {
      commentsApiService
        .getComments(this.optional_params)
        .then((response) => {
          const responseData = response.data

          this.comments = responseData.comments
          this.count = responseData.count
        })
        .finally(() => {
          this.fetchReportReasons()
        })
    },
    fetchReportReasons() {
      commentsApiService
        .getReportReasons(this.optional_params)
        .then((response) => {
          this.reportReasons = response.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    updateComment(comment) {
      const storedComment = this.comments.find(
        (element) => element.id === comment.id
      )
      Object.assign(storedComment, comment)
    },
    deleteComment(comment) {
      remove(this.comments, (element) => element.id === comment.id)
      this.count--
    },
    updateComments() {
      this.loading = true
      this.fetchComments()
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../shared/assets/styles/global';

.comments-header {
  font-weight: 500;
  display: flex;
  justify-content: space-between;

  .title-text {
    font-size: 20px;
    color: $white;
    margin-right: 4px;
  }

  .counter {
    font-size: 14px;
    color: $quick-silver;
  }
}

.comments-title {
  margin: auto 0;
}

.comments-container {
  display: flex;
  background-color: #2b2834;
  border-radius: 8px;
  padding-inline-start: 0;
  padding-bottom: 34px;
  height: 300px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 3px;

    &:hover {
      width: 10px;
    }
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #8f8a9b;
    border-radius: 4px;
    border: 4px solid #8f8a9b;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}

.comments-list {
  flex-wrap: wrap;
  width: 100%;
}

.comments-loader {
  margin: 50px auto;
  width: 200px;
  display: flex;
  vertical-align: middle;
}

.comments-form-container {
  min-height: 55px;
}

.no-comments {
  display: flex;
  justify-content: center;
  align-content: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #8f8a9b;
  margin-top: 100px;
}
</style>
