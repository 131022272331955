<template>
  <div
    class="comment-wrapper"
    :class="[theme, { 'create-mode': isCreateMode }]"
  >
    <CommentReportForm
      v-if="reportFormVisibiltiy"
      :report-reasons="reportReasons"
      :comment="localComment"
      @[EMITTERS.REPORT_CLOSE_MODAL]="closeReportForm"
    />
    <AuthorizationPopUp
      v-if="authorizationPopupVisibility"
      @[EMITTERS.AUTHORIZATION_CLOSE_MODAL]="closeModel"
    />
    <ConfirmationDialog
      v-if="isDeleteDialogVisibility"
      theme="dark"
      :entity="localComment"
      :confirmation-text="
        $vueTranslate('study_tools.comment.confirmation_text')
      "
      :delete-function="COMMENT_DELETE_FUNCTION"
      :on-deleted-emitter="EMITTERS.COMMENT_DELETE_EMITTER"
      @[EMITTERS.CLOSE_DIALOG_EMITTER]="closeDeleteDialog"
      @[EMITTERS.COMMENT_DELETE_EMITTER]="commentDeleted"
    />
    <div class="comment">
      <div class="comment-main">
        <div class="comment-user-block">
          <div class="avatar">
            <span class="initial">
              {{ getUserName()[0].toUpperCase() }}
            </span>
          </div>
        </div>
        <div class="comment-title-wrapper">
          <div v-if="isShowMode" class="comment-block">
            <div class="comment-title">
              <span class="text">
                {{ comment.user.username }}
              </span>
              <VerifiedIcon
                v-if="comment.user.verified"
                class="verified-icon"
              />
              <span class="timestamp">
                {{ formatDate(comment.created_at) }}
              </span>

              <span v-if="isEdited()" class="edit-info">
                {{ $vueTranslate('study_tools.comment.edit_label') }}
              </span>

              <div v-if="canUseActions()" class="actions-block">
                <MoreIcon
                  class="actions-btn"
                  :theme="THEMES.DARK"
                  @click="toggleActions()"
                />
                <div v-if="actionsVisibility" class="actions-modal">
                  <div v-if="isOwnComment()" class="action-btn" @click="edit">
                    <EditIconComponent :theme="THEMES.DARK" />
                    <span class="text">
                      {{ $vueTranslate('study_tools.comment.edit_btn') }}
                    </span>
                  </div>
                  <div v-if="canDelete()" class="action-btn" @click="destroy">
                    <DeleteIconComponent :theme="THEMES.DARK" />
                    <span class="text">
                      {{ $vueTranslate('study_tools.comment.delete_btn') }}
                    </span>
                  </div>
                  <div
                    v-if="!isOwnComment()"
                    class="action-btn"
                    @click="report"
                  >
                    <FlagIcon />
                    <span class="text">
                      {{ $vueTranslate('study_tools.comment.report_btn') }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <VideoCommentText :id="comment.id" :text="localComment.text" />
          </div>

          <textarea
            v-if="isModifyMode"
            ref="newCommentArea"
            v-model="localComment.text"
            type="text"
            wrap="hard"
            class="new-comment-area"
            :maxlength="COMMENT_TEXT_LIMIT"
            :placeholder="
              $vueTranslate('study_tools.comment.title_placeholder')
            "
            @mousedown="mouseDown"
            @keydown.enter.exact.prevent
            @keyup.enter.exact.prevent
            @keydown.enter.shift.exact.prevent
            @input="inputTextarea"
            @focus="focusTextarea"
          />
          <span
            v-if="isModifyMode && isCounterEnabled"
            class="comment-counter"
            :class="{ 'comment-counter__edit': isEditMode }"
          >
            {{ localComment.text.length }}/{{ COMMENT_TEXT_LIMIT }}
          </span>
        </div>

        <div v-if="isModifyMode" class="comment-edit-actions">
          <form @submit.prevent="submitForm()">
            <input v-model="localComment.text" type="hidden" />

            <button
              v-if="focus || isEditMode"
              class="comment__cancel-button"
              type="button"
              @submit.prevent
              @click="clearForm()"
            >
              {{ $vueTranslate('study_tools.comment.cancel_btn') }}
            </button>
            <button
              v-if="focus || isEditMode"
              :disabled="!localComment.text.length"
              class="comment__save-button"
              type="submit"
            >
              {{ $vueTranslate('study_tools.comment.save_btn') }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'

import {
  COMMENT_MODES as MODES,
  SITES,
  THEMES,
} from '../../shared/utils/view_options'
import commentsApiService from '../services/commentsApi.service'
import { EMITTERS } from '../../shared/utils/emitters'
import AuthorizationPopUp from './AuthorizationPopUp'
import MoreIcon from '../../shared/components/icons/MoreIcon'
import EditIconComponent from '../../shared/components/icons/Edit'
import VerifiedIcon from '../../shared/components/icons/VerifiedIcon'
import DeleteIconComponent from '../../shared/components/icons/Delete'
import ConfirmationDialog from './ConfirmationDialog'
import CommentReportForm from './CommentReportForm'
import FlagIcon from '../../shared/components/icons/FlagIcon'
import VideoCommentText from './VideoCommentText.vue'

const EMPTY_COMMENT = {
  id: null,
  text: '',
}

export default {
  name: 'VideoComment',
  components: {
    VideoCommentText,
    FlagIcon,
    CommentReportForm,
    ConfirmationDialog,
    DeleteIconComponent,
    VerifiedIcon,
    EditIconComponent,
    MoreIcon,
    AuthorizationPopUp,
  },
  props: {
    mode: {
      required: true,
      type: String, // MODES.EDIT | MODES.SHOW | MODES.CREATE,
    },
    theme: {
      required: true,
      type: String, // THEMES.DARK | THEMES.LIGHT,
    },
    comment: {
      type: Object,
      default: () => ({
        id: null,
        text: '',
        created_at: '',
        user: {
          username: '',
        },
      }),
      required: true,
    },
    reportReasons: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      EMITTERS,
      localComment: EMPTY_COMMENT,
      currentMode: this.mode,
      focus: false,
      COMMENT_TEXT_LIMIT: 1200,
      COMMENT_INPUT_MIN_HEIGHT: 32,
      authorizationPopupVisibility: false,
      actionsVisibility: false,
      isDeleteDialogVisibility: false,
      reportFormVisibiltiy: false,
      COMMENT_DELETE_FUNCTION: commentsApiService.deleteComment,
    }
  },
  computed: {
    THEMES() {
      return THEMES
    },
    isModifyMode() {
      return this.isEditMode || this.isCreateMode
    },
    isEditMode() {
      return this.currentMode === MODES.EDIT
    },
    isShowMode() {
      return this.currentMode === MODES.SHOW
    },
    isCreateMode() {
      return this.currentMode === MODES.CREATE
    },
    isCounterEnabled() {
      return this.localComment.text.length >= 1
    },
  },
  watch: {
    currentMode(newMode, _oldMode) {
      this.$nextTick(() => {
        if (newMode === MODES.EDIT) this.updateTextareaHeight()
      })
    },
    comment() {
      this.reloadComment()
    },
  },
  created() {
    this.reloadComment()
  },
  mounted() {
    this.resetTextAreaHeight()
    this.emitter.on(EMITTERS.COMMENT_TOGGLE_ACTIONS_EMITTER, this.handleActions)
    this.emitter.on(EMITTERS.COMMENT_REPORT_ACTION, this.handleReportAction)
  },
  methods: {
    report() {
      this.reportFormVisibiltiy = true
      this.emitter.emit(EMITTERS.COMMENT_REPORT_ACTION, this.localComment.id)
    },
    handleActions(id) {
      if (id !== this.localComment.id) this.actionsVisibility = false
    },
    handleReportAction(id) {
      if (id !== this.localComment.id) this.reportFormVisibiltiy = false
    },
    authorized() {
      return !!this.optional_params.user_id
    },
    role() {
      return this.optional_params.role || 'member'
    },
    isStaff() {
      return this.role() === 'moderator' || this.role() === 'admin'
    },
    mouseDown(e) {
      if (!this.authorized()) {
        this.authorizationPopupVisibility = true
        e.preventDefault()
      }
    },
    focusTextarea() {
      this.focus = true
    },
    inputTextarea() {
      this.updateTextareaHeight()
    },
    showMode() {
      this.currentMode = MODES.SHOW
    },
    resetTextAreaHeight() {
      if (this.$refs.newCommentArea)
        this.$refs.newCommentArea.style.height =
          this.COMMENT_INPUT_MIN_HEIGHT + 'px'
    },
    updateTextareaHeight() {
      this.resetTextAreaHeight()
      if (this.$refs.newCommentArea)
        this.$refs.newCommentArea.style.height =
          this.$refs.newCommentArea.scrollHeight + 'px'
    },
    clearForm() {
      this.resetTextAreaHeight()
      this.reloadComment()
      this.focus = false
      this.$emit(EMITTERS.COMMENT_CANCEL_EMITTER)
      if (this.isEditMode) this.showMode()
    },
    submitForm() {
      if (this.isCreateMode) this.createComment()
      if (this.isEditMode) this.updateComment()
    },
    reloadComment() {
      const comment = this.isCreateMode ? EMPTY_COMMENT : this.comment
      this.localComment = cloneDeep(comment)
    },
    updateComment() {
      commentsApiService
        .updateComment(this.localComment, this.optional_params)
        .then((response) => {
          const comment = response.data.comment
          this.$emit(EMITTERS.COMMENT_UPDATED_EMITTER, comment)
          this.localComment = comment
          this.clearForm()
          this.showMode()
          this.emitter.emit(EMITTERS.SHOW_NOTIFICATION_EMITTER, {
            message: this.$vueTranslate('study_tools.comment.edit_alert'),
            site: SITES.WATCH,
          })
        })
        .catch(console.error)
    },
    createComment() {
      commentsApiService
        .createComment(this.localComment, this.optional_params)
        .then(() => {
          this.$emit(EMITTERS.COMMENT_CREATED_EMITTER)
          this.clearForm()
          this.emitter.emit(EMITTERS.SHOW_NOTIFICATION_EMITTER, {
            message: this.$vueTranslate('study_tools.comment.create_alert'),
            site: SITES.WATCH,
          })
        })
        .catch(console.error)
    },
    getUserName() {
      const username = localStorage.getItem('username') || 'User'

      if (!this.localComment) return username

      if (!this.localComment.user) return username

      return (
        this.localComment.user.username || username
      )
    },
    formatDate(timestamp) {
      const now = new Date()
      const createdDate = new Date(timestamp)
      const diffInMilliseconds = now - createdDate

      const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24))

      if (diffInDays === 0) {
        return 'Today'
      } else if (diffInDays === 1) {
        return 'Yesterday'
      } else if (now.getFullYear() === createdDate.getFullYear()) {
        return this.formatDateToString(createdDate, false)
      } else {
        return this.formatDateToString(createdDate)
      }
    },
    formatDateToString(date, showYear = true) {
      const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ]

      return showYear
        ? `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`
        : `${date.getDate()} ${months[date.getMonth()]}`
    },
    closeReportForm() {
      this.reportFormVisibiltiy = false
    },
    closeModel() {
      this.authorizationPopupVisibility = false
    },
    toggleActions() {
      this.actionsVisibility = !this.actionsVisibility
      this.emitter.emit(
        EMITTERS.COMMENT_TOGGLE_ACTIONS_EMITTER,
        this.localComment.id
      )
    },
    canUseActions() {
      return this.authorized()
    },
    canDelete() {
      return this.isOwnComment() || this.isStaff()
    },
    isOwnComment() {
      let username = this.localComment
      username = username.user && username.user.username

      return (
        username === localStorage.getItem('username')
      )
    },
    edit() {
      this.toggleActions()
      this.currentMode = MODES.EDIT
    },
    destroy() {
      this.toggleActions()
      this.isDeleteDialogVisibility = true
    },
    commentDeleted(comment) {
      this.emitter.emit(EMITTERS.SHOW_NOTIFICATION_EMITTER, {
        message: this.$vueTranslate('study_tools.comment.delete_alert'),
        site: SITES.WATCH,
      })
      this.$emit(EMITTERS.COMMENT_DELETE_EMITTER, comment)
    },
    closeDeleteDialog() {
      this.isDeleteDialogVisibility = false
    },
    isEdited() {
      const createTime = new Date(this.localComment.created_at).getTime()
      const updateTime = new Date(this.localComment.updated_at).getTime()

      return createTime !== updateTime
    },
  },
}
</script>

<style lang="scss">
.read-more-link {
  display: flex;
  font-family: DM Sans, serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #8f8a9b;
  cursor: pointer;

  &:hover {
    color: #8f8a9b;
  }
}

.comment-wrapper .comment {
  padding: 12px 16px;
  border-bottom: 1px #444050 solid;
}

.create-mode .comment {
  margin-left: 0;
  margin-right: 0;
  padding: 12px 16px;
}

.comment-wrapper.dark:hover {
  background-color: #32303c;
}

.comment {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
}

.comment-main {
  display: flex;
  flex: 0 0 100%;
}

.comment-block {
  display: flex;
  flex-direction: column;
}

.comment-title {
  display: flex;
  flex-direction: row;
  gap: 8px;

  .timestamp {
    color: #8f8a9b;
    font-weight: 400;
  }

  .edit-info {
    color: #8f8a9b;
    font-weight: 400;
  }
}

.comment-title-wrapper {
  position: relative;
  margin-left: 10px;
  color: $white;
  line-height: 26px;
  width: 100%;
}

.dark .new-comment-area {
  padding-top: 0;
  cursor: pointer;
}

.light .new-comment-area {
  padding-top: 4px;
}

.new-comment-area {
  cursor: default;
  padding-left: 0;

  &:focus {
    outline: none;
  }
}

.comment-counter__edit {
  color: #8f8a9b;
}

.comment-title {
  cursor: pointer;
}

.new-comment-area,
.comment-title {
  background: transparent;
  border: none;
  color: $white;
  resize: none;
  display: flex;
  width: 100%;
  flex-basis: 100%;
  overflow-wrap: anywhere;
  min-height: 32px;

  &:focus {
    outline-width: 0;
    cursor: auto;
  }
}

.comment-counter {
  justify-content: flex-end;
  display: flex;
}

.comment-actions {
  height: 30px;
}

.comment-action {
  margin: 0 5px;
}

.delete-action {
  margin-right: 16px;
}

.comment-edit-actions {
  position: relative;
}

.comment-edit-actions,
.comment-edit-actions form,
.comment-actions {
  display: flex;
  margin-left: auto;
  justify-content: end;
  width: 205px;
}

.comment__action-button {
  border-radius: 4px;
  border: none;
  font-size: 14px;
  height: 32px;
  width: 85px;
  color: $white;
}

.comment__save-button {
  @extend .comment__action-button;
  background-color: #6998ae;
  margin: 0 8px;

  &:disabled,
  &[disabled] {
    color: #8f8a9b;
    background-color: #3b3946;
  }
}

.comment__cancel-button {
  @extend .comment__action-button;
  background-color: #444050;
  margin-left: auto;
}

.comment-user-block {
  display: flex;
  justify-content: center;

  .avatar {
    width: 24px;
    height: 24px;
    background-color: #8f8a9b;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .initial {
    color: #fff;
    text-align: center;
    font-family: 'DM Sans', serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.021px;
  }
}

.actions-block {
  display: flex;
  margin-left: auto;
  position: relative;

  .actions-btn {
    display: flex;
  }

  .actions-modal {
    position: absolute;
    top: 25px;
    right: 10px;
    min-width: 140px;
    z-index: 1;
    border-radius: 8px;
    padding: 16px 8px 16px 8px;
    height: auto;
    background-color: #2b2834;
    -webkit-box-shadow: 0 2px 32px 0 rgba(0, 0, 0, 0.7);
    -moz-box-shadow: 0 2px 32px 0 rgba(0, 0, 0, 0.7);
    box-shadow: 0 2px 32px 0 rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .action-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    .text {
      font-family: DM Sans, serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0;
      text-align: left;
      color: #ffffff;
    }
  }
}

.verified-icon {
  margin-top: 3px;
}
</style>
