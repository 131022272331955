import axios from 'axios'

import { API_ROUTES, BASE_API_CONFIG } from '../../../shared/utils/api'

export default {
  addLike(optional_params = {}) {
    return axios.request({
      ...BASE_API_CONFIG,
      method: 'POST',
      url: API_ROUTES.like(optional_params.episode_id),
    })
  },
  removeLike(optional_params = {}) {
    return axios.request({
      ...BASE_API_CONFIG,
      method: 'DELETE',
      url: API_ROUTES.like(optional_params.episode_id),
    })
  },
}
