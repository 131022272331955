$(document).on('turbolinks:load', function () {
  const localStorageKey = 'donateWarningNavbarClosed'
  const localStorageDate = parseInt(localStorage.getItem(localStorageKey))
  const dayAgo = new Date().getTime() - 24 * 60 * 60 * 1000
  if (!$('.site-main.donation').length && !(localStorageDate >= dayAgo)) {
    $('.donate-warning-navbar').show()
  }

  $(document).on('click', '#donate-warning-navbar__close', (event) => {
    event.preventDefault()
    $('.donate-warning-navbar').remove()
    localStorage.setItem(localStorageKey, new Date().getTime())

    const authorization = localStorage.getItem('AD-Authorization')
    $.ajax({
      url: '/api/users/donate_warning',
      type: 'DELETE',
      headers: {
        'AD-Authorization': authorization,
        Accept: 'application/json',
      },
      contentType: 'application/json',
      dataType: 'json',
      success: () => {},
      error: () => {},
    })
  })
})
