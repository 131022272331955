<template>
  <div class="featured-videos-block">
    <div v-if="!isLoading() && hasItems()" class="decoration" />
    <span v-if="!isLoading() && hasItems()" class="title">
      {{ $vueTranslate('news.featured_videos.app.title') }}
    </span>
    <img
      v-if="isLoading()"
      src="../../shared/assets/images/loading.gif"
      alt="loading"
      class="loader"
    />
    <EntriesCarousel
      v-else
      :is-light="true"
      :settings="settings"
      :items="items"
    />
  </div>
</template>

<script>
import EntriesCarousel from '../../news/featured_videos/components/EntriesCarousel'
import settingsApiService from '../../shared/services/settingsApi.service'
import featuredVideoApiService from './../services/featuredVideoApi.service'

export default {
  name: 'FeaturedVideo',
  components: { EntriesCarousel },
  props: {
    entityId: {
      default: '0',
      type: String,
    },
    entityType: {
      default: 'ArticlesFeaturedVideo',
      type: String,
    },
  },
  data() {
    return {
      items: [],
      settings: null,
      loadingSettings: true,
      loadingVideos: true,
    }
  },
  mounted() {
    this.fetchSettings()
    this.fetchVideos()
  },
  methods: {
    isLoading() {
      return this.loadingSettings && this.loadingVideos
    },
    hasItems() {
      return this.items.length >= 1
    },
    fetchSettings() {
      settingsApiService
        .fetchSettings()
        .then((response) => {
          this.settings = response.data
        })
        .finally(() => {
          this.loadingSettings = false
        })
    },
    fetchVideos() {
      featuredVideoApiService
        .getFeaturedVideos(this.entityId, this.entityType)
        .then((result) => {
          this.items = result.data
        })
        .finally(() => {
          this.loadingVideos = false
        })
    },
  },
}
</script>

<style scoped lang="scss">
.featured-videos-block {
  max-width: 870px;
  margin-top: 45px;
  display: block;
  flex-direction: column;

  .decoration {
    width: 32px;
    height: 2px;
    border: #d18f42 solid 2px;
    margin-bottom: 12px;
  }

  .loader {
    display: flex;
    width: 32px;
    height: 32px;
    margin: 0 auto;
  }

  .title {
    width: 100%;
    display: flex;
    justify-items: start;
    align-items: center;
    font-family: DM Sans, serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    color: #323232;
  }
}
</style>
