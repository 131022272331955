export default {
  install: (app) => {
    app.config.globalProperties.$vueTranslate = (path) => {
      const lang = app.config.globalProperties.$locale || 'en'
      const translations = require(`./locales/${lang}.json`)

      return path
        .split('.')
        .reduce(
          (acc, key) => (acc && acc[key] ? acc[key] : undefined),
          translations
        )
    }

    app.config.globalProperties.$setLocale = (locale) => {
      app.config.globalProperties.$locale = locale
    }
  },
}
