<template>
  <div class="videos-tab">
    <VideoCard
      v-for="episode in entries"
      :key="episode.id"
      :title="episode.title"
      :title-alt="episode.title_alt"
      :thumbnail="episode.thumbnail_url || episode.cover_url"
      :slug="episode.slug"
      :series-slug="episode.series_slug"
      :topics="episode.topics"
      :exclusive="episode.exclusive"
      :premiering="episode.premiering"
      :live-at-label="episode.live_at_label"
      :countdown-tooltip="episode.countdown_tooltip"
      :is-series="episode.is_series"
      :sequence-number="episode.sequence_number"
      :settings="settings"
      :search-query="query"
      :is-light="isLight"
      :episodes-count="episode.episodes_count"
      :series-label="episode.series_label"
    />
  </div>
</template>

<script>
import VideoCard from './VideoCard'

export default {
  name: 'VideoTab',
  components: { VideoCard },
  props: {
    entries: {
      required: true,
      type: Array,
      default: () => [],
    },
    settings: {
      required: true,
      default: () => ({
        watch_host: '',
        main_host: '',
        events_host: '',
      }),
      type: Object,
    },
    query: {
      required: true,
      default: '',
      type: String,
    },
    isLight: {
      default: false,
      type: Boolean,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.videos-tab {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;

  @media (max-width: 463.98px) {
    justify-content: center;
  }
}
</style>
