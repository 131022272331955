<template>
  <div class="episode-container row">
    <div class="image-block">
      <div class="thumbnail-container">
        <a :href="episode.episode_path">
          <img
              class="thumbnail"
              :alt="episode.title"
              loading="lazy"
              :src="episode.thumbnail"
          />
          <div :class="{'duration-with-offset': hasProgressBar}" class="duration">{{ episode.duration }}</div>
          <div v-if="hasProgressBar" class="progress-wrapper">
            <EpisodeProgressComponent :progressBar="episode.progress_bar" />
          </div>
        </a>
      </div>
      <SpecialEpisodeLabels :episode="episode" />
    </div>

    <div class="text-content">
      <div class="title">
        <a :href="episode.episode_path">{{ episode.title }}</a>
      </div>

      <div
          class="speakers-short"
          v-if="episode.speakers && episode.speakers.length > 0"
      >
        <div class="speaker-wrapper" v-for="speaker in episode.speakers">
          <img
              class="speaker-avatar"
              :alt="speaker.full_name"
              loading="lazy"
              :src="speaker.thumbnail_url"
          />
        </div>

        <span class="speaker-name">
            {{ speakerNames() }}
          </span>
      </div>

      <a
          class="series-title"
          :href="episode.episode_path"
          v-if="episode.series"
      >
        {{ `E${episode.sequence_number} | ${episode.series.title}` }}
      </a>
    </div>
    <div class="delete-button-wrapper" @click.stop>
      <a class="note-action delete-action">
        <DeleteIcon :theme="theme" @click="openDeleteDialog(episode)" />
      </a>
    </div>
  </div>
  <ConfirmationDialog
      v-if="episodeToDelete"
      :theme="theme"
      :entity="episodeToDelete"
      :on-deleted-emitter="EMITTERS.WATCH_HISTORY_EPISODE_DELETED"
      :confirmation-text="DELETE_CONFIRMATION_TEXT"
      :delete-function="DELETE_FUNCTION"
      @[EMITTERS.CLOSE_DIALOG_EMITTER]="closeDeleteDialog"
      @[EMITTERS.WATCH_HISTORY_EPISODE_DELETED]="watchHistoryEpisodeDeleted"
      class="watch-history-confirmation-dialog"
  />
</template>

<script>
import ArrowDownIcon from '../../shared/components/icons/ArrowDown'
import ArrowUpIcon from '../../shared/components/icons/ArrowUp'
import SpecialEpisodeLabels from '../../shared/components/SpecialEpisodeLabels.vue'
import EpisodeProgressComponent from '../../shared/components/EpisodeProgress.vue'
import { THEMES } from '../../shared/utils/view_options'
import { EMITTERS } from '../../shared/utils/emitters'
import DeleteIcon from "../../shared/components/icons/Delete.vue";
import ConfirmationDialog from "../../study_tools/components/ConfirmationDialog.vue";
import ApiService from '../services/episodesApi.service'

export default {
  name: 'EpisodeComponent',
  components: {
    ConfirmationDialog,
    DeleteIcon,
    SpecialEpisodeLabels,
    ArrowUpIcon,
    ArrowDownIcon,
    EpisodeProgressComponent,
  },
  props: {
    episode: {
      title: String,
      id: Number,
      meta_title: String,
      thumbnail: String,
      episode_path: String,
      speakers: Array,
      duration: String,
      sequence_number: Number,
      progress_bar: {
        percent: Number,
        start_time_seconds: Number,
      },
      series: {
        id: Number,
        title: String,
      },
    },
  },
  emits: [EMITTERS.NOTE_DELETED_EMITTER],
  data() {
    return {
      theme: THEMES.LIGHT,
      episodeToDelete: undefined,
      DELETE_CONFIRMATION_TEXT: 'Delete this video from Watch History?',
      DELETE_FUNCTION: ApiService.deleteHistoryItem,
      EMITTERS,
    }
  },
  computed: {
    hasProgressBar() {
      return this.episode.progress_bar && this.episode.progress_bar.percent
    }
  },
  methods: {
    speakerNames() {
      const firstSpeaker = this.episode.speakers[0].full_name
      const speakersCount = this.episode.speakers.length - 1
      if (this.episode.speakers.length === 1) return firstSpeaker

      return firstSpeaker + ' (+' + speakersCount + ')'
    },
    openDeleteDialog(episode) {
      this.episodeToDelete = episode
    },
    closeDeleteDialog() {
      this.episodeToDelete = undefined
    },
    watchHistoryEpisodeDeleted(episode) {
      this.$emit(EMITTERS.WATCH_HISTORY_EPISODE_DELETED, {
        deletedEpisode: episode,
      })
    },
  },
}
</script>

<style lang="scss">
.watch-history-confirmation-dialog.light.modal-b {
  .modal-c {
    width: 422px;
  }
}
</style>

<style lang="scss" scoped>
@import '../../shared/assets/styles/global';

.image-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.thumbnail-container {
  width: 180px;
  height: 90px;
  position: relative;

  .thumbnail {
    object-fit: cover;
  }

  .progress-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .duration {
    position: absolute;
    width: 69px;
    height: 24px;
    bottom: 0;
    right: 0;
    background-color: #1c1923;
    color: #ffffff;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    vertical-align: middle;
    font-weight: 500;
    opacity: 0.8;
  }
  .duration-with-offset {
    bottom: 4px;
  }

  img {
    width: 180px;
    height: 90px;
  }
}

.episode-container {
  width: 100%;
  background-color: #ffffff;
  margin-bottom: 16px;
  padding: 16px;
  position: relative;
}
.delete-button-wrapper {
  position: absolute;
  right: 10px;
  top: 10px;
}
.text-content {
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}

.title,
.series-title {
  max-width: 320px;
}

.title {
  a {
    font-size: 17px;
    line-height: 22px;
    color: #323232;
    display: block;
  }
}

.series-title {
  margin-top: 2px;
  font-size: 14px;
  color: #5c5c5c;
  display: block;
}

.speakers-short {
  display: flex;
  margin-top: 5px;

  .speaker-wrapper {
    height: 16px;
  }
  .speaker-avatar {
    margin-top: -16px;
    width: 16px !important;
    height: 16px !important;
    border: 0.5px solid #D1D3D4;
    border-radius: 50%;

    &:not(:first-child) {
      margin-left: -12px;
    }
  }

  .speaker-name {
    font-family: 'DM Sans', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #5C5C5C;
    margin-left: 6px;
  }
}
</style>
