<template>
  <div
    v-if="isCreateMode || isEditMode"
    class="video-resources__form-control document__document transcript__form-control"
  >
    <div
      class="transcript__button"
      :class="maybeDisabled()"
      @click="generateTranscript"
    >
      <div class="transcript__button-text">Autogenerate transcript</div>
    </div>
    <DragAndDrop
      :key="localTranscript.document"
      :attachment="localTranscript.document"
      :disabled="isButtonDisabled"
      :simple-button="true"
      placeholder="Upload manually"
      theme="simple-button"
      hint="Only vtt format"
      @[EMITTERS.FILE_SELECTED_EMITTER]="transcriptSelected"
    />
    <span
      class="document__file-error video-resources__form-error transcript__error"
    >
      {{ errors.document.join() }}
    </span>
  </div>

  <div
    v-if="isShowMode"
    ref="transcriptContainer"
    class="document__container transcript"
  >
    <div class="video-resources__file__icon">
      <div v-if="readOnlyMode" class="video-resources__file__format">
        {{ fileFormat }}
      </div>
    </div>
    <span
      :class="{ document__text__wrapped: readOnlyMode }"
      class="document__text"
    >
      {{ fileTitle }}
    </span>
    <div class="document__control-spacer" />
    <a
      :href="localTranscript.document.downloadLink"
      download
      target="_blank"
      class="video-resources_download"
      @click.stop
    >
      <DownloadIcon theme="light" />
      <div class="video-resources__filesize">
        {{ localTranscript.document.file_size }}
      </div>
    </a>
    <a class="video-resources_download" @click.stop="openPreview">
      <PreviewIcon />
      <div class="video-resources__filesize">
        Preview
      </div>
    </a>
    <div class="video-resources__controls">
      <div class="document__delete" @click.stop="openDialog">
        <div class="document__delete-icon" />
      </div>
    </div>
    <PreviewDialog
      v-if="showPreview"
      theme="light"
      :entity="transcript"
      @[EMITTERS.CLOSE_DIALOG_EMITTER]="closePreview"
    />
  </div>
  <button
    v-if="!localTranscript.public && isShowMode"
    class="video-resources__control-button transcripts__attach"
    @click="toggleTranscript"
  >
    {{ attachButtonText() }}
  </button>
  <ConfirmationDialog
    v-if="showDialog"
    theme="light"
    :entity="transcript"
    :confirmation-text="confirmationText"
    :delete-function="TRANSCRIPT_DELETE_FUNCTION"
    :on-deleted-emitter="EMITTERS.TRANSCRIPT_DELETED_EMITTER"
    @[EMITTERS.CLOSE_DIALOG_EMITTER]="closeDialog"
    @[EMITTERS.TRANSCRIPT_DELETED_EMITTER]="transcriptDeleted"
  />
</template>
<script>
import { cloneDeep } from 'lodash'

import TranscriptsApiService from '../services/transcriptsApi.service'
import { VIDEO_RESOURCES_MODES as MODES } from '../../../shared/utils/view_options'
import { EMITTERS } from '../../../shared/utils/emitters'
import DragAndDrop from '../../video_resources/components/DragAndDrop'
import DownloadIcon from '../../../shared/components/icons/Download'
import PreviewDialog from '../../video_resources/components/PreviewDialog'
import PreviewIcon from '../../../shared/components/icons/Preview'
import ConfirmationDialog from '../../../study_tools/components/ConfirmationDialog'

export default {
  name: 'TranscriptComponent',
  components: {
    DragAndDrop,
    DownloadIcon,
    PreviewIcon,
    PreviewDialog,
    ConfirmationDialog,
  },
  props: {
    active: {
      type: Number,
      default: null,
    },
    // eslint-disable-next-line vue/require-default-prop
    transcript: {
      id: Number | null,
      title: String,
      description: String,
      document: File,
    },
    mode: {
      type: String,
      default: MODES.SHOW,
    },
    readOnlyMode: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    EMITTERS.TRANSCRIPT_CREATED_EMITTER,
    EMITTERS.TRANSCRIPT_EDIT_EMITTER,
    EMITTERS.TRANSCRIPT_UPDATED_EMITTER,
    EMITTERS.TRANSCRIPT_DELETED_EMITTER,
    EMITTERS.TRANSCRIPT_CANCEL_EMITTER,
    EMITTERS.TRANSCRIPT_SELECTED_EMITTER,
    EMITTERS.TRANSCRIPT_FAILED_EMITTER,
  ],
  data() {
    return {
      EMITTERS,
      localTranscript: this.transcript,
      isButtonDisabled: this.disabled,
      isAutogenerateDisabled: this.transcript.id !== null,
      uploading: false,
      progress: 0,
      showPreview: false,
      showDialog: false,
      confirmationText: 'Delete this transcript?',
      TRANSCRIPT_DELETE_FUNCTION: TranscriptsApiService.deleteTranscript,
      errors: {
        document: [],
      },
    }
  },
  computed: {
    isEditMode() {
      return this.mode === MODES.EDIT
    },
    isCreateMode() {
      return this.mode === MODES.CREATE
    },
    isShowMode() {
      return this.mode === MODES.SHOW
    },
    submitButtonTitle() {
      return this.isCreateMode ? 'Save Transcript' : 'Update'
    },
    maybeActive() {
      return this.active === this.transcript.id ? 'active' : ''
    },
    maybeCursorDefault() {
      return this.readOnlyMode ? 'video-resources__cursor-default' : ''
    },
    fileTitle() {
      return this.transcript.title
    },
    fileFormat() {
      if (!this.transcript.document.name) return ''
      if (this.transcript.document.name.split('.').length === 1) return ''

      return this.transcript.document.name
        .match(/\.\w+/gi)
        .pop()
        .replace('.', '')
    },
  },
  methods: {
    toggleTranscript() {
      this.localTranscript.public = true
      this.updateTranscript()

      this.emitter.emit(EMITTERS.SHOW_NOTIFICATION_EMITTER, {
        message: 'Transcript was attached to video',
      })
    },
    attachButtonText() {
      return 'Attach to video'
    },
    async updateTranscript() {
      if (this.isCreateMode) return
      this.isButtonDisabled = true
      this.uploading = true

      try {
        const response = await TranscriptsApiService.updateTranscript(
          this.localTranscript,
          this.optional_params,
          (progress) => {
            this.progress = progress
          }
        )
        this.clearForm()
        this.clearErrors()
        this.$emit(EMITTERS.TRANSCRIPT_UPDATED_EMITTER, response.transcript)
      } catch (error) {
        this.updateErrors(error)
        this.$emit(EMITTERS.TRANSCRIPT_FAILED_EMITTER, this.storedTranscript)
      } finally {
        this.uploading = false
      }
    },
    async createTranscript() {
      this.uploading = true
      this.isButtonDisabled = true

      try {
        const response = await TranscriptsApiService.createTranscript(
          this.localTranscript,
          this.optional_params,
          (progress) => {
            this.progress = progress
          }
        )
        this.clearForm()
        this.clearErrors()
        this.$emit(EMITTERS.TRANSCRIPT_CREATED_EMITTER, response.transcript)
      } catch (error) {
        this.updateErrors(error)
        this.$emit(EMITTERS.TRANSCRIPT_FAILED_EMITTER, this.storedTranscript)
      } finally {
        this.uploading = false
      }
    },
    clearForm() {
      this.progress = 0
    },
    clearErrors() {
      this.errors.title = []
      this.errors.document = []
    },
    submitForm() {
      if (this.isCreateMode) this.createTranscript()
      if (this.isEditMode) this.updateTranscript()
    },
    updateErrors(e) {
      this.errors.title = e.response.data.errors.title || []
      this.errors.document = e.response.data.errors.document || []
    },
    transcriptSelected(file) {
      if (this.localTranscript.document === file || file === File) return
      this.storedTranscript = cloneDeep(this.localTranscript)
      this.$emit(EMITTERS.TRANSCRIPT_SELECTED_EMITTER)
      this.localTranscript.document = file
      this.localTranscript.public = false
      this.clearErrors()
      this.clearForm()
      this.submitForm()
    },
    transcriptEdit() {
      if (this.readOnlyMode) return

      const transcriptProps = cloneDeep(this.localTranscript)
      this.$emit(EMITTERS.TRANSCRIPT_EDIT_EMITTER, transcriptProps)
    },
    enableButton() {
      this.isButtonDisabled = false
    },
    cancelUpdate() {
      this.$emit(EMITTERS.TRANSCRIPT_CANCEL_EMITTER)
    },
    openPreview() {
      this.showPreview = true
    },
    closePreview() {
      this.showPreview = false
    },
    closeDialog() {
      this.showDialog = false
    },
    openDialog() {
      this.showDialog = true
    },
    transcriptDeleted(transcript) {
      this.$emit(EMITTERS.TRANSCRIPT_DELETED_EMITTER, transcript)
    },
    disableButtons() {
      this.isButtonDisabled = true
      this.isAutogenerateDisabled = true
    },
    maybeDisabled() {
      return this.isAutogenerateDisabled ? 'disabled' : ''
    },
    generateTranscript() {
      if (this.isAutogenerateDisabled) return

      this.disableButtons()

      TranscriptsApiService.generateTranscript(this.optional_params)
        .catch(console.error)
        .finally(() => {
          this.$emit(EMITTERS.TRANSCRIPT_GENERATE_EMITTER)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../shared/assets/styles/video_resources';
@import '../../../shared/assets/styles/document';

.transcript {
  &.document__container {
    width: 100%;
    border: none;
    gap: 10px;
    height: 39px;
    padding: 0;

    &:hover {
      background: #ffffff;
      cursor: default;
    }
  }

  .document__text {
    padding: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
}

.transcript__button {
  border-radius: 4px;
  background: #323232;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  width: 142px;
  height: 62px;
  padding: 12px 0px;

  &.disabled {
    background: #a6a6a6;

    &:hover {
      cursor: default;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    }
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0 2px 3px rgba(23, 24, 28, 0.6);
  }
}

.transcript__button-text {
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.transcript__form-control {
  display: flex;
  column-gap: 16px;
  flex-wrap: wrap;
  flex-direction: row;
}

.transcript__error {
  width: 100%;
  position: relative;
  right: 110px;
  top: 12px;
  color: #d35163;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.transcripts__attach {
  width: 171px;
  padding: 6px 12px;
}
</style>
