<template>
  <div class="tags-wrapper">
    <div class="bookmark-tags-backdrop" @click="emitToggleTagBlock" />

    <div ref="tagsContainer" class="bookmark-tags-container" :class="theme">
      <div class="bookmarks-tags-heading">
        <div class="bookmark-tags-title">
          {{ $vueTranslate('study_tools.tags.title') }}
        </div>
        <div class="bookmark-tags-close" @click="emitToggleTagBlock">
          <CloseIcon :theme="theme" />
        </div>
      </div>
      <div class="bookmark-tags">
        <div v-for="tag in tags" :key="tag.id" class="bookmark-tag">
          {{ tag.name }}
        </div>
      </div>
    </div>
  </div>

  <div
    class="alert alert-dismissible profile alert-success"
    role="alert"
    data-v-16021b83=""
    data-v-7e8e4c30=""
  >
    Comment deleted
    <button type="button" class="close close-notification" data-v-16021b83="">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="close-icon"
        data-v-539a5951=""
        data-v-16021b83=""
      >
        <path
          d="M3.80238 2.86128C3.54203 2.60093 3.11992 2.60093 2.85957 2.86128C2.59922 3.12163 2.59922 3.54374 2.85957 3.80409L7.01518 7.9597L2.85932 12.1156C2.59898 12.3759 2.59898 12.798 2.85932 13.0584C3.11967 13.3187 3.54178 13.3187 3.80213 13.0584L7.95799 8.90251L12.1133 13.0578C12.3736 13.3182 12.7958 13.3182 13.0561 13.0578C13.3165 12.7975 13.3165 12.3754 13.0561 12.115L8.9008 7.9597L13.0559 3.80464C13.3162 3.54429 13.3162 3.12218 13.0559 2.86183C12.7955 2.60148 12.3734 2.60148 12.113 2.86183L7.95799 7.01689L3.80238 2.86128Z"
          fill-rule="evenodd"
          clip-rule="evenodd"
          class="close-icon__element"
          fill="#A6A6A6"
          data-v-539a5951=""
        ></path>
      </svg>
    </button>
  </div>
</template>

<script>
import CloseIcon from '../../shared/components/icons/Close'
import { EMITTERS } from '../../shared/utils/emitters'

export default {
  name: 'TagsComponent',
  components: {
    CloseIcon,
  },
  props: {
    element: EventTarget,
    theme: {
      required: true,
      type: String, // THEMES.DARK | THEMES.LIGHT,
    },
    tags: {
      type: Array,
      required: true,
    },
    containerName: {
      type: String,
      required: false,
    },
  },
  emits: [EMITTERS.TOGGLE_TAG_BLOCK_EMITTER],
  data() {
    return {
      bookmarksContainer: document.getElementsByClassName(
        this.containerName ? this.containerName : 'bookmarks-container'
      )[0],
    }
  },
  mounted() {
    if (this.bookmarksContainer) this.setPositionForWatchTags()
  },
  created() {
    if (this.bookmarksContainer)
      this.bookmarksContainer.addEventListener(
        'scroll',
        this.emitToggleTagBlock
      )
  },
  destroyed() {
    if (this.bookmarksContainer)
      this.bookmarksContainer.removeEventListener(
        'scroll',
        this.emitToggleTagBlock
      )
  },
  methods: {
    emitToggleTagBlock() {
      this.$emit(EMITTERS.TOGGLE_TAG_BLOCK_EMITTER, { hide: true })
    },
    setPositionForWatchTags() {
      const currentTop = this.$refs.tagsContainer.offsetTop
      const currentScroll = this.bookmarksContainer.scrollTop
      this.$refs.tagsContainer.style.top = currentTop - currentScroll + 'px'
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../shared/assets/styles/global';
.bookmark-tags-backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1;
}
.bookmark-tags-container {
  position: absolute;
  padding: 12px;
  border-radius: 4px;
  min-width: 230px;
  max-width: 350px;
  width: auto;
  font-size: 12px;
  z-index: 2;

  .bookmark-tags {
    margin: 0 -3px;
    flex-wrap: wrap;
    display: flex;
  }

  .bookmark-tag {
    border-radius: 12px;
    padding: 4px 10px;
    margin: 6px 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .bookmark-tags-title {
    font-size: 12px;
  }
  .bookmarks-tags-heading {
    display: flex;
    justify-content: space-between;
  }
}
.bookmark-tags-container.dark {
  background-color: #2b2834;
  border: 1px solid #3b3946;
  margin-top: 2px;

  .bookmark-tags-title {
    color: $white;
  }
  .bookmark-tag {
    border: 1px solid #444050;
    background-color: #3b3946;
    color: #d1d3d4;
  }
}
.bookmark-tags-container.light {
  background-color: $white;
  border: 1px solid #d1d3d4;
  top: 30px;
  right: 0;
  .bookmark-tags-title {
    color: #323232;
  }
  .bookmark-tag {
    border: 1px solid #d1d3d4;
    background-color: $white;
    color: #5c5c5c;
  }
}
</style>
