<template>
  <div>
    <History />
  </div>
</template>

<script>
import History from './components/History'

export default {
  name: 'ProfileWatchHistoryAppComponent',
  components: {
    History
  },
}
</script>

<style lang="scss" scoped>
@import '../shared/assets/styles/global';
</style>
