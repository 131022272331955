<template>
  <div class="horizontal-calendar">
    <div
      class="arrow-left"
      :class="{ disabled: leftArrowDisabled }"
      @click="scrollDaysLeft()"
    >
      &#60;
    </div>
    <div ref="calendar-days" class="calendar-days">
      <div
        v-for="(date, index) in allDates"
        :id="index"
        :key="index"
        class="calendar-day"
        :class="{
          'calendar-day__selected': datesAreOnSameDay(date, selectedDate),
          disabled: dateDisabled(date),
        }"
        @click="datePicked(date)"
      >
        <div class="calendar-weekday">
          {{ parseFormatString(date, { weekday: 'short' }) }}
        </div>

        <div class="calendar-date">
          {{
            parseFormatString(date, {
              month: 'short',
              day: 'numeric',
            })
          }}
        </div>
      </div>
    </div>
    <div
      class="arrow-right"
      :class="{ disabled: rightArrowDisabled }"
      @click="scrollDaysRight()"
    >
      &#62;
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue'

import { EMITTERS } from '../utils/emitters'
import { formatDateToString } from '../../schedule/utils/time_utils'
import { IS_MOBILE } from '../utils/is_mobile'

export default {
  name: 'HorizontalCalendar',
  props: {
    selectedDate: {
      required: true,
      type: Date,
    },
    timezone: {
      type: String,
      required: true,
    },
    availableDates: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      leftArrowDisabled: false,
      rightArrowDisabled: false,
      allDates: [],
      scrollAmount: {
        mobile: 100,
        desktop: 200,
      },
    }
  },
  watch: {
    availableDates() {
      this.parseAllDates()
    },
    selectedDate() {
      this.scrollToView()
    },
    allDates(_value) {
      this.scrollToView()
    },
  },
  mounted() {
    this.parseAllDates()
    this.scrollToView(this.selectedDate)
  },
  methods: {
    parseAllDates() {
      const startDate = new Date(this.availableDates[0])
      const endDate = new Date(this.availableDates.at(-1))

      const date = new Date(startDate.getTime())

      const dates = []

      while (date <= endDate) {
        dates.push(new Date(date))
        date.setDate(date.getDate() + 1)
      }

      this.allDates = dates
    },
    datePicked(date) {
      this.emitter.emit(EMITTERS.DATE_PICKED, new Date(date))
    },
    scrollToView() {
      nextTick(() => {
        const dateId = this.allDates.findIndex((date) => {
          return (
            formatDateToString(date) === formatDateToString(this.selectedDate)
          )
        })

        const element = document.getElementById(dateId.toString())

        if (!element) return

        element.parentNode.scrollLeft =
          element.offsetLeft - element.parentNode.offsetLeft

        this.checkArrows()
      })
    },
    scrollDaysLeft() {
      const content = this.$refs['calendar-days']
      content.scrollLeft -= IS_MOBILE
        ? this.scrollAmount.mobile
        : this.scrollAmount.desktop
      this.checkArrows()
    },
    scrollDaysRight() {
      const content = this.$refs['calendar-days']
      content.scrollLeft += IS_MOBILE
        ? this.scrollAmount.mobile
        : this.scrollAmount.desktop
      this.checkArrows()
    },
    checkArrows() {
      const content = this.$refs['calendar-days']
      const maxScrollLeft = content.scrollWidth - content.clientWidth

      this.rightArrowDisabled = content.scrollLeft >= maxScrollLeft
      this.leftArrowDisabled = content.scrollLeft <= 0
    },
    parseFormatString(dateString, options) {
      const date = new Date(dateString)

      return date.toLocaleDateString('en-US', options)
    },
    dateDisabled(targetDate) {
      return !this.availableDates.find((date) => {
        return formatDateToString(targetDate) === date
      })
    },
    datesAreOnSameDay(first, second) {
      return (
        first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate()
      )
    },
  },
}
</script>

<style lang="scss">
.horizontal-calendar {
  display: flex;
  width: 100%;
  height: 88px;
  margin: 20px 0;
  background: #f3f4f5;
  border-radius: 8px;
  position: relative;
  justify-content: space-between;

  .calendar-days {
    display: flex;
    overflow-x: hidden;
  }
  .calendar-day {
    text-align: center;
    font-weight: 500;
    color: #5c5c5c;
    margin: auto 10px;
    white-space: nowrap;
    padding: 6px 30px;
    border-radius: 8px;
    cursor: pointer;
    user-select: none; /* standard syntax */
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */

    &.disabled {
      color: #a6a6a6 !important;
      cursor: not-allowed !important;
      pointer-events: none !important;
    }
    .calendar-weekday {
      font-size: 20px;
    }
    .calendar-date {
      font-size: 14px;
    }
  }
  .arrow-left,
  .arrow-right {
    margin: 12px;
    width: 64px;
    height: 64px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 40px rgba(167, 167, 167, 0.15);
    text-align: center;
    padding: 20px 25px;
    color: #131118;

    &.disabled {
      color: #a6a6a6;
      cursor: not-allowed;
    }
  }

  .arrow-left,
  .arrow-right {
    cursor: pointer;
    user-select: none; /* standard syntax */
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
  }

  .calendar-day__selected {
    background: #6998ae;
    color: #f3f4f5;
  }
}
</style>
