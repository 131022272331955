import axios from 'axios'

import {
  API_ROUTES,
  FILE_UPLOAD_CONFIG,
  config,
} from '../../../shared/utils/api'

export default {
  DEFAULT_ORDER: 'desc',

  getAllEbooks(optional_params = {}) {
    return axios.request({
      ...FILE_UPLOAD_CONFIG,
      method: 'GET',
      url: API_ROUTES.ebooks(optional_params.episode_id),
    })
  },

  async createEbook(ebook, optional_params = {}, onProgress) {
    return new Promise(async (resolve, reject) => {
      await axios
        .post(
          API_ROUTES.ebooks(optional_params.episode_id),
          this.formData(ebook),
          config(onProgress)
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error))
    })
  },

  deleteEbook(ebook, optional_params = {}) {
    return axios.request({
      ...FILE_UPLOAD_CONFIG,
      method: 'DELETE',
      url: API_ROUTES.ebook(optional_params.episode_id, ebook.id),
    })
  },

  async updateEbook(ebook, optional_params = {}, onProgress) {
    return new Promise(async (resolve, reject) => {
      await axios
        .put(
          API_ROUTES.ebook(optional_params.episode_id, ebook.id),
          this.formData(ebook),
          config(onProgress)
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error))
    })
  },

  formData(ebook) {
    const formData = new FormData()
    if (ebook.document && ebook.document.size !== undefined) {
      const documentName =
        ebook.name + '.' + ebook.document.name.split('.').pop()
      formData.append('file', ebook.document, documentName)
    }
    if (ebook.cover && ebook.cover.size !== undefined) {
      formData.append('cover', ebook.cover)
    }
    formData.append('name', ebook.name)
    formData.append('author', ebook.author)
    formData.append('description', ebook.description)
    if (ebook.ebook_id) {
      formData.append('ebook_id', ebook.ebook_id)
    }

    return formData
  },
}
