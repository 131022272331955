import videojs from 'video.js'

import { videoJSPlayer } from 'video/player'

export default class EpisodeMomentPreviewer {
  constructor(videoElementId) {
    this.SIZE = { width: 174, height: 98 }
    this.CLONE_CONTAINER = $('#video-copy-for-capture-container')
    this.PREVIEW_CONTAINER_ID = 'video-preview'
    this.cachedTimestamp = null
    this.videoElementId = videoElementId
    this.originalPlayer = videojs.getPlayer(`#${this.videoElementId}`)
    this.timeTooltip = $(this.originalPlayer.el_)
      .find('.vjs-mouse-display')
      .find('.vjs-time-tooltip')
    this.initPreviewContainer()
    this.createCloneForPreview()
    this.handleHover()
  }
  initPreviewContainer() {
    const container = $('<div class="video-preview-container"></div>')
    container.append($(`<canvas id="${this.PREVIEW_CONTAINER_ID}"></canvas>`))
    container.insertAfter(this.timeTooltip)

    this.$previewCanvas = $(`#${this.PREVIEW_CONTAINER_ID}`)
  }
  resetCanvas() {
    if (this.$previewCanvas[0]) {
      this.$previewCanvas[0]
        .getContext('2d')
        .clearRect(0, 0, this.SIZE.width, this.SIZE.height)
    }

    this.$previewCanvas.hide()
  }
  captureMomentAsCanvas(video) {
    this.$previewCanvas
      .attr(this.SIZE)[0]
      .getContext('2d')
      .drawImage(video, 0, 0, this.SIZE.width, this.SIZE.height)
    this.$previewCanvas.show()
  }
  createCloneForPreview() {
    const videoOrig = $('#episode_player_html5_api')
    this.$videoElementClone = videoOrig.clone()
    this.$videoElementClone.attr('id', `video-copy-for-capture`)
    this.$videoElementClone.attr(this.SIZE)
    this.CLONE_CONTAINER.append(this.$videoElementClone)
    videoJSPlayer('video-copy-for-capture', {
      muted: true,
    })

    this.clonePlayer = videojs.getPlayer('video-copy-for-capture')
    $(this.clonePlayer.el_).hide()
    this.selectQuality()
  }
  selectQuality() {
    const self = this
    this.clonePlayer.play().then(() => {
      const qualityOptionsList =
        self.clonePlayer.hlsQualitySelector._qualityButton.items
      const qualityOptionIndex = 0
      qualityOptionsList[qualityOptionIndex].handleClick()
    })
  }
  handleHover() {
    const self = this
    const progressBar = self.originalPlayer.controlBar.progressControl.el()

    $(progressBar).on('mousemove', () => {
      const timestamp = self.calcTimestamp(self.timeTooltip)

      if (self.cachedTimestamp) {
        if (Math.abs(self.cachedTimestamp - timestamp) < 5) return
      }

      self.cachedTimestamp = timestamp

      self.clonePlayer.currentTime(timestamp)
      self.resetCanvas()
    })

    self.clonePlayer.on('canplay', () => {
      self.captureMomentAsCanvas(self.$videoElementClone[0])
    })
  }
  calcTimestamp(tooltip) {
    const time = tooltip.text().split(':')

    let timestamp = 0
    if (time.length === 3) timestamp += parseInt(time.shift()) * 60 * 60
    timestamp += parseInt(time[0]) * 60 + parseInt(time[1])

    return timestamp
  }
}
