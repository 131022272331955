<template>
  <div class="recent-search__item" data-turbolinks="false">
    <a :href="getUrl(item)" class="name" :class="{ name__light: isLight }">
      {{ item.title }}
    </a>
    <CloseIconComponent
      v-if="!destroying_process"
      class="delete-item"
      color="#8f8a9b"
      :theme="theme"
      @click="removeItemHistory()"
    />
    <img
      v-else
      src="../../shared/assets/images/loading.gif"
      alt="loading"
      class="destroying-loader"
    />
  </div>
</template>

<script>
import CloseIconComponent from '../../shared/components/icons/Close'
import RecentSearchService from '../services/recentSearch.service'
import { EMITTERS } from '../../shared/utils/emitters'

export default {
  name: 'RecentSearchItem',
  components: { CloseIconComponent },
  props: {
    item: {
      required: true,
      type: Object,
    },
    isLight: {
      required: true,
      type: Boolean,
    },
    theme: {
      required: true,
      type: String,
    },
    settings: {
      required: true,
      default: () => ({
        watch_host: '',
        main_host: '',
        events_host: '',
      }),
      type: Object,
    },
  },
  data() {
    return {
      destroying_process: false,
      EMITTERS,
    }
  },
  methods: {
    getUrl(item) {
      const protocol = window.location.protocol
      const watchHost = this.settings.watch_host
      const mainHost = this.settings.main_host
      const seriesSlug = item.series_slug || 'single-episode'
      const queryParams = `?search_from=web`

      if (item.type === 'news')
        return `${protocol}//${mainHost}/prophecy-news/${item.slug}${queryParams}`

      return `${protocol}//${watchHost}/${seriesSlug}/${item.slug}${queryParams}`
    },
    removeItemHistory() {
      this.destroying_process = true

      setTimeout(() => {
        RecentSearchService.deleteRecentlySearch(
          this.item,
          this.optional_params
        )
          .then(() => {
            this.$emit(EMITTERS.RECENT_SEARCH_ITEM_REMOVE, this.item)
          })
          .finally(() => {
            this.destroying_process = false
          })
      }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
.recent-search__item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 8px;

  .name {
    font-family: DM Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #fff;
    cursor: pointer;
    max-width: 660px;

    @media (max-width: 767.98px) {
      max-width: 475px;
    }

    @media (max-width: 575.98px) {
      max-width: 285px;
    }
  }

  .name__light {
    color: #5c5c5c;
  }

  .delete-item {
    cursor: pointer;

    @media (max-width: 991.98px) {
      width: 16px;
      height: 16px;
    }
  }

  .destroying-loader {
    cursor: not-allowed;
    width: 16px;
    height: 16px;
  }
}
</style>
