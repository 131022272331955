<template>
  <div>
    <BreadcrumbsComponent v-if="showBreadcrumbs" />
    <Notes />
  </div>
</template>

<script>
import Notes from './components/Notes'
import BreadcrumbsComponent from '../shared/components/Breadcrumbs'

export default {
  name: 'ProfileNotesAppComponent',
  components: {
    Notes,
    BreadcrumbsComponent,
  },
  computed: {
    showBreadcrumbs() {
      return this.$route && this.$route.name === 'profile_note_edit'
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../shared/assets/styles/global';
</style>
