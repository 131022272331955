<template>
  <div class="container mb-4">
    <div class="info-block">
      <div class="decoration" />
      <span class="title">
        {{ $vueTranslate('all_store_items.app.header') }}
      </span>
    </div>

    <div v-if="!loading" class="entries-block">
      <StoreItemCard
        v-for="item in entries"
        :key="item"
        :item="item"
        :is-light="true"
      />
    </div>
    <LoaderComponent v-else class="loader" />

    <PreviewStoreItem
      v-if="showPreview"
      :theme="theme"
      :entity="storeItem"
      @[EMITTERS.CLOSE_DIALOG_EMITTER]="closePreview"
    />
  </div>
</template>

<script>
import StoreItemCard from '../store_items/components/StoreItemCard'
import storeItemsApiService from '../store_items/services/storeItemsApi.service'
import LoaderComponent from '../shared/components/Loader'
import PreviewStoreItem from '../store_items/components/PreviewStoreItem'
import { THEMES } from '../shared/utils/view_options'
import { EMITTERS } from '../shared/utils/emitters'

export default {
  name: 'App',
  components: { PreviewStoreItem, LoaderComponent, StoreItemCard },
  data() {
    return {
      EMITTERS,
      loading: false,
      entries: [],
      page: 1,
      next_page: 1,
      total_count: 0,
      bottomOffset: 550,
      showPreview: false,
      theme: THEMES.LIGHT,
      storeItem: {},
    }
  },
  mounted() {
    document.addEventListener('scroll', this.handleScroll, true)

    this.fetchStoreItems()

    this.emitter.on(EMITTERS.STORE_ITEM_PREVIEW, this.openPreview)
  },
  methods: {
    openPreview(item) {
      this.storeItem = item
      this.showPreview = true
    },
    closePreview() {
      this.storeItem = {}
      this.showPreview = false
    },
    fetchStoreItems() {
      storeItemsApiService
        .fetch(this.optional_params, this.page, 12)
        .then((response) => {
          if (this.page === 1) this.entries = response.data.entries
          else this.entries = [...this.entries, ...response.data.entries]

          this.next_page = response.data.next_page
          this.total_count = response.data.total_count
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleScroll() {
      if (this.loading) return

      const resultBox = document.querySelector('body')
      const scrollPosition = resultBox.scrollTop + resultBox.clientHeight
      const totalHeight = resultBox.scrollHeight

      if (this.shouldLoadMoreResults(scrollPosition, totalHeight)) {
        this.loading = true
        this.page++
        this.fetchStoreItems()
      }
    },
    shouldLoadMoreResults(scrollPosition, totalHeight) {
      const canLoadMore =
        !this.loadingData && this.entries.length < this.total_count
      const reachedBottom = scrollPosition >= totalHeight - this.bottomOffset
      return canLoadMore && reachedBottom
    },
  },
}
</script>

<style scoped lang="scss">
.info-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-bottom: 32px;
  margin-top: 52px;

  .decoration {
    width: 32px;
    height: 4px;
    background-color: #d18f42;
  }

  .title {
    font-family: DM Sans, serif;
    font-size: 28px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    color: #323232;
  }
}

.entries-block {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;

  @media (max-width: 463.98px) {
    justify-content: center;
  }
}

.loader {
  margin-top: 8px;
  margin-bottom: 8px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
