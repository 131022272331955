export function shouldDisplayTooltip(key) {
  if (!key) return false

  let lastShownAt = localStorage.getItem(tooltipStorageItemKey(key))
  let numberOfShows = localStorage.getItem(
    tooltipStorageItemKey(key) + '_number_of_shows'
  )
  numberOfShows = parseInt(numberOfShows || 0)

  const today = new Date()
  lastShownAt = new Date(lastShownAt)

  let threeMonthsLater = new Date(lastShownAt)
  threeMonthsLater.setMonth(lastShownAt.getMonth() + 3)

  if (!dateValid(lastShownAt)) {
    localStorage.setItem(tooltipStorageItemKey(key), today.toString())
    setNumberOfShows(tooltipStorageItemKey(key), 1)
    return true
  }

  if ((numberOfShows && numberOfShows >= 7) || today > threeMonthsLater)
    return false

  lastShownAt.setDate(lastShownAt.getDate() + 1)
  if (lastShownAt < today) {
    setNumberOfShows(tooltipStorageItemKey(key), numberOfShows + 1)
    localStorage.setItem(tooltipStorageItemKey(key), today.toString())
  }
  return true
}

function tooltipStorageItemKey(key) {
  return `tooltip_${key}`
}

function setNumberOfShows(tabName, number) {
  localStorage.setItem(tabName + '_number_of_shows', number.toString())
}

function dateValid(date) {
  return date && !isNaN(date) && date.getFullYear() >= 2000
}
