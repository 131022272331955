import axios from 'axios'

import { API_ROUTES, BASE_API_CONFIG } from '../../shared/utils/api'

export default {
  fetch(optional_params, page = 1, perPage = 4) {
    return axios.request({
      ...BASE_API_CONFIG,
      method: 'GET',
      url: API_ROUTES.store_items_ebook_topics,
      params: {
        page: page,
        per_page: perPage,
        ebook_topic_id: optional_params.ebook_topic_id,
      },
    })
  },
}
