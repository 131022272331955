export const BOOKMARK_EDITED = 'The bookmark was edited'
export const BOOKMARK_DELETED = 'The bookmark was deleted'
export const BOOKMARK_ARCHIVED =
  'The text was edited after you bookmarked it, so you can’t go to a specific bookmarked place'
export const NOTE_CREATED = 'The note was created'
export const NOTE_UPDATED = 'The note was edited'
export const NOTE_DELETED = 'The note was deleted'

export const WATCH_HISTORY_EPISODE_DELETED =
  'The video was deleted from Watch history list'
