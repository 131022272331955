<template>
  <div class="links__container">
    <Link
      v-for="link of links"
      v-if="!loading"
      :key="link.id"
      :title="link.title"
      :link="link.link"
      :description="link.description"
    />
    <img
      v-else
      src="../../../shared/assets/images/loading.gif"
      alt="loading"
      class="documents-loader"
    />
  </div>
</template>

<script>
import Link from './Link'
import { VIDEO_RESOURCES_MODES as MODES } from '../../../shared/utils/view_options'
import LinksApiService from '../../../admin/video_resources/services/linksApi.service'

export default {
  name: 'LinksComponent',
  components: {
    Link,
  },
  data() {
    return {
      MODES,
      links: [],
      loading: true,
    }
  },
  created() {
    this.fetchLinks()
  },
  methods: {
    fetchLinks() {
      LinksApiService.getAllLinks(this.optional_params)
        .then((response) => {
          const responseData = response.data

          this.links = responseData.links
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../shared/assets/styles/documents';

.links__container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 10px;
  width: 100%;

  &:first-child {
    padding-top: 16px;
  }
}

.links__empty {
  width: 495px;
  height: 36px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}
</style>
