import * as ShareButtons from 'share-buttons'

// Using 1 as the min scroll position value because 0 is not handled properly by browsers without
// native ScrollToOptions support
const SCROLL_POSITION_MIN = 1

function scrollBy($cardsContainer, offset) {
  $cardsContainer[0].scrollBy({ top: offset, behavior: 'smooth' })
}

function scrollTo($cardsContainer, position) {
  $cardsContainer[0].scrollTo({ top: position, behavior: 'smooth' })
}

function showScrollButton($scrollButton) {
  $scrollButton.removeClass('hidden')
}

function hideScrollButton($scrollButton) {
  $scrollButton.addClass('hidden')
}

function scrollPosition($cardsContainer) {
  return $cardsContainer[0].scrollTop
}

function findCardsSlider() {
  return $('.cards_slider')
}

function findScrollUpButton($cardsSlider) {
  return $cardsSlider.find('.scroll_up_button')
}

function findScrollDownButton($cardsSlider) {
  return $cardsSlider.find('.scroll_down_button')
}

function setupCardsSlider() {
  const $cardsSlider = findCardsSlider()
  if ($cardsSlider.length === 0) return

  // If slider has been initialized then do nothing
  if ($cardsSlider.data('upgraded')) return

  const $cardsContainer = $cardsSlider.find('.cards_container')
  const $scrollUpButton = findScrollUpButton($cardsSlider)
  const $scrollDownButton = findScrollDownButton($cardsSlider)

  const $firstCard = $cardsSlider.find('.suggested_next_card')
  const cardHeight = $firstCard.outerHeight(true)
  const sliderHeight = $cardsSlider.innerHeight()
  // full container height including the overflow
  const cardsContainerHeight = $cardsContainer[0].scrollHeight

  if (cardsContainerHeight <= sliderHeight) {
    $scrollUpButton.addClass('hidden')
    $scrollDownButton.addClass('hidden')

    return
  }

  resetScrollButtons($cardsSlider)
  $cardsSlider.data('upgraded', true)

  const maxScrollPosition = cardsContainerHeight - sliderHeight

  $cardsContainer.on('scroll', () => {
    const scrollPos = scrollPosition($cardsContainer)

    if (scrollPos <= SCROLL_POSITION_MIN) {
      hideScrollButton($scrollUpButton)
    }

    if (scrollPos > SCROLL_POSITION_MIN) {
      showScrollButton($scrollUpButton)
    }

    if (scrollPos <= maxScrollPosition) {
      hideScrollButton($scrollDownButton)
    }

    if (scrollPos < maxScrollPosition) {
      showScrollButton($scrollDownButton)
    }
  })

  $scrollUpButton.on('click', () => {
    const scrollPos = scrollPosition($cardsContainer)

    // If scroll position is within the first 2 cards height and is something in between the cards,
    // we just scroll to the top to avoid any intermediate scroll positioning
    if (scrollPos < 2 * cardHeight) {
      scrollTo($cardsContainer, SCROLL_POSITION_MIN)
    } else {
      scrollBy($cardsContainer, -cardHeight)
    }
  })

  $scrollDownButton.on('click', () => {
    const scrollPos = scrollPosition($cardsContainer)

    if (scrollPos > maxScrollPosition - 2 * cardHeight) {
      scrollTo($cardsContainer, maxScrollPosition)
    } else {
      scrollBy($cardsContainer, cardHeight)
    }
  })
}

function resetCardsSlider() {
  const $cardsSlider = findCardsSlider()
  if ($cardsSlider.length === 0) return

  $cardsSlider.data('upgraded', false)
  resetScrollButtons($cardsSlider)
}

function resetScrollButtons($cardsSlider) {
  const $scrollUpButton = findScrollUpButton($cardsSlider)
  const $scrollDownButton = findScrollDownButton($cardsSlider)

  // Restore original state of scroll buttons before putting them into
  // Turbolinks cache
  hideScrollButton($scrollUpButton)
  showScrollButton($scrollDownButton)
}

// The most compatible way of copying text with JS is to create input,
// select its content, and execute "copy" command.
// TODO: replace with Clipboard API when we drop support for IE.
function copyCurrentURL() {
  // Get current URL but without query parameters
  const currentURL = window.location.origin + window.location.pathname
  const $inputForURL = $('<input>').attr({ value: currentURL }).appendTo('body')
  const tooltip = $('.copy_current_url-tooltip__container')

  $inputForURL.trigger('select')
  document.execCommand('copy')
  $inputForURL.remove()

  tooltip.show()
  setTimeout(() => tooltip.hide(), 2000)
}

function copyEmbedCode() {
  const source = window.location.origin + '/embedded' + window.location.pathname
  const iframe = `<iframe src="${source}" width="560" height="314" allowfullscreen="allowfullscreen"></iframe>`
  const $inputForURL = $('<input>').attr({ value: iframe }).appendTo('body')

  $inputForURL.trigger('select')
  document.execCommand('copy')
  $inputForURL.remove()
}

$(document).on('turbolinks:load', () => {
  ShareButtons.update()
  setupCardsSlider()

  $('.copy_current_url').on('click', copyCurrentURL)
  $('.copy_embed_code').on('click', copyEmbedCode)
})
$(window).resize(setupCardsSlider)
$(document).on('turbolinks:before-cache', resetCardsSlider)
