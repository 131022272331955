<template>
  <div class="store-items__form">
    <div class="store-items__input-group">
      <input
        v-model="title"
        :maxlength="TITLE_MAX_LENGTH"
        class="store-items__input"
      />
      <span class="counter"> {{ title.length }}/{{ TITLE_MAX_LENGTH }} </span>
      <input
        v-model="author"
        :maxlength="AUTHOR_MAX_LENGTH"
        class="store-items__input"
      />
      <span class="counter"> {{ author.length }}/{{ AUTHOR_MAX_LENGTH }} </span>
      <textarea
        v-model="description"
        :maxlength="DESCRIPTION_MAX_LENGTH"
        class="store-items__input store-items__input-area"
      />
      <span class="counter">
        {{ description.length }}/{{ DESCRIPTION_MAX_LENGTH }}
      </span>

      <span class="store-items__buttons-title">
        {{ $vueTranslate('admin.store_items.app.buttons_title') }}
      </span>

      <div class="store-items__buttons-group">
        <ItemTooltip
          :title="cadButtonTitle"
          :link="cadButtonUrl"
          @[EMITTERS.STORE_ITEM_TOOLTIP_CHANGED]="changeCadValues"
        />
        <ItemTooltip
          :title="usdButtonTitle"
          :link="usdButtonUrl"
          @[EMITTERS.STORE_ITEM_TOOLTIP_CHANGED]="changeUsdValues"
        />
      </div>
    </div>
    <div class="store-items__preview">
      <img :src="thumbnailUrl" :alt="title" />
    </div>
  </div>
</template>

<script>
import ItemTooltip from './ItemTooltip'
import { EMITTERS } from '../../../shared/utils/emitters'

export default {
  name: 'ItemForm',
  components: { ItemTooltip },
  props: {
    item: {
      type: Object,
      default: () => ({
        title: '',
        author: '',
        description: '',
        thumbnail_url: '',
        usd_store_name: '',
        usd_store_link: '',
        cad_store_name: '',
        cad_store_link: '',
      }),
      required: true,
    },
    sku: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {
      title: this.item.title,
      author: this.item.author,
      description: this.item.description,
      thumbnailUrl: this.item.thumbnail_url,
      usdButtonTitle: this.item.usd_store_name,
      usdButtonUrl: this.item.usd_store_link,
      cadButtonTitle: this.item.cad_store_name,
      cadButtonUrl: this.item.cad_store_link,
      cadInputsVisibility: false,
      usdInputsVisibility: false,
      TITLE_MAX_LENGTH: 255,
      AUTHOR_MAX_LENGTH: 255,
      DESCRIPTION_MAX_LENGTH: 10000,
      EMITTERS,
    }
  },
  watch: {
    $data: {
      deep: true,
      handler() {
        this.emitUpdatedItem()
      },
    },
  },
  methods: {
    emitUpdatedItem() {
      const updatedItem = {
        sku: this.sku,
        title: this.title,
        author: this.author,
        description: this.description,
        thumbnail_url: this.thumbnailUrl,
        usd_store_name: this.usdButtonTitle,
        usd_store_link: this.usdButtonUrl,
        cad_store_name: this.cadButtonTitle,
        cad_store_link: this.cadButtonUrl,
      }

      this.$emit(EMITTERS.STORE_ITEM_FORM_UPDATED, updatedItem)
    },
    changeCadValues(title, link) {
      this.cadButtonTitle = title
      this.cadButtonUrl = link
    },
    changeUsdValues(title, link) {
      this.usdButtonTitle = title
      this.usdButtonUrl = link
    },
  },
}
</script>

<style scoped lang="scss">
.store-items__input {
  width: 100%;
  height: 32px;
  top: 145px;
  left: 22px;
  border-radius: 2px;
  padding: 12px 16px 12px 16px;
  border: 1px solid #d1d3d4;
  font-family: DM Sans, serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;

  &:focus-visible {
    outline: none;
  }

  margin-bottom: 6px;
}

.store-items__input-area {
  height: 111px;
  font-family: DM Sans, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #323232;
  resize: none;
}

.store-items__preview {
  max-width: 240px;
  max-height: 200px;
  border-radius: 2px;
  border: 1px solid #d1d3d4;

  img {
    width: 100%;
    height: 100%;
  }
}

.store-items__input-group {
  width: 520px;
}

.store-items__form {
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.store-items__buttons-title {
  font-family: DM Sans, serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #5c5c5c;
}

.store-items__buttons-group {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  gap: 13px;
}

.counter {
  font-family: DM Sans, serif;
  font-size: 8px;
  font-weight: 400;
  line-height: 16px;
  text-align: right;
  color: #a6a6a6;
  display: flex;
  justify-content: end;
}
</style>
