import axios from 'axios'

import {
  API_ROUTES,
  FILE_UPLOAD_CONFIG,
  config,
} from '../../../shared/utils/api'

export default {
  DEFAULT_ORDER: 'desc',

  getAllDocuments(optional_params = {}) {
    return axios.request({
      ...FILE_UPLOAD_CONFIG,
      method: 'GET',
      url: API_ROUTES.documents(optional_params.episode_id),
    })
  },

  async createDocument(document, optional_params, onProgress) {
    return new Promise(async (resolve, reject) => {
      await axios
        .post(
          API_ROUTES.documents(optional_params.episode_id),
          this.formData(document),
          config(onProgress)
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error))
    })
  },

  deleteDocument(document, optional_params = {}) {
    return axios.request({
      ...FILE_UPLOAD_CONFIG,
      method: 'DELETE',
      url: API_ROUTES.document(optional_params.episode_id, document.id),
    })
  },

  async updateDocument(document, optional_params = {}, onProgress) {
    return new Promise(async (resolve, reject) => {
      await axios
        .put(
          API_ROUTES.document(optional_params.episode_id, document.id),
          this.formData(document),
          config(onProgress)
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error))
    })
  },

  formData(document) {
    const formData = new FormData()
    if (document.document && document.document.size !== undefined) {
      const documentName =
        document.title + '.' + document.document.name.split('.').pop()
      formData.append('document', document.document, documentName)
    }
    formData.append('title', document.title)
    formData.append('description', document.description)

    return formData
  },
}
