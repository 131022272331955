<template>
  <div class="articles-tab">
    <ArticleCard
      v-for="article in entries"
      :key="article.id"
      :item-id="article.id"
      :title="article.title"
      :description="article.description"
      :subject="article.subject"
      :cover-url="article.cover_url"
      :topics="article.topics"
      :settings="settings"
      :search-query="query"
      :is-light="isLight"
    />
  </div>
</template>

<script>
import ArticleCard from './ArticleCard'

export default {
  name: 'ArticleTab',
  components: { ArticleCard },
  props: {
    entries: {
      required: true,
      type: Array,
      default: () => [],
    },
    settings: {
      required: true,
      default: () => ({
        watch_host: '',
        main_host: '',
        events_host: '',
      }),
      type: Object,
    },
    query: {
      required: true,
      default: '',
      type: String,
    },
    isLight: {
      default: false,
      type: Boolean,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.articles-tab {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;

  @media (max-width: 463.98px) {
    justify-content: center;
  }
}
</style>
