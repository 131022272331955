<template>
  <div
    :key="reloadTrigger"
    class="rich-text-container"
    :class="[theme, viewOnlyClass, toolboxOnTopClass]"
  >
    <QuillEditor
      theme="snow"
      class="quill-editor"
      @ready="storeQuill"
      :placeholder="placeholder"
      :options="OPTIONS"
      v-model:content="content"
      :content-type="'html'"
      @editorChange="emitContentUpdate"
    />
    <SocialHint />
  </div>
</template>

<script>
import { Quill, QuillEditor, Delta } from '@vueup/vue-quill'
import BlotFormatter from 'quill-blot-formatter'

import CustomImage from './rich_text/custom_image'
import CustomLinkSanitizer from './rich_text/custom_link_sanitizer'
import CustomTooltip from './rich_text/custom_tooltip'
import Definition from './rich_text/definition'
import { titleBackspaceHandler } from './rich_text/blot_wrapper'
import { initCommentary } from './rich_text/commentary'
import { initQuote } from './rich_text/quote'
import { initExpandSection, ExpandContainer } from './rich_text/expand_section'
import { initExtendedImage, updateAlignOverlays } from './rich_text/image_with_description'

import '@vueup/vue-quill/dist/vue-quill.snow.css'
import { THEMES } from '../utils/view_options'
import PreviewComponent from './icons/Preview.vue'
import SocialHint from './SocialHint.vue'

// Override code-block icon
const icons = Quill.import('ui/icons')
icons['ad-definition'] =
  '<div class="icon-definition" aria-hidden="true">Definition</div>'

// Link Sanitizer
const Link = Quill.import('formats/link')
// Override the existing property on the Quill global object and add custom protocols
Link.PROTOCOL_WHITELIST = [
  'http',
  'https',
  'mailto',
  'tel',
  'radar',
  'rdar',
  'smb',
  'sms',
]

const VUE_QUILL_TOOLBAR = [
  ['undo', 'redo'],
  [{ size: ['small', false, 'large', 'huge'] }],
  ['bold', 'italic', 'underline'],
  [{ list: 'bullet' }, { list: 'ordered' }],
  [{ align: [] }],
  [{ indent: '-1' }, { indent: '+1' }],
  ['link'],
]

const ADMIN_TOOLBAR_OPTIONS = [[{ 'script': 'sub'}, { 'script': 'super' }], ['image', 'video']]

const ADMIN_CUSTOM_TOOLBAR_OPTIONS = [['ad-definition', 'quote', 'commentary', 'expand', 'extended-image']]

// Blot formatter
Quill.register('formats/image', CustomImage)
Quill.register('modules/blotFormatter', BlotFormatter)

Quill.register(CustomLinkSanitizer, true)
Quill.register(Definition)
initQuote()
initCommentary()
initExpandSection()
initExtendedImage()

export default {
  name: 'RichTextComponent',
  components: {
    QuillEditor,
    PreviewComponent,
    SocialHint,
  },
  props: {
    placeholder: {
      type: String,
      default: null,
    },
    content: {
      type: String,
      required: true,
    },
    theme: {
      type: String,
      default: THEMES.DARK,
    },
    contentUpdated: {
      type: Boolean,
      default: false,
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
    extendedToolbar: {
      type: Boolean,
      default: false,
    },
    toolboxOnTop: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['changed', 'update:content'],
  computed: {
    viewOnlyClass() {
      return this.viewOnly ? 'view-only' : ''
    },
    toolboxOnTopClass() {
      return this.toolboxOnTop ? 'toolbox-top' : ''
    },
  },
  data() {
    return {
      OPTIONS: this.options(),
      quill: null,
      definitionToltip: null,
      reloadTrigger: true,
    }
  },
  watch: {
    contentUpdated() {
      // This trigger is needed, because VueQuill does not watch content change, so it must be done manually
      this.reloadTrigger = !this.reloadTrigger
    },
    content() {
      this.$emit('changed', this.content)
      this.updateExtendedImageOverlays()
    }
  },
  methods: {
    storeQuill(quill) {
      this.quill = quill
      this.addFormatHandler()
      if (this.extendedToolbar) this.initDefinitionTooltip()
      this.initDefinitionTooltipsDraw()
      if (this.viewOnly) this.initExpandSections()
      quill.container.addEventListener('click', (event) => {
        if ($('.ad-extended-image').find($(event.target)).length === 0) {
          this.updateExtendedImageOverlays()
        }
      })
      this.updateExtendedImageOverlays()
      this.addClipboardMatcher()
    },
    addClipboardMatcher() {
      this.quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
        let ops = []
        delta.ops.forEach(op => {
          if (op.insert && typeof op.insert === 'string') {
            ops.push({
              insert: op.insert
            })
          }
        })
        delta.ops = ops
        return delta
      })
    },
    addFormatHandler() {
      this.quill.clipboard.addMatcher(Node.ELEMENT_NODE, function (
        node,
        delta
      ) {
        delta.forEach((e) => {
          if (e.attributes) {
            e.attributes.color = ''
            e.attributes.background = ''
          }
        })
        return delta
      })
    },
    initDefinitionTooltip() {
      this.definitionToltip = new CustomTooltip('ad-definition', this.quill, {
        inputLabel: 'Definition for:',
        inputPlaceholder: 'Definition...',
        actionText: 'Add',
        hideOnTyping: true,
        clearAfterHide: true,
        textarea: true,
        onAction: this.handleDefinitionInput,
        onShow: this.initDefinition,
        showSelection: true,
        additionalFields: ['link'],
      })
    },
    undo() {
      const history = this.quill.getModule('history')
      history.undo()
    },
    redo() {
      const history = this.quill.getModule('history')
      history.redo()
    },
    handleDefinitionInput(value, link, selection, _event) {
      try {
        this.quill.format(
          'ad-definition',
          { value, link: link.link },
          Quill.sources.USER
        )
        this.reloadTrigger = !this.reloadTrigger
        setTimeout(() => {
          this.quill.setSelection(
            selection.index,
            selection.length,
            Quill.sources.USER
          )
          this.quill.focus()
          this.initDefinitionTooltipsDraw()
        }, 1)
      } catch (error) {}
    },
    initDefinitionTooltipsDraw() {
      const definitions = this.quill.container.getElementsByClassName(
        'ad-definition'
      )
      for (let definition of definitions) {
        Definition.initDrawTooltip(definition)
      }
    },
    initExpandSections() {
      const expandSections = this.quill.container.getElementsByClassName(
        'article-references'
      )
      for (let expandSection of expandSections) {
        ExpandContainer.initCollapse(expandSection)
      }
    },
    initDefinition() {
      const currentSelection = this.quill.getSelection(false)
      const currentFormat = this.quill.getFormat(
        currentSelection.index,
        currentSelection.length
      )

      if (!currentFormat) return
      if (!currentFormat['ad-definition']) return

      this.definitionToltip.setInputValue(currentFormat['ad-definition'].value)
      this.definitionToltip.setAdditionalFieldValue(
        'link',
        currentFormat['ad-definition'].link
      )
    },
    emitContentUpdate() {
      let content = this.content
      const text = this.quill.getText()
      if (!text || text === '' || text === '\n') content = ''

      this.$emit('update:content', content)
    },
    updateExtendedImageOverlays() {
      if (this.viewOnly) return

      updateAlignOverlays(this.quill)
    },
    toolbarOptions() {
      if (!this.extendedToolbar) return _.concat(VUE_QUILL_TOOLBAR, [['clean']])
      return _.concat(
        VUE_QUILL_TOOLBAR,
        ADMIN_TOOLBAR_OPTIONS,
        ADMIN_CUSTOM_TOOLBAR_OPTIONS,
        [['clean']]
      )
    },
    options() {
      const self = this
      let opts = {
        readOnly: this.viewOnly,
        modules: {
          keyboard: {
            bindings: {
              preventNewExpandTitle: {
                key: 'enter',
                format: ['expand-title'],
                handler: () => {}, // Prevent adding new title to expand block
              },
              preventRemovingExpandTitle: {
                key: 'backspace',
                format: ['expand-title', 'commentary-title'],
                handler: (range, context) => {
                  titleBackspaceHandler.call(self, range, context, ['expand-title', 'commentary-title'])
                }
              },
              preventNewCommentaryTitle: {
                key: 'enter',
                format: ['commentary-title'],
                handler: () => {}, // Prevent adding new title to expand block
              },
            },
          },
          toolbar: {
            container: this.toolbarOptions(),
            handlers: {
              undo: this.undo,
              redo: this.redo,
            },
          },
          history: {
            delay: 100,
            userOnly: false,
          },
          syntax: false,
        },
      }
      if (!this.viewOnly) opts.modules.blotFormatter = {}

      return opts
    },
  },
}
</script>

<style lang="scss">
@import '../../shared/assets/styles/global';
</style>
