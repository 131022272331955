$(document).on('turbolinks:load', () => {
  if (tooltipSkipCondition()) return

  $('.login-tooltip__close').on('click', closeTooltip)
  setTimeout(showTooltip, 2000)
})

function showTooltip() {
  getTooltip().css('visibility', 'visible')
  getTooltip().css('opacity', '1')
  updateTooltipData()

  return null
}

function getTooltip() {
  return $('.login-tooltip__container')
}

function closeTooltip() {
  getTooltip().remove()

  return null
}

function updateTooltipData() {
  let tooltip_shown_at = new Date().getTime()
  let tooltip_count = localStorage.tooltip_count || 0
  localStorage.setItem('tooltip_shown_at', tooltip_shown_at)
  localStorage.setItem('tooltip_count', ++tooltip_count)

  return null
}

function tooltipSkipCondition() {
  let currentTimestamp = new Date().getTime()
  let tooltipShownAt = parseInt(localStorage.tooltip_shown_at)
  const tooltipTimeout = parseInt(getTooltip().data('tooltipTimeout'))
  const tooltipMaxCount = parseInt(getTooltip().data('tooltipMaxCount'))

  return (
    getTooltip().length === 0 ||
    localStorage.tooltip_count >= tooltipMaxCount ||
    localStorage.dismiss_tooltip === 'true' ||
    tooltipShownAt + tooltipTimeout * 60 * 1000 > currentTimestamp
  )
}
