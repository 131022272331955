<template>
  <div v-if="currentEpisode" class="episode-selector">
    <div class="selected-episode" @click="toggleDropdown">
      <span class="selected-episode__text">
        {{
          seriesExcluded
            ? `${EPISODE_PREFIX} ${currentEpisode.sequence_number}`
            : currentEpisode.title
        }}
      </span>
      <ArrowDownIcon class="selected-episode__icon" />
    </div>

    <div class="episodes-dropdown" :class="{ 'episode-hidden': !dropdownOpen }">
      <div class="episodes-dropdown__wrapper">
        <a
          v-for="episode of episodes"
          :key="episode"
          class="episode-item"
          :class="{ 'episode-selected': episode === currentEpisode }"
          :href="episode.episode_path"
        >
          {{
            seriesExcluded
              ? `${EPISODE_PREFIX} ${episode.sequence_number}: ${episode.title}`
              : episode.title
          }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { SeriesApiService as ApiService } from '../services'
import ArrowDownIcon from '../../shared/components/icons/ArrowDown'
import { remove } from 'lodash'

export default {
  name: 'EpisodeSelectorComponent',
  components: { ArrowDownIcon },
  data() {
    return {
      EPISODE_PREFIX: 'Episode',
      EXCLUDED_SERIES: ["What's Up Prof"],

      dropdownOpen: false,
      seriesExcluded: false,

      episodes: [],
      currentEpisode: {},
    }
  },
  created() {
    this.fetchEpisodes()
  },
  methods: {
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen
    },
    getCurrentEpisode() {
      return this.episodes.find(
        (episode) => window.location.pathname === episode.episode_path
      )
    },
    fetchEpisodes() {
      let seriesId = document
        .getElementById('episodes-selector')
        .getAttribute('data-series-id')
      ApiService.getSeries(seriesId)
        .then((response) => {
          const responseData = response.data
          this.seriesExcluded = !this.EXCLUDED_SERIES.includes(
            responseData.series.title
          )
          const episodes = responseData.series.episodes
          remove(episodes, item => item.unpublished_at !== null)
          this.episodes = episodes
        })
        .catch(console.error)
        .finally(() => {
          this.currentEpisode = this.getCurrentEpisode()
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../shared/assets/styles/global';

.episode-selector {
  top: 1rem;
  left: 1.5rem;
  position: absolute;
  z-index: 10;
}

.episodes-dropdown {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;

  color: $white;

  position: relative;
  left: 0;
  right: 0;
  z-index: 1;

  background: rgba(65, 64, 66, 0.8);
  border-radius: 0.375rem;
  top: 2.125rem;
  width: auto;
  padding-right: 0.25rem;
}

.episodes-dropdown__wrapper {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 14rem;
}

.episodes-dropdown__wrapper::-webkit-scrollbar {
  width: 0.188rem;
  height: 5.125rem;
}

.episodes-dropdown__wrapper::-webkit-scrollbar-thumb {
  background: $quick-silver;
  border-radius: 0.25rem;
}

.episode-item {
  display: flex;
  color: white;
  padding: 0.5rem 1rem 0 0.75rem;
  cursor: pointer;
  text-decoration: inherit;
  user-select: none;
  width: auto;
  height: 2rem;
}

.episode-item:hover {
  background: rgba(115, 115, 115, 0.5);
}

.episode-selected {
  background: rgba(115, 115, 115, 0.5);
}

.selected-episode {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: $white;
  padding: 0.5rem 0 0.5rem 0.3rem;
  height: 2rem;
  background: rgba(65, 64, 66, 0.6);
  border-radius: 0.375rem;
  border: 0;
  border-right: 0.5rem solid transparent;
  outline: 0;
  cursor: pointer;
  user-select: none;
  position: absolute;
  width: max-content;
  display: flex;
  justify-content: space-between;
}

.selected-episode__text {
  padding-left: 0.5rem;
}

.selected-episode__icon {
  padding-bottom: 0.375rem;
}

.episode-hidden {
  display: none;
}
</style>
