<template>
  <div class="episode-container row">
    <div class="thumbnail-container">
      <a :href="episode.episode_path">
        <img
          class="thumbnail"
          :alt="episode.title"
          loading="lazy"
          :src="episode.thumbnail"
        />
        <div :class="{'duration-with-offset': hasProgressBar}" class="duration">{{ episode.duration }}</div>
        <div v-if="hasProgressBar" class="progress-wrapper">
          <EpisodeProgressComponent :progressBar="episode.progress_bar" />
        </div>
      </a>

      <div class="text-content">
        <a class="title" :href="episode.episode_path">{{ episode.title }}</a>

        <div
          v-if="episode.speakers && episode.speakers.length > 0"
          class="speakers-short"
        >
          <div v-for="speaker in episode.speakers" :key="speaker">
            <img
              class="speaker-avatar"
              :alt="speaker.full_name"
              loading="lazy"
              :src="speaker.thumbnail_url"
            />
          </div>

          <span class="speaker-name">
            {{ speakerNames() }}
          </span>
        </div>

        <a
          v-if="episode.series"
          class="series-title"
          :href="episode.episode_path"
        >
          {{ `E${episode.sequence_number} | ${episode.series.title}` }}
        </a>
      </div>

      <SpecialEpisodeLabels :episode="episode" />
    </div>

    <div class="episode-notes-container col">
      <NotesList
        :is-locked="false"
        :reload-trigger="false"
        :theme="theme"
        :episode-id="episode.slug"
        :inline-edit="true"
        :notes-limit="notesToShow"
        @[EMITTERS.NOTE_DELETED_EMITTER]="noteDeleted"
      />
      <div
        v-if="!noteEditing && episode.notes.length > notesLimit"
        class="toggle-container"
      >
        <span v-if="!expanded" class="show-more" @click="showMore">
          Show more ({{ episode.notes.length - notesLimit }})
          <ArrowDownIcon :theme="theme" />
        </span>
        <span v-else class="show-less" @click="showLess">
          Show less ({{ notesLimit }})
          <ArrowUpIcon :theme="theme" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import NotesList from '../../study_tools/components/NotesList'
import ArrowDownIcon from '../../shared/components/icons/ArrowDown'
import ArrowUpIcon from '../../shared/components/icons/ArrowUp'
import SpecialEpisodeLabels from '../../shared/components/SpecialEpisodeLabels.vue'
import { THEMES } from '../../shared/utils/view_options'
import { EMITTERS } from '../../shared/utils/emitters'
import EpisodeProgressComponent from '../../shared/components/EpisodeProgress.vue'

export default {
  name: 'EpisodeComponent',
  components: {
    EpisodeProgressComponent,
    SpecialEpisodeLabels,
    ArrowUpIcon,
    ArrowDownIcon,
    NotesList,
  },
  props: {
    episode: {
      title: String,
      id: Number,
      meta_title: String,
      thumbnail: String,
      episode_path: String,
      speakers: Array,
      duration: String,
      sequence_number: Number,
      progress_bar: {
        percent: Number,
        start_time_seconds: Number,
      },
      series: {
        id: Number,
        title: String,
      },
    },
  },
  emits: [EMITTERS.NOTE_DELETED_EMITTER],
  data() {
    return {
      theme: THEMES.LIGHT,
      expanded: false,
      notesLimit: 2,
      EMITTERS,
    }
  },
  computed: {
    hasProgressBar() {
      return this.episode.progress_bar && this.episode.progress_bar.percent
    },
    notesToShow() {
      if (this.noteEditing) return null
      if (!this.expanded) return this.notesLimit

      return null
    },
    noteEditing() {
      if (!this.$store.state.note) return false
      if (!this.$store.state.note.id) return false

      return !!this.episode.notes.find(
        (note) => this.$store.state.note.id === note.id
      )
    },
  },
  methods: {
    showMore(_event) {
      this.expanded = true
    },
    showLess(_event) {
      this.expanded = false
    },
    speakerNames() {
      const firstSpeaker = this.episode.speakers[0].full_name
      const speakersCount = this.episode.speakers.length - 1
      if (this.episode.speakers.length === 1) return firstSpeaker

      return firstSpeaker + ' (+' + speakersCount + ')'
    },
    noteDeleted(notes, deletedNote) {
      this.$emit(EMITTERS.NOTE_DELETED_EMITTER, {
        episode: this.episode,
        notes: notes,
        deletedNote: deletedNote,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../shared/assets/styles/global';

.thumbnail-container {
  width: 180px;
  height: 90px;
  position: relative;

  .thumbnail {
    object-fit: cover;
  }

  .progress-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .duration {
    position: absolute;
    width: 69px;
    height: 24px;
    bottom: 0;
    right: 0;
    background-color: #1c1923;
    color: #ffffff;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    vertical-align: middle;
    font-weight: 500;
    opacity: 0.8;
  }
  .duration-with-offset {
    bottom: 4px;
  }

  img {
    width: 180px;
    height: 90px;
  }
}

.episode-container {
  width: 100%;
  min-height: 220px;
  background-color: #ffffff;
  margin-bottom: 16px;
  padding: 16px;
}

.text-content {
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 500;
}

.title,
.series-title {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 180px;
  white-space: nowrap;
}

.title {
  height: 18px;
  margin-top: 6px;
  font-size: 14px;
  color: #323232;
}

.series-title {
  margin-top: 2px;
  height: 18px;
  font-size: 14px;
  line-height: 18px;
  color: #5c5c5c;
}

.toggle-container {
  margin-top: 12px;
}

.show-more,
.show-less {
  cursor: pointer;
  background-color: #ffffff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #a6a6a6;
}

.episode-notes-container {
  padding-left: 24px;
  width: calc(100% - 180px);
}

.speakers-short {
  display: flex;

  .speaker-avatar {
    width: 16px !important;
    height: 16px !important;
    border: 0.5px solid #d1d3d4;
    border-radius: 50%;

    &:not(:first-child) {
      margin-left: -12px;
    }
  }

  .speaker-name {
    font-family: 'DM Sans', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #5c5c5c;
    overflow: hidden;
    margin-left: 6px;
    margin-top: 8px;
  }
}
</style>
