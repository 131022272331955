<template>
  <div class="ebook-card-block">
    <div class="ebook-card-link" :title="title">
      <img class="thumbnail" :alt="title" :src="coverUrl" />
      <span
        class="ebook-card-title"
        :class="{ 'ebook-card-title__light': isLight }"
      >
        {{ title }}
      </span>
    </div>
    <span
      v-if="author"
      class="card-description"
      :class="{ 'ebook-card-description__light': isLight }"
    >
      {{ author }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'EbookCard',
  props: {
    itemId: {
      required: true,
      type: Number,
    },
    title: {
      required: true,
      default: '',
      type: String,
    },
    author: {
      required: true,
      default: '',
      type: String,
    },
    coverUrl: {
      required: true,
      default: '',
      type: String,
    },
    searchQuery: {
      required: true,
      default: '',
      type: String,
    },
    settings: {
      required: false,
      default: () => ({
        watch_host: '',
        main_host: '',
        events_host: '',
      }),
      type: Object,
    },
    isLight: {
      required: true,
      default: true,
      type: Boolean,
    },
  },
  computed: {},
}
</script>

<style lang="scss" scoped>
.ebook-card-block {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  max-width: 260px;
  flex: 1;

  .thumbnail {
    height: 140px;
    width: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;', serif;
    margin-bottom: 8px;
  }
}

.ebook-card-title {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  font-family: 'DM Sans', sans-serif !important;
  max-width: 260px;
  display: flex;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ebook-card-title__light {
  color: #323232;
}

.card-description {
  margin-top: 4px;
  color: #a6a6a6;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.01em;
  font-family: 'DM Sans', sans-serif !important;
  white-space: break-spaces;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.ebook-card-description__light {
  color: #5c5c5c;
}

.ebook-card-link {
  color: inherit;
  white-space: break-spaces;
  cursor: pointer;
  line-height: 0;
}
</style>
