<template>
  <div class="bookmark-search-block">
    <input
      v-model="searchQuery"
      type="text"
      class="bookmark-search__input"
      :placeholder="
        $vueTranslate('study_tools.bookmarks_search.input_placeholder')
      "
    />
    <MagnifyingGlass class="bookmark-search__input-icon" />
  </div>
</template>

<script>
import MagnifyingGlass from '../../shared/components/icons/MagnifyingGlass'

export default {
  name: 'BookmarkSearch',
  components: { MagnifyingGlass },
  data() {
    return {
      loadedByWatch: false,
      searchQuery: '',
    }
  },
  watch: {
    searchQuery(newQuery) {
      this.$router.push({
        name: 'profile_bookmarks_list',
        query: { ...this.$route.query, query: newQuery },
      })
    },
    $route(newRoute) {
      const urlParams = (newRoute && newRoute.query) || this.$route.query
      const queryText = urlParams.query

      if (queryText) this.searchQuery = queryText

      this.loadedByWatch = true
    },
  },
  mounted() {
    this.checkRender()
  },
  methods: {
    checkRender() {
      setTimeout(() => {
        if (!this.loadedByWatch) {
          const queryText = this.$route.query && this.$route.query.query

          if (queryText) this.searchQuery = queryText
          this.loadedByWatch = false
        }
      }, 1500)
    },
  },
}
</script>

<style lang="scss">
.bookmark-search-block {
  display: flex;
  justify-content: start;
  align-items: start;
  position: relative;
  width: 360px;
}

.bookmark-search__input {
  display: flex;
  width: 100%;
  height: 32px;
  padding: 12px 36px 12px 12px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--primary-steel-blue-default, #6998ae);
  background: var(--backgrounds-white, #fff);
  color: var(--text-primary, #323232);
  outline-color: var(--primary-steel-blue-default, #6998ae);
  font-size: 14px;
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;

  &:placeholder-shown {
    border: 1px solid var(--Text-Disabled, #D1D3D4);
    outline-color: var(--Text-Disabled, #D1D3D4);
  }
}

.bookmark-search__input::placeholder {
  color: var(--text-input, #a6a6a6);
  font-size: 14px;
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.bookmark-search__input-icon {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 12px;
  top: 6px;
}
</style>
