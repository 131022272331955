<template>
  <Episodes />
</template>

<script>
import Episodes from './components/Episodes'

export default {
  name: 'ProfileAppComponent',
  components: {
    Episodes,
  },
}
</script>

<style lang="scss" scoped>
@import '../shared/assets/styles/global';
</style>
