import axios from 'axios'

import { API_ROUTES, BASE_API_CONFIG } from '../../shared/utils/api'

export default {
  getAllRecentlySearches(optional_params = {}) {
    return axios.request({
      ...BASE_API_CONFIG,
      method: 'GET',
      url: API_ROUTES.recent_searches,
      params: {
        ...optional_params,
      },
    })
  },
  deleteRecentlySearch(recent_search, optional_params = {}) {
    const required_params = {
      type: recent_search.type,
    }

    return axios.request({
      ...BASE_API_CONFIG,
      method: 'DELETE',
      url: API_ROUTES.recent_search(recent_search.id),
      params: {
        ...optional_params,
        ...required_params,
      },
    })
  },
  deleteAllRecentlySearch(optional_params = {}) {
    const required_params = {
      type: 'all',
    }

    return axios.request({
      ...BASE_API_CONFIG,
      method: 'DELETE',
      url: API_ROUTES.recent_search(-1),
      params: {
        ...optional_params,
        ...required_params,
      },
    })
  },
}
