<template>
  <div v-if="!readOnlyMode">
    <div class="images__create-container light">
      <div class="images__title">
        {{ $vueTranslate('admin.images.title') }}
      </div>
      <div class="images__container">
        <div class="images__create">
          <div class="images__secondary-title">{{ secondaryTitle() }}</div>
          <Image
            :key="currentImage.id"
            :mode="currentMode"
            :image="currentImage"
            @[EMITTERS.IMAGE_CREATED_EMITTER]="imageCreated"
            @[EMITTERS.IMAGE_UPDATED_EMITTER]="imageUpdated"
            @[EMITTERS.IMAGE_CANCEL_EMITTER]="imageUpdateCanceled"
          />
        </div>

        <div class="images__secondary-title">
          {{ $vueTranslate('admin.images.created_images_btn') }}
        </div>
        <div class="images__index">
          <Image
            v-for="image of images"
            :key="image.id"
            :active="activeImageId"
            :image="image"
            :mode="MODES.SHOW"
            :read-only-mode="readOnlyMode"
            @[EMITTERS.IMAGE_DELETED_EMITTER]="imageDeleted"
            @[EMITTERS.IMAGE_EDIT_EMITTER]="imageEdit"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div v-if="!loading" class="images__index">
      <Image
        v-for="image of images"
        :key="image.id"
        :active="activeImageId"
        :image="image"
        :mode="MODES.SHOW"
        :read-only-mode="readOnlyMode"
      />
    </div>
    <img
      v-else
      src="../../../shared/assets/images/loading.gif"
      alt="loading"
      class="documents-loader"
    />
  </div>
</template>

<script>
import Image from './Image'
import { VIDEO_RESOURCES_MODES as MODES } from '../../../shared/utils/view_options'
import ImagesApiService from '../services/imagesApi.service'
import { EMITTERS } from '../../../shared/utils/emitters'

const EMPTY_IMAGE = {
  id: null,
  description: '',
  image: null,
  url: null,
  alchemy_picture_id: null,
}

export default {
  name: 'ImagesComponent',
  components: {
    Image,
  },
  props: {
    readOnlyMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      MODES,
      EMITTERS,
      images: [],
      currentMode: MODES.CREATE,
      currentImage: EMPTY_IMAGE,
      activeImageId: 0,
      loading: true,
    }
  },
  created() {
    this.fetchImages()
  },
  methods: {
    fetchImages() {
      ImagesApiService.getAllImages(this.optional_params)
        .then((response) => {
          const responseData = response.data

          this.images = responseData.images
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false
        })
    },
    imageCreated() {
      this.fetchImages()
    },
    imageDeleted() {
      this.imageUpdateCanceled()
      this.fetchImages()
    },
    imageUpdated(image) {
      this.currentMode = MODES.CREATE
      const activeImage = this.images.find(
        (element) => element.id === this.activeImageId
      )
      Object.assign(activeImage, image)
      this.activeImageId = 0
    },
    imageEdit(image) {
      this.currentMode = MODES.EDIT
      this.currentImage = image
      this.activeImageId = image.id
    },
    imageUpdateCanceled() {
      this.currentMode = MODES.CREATE
      this.activeImageId = 0
      this.currentImage = EMPTY_IMAGE
    },
    secondaryTitle() {
      return this.currentMode === MODES.CREATE ? 'Add image' : 'Edit image'
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../shared/assets/styles/documents';

.images__container {
  background-color: #ffffff;
  width: 95%;
  min-height: 610px;
}

.images__title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #323232;
  margin-bottom: 16px;
}

.images__secondary-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #5c5c5c;
  padding-bottom: 10px;
}

.images__create {
  padding: 16px;
}

.images__index {
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
  padding: 22px 14px;
  justify-content: flex-start;
}

.images__create {
  border-bottom: 1px solid #eaedee;
}
@media (max-width: 1030px) {
  .images__index {
    gap: 17px;
  }
}

@media (max-width: 991px) {
  .images__index {
    padding: 0;
    padding-top: 10px;
    padding-left: 10px;
    gap: 10px;
  }
}
</style>
