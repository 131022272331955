<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
      :stroke="fillIcon()"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7 10L12 15L17 10"
      :stroke="fillIcon()"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 15V3"
      :stroke="fillIcon()"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
import { THEMES } from '../../utils/view_options'

export default {
  name: 'DownloadComponent',
  props: {
    theme: {
      required: true,
      type: String, // THEMES.DARK | THEMES.LIGHT,
      default: THEMES.DARK,
    },
  },
  methods: {
    fillIcon() {
      return this.theme === THEMES.DARK ? 'white' : '#8F8A9B'
    },
  },
}
</script>

<style scoped>
svg {
  cursor: pointer;
}
</style>
