<template>
  <div class="link__form-control">
    <input
      v-model="localLink.title"
      type="text"
      class="link__form-input"
      :maxlength="80"
      placeholder="Title"
      @keydown.enter.exact.prevent
      @keyup.enter.exact.prevent
      @keydown.enter.shift.exact.prevent
      @change="updateLink"
    />
    <span class="link__error">{{ errors.title.join() }}</span>
    <span class="link__counter"> {{ localLink.title.length }}/{{ 80 }} </span>
    <div v-if="isEditMode" class="link__delete" @click="deleteLink">
      <div class="link__delete-icon" />
    </div>
  </div>

  <div class="link__form-control link__link">
    <input
      v-model="localLink.link"
      type="text"
      class="link__form-input"
      placeholder="Link"
      @keydown.enter.exact.prevent
      @keyup.enter.exact.prevent
      @keydown.enter.shift.exact.prevent
      @change="updateLink"
    />
    <span class="link__error">{{ errors.link.join() }}</span>
  </div>

  <TextAreaInput
    v-if="isCreateMode"
    v-model:value="localLink.description"
    :max-length="800"
    placeholder="Description"
    :error="errors.description.join()"
  />

  <div class="link__form-button">
    <button v-if="isCreateMode" class="link__button" @click="submitForm">
      {{ $vueTranslate('admin.link.add_btn') }}
    </button>
  </div>
</template>

<script>
import LinksApiService from '../services/linksApi.service'
import { VIDEO_RESOURCES_MODES as MODES } from '../../../shared/utils/view_options'
import { EMITTERS } from '../../../shared/utils/emitters'
import TextAreaInput from './lib/TextAreaInput.vue'

const EMPTY_LINK = {
  id: null,
  title: '',
  description: '',
  link: '',
}
export default {
  name: 'LinkComponent',
  components: {
    TextAreaInput,
  },
  props: {
    link: {
      id: Number | null,
      title: String,
      link: Number,
      description: String,
    },
    mode: {
      type: String,
    },
  },
  emits: [EMITTERS.LINK_CREATED_EMITTER],
  data() {
    return {
      localLink: this.link || EMPTY_LINK,
      errors: {
        title: [],
        link: [],
        description: [],
      },
    }
  },
  computed: {
    isEditMode() {
      return this.mode === MODES.EDIT
    },
    isCreateMode() {
      return this.mode === MODES.CREATE
    },
  },
  methods: {
    updateLink() {
      if (this.isCreateMode) return
      LinksApiService.updateLink(this.localLink, this.optional_params).catch(
        (e) => {
          this.updateErrors(e)
        }
      )
    },
    createLink() {
      LinksApiService.createLink(this.localLink, this.optional_params)
        .then((response) => {
          this.$emit(EMITTERS.LINK_CREATED_EMITTER, response.data.link)
          this.clearForm()
          this.clearErrors()
        })
        .catch((e) => {
          this.updateErrors(e)
        })
    },
    deleteLink() {
      LinksApiService.deleteLink(this.localLink, this.optional_params)
        .then(() => {
          this.$emit(EMITTERS.LINK_DELETED_EMITTER)
        })
        .catch(console.error)
    },
    clearForm() {
      this.localLink.title = ''
      this.localLink.link = ''
      this.localLink.description = ''
      this.localLink.id = null
    },
    clearErrors() {
      this.errors.title = []
      this.errors.link = []
      this.errors.description = []
    },
    submitForm() {
      if (this.isCreateMode) this.createLink()
      if (this.isEditMode) this.updateLink()
    },
    updateErrors(e) {
      this.errors.title = e.response.data.errors.title || []
      this.errors.link = e.response.data.errors.link || []
      this.errors.description = e.response.data.errors.description || []
    },
  },
}
</script>

<style lang="scss" scoped>
.link__form-input {
  font-size: 14px;
  line-height: 18px;
  color: #323232;
  width: 100%;

  &::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #a6a6a6;
  }
}

.link__counter {
  font-size: 12px;
  line-height: 12px;
  text-align: right;
  color: #a6a6a6;
  font-family: 'DM Sans';
  font-style: normal;
}

.link__form-control {
  display: flex;
  width: 517px;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 6px;
}

.link__form-button {
  padding-top: 8px;
}

.link__button {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding: 3px 50px;
  background-color: #000000;
  color: #ffffff;

  &:disabled {
    background-color: #555555;
  }

  &:hover {
    box-shadow: 0 2px 3px rgba(23, 24, 28, 0.6);
  }
}

.link__link {
  padding-bottom: 8px;
}

.link__delete {
  position: absolute;
  right: -42px;
  top: 5px;
  padding: 12px 0;
  cursor: pointer;
}

.link__delete-icon {
  width: 19px;
  height: 2px;
  background: #5c5c5c;
}

.link__error {
  position: absolute;
  top: 36px;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #d35163;
}
</style>
