<template>
  <div class="selector-block">
    <VueMultiselect
      v-model="selectedResources"
      :options="options"
      :multiple="true"
      :close-on-select="true"
      class="custom-multiselect"
      :placeholder="
        $vueTranslate(
          'admin.featured_videos.resource_selector.search_placeholder'
        )
      "
      open-direction="bottom"
      :max-height="400"
      label="title"
      track-by="title"
      :max="15"
      :disabled="loading"
      @select="selectItem"
      @remove="removeItem"
    >
      <template #maxElements>
        {{
          $vueTranslate('admin.featured_videos.resource_selector.limit_message')
        }}
      </template>
    </VueMultiselect>
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect'

import featuredVideoApiService from '../services/featuredVideoApi.service'

export default {
  name: 'ResourceSelector',
  components: { VueMultiselect },
  props: {
    entityId: {
      type: Number,
      required: true,
      default: 0,
    },
    entityType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedResources: [],
      loading: true,
      options: [],
    }
  },
  mounted() {
    featuredVideoApiService
      .getFeaturedVideos()
      .then((response) => {
        this.options = response.data
      })
      .finally(() => {
        this.loading = false
      })

    if (this.entityId) {
      featuredVideoApiService
        .getSelectedFeaturedVideos(this.entityId, this.entityType)
        .then((response) => {
          this.selectedResources = response.data
        })
    }
  },
  methods: {
    selectItem(item) {
      this.loading = true
      featuredVideoApiService
        .addFeaturedVideo(item.type, item.id, this.entityId, this.entityType)
        .then((response) => {
          this.addFeaturedVideoId(
            item.id,
            item.type,
            response.data.featured_video_id
          )
        })
        .finally(() => {
          this.loading = false
        })
    },
    removeItem(item) {
      this.loading = true
      featuredVideoApiService
        .removeFeaturedVideo(item.featured_video_id, this.entityType)
        .finally(() => {
          this.loading = false
        })
    },
    addFeaturedVideoId(id, type, feautedVideoId) {
      const foundObject = this.selectedResources.find(
        (obj) => obj.id === id && obj.type === type
      )

      if (foundObject) foundObject['featured_video_id'] = feautedVideoId
    },
  },
}
</script>

<style lang="scss">
@import '../../../../../../../node_modules/vue-multiselect/dist/vue-multiselect.css';

.selector-block {
  margin-left: 8px;
  width: 965px;
}

.custom-multiselect {
  .multiselect__input {
    transition: none !important;
    border: none !important;
  }
  .multiselect__tag {
    background: #e4e4e4 !important;
    color: #333b51cc !important;

    .multiselect__tag-icon::after {
      color: #333b51cc !important;
    }
  }

  .multiselect__content-wrapper {
    max-height: 250px !important;
  }

  .multiselect__option--highlight {
    background: #214166 !important;
  }
}
</style>
