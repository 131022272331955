import 'url-search-params-polyfill'

import videojs from 'video.js'

import { videoJSPlayer } from 'video/player'
import { isTouchDevice } from 'utils'

import EpisodeMomentPreviewer from './episode_moment_preview'

import 'watch/middle_spacer'

const VIDEO_ELEMENT_ID = 'episode_player'
const VIDEOJS = videojs
const DEFAULT_PLAYER_CONTROLS = [
  'playToggle',
  'VolumePanel',
  'currentTimeDisplay',
  'timeDivider',
  'durationDisplay',
  // adding middleSpacer upon init because it's impossible to insert it
  // at the correct index right after player creation
  'middleSpacer',
  // Need to insert this spacer for the qualitySelector to be correctly positioned
  // (after the middleSpacer). It doesn't work without it, and I don't understand why.
  // Without it, the qualitySelector is being inserted at the wrong index.
  // The actual 'spacer' element is being put at the end of the elements sequence.
  'spacer',
  'PlaybackRateMenuButton',
  'qualitySelector',
  'FullscreenToggle',
  'progressControl',
]

const NEXT_EPISODE_TICKS = 8
let nextEpisodeInterval

function buildPlayerControls() {
  // Removing volume control on touch devices assuming they have hardware
  // or native volume controls.
  if (isTouchDevice()) {
    return DEFAULT_PLAYER_CONTROLS.filter((x) => x !== 'VolumePanel')
  }

  return DEFAULT_PLAYER_CONTROLS
}

function cloneVideoElement($videoElement) {
  const $videoElementClone = $videoElement.clone()
  // Replace id of original element to avoid having 2 elements with the same id
  $videoElement.attr('id', `${VIDEO_ELEMENT_ID}_original`)
  $videoElementClone.insertAfter($videoElement)
  $videoElement.hide()
}

function setTimeMark(player) {
  const search = new URLSearchParams(window.location.search)
  const seconds = parseInt(search.get('t'))

  if (isNaN(seconds)) {
    return moveToStoredProgress()
  }

  player.currentTime(seconds)
}

function storeCurrentProgress() {
  const player = VIDEOJS.getPlayer(VIDEO_ELEMENT_ID)
  if (!player) return undefined

  const timestamp = parseInt(player.currentTime())
  const authorization = localStorage.getItem('AD-Authorization')
  const episodeId = player.tagAttributes['episode-id']

  if (timestamp === 0) return undefined
  $.ajax({
    url: Routes.api_progress_bars_path(),
    type: 'POST',
    data: JSON.stringify({
      start_time_seconds: timestamp,
      episode_slug: episodeId,
    }),
    headers: {
      'AD-Authorization': authorization,
      Accept: 'application/json',
    },
    contentType: 'application/json',
    dataType: 'json',
    success: () => {},
    error: () => {},
  })
  return undefined
}

function moveToStoredProgress() {
  const player = videojs.getPlayer(VIDEO_ELEMENT_ID)
  if (!player) return

  let timestamp = player.tagAttributes['start-from']
  if (!timestamp) return

  timestamp = parseInt(timestamp)
  player.currentTime(timestamp)
}
function setupProgressTracking() {
  const player = videojs.getPlayer(VIDEO_ELEMENT_ID)
  if (!player) return

  player.on('timeupdate', (event) => {
    if (event.manuallyTriggered) {
      storeCurrentProgress()
    }
  })
  player.on('play', storeCurrentProgress)
  player.on('pause', storeCurrentProgress)
  setInterval(storeCurrentProgress, 60000)
  $('.search-form input').focus(storeCurrentProgress)
  $('a').click(storeCurrentProgress)
}

// We need to destroy VideoJS player before putting the page into Turbolinks
// cache. Otherwise, the player will be broken after navigating back
// to the page with back button of the browser. According to the VideoJS docs
// (https://docs.videojs.com/tutorial-player-workflows.html#removing-players)
// there's one way to destroy player -- by calling ".dispose()" on the player
// instance. However, "dispose" doesn't return DOM to its original state but
// removes related elements from DOM completely. But we need original state
// of DOM to be put into Turbolinks cache to make player work properly.
// To achieve that we create a copy of original video element, hide original
// and set up VideoJS player on its copy. Then we dispose copy and restore
// original element before caching the page by Turbolinks.

function setupEpisodePlayer() {
  const $videoElement = $(`#${VIDEO_ELEMENT_ID}`)

  // If length is falsy then element doesn't exist on page.
  if (!$videoElement.length) return

  cloneVideoElement($videoElement)

  // Set up VideoJS player on copied element
  const player = videoJSPlayer(VIDEO_ELEMENT_ID, {
    controlBar: {
      children: buildPlayerControls(),
    },
  })
  player.ready(() => setTimeMark(player))
}

function destroyEpisodePlayer() {
  const player = videojs.getPlayer(VIDEO_ELEMENT_ID)
  if (!player) return
  storeCurrentProgress()

  player.dispose()

  // Restore original element and its id.
  const $videoElement = $(`#${VIDEO_ELEMENT_ID}_original`)
  $videoElement.attr('id', VIDEO_ELEMENT_ID)
  $videoElement.show()
}

function scrollToBookmarks() {
  const player = videojs.getPlayer('episode_player')
  player.pause()

  if (!!window.studyToolsRouter) {
    window.studyToolsRouter.push('/bookmarks/new')
  }

  const bookmarksBlock = document.querySelector('#study-tools-app')

  if (bookmarksBlock != null) {
    const newBookmarkArea = bookmarksBlock.querySelector('.new-bookmark-area')
    bookmarksBlock.scrollIntoView({ behavior: 'smooth', block: 'end' })

    if (newBookmarkArea != null) {
      newBookmarkArea.focus()
    }
  } else {
    const bookmarksLoginBlock = document.querySelector(
      '#study-tools-login-block'
    )
    bookmarksLoginBlock.scrollIntoView({ behavior: 'smooth', block: 'end' })
  }
}

function changeTutorialStep(nextStep) {
  const authorization = localStorage.getItem('AD-Authorization')
  if (!authorization) return
  $.ajax({
    url: Routes.api_tutorial_path(),
    type: 'PUT',
    data: JSON.stringify({ user: { tutorial_step: nextStep } }),
    headers: {
      'AD-Authorization': authorization,
      Accept: 'application/json',
    },
    contentType: 'application/json',
    dataType: 'json',
    success: () => {
      $("[data-step='1']").remove()
    },
  })
}

function onCreateBookmarkClick() {
  closeNextEpisode()
  scrollToBookmarks()
  changeTutorialStep(2)
}

function configEmbeddedPlayer() {
  const $embedded = $('.embedded')
  if (!$embedded.length) return

  $embedded.find('.video-js').removeClass('vjs-fluid')
}

function closeNextEpisode() {
  if (!nextEpisodeInterval) return

  clearInterval(nextEpisodeInterval)
  $('.player-next-episode__container').hide()
}
function goToNextEpisode(container) {
  clearInterval(nextEpisodeInterval)
  history.pushState({}, null, container.attr('episode-link'))
  container.hide()
  location.reload()
}
function nextEpisodeTick() {
  const container = $('.player-next-episode__container')
  const counterSpan = container.find('.player-next-episode__counter')
  const counter = parseInt(counterSpan.text()) - 1
  if (counter < 0) {
    goToNextEpisode(container)
  } else {
    counterSpan.text(counter)
  }
}

function resizeNextEpisode() {
  const player = videojs.getPlayer('episode_player')
  const videoHeight = $(player.el()).find('video').height()
  $('.player-next-episode__background').height(videoHeight + 1)
}
function showNextEpisode() {
  const container = $('.player-next-episode__container')
  const player = videojs.getPlayer('episode_player')
  if (player.isFullscreen()) {
    player.exitFullscreen()
  }

  container.find('.player-next-episode__counter').text(NEXT_EPISODE_TICKS)
  resizeNextEpisode()
  $(window).resize(resizeNextEpisode)
  container.show()

  nextEpisodeInterval = setInterval(nextEpisodeTick, 1000)
  container.find('.cancel').click(() => {
    closeNextEpisode()
  })
  $('.bookmark-timestamp, .bookmark-title').click(() => {
    closeNextEpisode()
  })
  container.find('.play_now').click(() => {
    goToNextEpisode(container)
  })
}
function setupNextEpisode() {
  const player = videojs.getPlayer('episode_player')
  if (!player) return

  player.on('ended', showNextEpisode)
}

function autoplay() {
  const urlParams = new URLSearchParams(window.location.search)
  const play = urlParams.get('play')
  if (!play) return

  const player = videojs.getPlayer('episode_player')
  if (!player) return

  player.play().catch(() => {
    player.muted(true)
    player.play()
  })
}

function setupPreview() {
  const player = videojs.getPlayer('episode_player')
  if (!player) return
  const episodeId = $(player.el()).attr('episode-id')
  if (!episodeId || episodeId === '') return

  new EpisodeMomentPreviewer(VIDEO_ELEMENT_ID)
}

$(document).on('turbolinks:load', () => {
  const LAST_STEP = 10
  configEmbeddedPlayer()
  setupEpisodePlayer()
  if ($('#watch_online_streaming_player-2').length <= 0) {
    setupNextEpisode()
    setupProgressTracking()
    setupPreview()
  }
  autoplay()
  $('#createBookmarkButton')
    .not('.disabled-link')
    .on('click', onCreateBookmarkClick)
  $('#skipTutorial').on('click', () => changeTutorialStep(LAST_STEP + 1))
})
$(document).on('turbolinks:before-cache', destroyEpisodePlayer)
