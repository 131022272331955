<template>
  <div
    ref="transcriptRow"
    class="transcript-preview__row"
    :class="[maybeScroll(), theme]"
  >
    <div
      :class="theme"
      class="transcript-preview__timestamp"
      @click="moveToTimestamp(null)"
    >
      {{ timestamp() }}
    </div>
    <div>
      <span
        v-for="row in group.rows"
        :class="maybeHighlighted(row)"
        class="transcript-preview__row-text"
        @click="moveToTimestamp(row)"
      >
        {{ row.text }}
      </span>
    </div>
  </div>
</template>
<script>
import { EMITTERS } from '../../../shared/utils/emitters'
import {THEMES} from "../../../shared/utils/view_options"

export default {
  name: 'TranscriptPreviewComponent',
  props: {
    group: {
      type: Object,
    },
    autoscroll: {
      type: Boolean,
    },
    currentTime: {
      type: Number,
    },
    player: {
      type: Object,
    },
    theme: {
      type: String,
      default: THEMES.LIGHT,
    },
  },
  data() {
    return {
      active: false,
      EMITTERS,
      THEMES,
    }
  },
  methods: {
    isCurrentTranscript(row) {
      return row.startAt <= this.currentTime && this.currentTime <= row.endAt
    },
    isCurrentGroup() {
      return (
        this.group.startAt <= this.currentTime &&
        this.currentTime <= this.group.endAt
      )
    },
    maybeHighlighted(row) {
      return this.isCurrentTranscript(row) ? 'highlight' : ''
    },
    maybeScroll() {
      if (this.isCurrentGroup())
        this.$emit(EMITTERS.TRANSCRIPT_CURRENT, this.group.id)

      if (this.isCurrentGroup() && !this.active) {
        this.active = true
        if (this.autoscroll) this.scrollToGroup()
        return 'active'
      } else {
        this.active = this.isCurrentGroup()
        return ''
      }
    },
    targetPosition() {
      const baseOffset = this.$parent.$refs.transcriptContainer.offsetTop
      const groupOffset = this.$refs.transcriptRow.offsetTop
      const targetPosition = groupOffset - baseOffset + 10

      return targetPosition
    },
    scrollToGroup() {
      if (this.$refs.transcriptRow === undefined) return

      this.$emit(EMITTERS.TRANSCRIPT_AUTOSCROLL, this.targetPosition())
    },
    moveToTimestamp(row) {
      if (!this.player) return

      this.player.play()

      if (!!row) {
        this.player.currentTime(row.startAt)
      } else {
        this.player.currentTime(this.group.startAt)
      }
      this.$emit(EMITTERS.TRANSCRIPT_AUTOSCROLL, this.targetPosition())
    },
    timestamp() {
      const timestampSeconds = parseInt(this.group.startAt)
      if (!timestampSeconds) return '00:00:00'
      return new Date(timestampSeconds * 1000).toISOString().slice(-13, -5)
    },
  },
}
</script>

<style scoped lang="scss">
.highlight {
  background-color: rgba(202, 164, 137, 0.5);
  border-radius: 4px;
  transition-duration: 300ms;
}
.transcript-preview__row {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex-wrap: nowrap;
  padding-top: 15px;

  &.light {
    &:last-child {
      min-height: 136px;
      margin-bottom: 320px;
    }
  }

  &.dark {
    &:last-child {
      min-height: 136px;
      margin-bottom: 650px;
    }
  }
}

.transcript-preview__timestamp {
  cursor: pointer;
  width: 90px;
  height: 20px;
  border-radius: 25px;
  color: #5c5c5c;
  border: 1px solid #d1d3d4;
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  line-height: 20px;

  &.dark {
    color: #D1D3D4;

    border: 1px solid #444050;
  }
}

.transcript-preview__row-text {
  position: relative;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  width: auto;
  padding: 1px 3px;
  cursor: pointer;
  transition-duration: 300ms;

  &:hover {
    background-color: rgba(202, 164, 137, 0.35);
    border-radius: 4px;
    transition-duration: 300ms;
  }

  &:first-child {
    margin-left: -3px;
  }
}
</style>
