import axios from 'axios'

import { API_ROUTES, BASE_API_CONFIG } from '../../shared/utils/api'

export default {
  getHistory(params = {}) {
    return axios.request({
      ...BASE_API_CONFIG,
      method: 'GET',
      url: API_ROUTES.watch_history,
      params: params,
    })
  },
  deleteHistoryItem(item, params = {}) {
    return axios.request({
      ...BASE_API_CONFIG,
      method: 'DELETE',
      url: API_ROUTES.watch_history_item(item.id),
      params: params,
    })
  },
}
