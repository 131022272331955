<template>
  <svg
    width="19"
    height="2"
    viewBox="0 0 19 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="19" height="2" fill="white" />
  </svg>
</template>

<script>
export default {
  name: 'MinusIcon',
}
</script>

<style scoped>
svg {
  cursor: pointer;
}
</style>
