import axios from 'axios'

import { API_ROUTES, BASE_API_CONFIG } from '../../../shared/utils/api'

export default {
  getFeaturedVideos() {
    return axios.request({
      ...BASE_API_CONFIG,
      method: 'GET',
      url: API_ROUTES.admin_featured_videos,
    })
  },
  getSelectedFeaturedVideos(entity_id, entity_type) {
    return axios.request({
      ...BASE_API_CONFIG,
      method: 'GET',
      url: API_ROUTES.admin_featured_video(entity_id),
      params: { entity_type: entity_type },
    })
  },
  addFeaturedVideo(resource_type, resource_id, entity_id, entity_type) {
    const config = {
      featured_video: {
        resource_type,
        resource_id,
        entity_id,
        entity_type,
      },
    }

    return axios.request({
      ...BASE_API_CONFIG,
      method: 'POST',
      data: config,
      url: API_ROUTES.admin_featured_videos,
    })
  },
  removeFeaturedVideo(id, entity_type) {
    return axios.request({
      ...BASE_API_CONFIG,
      method: 'DELETE',
      url: API_ROUTES.admin_featured_video(id),
      params: { entity_type: entity_type },
    })
  },
}
