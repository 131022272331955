<template>
  <svg
    v-if="!active"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M2.71099 22.875H4.9767C5.85126 22.875 6.5627 22.1636 6.5627 21.2891V20.9574C7.933 21.6552 11.0452 22.875 16.1068 22.875H17.5568C19.6576 22.875 21.4411 21.3724 21.7973 19.3026L22.8123 13.4119C23.028 12.155 22.6809 10.8772 21.8617 9.90384C21.0433 8.93234 19.8434 8.375 18.5718 8.375H14.461C14.7174 7.68625 14.9739 6.68213 14.9739 5.42969C14.9739 2.02491 13.0471 1.125 12.0285 1.125C10.147 1.125 9.98935 2.8795 9.98935 4.52344C9.98935 6.52987 7.73905 8.19737 6.55092 8.93869C6.49019 8.11944 5.81139 7.46875 4.9767 7.46875H2.71099C1.83643 7.46875 1.125 8.18016 1.125 9.05469V21.2891C1.125 22.1636 1.83643 22.875 2.71099 22.875ZM11.3488 4.52344C11.3488 2.48438 11.6941 2.48438 12.0285 2.48438C12.6665 2.48438 13.6145 3.26919 13.6145 5.42969C13.6145 7.43794 12.8278 8.66862 12.8224 8.67769C12.6837 8.88612 12.6701 9.15438 12.7889 9.3755C12.9067 9.59663 13.1378 9.73438 13.3879 9.73438H18.5718C19.4419 9.73438 20.2621 10.115 20.8221 10.7793C21.3831 11.4445 21.6206 12.3199 21.4729 13.1799L20.4578 19.0706C20.214 20.4861 18.9942 21.5147 17.5568 21.5147H16.1068C9.94132 21.5156 6.85271 19.5871 6.5627 19.3959V10.5056C7.31763 10.105 11.3488 7.81222 11.3488 4.52344ZM2.48442 9.05469C2.48442 8.92963 2.58593 8.82812 2.71099 8.82812H4.9767C5.10177 8.82812 5.20327 8.92963 5.20327 9.05469V21.2891C5.20327 21.4123 5.09996 21.5156 4.9767 21.5156H2.71099C2.58593 21.5156 2.48442 21.4141 2.48442 21.2891V9.05469Z"
      fill="white"
    />
  </svg>
  <svg
    v-else
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.279 4.3C11.279 1.96667 11.7457 1.5 12.679 1.5C13.6123 1.5 15.7123 2.43333 15.7123 5.23333C15.7123 7.56667 15.0123 8.96667 15.0123 8.96667H19.4177C21.7323 8.96667 23.487 11.048 23.095 13.3347L22.0497 19.4013C21.7417 21.1933 20.1923 22.5 18.3723 22.5H16.879C12.2357 22.5 9.24433 21.4752 7.75006 20.7836V9.22147C9.13139 8.30213 11.279 6.5176 11.279 4.3ZM4.71667 22.2667H2.38333C1.48267 22.2667 0.75 21.534 0.75 20.6334V9.90003C0.75 8.99936 1.48267 8.26669 2.38333 8.26669H4.71667C5.61733 8.26669 6.35 8.99936 6.35 9.90003V20.6334C6.35 21.534 5.61733 22.2667 4.71667 22.2667Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'LikeIcon',
  props: {
    active: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
}
</script>

<style scoped>
svg {
  cursor: pointer;
}
</style>
