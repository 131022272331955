import { scrollToTarget } from 'utils'

$(document).on('click', '.btn_hero_scroll_down', function () {
  scrollToTarget($(this).data('scrollTarget'))
})

// click on hero-trending-content-button to scroll to the trending content section
$(document).on('click', '.hero-trending-content-button', function () {
  const selector = $(this).data('scrollTarget')

  if ($(selector).length === 0) {
    return scrollToTarget($(this).data('scrollTargetFallback'))
  }
  scrollToTarget(selector)
})
