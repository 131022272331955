// eslint-disable-next-line import/order
import videojs from 'video.js'

import 'videojs-youtube' // side-effect import to register Youtube tech
import qualityLevels from 'videojs-contrib-quality-levels'
import hlsQualitySelector from 'videojs-hls-quality-selector'
import seekButtons from 'videojs-seek-buttons'

import createTouchOverlayClass from 'vendor/videojs-mobile-ui/touchOverlay'
import mobileUi from 'vendor/videojs-mobile-ui/plugin'

const ALLOWED_QUALITY_LEVELS = [360, 540, 720, 1080, 1440, 2160]
const SEEK_SECONDS = 15

const DEFAULT_VIDEOJS_PLAYER_CONFIG = {
  // HLS quality selector doesn't work on platform with
  // native HLS support (i.e. Safari).
  // We have to drop native HLS support to make it work.
  // However, it won't work on mobile Safari anyway
  language: 'en',
  html5: {
    vhs: {
      overrideNative: true,
      // Fix audio-only playback when initial playlist has high bandwidth
      // options only.
      // More here: https://github.com/videojs/http-streaming/issues/175
      experimentalBufferBasedABR: true,
    },
    nativeAudioTracks: false,
    nativeVideoTracks: false,
    nativeTextTracks: false, // disable subtitles and captions controls
  },
  bigPlayButton: true,
  textTrackSettings: false, // disable subtitles and captions controls
  plugins: {
    hlsQualitySelector: {
      displayCurrentQuality: true,
    },
    mobileUi: {
      touchControls: {
        seekSeconds: SEEK_SECONDS,
      },
    },
  },
  playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
}

const videoJSPlayer = (elementId, options = {}) => {
  const config = { ...DEFAULT_VIDEOJS_PLAYER_CONFIG, ...options }

  const player = videojs(elementId, config)
  const playerQualityLevels = player.qualityLevels()

  playerQualityLevels.on('addqualitylevel', (event) => {
    const qualityLevel = event.qualityLevel

    // Remove broken and inappropriate quality levels from the list of quality levels
    if (
      hasDisabled(qualityLevel.id) ||
      !qualityLevel.height ||
      !ALLOWED_QUALITY_LEVELS.includes(qualityLevel.height)
    ) {
      playerQualityLevels.removeQualityLevel(qualityLevel)
    }
  })

  player.seekButtons({
    forward: SEEK_SECONDS,
    back: SEEK_SECONDS,
  })

  player.mobileUi()

  return player
}

function hasDisabled(id) {
  return ['1', '3', '6'].some((word) => id.startsWith(word))
}

// We have to register plugins directly due to video.js <-> plugins binding problems in bundlers
// like Webpack. All plugins ever used should be added to this registration method.
// Some insights: https://github.com/videojs/videojs-contrib-quality-levels/issues/90
const registerVideoJSPlugins = () => {
  videojs.registerPlugin('qualityLevels', qualityLevels)
  videojs.registerPlugin('hlsQualitySelector', hlsQualitySelector)
  videojs.registerPlugin('seekButtons', seekButtons)
  videojs.addLanguage('en', {
    'Seek forward {{seconds}} seconds': 'forward {{seconds}} seconds',
    'Seek back {{seconds}} seconds': 'backward {{seconds}} seconds',
  })
  const TouchOverlay = createTouchOverlayClass(videojs)
  const Component = videojs.getComponent('Component')
  Component.registerComponent('TouchOverlay', TouchOverlay) // must be before 'mobileUi' plugin registration
  videojs.registerPlugin('mobileUi', mobileUi)
}

export { videoJSPlayer, registerVideoJSPlugins }
