import axios from 'axios'

import { API_ROUTES, BASE_API_CONFIG } from '../../shared/utils/api'

function getEpisodeId() {
  const episode_ids = new URL(document.location.href).pathname.match(/[^/]+/g)
  return episode_ids.slice(-1)
}

export default {
  getComments(optional_params) {
    const required_params = {
      episode_id: getEpisodeId(),
    }

    return axios.request({
      ...BASE_API_CONFIG,
      method: 'GET',
      url: API_ROUTES.comments,
      params: {
        ...optional_params,
        ...required_params,
      },
    })
  },
  getReportReasons(optional_params) {
    return axios.request({
      ...BASE_API_CONFIG,
      method: 'GET',
      url: API_ROUTES.report_reasons,
      params: {
        ...optional_params,
      },
    })
  },
  reportComment(comment_id, report_reason_id) {
    const required_params = {
      report_reason_id: report_reason_id,
    }

    const data = {
      ...required_params,
    }

    return axios.request({
      ...BASE_API_CONFIG,
      method: 'POST',
      url: API_ROUTES.comment_reports(comment_id),
      data,
    })
  },
  createComment(comment, optional_params = {}) {
    const required_params = {
      comment: comment,
      episode_id: getEpisodeId(),
    }

    const data = {
      ...optional_params,
      ...required_params,
    }

    return axios.request({
      ...BASE_API_CONFIG,
      method: 'POST',
      url: API_ROUTES.comments,
      data,
    })
  },
  updateComment(comment, optional_params = {}) {
    return axios.request({
      ...BASE_API_CONFIG,
      method: 'PUT',
      url: API_ROUTES.comment(comment.id),
      data: {
        comment,
        ...optional_params,
      },
    })
  },
  deleteComment(comment, optional_params = {}) {
    return axios.request({
      ...BASE_API_CONFIG,
      method: 'DELETE',
      url: API_ROUTES.comment(comment.id),
      params: optional_params,
    })
  },
}
