$(document).on('turbolinks:load', () => {
  const sections = document.querySelectorAll('.watch-category-section-wrapper')

  sections.forEach((section) => {
    $.ajax({
      url: section.dataset.url,
      type: 'GET',
      success: function (response) {
        $(section).html(response)
        $(document).trigger('watch-section:load')
      },
    })
  })
})
