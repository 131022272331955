document.addEventListener('turbolinks:load', function () {
  const $bookmarksToggleButton = $('.sidebar_bookmarks_toggle')
  if ($bookmarksToggleButton.length === 0) return

  $bookmarksToggleButton.on('click', function () {
    $('.sidebar-bookmarks').find('.nav-item-options').toggleClass('active')
    $bookmarksToggleButton.find('.feather-chevron-up').toggleClass('active')
    $bookmarksToggleButton.find('.feather-chevron-down').toggleClass('active')
  })
})
