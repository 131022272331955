<template>
  <div>
    <div class="ebooks-tab">
      <EbookCard
        v-for="ebook in entries"
        :key="ebook.id"
        :item-id="ebook.id"
        :title="ebook.title"
        :author="ebook.author"
        :cover-url="ebook.cover_url"
        :settings="settings"
        :search-query="query"
        :is-light="isLight"
        @click="showEbook(ebook)"
      />
    </div>
    <PreviewDialog
      v-if="currentEbook"
      :theme="theme"
      :entity="currentEbook"
      @[EMITTERS.CLOSE_DIALOG_EMITTER]="closePreview"
    />
  </div>
</template>

<script>
import EbookCard from './EbookCard'
import { THEMES as THEME } from '../../shared/utils/view_options'
import PreviewDialog from '../../admin/video_resources/components/PreviewDialog'
import { EMITTERS } from '../../shared/utils/emitters'

export default {
  name: 'EbookTab',
  components: { EbookCard, PreviewDialog },
  props: {
    entries: {
      required: true,
      type: Array,
      default: () => [],
    },
    settings: {
      required: true,
      default: () => ({
        watch_host: '',
        main_host: '',
        events_host: '',
      }),
      type: Object,
    },
    query: {
      required: true,
      default: '',
      type: String,
    },
    isLight: {
      default: false,
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      EMITTERS,
      currentEbook: null,
    }
  },
  computed: {
    theme() {
      return this.isLight ? THEME.LIGHT : THEME.DARK
    },
  },
  methods: {
    showEbook(ebook) {
      this.currentEbook = ebook
    },
    closePreview() {
      this.currentEbook = null
    },
  },
}
</script>

<style lang="scss" scoped>
.ebooks-tab {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;

  @media (max-width: 463.98px) {
    justify-content: center;
  }
}
</style>
