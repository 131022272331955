<template>
  <div class="disclaimer" ref="disclaimer" />
</template>
<script>
export default {
  name: 'DisclaimerComponent',
  props: {
    disclaimer: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.appendDisclaimerHtml(this.disclaimer)
  },
  methods: {
    appendDisclaimerHtml() {
      this.$refs.disclaimer.innerHTML = this.disclaimer
    },
  },
}
</script>
<style scoped lang="scss">
.disclaimer {
  color: #5C5C5C;
  margin-top: 24px;
  overflow: hidden;
  width: 100%;
}
</style>
