<template>
  <div class="store-item-card-block">
    <div class="store-item-card-link" :title="title">
      <div class="action-buttons">
        <div class="btn btn-border btn-br-top" @click="destroyItem">
          <MinusIcon />
        </div>
        <div class="btn btn-br-bottom" @click="reloadItem">
          <ReloadIcon />
        </div>

        <LoaderComponent v-if="loading" class="loading" />
      </div>
      <img
        class="thumbnail"
        :alt="title"
        :class="{ 'no-image': isThumbnailEmpty() }"
        :src="isThumbnailEmpty() ? NO_IMAGE : coverUrl"
        @error="handleImageError"
      />
      <span
        class="store-item-card-title"
        :class="{ 'store-item-card-title__light': isLight }"
      >
        {{ title }}
      </span>
    </div>
    <span
      v-if="author"
      class="card-description"
      :class="{ 'store-item-card-description__light': isLight }"
    >
      {{ author }}
    </span>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'

import ReloadIcon from '../../../shared/components/icons/ReloadIcon'
import MinusIcon from '../../../shared/components/icons/MinusIcon'
import { EMITTERS } from '../../../shared/utils/emitters'
import storeItemsApiService from '../services/storeItemsApi.service'
import LoaderComponent from '../../../shared/components/Loader'

export default {
  name: 'StoreItemCard',
  components: { LoaderComponent, MinusIcon, ReloadIcon },
  props: {
    itemId: {
      required: true,
      type: Number,
    },
    sku: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      default: '',
      type: String,
    },
    author: {
      required: true,
      default: '',
      type: String,
    },
    coverUrl: {
      required: true,
      default: '',
      type: String,
    },
    isLight: {
      required: true,
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      NO_IMAGE: window.NO_IMAGE_FALLBACK,
    }
  },
  methods: {
    handleImageError(event) {
      event.target.src = this.NO_IMAGE
      event.target.style.objectFit = 'contain'
    },
    isThumbnailEmpty() {
      return isEmpty(this.coverUrl)
    },
    reloadItem() {
      this.loading = true
      storeItemsApiService
        .sync(this.sku)
        .then((response) => {
          if (response.data && response.data.error === 'no_item_found') {
            this.emitter.emit(EMITTERS.SHOW_ERROR_NOTIFICATION_EMITTER, {
              message: 'Sync failed. No remote items found by provided SKU',
            })
            return
          }

          this.emitter.emit(EMITTERS.SHOW_NOTIFICATION_EMITTER, {
            message: 'Item successfully synchronized',
          })
          this.$emit(EMITTERS.STORE_ITEM_RELOAD, this.sku, response.data)
        })
        .finally(() => {
          this.loading = false
        })
    },
    destroyItem() {
      this.loading = true
      storeItemsApiService
        .destroy(this.itemId, this.optional_params)
        .finally(() => {
          this.loading = false
          this.emitter.emit(EMITTERS.SHOW_NOTIFICATION_EMITTER, {
            message: 'Item successfully deleted',
          })

          this.$emit(EMITTERS.STORE_ITEM_DESTROY, this.sku)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.store-item-card-block {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  max-width: 240px;
  flex: 1;

  .thumbnail {
    height: 140px;
    width: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;', serif;
    margin-bottom: 8px;
  }
}

.store-item-card-title {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  font-family: 'DM Sans', sans-serif !important;
  width: 100%;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.store-item-card-title__light {
  color: #323232;
}

.card-description {
  margin-top: 4px;
  color: #a6a6a6;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.01em;
  font-family: 'DM Sans', sans-serif !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.store-item-card-description__light {
  color: #5c5c5c;
}

.store-item-card-link {
  color: inherit;
  white-space: break-spaces;
  line-height: 0;

  .action-buttons {
    position: absolute;
    right: 0;
    top: -2px;
    display: flex;
    flex-direction: column;
    width: 24px;
    height: 50px;
    border-radius: 4px;
    justify-content: end;

    .loading {
      position: absolute;
      top: 50px;
      right: 100px;
      opacity: 1;
    }

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      background-color: #cbcbcbcc;
      cursor: pointer;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    .btn-border {
      border-bottom: 1px solid white;
    }

    .btn-br-top {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    .btn-br-bottom {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

.no-image {
  object-fit: contain !important;
}
</style>
