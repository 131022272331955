<template>
  <div class="drag-drop-container">
    <div
      v-if="!attachment.url"
      class="drag-drop-area"
      @dragover.prevent
      @click="openImageDialog"
    >
      <div class="file-info">
        <div class="upload-icon"></div>
        <span class="file-info__text">{{ dragAndDropTitle() }}</span>
      </div>
    </div>
    <div
      v-else
      class="image__thumbnail-container"
      @dragover.prevent
      @click="openImageDialog"
    >
      <img :src="attachment.url" class="image__thumbnail" />
    </div>
  </div>
  <input
    :id="alchemyPictureInputId()"
    ref="fileInput"
    type="hidden"
    @change="handleFileInputChange"
  />
</template>

<script>
import { EMITTERS } from '../../../shared/utils/emitters'

export default {
  name: 'DragDropSingleFileUploader',
  props: {
    attachment: {
      id: Number | null,
      description: String,
      alchemy_picture_id: Number | null,
      image: File | null,
      url: String | null,
    },
  },
  emits: [EMITTERS.IMAGE_SELECTED_EMITTER],
  data() {
    return {
      EMITTERS,
    }
  },
  mounted() {
    window.addEventListener(
      'alchemyPictureIdChanged',
      this.handleFileInputChange
    )
  },
  methods: {
    handleFileInputChange(event) {
      if (
        !this.$refs.fileInput ||
        !event.detail[0] ||
        this.$refs.fileInput.id !== event.detail[0].id
      ) {
        return
      }

      const alchemyPictureId = event.detail.val()
      this.$emit(EMITTERS.IMAGE_SELECTED_EMITTER, alchemyPictureId)
    },
    openImageDialog() {
      Alchemy.openDialog(this.alchemyPicturesUrl(), this.modalOptions())
    },
    dragAndDropTitle() {
      return this.attachment.image == null
        ? this.$vueTranslate('admin.alchemy_picture_select.choose_file_btn')
        : this.attachment.image.name
    },
    alchemyPicturesUrl() {
      return `/admin/pictures?form_field_id=${this.alchemyPictureInputId()}`
    },
    alchemyPictureInputId() {
      return `alchemy_picture_id_attachment_${this.attachment.id}`
    },
    modalOptions() {
      return {
        title: 'Insert image',
        size: '790x590',
        padding: false,
      }
    },
  },
}
</script>

<style scoped>
.drag-drop-container {
  display: flex;
  flex-direction: column;
}

.drag-drop-area {
  background-image: url("data:image/svg+xml,%3Csvg width='133' height='133' viewBox='0 0 137 137' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='133' height='133' rx='6' stroke='%23A1A1A1' stroke-linecap='round' stroke-dasharray='5 7'/%3E%3C/svg%3E%0A");
  cursor: pointer;
  width: 133px;
  height: 133px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-info {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 295px;
  padding: 9px;
  border-radius: 5px;
  overflow: hidden;
  flex-direction: column;
  text-align: center;
}

.file-info__text {
  max-width: 235px;
  max-height: 69px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  overflow: hidden;
  text-overflow: ellipsis;

  color: #a1a1a1;
}

.upload-icon {
  width: 22px;
  height: 17px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='17' viewBox='0 0 22 17' fill='none'%3E%3Cpath d='M18.7928 10.625V13.4583C18.7928 13.8341 18.6042 14.1944 18.2686 14.4601C17.933 14.7257 17.4779 14.875 17.0033 14.875H4.47697C4.00238 14.875 3.54722 14.7257 3.21162 14.4601C2.87603 14.1944 2.6875 13.8341 2.6875 13.4583V10.625' stroke='%23A1A1A1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6.26562 7.08594L10.7393 10.6276L15.213 7.08594' stroke='%23A1A1A1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10.7344 10.625V2.125' stroke='%23A1A1A1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.image__thumbnail {
  object-fit: cover;
  width: 133px;
  height: 133px;
  border-radius: 4px;
  cursor: pointer;
}
</style>
